const InitialStates = {


    loading: false,
    loginResponse: [],
  
    pdfCustomsResponse:null,
    PdfNonHazardousResponse:null,
    PdfBLInstructionResponse:null
  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

      case "customsPdf_success":
        return { ...state, 'pdfCustomsResponse': action.payload,'loading': false };
       case "nonHazardousPdf_success":
          return { ...state, 'PdfNonHazardousResponse': action.payload,'loading': false };
          case "bLInstructionPdf_success":
            return { ...state, 'PdfBLInstructionResponse': action.payload,'loading': false };
      default:
        return state;
    }
  };
  