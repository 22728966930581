const InitialStates = {
    BuyerNumbersList:null,
  
    currentPage: 1,
   
    showBuyerNumberModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    BuyerNumbersList:null,
    BuyerNumberRegisterErrors:null,
    BuyerNumberUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showBuyerNumberModal":
      return { ...state, 'showWarModal':true };

      case "hideBuyerNumberModal":
        return { ...state, 'showWarModal':false, 'BuyerNumberRegisterErrors':'', 'name':'' };
  

        case "showEditBuyerNumberModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditBuyerNumberModal":
            return { ...state, 'showEditWarModal':false, 'BuyerNumberUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getBuyerNumber_started":
              return {...state, 'loading':true}
    case "registerBuyerNumber_started":
              return {...state, 'loading':true}

              // case "BuyerNumberEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "BuyerNumberUpdate_started":
              //     return {...state, 'loading':true}
// =============ends here==========================         

       case "getBuyerNumbersSettings_success":

         return { ...state, 'BuyerNumbersList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerBuyerNumber_success":
    return { ...state, 'registerBuyerNumberSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "BuyerNumberEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "BuyerNumberUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "BuyerNumberDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerBuyerNumber_failure":
    return { ...state, 'BuyerNumberRegisterErrors': action.payload,  'loading':false };

case "BuyerNumberUpdate_failure":
  return { ...state, 'BuyerNumberUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   