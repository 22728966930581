import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function PreDataLoader(props) {
  const loaderStyle={
position:'absolute',
zIndex:'9999',
left:props.left,
right:props.right,
top:props.top,
bottom:props.bottom,

  }
  return (
      <>
      <div className="loader" style={{backgroundColor:'#094274', display:'flex',justifyContent:'center',alignItems:'center', minHeight:'100vh'}}>
    <div style={{zIndex:'99999px'}}>
    <Spinner animation="grow"  style={{width:props.width,height:props.height,color:props.backgroundColor}}>
    <span style={{display:'flex',justifyContent:'center',alignItems:'center',color:'red',marginTop:'13px'}}>
    
    <i className="fas fa-car" style={{color:props.iconColor, fontSize:'18px'}}></i> 

    </span>
    </Spinner>

  </div>

</div>
  </>
  )
}

export default PreDataLoader;