export function calculateTotal(data, key) {
    return data?.reduce((acc, item) => {
      const value = parseFloat(item[key]);
  
      if (!isNaN(value)) {
        return acc + value;
      }
  
      return acc;
    }, 0);
  }
  


  export const calculateSum = (data,splitBy) => {
    return data.reduce((total, currentItem) => {
      const number = parseInt(currentItem.label?.split(splitBy)[1]);
  
      return total + number;
    }, 0);
  };
  