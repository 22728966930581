import React from 'react'
import '../../../css/balances.css'
function Balances() {
  return (
    <div>


<div className="content">
    
   

      <div className="table-responsive custom-table-responsive">

        <table className="table custom-table">
          <thead>
            <tr>  

              <th scope="col">
                <label className="control control--checkbox">
                  <input type="checkbox"  className="js-check-all"/>
                  {/* <div className="control__indicator"></div> */}
                </label>
              </th>
              
              <th scope="col">Order</th>
              <th scope="col">Name</th>
              <th scope="col">Occupation</th>
              <th scope="col">Contact</th>
              <th scope="col">Education</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row">
              <th scope="row">
                <label className="control control--checkbox">
                  <input type="checkbox"/>
                  {/* <div className="control__indicator"></div> */}
                </label>
              </th>
              <td>
                1392
              </td>
              <td><a href="#">James Yates</a></td>
              <td>
                Web Designer
                <small className="d-block">Far far away, behind the word mountains</small>
              </td>
              <td>+63 983 0962 971</td>
              <td>NY University</td>
            </tr>
            <tr className="spacer"><td colspan="100"></td></tr>
            
            
          </tbody>
        </table>
      </div>




  </div>
    

    </div>
  )
}

export default Balances