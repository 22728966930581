import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing Agreement settings model
// -------------------------------------------------------------------------
export const hideAgreementModal=()=>{
    return {
      type:'hideAgreementModal'
    }
    
    }
    export const showAgreementModal=()=>{
  
      return {
        type:'showAgreementModal'
      }
      
      }
  
  
      export const showEditAgreementModal=()=>{
        return {
          type:'showEditAgreementModal'
        }
        
        }
      
        export const hideEditAgreementModal=()=>{
          return {
            type:'hideEditAgreementModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all Agreements for settings
// ---------------------------------------------------------------------------------------------------------

export const getAgreements = (currentPage) => {



    return (dispatch) => {
        dispatch(getAgreementStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/Agreements?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getAgreementSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getAgreementFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getAgreementStarted = () => ({
      type: "getAgreement_started",
    });
    
    
    const getAgreementSuccess = (data,currentPage) => ({
      type: "getAgreementsSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getAgreementFailure = (error) => ({
      type: "getAgreement_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// to get terms and conditions guest page
// ---------------------------------------------------------------------------------------------------------

export const getAgreementMainPage = () => {



  return (dispatch) => {
      dispatch(getAgreementMainPageStarted());
  
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
 
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/agreementData`,axiosConfig)
        .then((response) => {
          console.log(response)
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getAgreementMainPageSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getAgreementMainPageFailure(err.response.data.errors));

        });
    };
  };
  
  const getAgreementMainPageStarted = () => ({
    type: "getAgreementMainPage_started",
  });
  
  
  const getAgreementMainPageSuccess = (data) => ({
    type: "getAgreementsMainPageSettings_success",
    payload: {
      ...data,
  },
  });
  
  const getAgreementMainPageFailure = (error) => ({
    type: "getAgreemenMainPage_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerAgreement = (data) => {
  
  return (dispatch) => {
    dispatch(registerAgreementStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let AgreementData={
     title:data.title,
     body:data.body,

    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/AgreementRegister`, AgreementData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerAgreementSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Agreement has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerAgreementFailure(err.response.data.errors));
     
      });
  };
};

const registerAgreementStarted = () => ({
  type: "registerAgreement_started",
});

const registerAgreementSuccess = (data) => ({
  type: "registerAgreement_success",
  payload: {
    ...data,
  },
});

const registerAgreementFailure = (error) => ({
  type: "registerAgreement_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the Agreement edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const AgreementEdit = (id) => {

  return (dispatch) => {
    dispatch(AgreementEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editAgreement/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AgreementEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AgreementEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const AgreementEditDetailsStarted = () => ({
  type: "AgreementEditDetails_started",
});

const AgreementEditDetailsSuccess = (data) => ({
  type: "AgreementEditDetails_success",
  payload: {
    ...data,
  },
});

const AgreementEditDetailsFailure = (error) => ({
  type: "AgreementEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the Agreement update 
// ---------------------------------------------------------------------------------------------------------

export const AgreementUpdate = (data,currentPage) => {
    return (dispatch) => {
      dispatch(AgreementUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      console.log( data?.body)
      console.log( data?.title)

      let agreementData={
        title:data?.title,
        body:data?.body,

       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateAgreement/`+data?.onId,agreementData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(AgreementUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Agreement has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(AgreementUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const AgreementUpdateStarted = () => ({
    type: "AgreementUpdate_started",
  });
  
  
  const AgreementUpdateSuccess = (data,currentPage) => ({
    type: "AgreementUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const AgreementUpdateFailure = (error) => ({
    type: "AgreementUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// Agreements Deletion
// ---------------------------------------------------------------------------------------------------------

export const AgreementDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(AgreementDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAgreement/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(AgreementDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Agreement has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(AgreementDeletionFailure(err.message));
      });
  };
};

const AgreementDeletionStarted = () => ({
  type: "AgreementDeletion_started",
});

const AgreementDeletionSuccess = (data,currentPage) => ({
  type: "AgreementDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const AgreementDeletionFailure = (error) => ({
  type: "AgreementDeletion_failure",
  payload: {
    error,
  },
});
