import React, { useState, useEffect } from "react";

import { Outlet,NavLink,Link,useLocation,useParams,useMatch,useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {can} from '../../../utils/roleUtils';
import CloseButton from "react-bootstrap/esm/CloseButton";
import ContainerImagesCarousel from '../ContainerImagesCarousel'
import Swal from "sweetalert2";
import { goBack } from "../../../utils/historyUtils";
import {
  getContainerDetails,
  downloadImagesContainerZip,
  getChargesContainersRows,
} from "../../../../redux/actions";
import SimpleCourousel from "../../../elements/SimpleCourousel";
import {calculateTotal} from '../../../utils/chargesUtils';
import moment from 'moment';



const ContainerView = (props) => {
  const location = useLocation();
  const [imagesModal, setImagesModal] = useState(false);

  const [isTabActive, setIsTabActive] = useState(false);
  const [id, setId] = useState('');
  const [onId, setOnId] = useState(null);

  const params = useParams();
  const {t}=useTranslation();

  const match = useMatch('/home/containerView/:id');

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;
  useEffect(() => {
    setIsTabActive(match && match.params.id !== undefined);
  }, [match]);


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.getContainerDetails(decryptValue);
 props.getChargesContainersRows(decryptValue);

 }
 },[])
 


 const downloadZipImages=(id)=>{


  Swal.fire({
    title: "Are you sure?",
    text: "You want to download images of container",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, download it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.downloadImagesContainerZip(id);
    }
  });
  

}
 const navigate=  useNavigate();



const viewAuto=(id)=>{
  navigate( `/home/autoView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListGhulam_123'))}`);
}



const totalCost = calculateTotal(props.getContainerChargesRows?.data, 'cost');
  const totalQuantity = calculateTotal(props.getContainerChargesRows?.data, 'quantity');
  const totalAmount = calculateTotal(props.getContainerChargesRows?.data, 'total');


const containerDetails = props.containerDetailsResponse;

  return (
    <div className="container">
    <div className="main-body">
    
     <div className="back-button">
         <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i>
</a> 

     </div>
    
          <div className="gutters-sm side-row">
            <div className=" fixed-sidebar-container mb-3">
              <div className="card card-custom">
                <div className="card-container">
                  <div className="align-items-center text-center">
                 
       { containerDetails?
        
<SimpleCourousel imagesLocation={process.env.REACT_APP_CONTAINER_IMAGES} images={containerDetails?.images} width={500} height={500}  dragEnable={false} play={false}/>:null
}
                    
                  </div>
                </div>
              </div>



  


<div className="card card-custom scroller mt-2 langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
<div className="card-body">
<ul className="list-group  list-group-flush">
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-info"/></span><span className="header-font">Container No</span></h6>
                    <span className="text-sub-head">{containerDetails?.containerInfo?.container_no}</span>
                  </li>
              { 
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-info"/></span><span className="header-font">Booking No</span></h6>
                    <span className="text-sub-head">{containerDetails?.containerInfo?.cont_booking_no}</span>
                  </li>
}
                <li className=" d-flex justify-content-between align-items-center flex-wrap mt-3 border-top p-2">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-check"/></span><span className="header-font">Process Status</span></h6>
                    <span className="process-status">{containerDetails?.containerInfo?.cont_catagory?.replace(/-/g,' ')?.toUpperCase()}</span>
                  </li>

                </ul>



                {can(currentUserPermissions,'Download Container Images Zip')?<button className="btn mt-2" onClick={()=>downloadZipImages(containerDetails?.containerInfo?.id)}>
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-download"/></span><span className="header-font">Download Images</span></h6>
                  </button>:null}


</div>
</div>



<div className="container py-4">
        
        <div className="row">
            <div className="col-md-12">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
             
                <NavLink to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListAli_123'))}`} end replace className={ (isTabActive ? 'nav-link  shadow active' : 'nav-link  shadow')}  aria-selected="true">
                        <i className="fa fa-truck"></i>
                        <span className="font-weight-bold small text-uppercase">Container information</span></NavLink>
{ can(currentUserPermissions,'Allow Individual Container Log')?

  <NavLink to="container-logs" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} >
                        <i className="fa fa-history mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Logs</span></NavLink>:null}
                   
                    </div>
            </div>


            
        </div>
    </div>


<div className="card card-custom scroller mt-2 langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
<div className="card-body">
<h6 className="mb-2"><span >Container Vehicles</span></h6>

<ul className="list-group  list-group-flush">
{containerDetails?.autosInContainer.map((vehicle,index)=>{
  return(
    <div key={index}>
   <hr/>
    <li onClick={()=>viewAuto(vehicle.autoId)} className=" d-flex justify-content-between align-items-center flex-wrap" style={{cursor:'pointer'}} key={index}>
    <h6 className="mb-0"><span className="p-2"><i className="fa fa-car"/></span><span className="header-font">{vehicle.vin}</span></h6>
    <span className="text-sub-head">LOT: {vehicle.lot}</span>
    <span className="text-sub-head">Vehicle by: {vehicle.userName}</span>

  </li>
  <hr/>

  </div>
  )
})}
             
          
                </ul>





</div>
</div>




            
            </div>

{/* sidebar ends here */}


            <div className=" scroller  overflow-auto-container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>

      

<Outlet/>

              </div>

              

            </div>
          </div>



        </div>
  );
};



const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    containerDetailsResponse,
    getContainerChargesRows
  } = state.ContainerHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    containerDetailsResponse:containerDetailsResponse,
    getContainerChargesRows:getContainerChargesRows
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
  
    getContainerDetails:(id)=>dispatch(getContainerDetails(id)),
    downloadImagesContainerZip:(id)=>dispatch(downloadImagesContainerZip(id)),
    getChargesContainersRows:(id)=>dispatch(getChargesContainersRows(id)),

  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ContainerView);
