const InitialStates = {
    AgreementsList:null,
  
    currentPage: 1,
   
    showAgreementModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    AgreementsList:null,
    AgreementRegisterErrors:null,
    AgreementUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showAgreementModal":
      return { ...state, 'showWarModal':true };

      case "hideAgreementModal":
        return { ...state, 'showWarModal':false, 'AgreementRegisterErrors':'', 'name':'' };
  

        case "showEditAgreementModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditAgreementModal":
            return { ...state, 'showEditWarModal':false, 'AgreementUpdateErrors':'','name':'' };

// =======================================
// for loading purposes
// =======================================
            case "getAgreement_started":
              return {...state, 'loading':true}
    case "registerAgreement_started":
              return {...state, 'loading':true}

              // case "AgreementEditDetails_started":
              //   return {...state, 'loading':true}

                // case "AgreementUpdate_started":
                //   return {...state, 'loading':true}

                
// ==============ends here=========================

       case "getAgreementsSettings_success":

         return { ...state, 'AgreementsList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       case "getAgreementsMainPageSettings_success":
       return { ...state, 'termConditions': action.payload, 'loading':false };

     case "registerAgreement_success":
    return { ...state, 'registerAgreementSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "AgreementEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "AgreementUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "AgreementDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerAgreement_failure":
    return { ...state, 'AgreementRegisterErrors': action.payload,  'loading':false };

case "AgreementUpdate_failure":
  return { ...state, 'AgreementUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   