import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");



export const toggleVendorMakePaymentModal=()=>{
  return {
    type:"toggleModalMakePayment"
  }
  
  }



export const togglevendorCompanyModal=()=>{
  return {
    type:"toggleModalAction"
  }
  
  }

  export const togglevendorCompanyEditModal=()=>{
    return {
      type:"toggleModalEditAction"
    }
    
    }

    export const toggleVendorModal=()=>{
      return {
        type:"toggleVendorModal"
      }
      
      }


      export const toggleVendorEditModal=()=>{
        return {
          type:"toggleVendorEditModal"
        }
        
        }
  


// ---------------------------------------------------------------------------------------------------------
// vendors List in the system with pagination Action - the authenticated vendor would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------


export const vendorsList = (pageNumber) => {
    return (dispatch) => {
      dispatch(fetchvendorsListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/vendorslist?page=${pageNumber}`, axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(fetchvendorsListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(fetchvendorsListFailure(err.message));
        });
    };
  };
  
  const fetchvendorsListStarted = () => ({
    type: "fetch_vendors_started",
  });
  
  const fetchvendorsListSuccess = (data) => ({
    type: "fetch_vendors_success",
    payload: {
      ...data,
    },
  });
  
  const fetchvendorsListFailure = (error) => ({
    type: "fetch_vendors_failure",
    payload: {
      error,
    },
  });
  





// ---------------------------------------------------------------------------------------------------------
// the vendor edit function will only fetch the data of a single vendor 
// ---------------------------------------------------------------------------------------------------------

export const vendorEdit = (id) => {

  return (dispatch) => {
    dispatch(vendorEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetailsVendor/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(vendorEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(vendorEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const vendorEditDetailsStarted = () => ({
  type: "vendorEditDetails_started",
});

const vendorEditDetailsSuccess = (data) => ({
  type: "vendorEditDetails_success",
  payload: {
    ...data,
  },
});

const vendorEditDetailsFailure = (error) => ({
  type: "vendorEditDetails_failure",
  payload: {
    error,
  },
});













// -------------------------------------------------------------------------
// for opening registration model
// -------------------------------------------------------------------------
export const hideVendorUpdateModal=()=>{
  return {
    type:'hideModalAction'
  }
  
  }






// ---------------------------------------------------------------------------------------------------------
// the vendor update function will only fetch the data of a single vendor 
// ---------------------------------------------------------------------------------------------------------

export const vendorUpdate = (inputData,currentPage) => {
//console.log(name)
  return (dispatch) => {
    dispatch(vendorUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    
    let vendorData={
      type:inputData.type,
      company_name:inputData.company_name,
      contact_name:inputData.contact_name,
      email:inputData.email,
      contact:inputData.contact,
      secondary_contact:inputData.secondary_contact,
      address:inputData.address,
      city:inputData.city,
      state:inputData.state,
      zip_code:inputData.zip_code,
      suite:inputData.suite,
      type:inputData.type?.value,
      country:inputData.country?.value,
    
     }



    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateVendor/`+inputData.id,vendorData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(vendorUpdateSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The vendor has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(vendorUpdateFailure(err.response.data.errors));
     
      });
  };
};

const vendorUpdateStarted = () => ({
  type: "vendorUpdate_started",
});


const vendorUpdateSuccess = (data,currentPage) => ({
  type: "vendorUpdate_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const vendorUpdateFailure = (error) => ({
  type: "vendorUpdate_failure",
  payload: {
    error,
  }, 
});






// ---------------------------------------------------------------------------------------------------------
// the vendor edit function will only fetch the data of a single vendor 
// ---------------------------------------------------------------------------------------------------------

export const vendorCompanyEdit = (id) => {

  return (dispatch) => {
    dispatch(vendorCompanyEditStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetailsvendorCompany/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(vendorCompanyEditSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(vendorCompanyEditFailure(err.response.data.errors));
     
      });
  };
};

const vendorCompanyEditStarted = () => ({
  type: "vendorCompanyEdit_started",
});

const vendorCompanyEditSuccess = (data) => ({
  type: "vendorCompanyEdit_success",
  payload: {
    ...data,
  },
});

const vendorCompanyEditFailure = (error) => ({
  type: "vendorCompanyEdit_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// for unpaid and paid vehicles
// ---------------------------------------------------------------------------------------------------------

export const perVendorVehicles = (id,pageNumber,status) => {

  return (dispatch) => {
    dispatch(pervendorVehiclesUnpaidStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/autoListPerVendor/${id}/${status}?page=${pageNumber}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(pervendorVehiclesUnpaidSuccess(response.data,pageNumber));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(pervendorVehiclesUnpaidFailure(err.response.data.errors));
     
      });
  };
};

const pervendorVehiclesUnpaidStarted = () => ({
  type: "perVendorVehiclesUnpaid_started",
});

const pervendorVehiclesUnpaidSuccess = (data,currentPage) => ({
  type: "perVendorVehiclesUnpaid_success",
  payload: {
    ...data,
    'currentPage':currentPage
    },
});

const pervendorVehiclesUnpaidFailure = (error) => ({
  type: "perVendorVehiclesUnpaid_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// for unpaid, and paid containers
// ---------------------------------------------------------------------------------------------------------

export const perVendorContainers = (id,pageNumber,status,containerCatagory) => {

  return (dispatch) => {
    dispatch(pervendorContainersStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerListPerVendor/${id}/${status}/${containerCatagory}?page=${pageNumber}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(pervendorContainersSuccess(response.data,pageNumber));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(pervendorContainersFailure(err.response.data.errors));
     
      });
  };
};

const pervendorContainersStarted = () => ({
  type: "perVendorContainers_started",
});

const pervendorContainersSuccess = (data,currentPage) => ({
  type: "perVendorContainers_success",
  payload: {
    ...data,
    'currentPage':currentPage
    },
});

const pervendorContainersFailure = (error) => ({
  type: "perVendorContainers_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// for unpaid, and paid containers
// ---------------------------------------------------------------------------------------------------------

export const perVendorContainersPayment = (id,pageNumber,vendorCatagory) => {

  return (dispatch) => {
    dispatch(pervendorContainersPaymentStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/vendorsBasedContainerPayment/${id}/${vendorCatagory}?page=${pageNumber}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(pervendorContainersPaymentSuccess(response.data,pageNumber));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(pervendorContainersPaymentFailure(err.response.data.errors));
     
      });
  };
};

const pervendorContainersPaymentStarted = () => ({
  type: "perVendorContainersPayment_started",
});

const pervendorContainersPaymentSuccess = (data,currentPage) => ({
  type: "perVendorContainersPayment_success",
  payload: {
    ...data,
    'currentPage':currentPage
    },
});

const pervendorContainersPaymentFailure = (error) => ({
  type: "perVendorContainersPayment_failure",
  payload: {
    error,
  },
});






export const addVendorDetails = (inputData) => {
  //console.log(name)
    return (dispatch) => {
      dispatch(addVendorDetailsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let vendorCompanyData={
        type:inputData.type,
        company_name:inputData.company_name,
        contact_name:inputData.contact_name,
        email:inputData.email,
        contact:inputData.contact,
        secondary_contact:inputData.secondary_contact,
        address:inputData.address,
        city:inputData.city,
        state:inputData.state,
        zip_code:inputData.zip_code,
        suite:inputData.suite,
        type:inputData.type?.value,
        country:inputData.country?.value,
      
       }
  
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/addVendor`,vendorCompanyData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(addVendorDetailsSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The vendor details has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(addVendorDetailsFailure(err.response.data.errors));
       
        });
    };
  };
  
  const addVendorDetailsStarted = () => ({
    type: "addVendorDetails_started",
  });
  
  
  const addVendorDetailsSuccess = (data) => ({
    type: "addVendorDetails_success",
    payload: {
      ...data,
    },
  });
  
  const addVendorDetailsFailure = (error) => ({
    type: "addVendorDetails_failure",
    payload: {
      error,
    }, 
  });
  
  
  
  
  








// ---------------------------------------------------------------------------------------------------------
// vendors deletion in the system with pagination Action - the authenticated vendor would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const vendorDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(vendorDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteVendor/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(vendorDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The vendor has been successfully deleted',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(vendorDeletionFailure(err.message));
      });
  };
};

const vendorDeletionStarted = () => ({
  type: "vendorDeletion_started",
});

const vendorDeletionSuccess = (data,currentPage) => ({
  type: "vendorDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const vendorDeletionFailure = (error) => ({
  type: "vendorDeletion_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// for unpaid, and paid containers
// ---------------------------------------------------------------------------------------------------------

export const storePayment = (vendorID,data,vendorCatagory) => {

  return (dispatch) => {
    dispatch(StorePaymentStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    const paymentData = new FormData();

    const ids= data?.payment_for?.map((list,index)=>{
      const ids=[];
      ids.push(list.value);
      return ids;
  
    })
    let date= data?.date? moment(data?.date).format('YYYY-MM-DD'):'';

    paymentData.append("vendorId",vendorID)
    paymentData.append("ids",ids);
    paymentData.append('date',date);
    paymentData.append("check", data?.check||'');
    paymentData.append("invoice", data?.invoice||'');
    paymentData.append("paid_by", data?.paid_by||'');
    paymentData.append("amount", data?.amount||'');
    paymentData.append("note", data?.note||'');
    paymentData.append("payment_type", data?.payment_type?.value||null);
    paymentData.append("vendorCatagory",vendorCatagory);

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/paymentDataStore`,paymentData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
         

          dispatch(StorePaymentSuccess(response.data));
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The payment has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(StorePaymentFailure(err.response.data.errors));
     
      });
  };
};

const StorePaymentStarted = () => ({
  type: "StorePayment_started",
});

const StorePaymentSuccess = (data) => ({
  type: "StorePayment_success",
  payload: {
    ...data,
    },
});

const StorePaymentFailure = (error) => ({
  type: "StorePayment_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// delete payment
// ---------------------------------------------------------------------------------------------------------

export const paymentDelete = (id,vendorCatagory) => {

  return (dispatch) => {
    dispatch(paymentDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/paymentDelete/${id}/${vendorCatagory}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(paymentDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The vendor has been successfully deleted',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(paymentDeletionFailure(err.message));
      });
  };
};

const paymentDeletionStarted = () => ({
  type: "paymentDeletion_started",
});

const paymentDeletionSuccess = (data) => ({
  type: "paymentDeletion_success",
  payload: {
    ...data,
  },
});

const paymentDeletionFailure = (error) => ({
  type: "paymentDeletion_failure",
  payload: {
    error,
  },
});


