import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { LoginAuth } from "../../../redux/actions";
import Loader from "../../elements/Loader";
import { useTranslation } from 'react-i18next'


function LoginInfo(props) {

  const {t} = useTranslation();

    const eyeIcon = {
        position: "absolute",
        cursor: "pointer",
        left: "102%",
        top: "50px",
        zIndex: "100px",
        color:'#094274'
      };
    


    const [showPassword, setShowPassword] = useState("password");
    const [credentials, setCredentials] = useState({username:'', password:''});
  



    const onInputchange = (e) => {
        // props.inputHandler({ key: e.target.name, value: e.target.value });
        setCredentials({...credentials, [e.target.name]: e.target.value });
      
    
      };
    
    const onSignIn=(e)=>{
      e.preventDefault();
      if(credentials.username!="" && credentials.password!=""){
        props.login(credentials);
      }
    
    }
    
    
    
      const ShowPasswordToggle = () => {
        if (showPassword == "password") {
          setShowPassword("text");
        } else {
          setShowPassword("password");
        }
      };
    





  return (
    <>


<div className="login-wrap p-4 p-md-5">

{props.loading ? (
                  <div className="LoginLoader"></div>
          ) : (
            ""
          )}
              

          <div className="d-flex langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                      <div className="w-100">
                        <h3 className="mb-4">{t('key_sign_in')}</h3>
                      </div>
                      {/* <div className="w-100">
									<p className="social-media d-flex justify-content-end">
										<a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-facebook"></span></a>
										<a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-twitter"></span></a>
									</p>
								</div> */}
                    </div>
                    <form onSubmit={(e) => { onSignIn(e); }} className="signin-form langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                      <div className="form-group mb-3">
                        <label className="label" htmlFor="name">
                          {t('key_username')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder= {t('key_username')}
                          name="username"
                          value={props.username}
                          autoComplete='off'

                          onChange={(e) => {
                            onInputchange(e);
                          }}
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="label" htmlFor="password">
                        {t('key_password')}

                        </label>
                        <input
                          type={showPassword}
                          className="form-control"
                          placeholder= {t('key_password')}
                          autoComplete="true"
                          name="password"
                          onChange={(e) => {
                            onInputchange(e);
                          }}
                      

                          required={true}
                        />
                        <div onClick={ShowPasswordToggle}>
                          {/* <i class="fa fa-eye-slash" id="togglePassword" style={eyeIcon}></i> */}
                          <i
                            className={showPassword=='text'?"fa fa-eye-slash":"fa fa-eye"}
                            id="togglePassword"
                            style={eyeIcon}
                          ></i>
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="form-control btn  rounded px-3"
              
                        >
                          {t('key_sign_in')}
                        </button>
                      </div>
                      <div className="form-group d-md-flex">
                        {/* <div className="w-50 text-left">
                          <label className="checkbox-wrap checkbox-primary mb-0">
                            Remember Me
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </div> */}
                        <div className="w-50 text-md-right">
                          {/* <a href="#">Forgot Password</a> */}
                        </div>
                      </div>
                    </form>
                    {/* <p className="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p> */}
                  </div>


    </>
  )
}


const mapStateToProps = (state) => {
    const { loading, loginResponse, isAuthenticated } = state.authenticationHandler;
   
    return {
      loading:loading,
      loginResponse:loginResponse,
      isAuthenticated:isAuthenticated,
  
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      login: (credentials) =>
        dispatch(LoginAuth(credentials)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo)