import React, { useState, useEffect } from "react";
import '../../../../css/profile.css';
import { Outlet,NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import { goBack } from "../../../utils/historyUtils";

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import {
  userDelete,
  addDetails,
  companyEdit,
  toggleCompanyModal,
} from "../../../../redux/actions";




const UserProfile = (props) => {
  const location = useLocation();
  const [isTabActive, setIsTabActive] = useState(false);
  const [id, setId] = useState('');

  const params = useParams();
  const {t}=useTranslation();

  const match = useMatch('/home/userProfile/:id');

  useEffect(() => {
    setIsTabActive(match && match.params.id !== undefined);
  }, [match]);


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.companyEdit(decryptValue);
 }
 },[])
 




  return (
    <div className="container">
    <div className="main-body">
    
     <div className="back-button">
         <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i>
</a> 

     </div>
    
          <div className="gutters-sm side-row">
            <div className=" fixed-sidebar mb-3">
              <div className="card card-custom">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src={require('../../../assets/male.jpg')} alt="Admin" className="rounded-circle" width="150"/>
                    <div className="mt-3">
                      <h6>{props.companyEditInfo?.username?.toUpperCase()}</h6>
                      <p className="text-sub-head mb-1">{props.companyEditInfo?.company_name?.toUpperCase()}</p>
                    <span className="icon-frame"><i className="fa fa-phone ml-1"/></span>  <span className="text-sub-head mb-1">{props.companyEditInfo?.contact}</span>

                      {/* <p className="text-muted custom-text font-size-sm">Bay Area, San Francisco, CA</p> */}
                      {/* <button className="btn">Follow</button>
                      <button className="btn ">Message</button> */}
                    </div>
                  </div>
                </div>
              </div>



  <div className="container py-4">
        
        <div className="row">
            <div className="col-md-12">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <NavLink to={`/home/userProfile/${encodeURIComponent(CryptoAES.encrypt(id?.toString(),'autoListAli_123'))}`} end replace className={ (isTabActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i className="fa fa-user-circle-o mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Personal information</span></NavLink>

  <NavLink to="consignees" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i className="fa fa-calendar-minus-o mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Consignees</span></NavLink>

                    <NavLink to="vehicles" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')}  id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i className="fa fa-car mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Vehicles</span></NavLink>

                  

                    <NavLink to="containers" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i className="fa fa-truck mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Containers</span>
                   </NavLink>

                   
                    </div>
            </div>


            
        </div>
    </div>


<div className="card card-custom scroller">
<div className="card-body">
<ul className="list-group  list-group-flush">
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-envelope ml-1"/></span><span className="header-font">Primary Email</span></h6>
                    <span className="text-sub-head">{props.companyEditInfo?.email?.toUpperCase()}</span>
                  </li>
              { props.companyEditInfo?.secondary_email?
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-envelope ml-1"/></span><span className="header-font">Secondary Email</span></h6>
                    <span className="text-sub-head">{props.companyEditInfo?.secondary_email?.toUpperCase()}</span>
                  </li>:null
}
{ props.companyEditInfo?.other_email ?
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-envelope ml-1"/></span><span className="header-font">Other Email</span></h6>
                    <span className="text-sub-head">{props.companyEditInfo?.other_email?.toUpperCase()}</span>
                  </li>:null
}
               
                </ul>

</div>
</div>

            
            </div>
            <div className=" scroller  overflow-auto-cont">
               
              <Outlet/>
              
              </div>

              

            </div>
          </div>

        </div>
  );
};



const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    showCompanyModal,
    companyEditInfo,
  } = state.userManagementHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    showCompanyModal:showCompanyModal,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    companyEditInfo:companyEditInfo?.data,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),
    addDetails:(inputData)=>dispatch(addDetails(inputData)),
    companyEdit:(id)=>dispatch(companyEdit(id)),
    toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(UserProfile);
