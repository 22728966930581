import React,{useEffect,useState} from 'react'
import '../../../css/communication.css'
import { connect } from "react-redux";
import NodataFound from '../error/noDataFoundMain';
import Checkbox from '../../elements/Checkbox';
import Swal from "sweetalert2";
import moment from "moment";
import 'moment-timezone';
import { useTranslation } from 'react-i18next'
import {can} from '../../utils/roleUtils';
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import _ from "lodash";
import {
    getAutoCommunication,
    AutoCommunication,
    editSelectedAutoNotes,
    deleteSelectedAutoNotes,
    showEditNoteAutoModal,
    hideEditNoteAutoModal,
    getDataEditAutoNote,
    autosList,
  } from "../../../redux/actions";
function AutosCommunication(props) {

    const [noteBody,setNoteBody]= new useState('')
    const [editNoteBody,setEditNoteBody]= new useState('')
    const [attachment,setAttachment]= new useState({fileName:'',file:null})
    const [editAttachment,setEditAttachment]= new useState({fileName:'',file:null})

    const [onId,setOnId]= new useState('')


    const [isCheck, setIsCheck] = useState([]);

useEffect(()=>{
  setEditNoteBody(props.getEditAutoNoteInfo?.noteInfo?.body)

 props.getAutoCommunication(props.AutoId);

if(props.registerNoteResponse||props.deletedSelectedNotes){
  props.autosList(props.currentPage)
}

if(props.textAreaValue=='' ){
  setNoteBody('');
  setIsCheck([]);
  setAttachment({'fileName':'','file':null})
}
if( props.attachmentValue==''){
  setEditAttachment({'fileName':'','file':null});

}
},[props.registerNoteResponse,props.deletedSelectedNotes,props.editedSelectedNote,props.getEditAutoNoteInfo])


const {t}=useTranslation();


const onSubmitForm=(e)=>{
e.preventDefault();
    props.AutoCommunication(props.AutoId,noteBody,attachment.file);
}


const onEditForm=(e)=>{
  e.preventDefault();
      props.editSelectedAutoNotes(onId,editNoteBody,editAttachment.file)
  }
  

const handleTextAreaEdit=(e)=>{
  setEditNoteBody(e.target.value);
}

const handleTextArea=(e)=>{
  setNoteBody(e.target.value);
  }



  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

const handleClick = e => {
    const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
   
  };
  

  const deleteAutoNotes=()=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the selected notes",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteSelectedAutoNotes(isCheck,props.AutoId);
        // setIsCheck([])
      }
    });
  
  }
  
  const handleClose=()=>{

    props.hideEditNoteAutoModal()
  }

  const handleAttachment=(e)=>{
    if(e.target?.files[0]?.name==undefined || e.target?.files[0]==undefined ){
      setAttachment({'fileName':'', 'file':null})

    }else{
    setAttachment({'fileName':e.target?.files[0]?.name, 'file':e.target?.files[0]})
    }
  }


  const handleEditAttachment=(e)=>{
    if(e.target?.files[0]?.name==undefined || e.target?.files[0]==undefined ){
      setEditAttachment({'fileName':'', 'file':null})

    }else{
      setEditAttachment({'fileName':e.target?.files[0]?.name, 'file':e.target?.files[0]})
    }
  }

  return (
    <div>
    { isCheck.length>0?
        <div className="headerButton">
        <button className="btnDelet" onClick={()=>deleteAutoNotes()}>
            {t('key_delete_note')} ({isCheck.length})
        </button>
        </div>:''
    }


{props.getAutoListCommunication?.notes.length>0 ?props.getAutoListCommunication?.notes?.map((list,index)=>{


    return(
        <div key={index}>
      
        <div  className={list.by==process.env.REACT_APP_SUPER_ADMIN || list.by==process.env.REACT_APP_ADMIN?"d-flex justify-content-center py-2 adminOutLayer":"d-flex justify-content-center py-2"}>
        
        <div className={ list.by==process.env.REACT_APP_SUPER_ADMIN || list.by==process.env.REACT_APP_ADMIN?"adminSecond py-2 px-2":'second py-2 px-2'} style={{position:'relative'}}>
      { can(currentUserPermissions,'Delete Auto Notes')? <> {props.preDataLoadResponse.currentUserId == list.users_id?

        <span className="check"> <Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />
            
            
            </span>:''
            }</>:''}
        <div className={ list.by==process.env.REACT_APP_SUPER_ADMIN ||  list.by==process.env.REACT_APP_ADMIN? "textRightColor textRight":"textRight"}>{moment(list.created_at).tz("America/Los_Angeles").format('D-M-Y h:m A')}</div>
        
        <span className={ list.by==process.env.REACT_APP_SUPER_ADMIN ||  list.by==process.env.REACT_APP_ADMIN?"text1 admintextColor":"text1"}>{list.body}</span> 
            <div className="d-flex justify-content-between py-1 pt-2">
                <div><span className="text2"><span style={{color:'black',fontSize:'10px'}}>Note By:</span> {list.username}</span></div>
                <div>{list.by==process.env.REACT_APP_SUPER_ADMIN ||  list.by==process.env.REACT_APP_ADMIN?<span className="text4" style={{fontSize:'10px'}}><span style={{color:'black',fontSize:'10px'}}>Role: </span>{list.by}</span>:''}</div>
                {list.attached_file?<div style={{fontSize:'11px'}}><i className="fa fa-paperclip" aria-hidden="true"></i> : <a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_ATTACHMENT_FIlES}/${list.attached_file}`} target={"_blank"}>{list.attached_file.substring(10,17).concat('...')}</a></div>:''}
                <div>
          
               {props.preDataLoadResponse.currentUserId == list.users_id?
                <div>
                <span className="text3"><i className="fas fa-edit" style={{color:'#2e5287'}} onClick={()=>{
                  props.getDataEditAutoNote(list.id);
                  setOnId(list.id);
                  props.showEditNoteAutoModal();
                }}></i></span>
                </div>:''
                
                }

                </div>
            </div>
        </div>
    </div>
    </div>
    )
}):<NodataFound notFoundText={t('key_communication_message_below')} headerText={t('key_communication_message_header')}  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="#d9dbdb" notFoundColor="#1e5f94"/> }
   
    
   

{/* text area starts from here */}
<form onSubmit={onSubmitForm}>
    <div className="textAreaContainer">
    <div className="d-flex justify-content-center pt-3 pb-2 flex-row"> 

    <div className="px-4 txtarea" > 
    <textarea id="w3review" className="addTxt form-control" placeholder="add your notes here" name="w3review" rows="2" cols="50" required
     onChange={handleTextArea}
     value={noteBody}
     >

    </textarea> 
</div>
    <div className="px-4">
   <span>
   <label className="custom-file-upload btn btn-primary" style={{marginTop:'9px'}} >
    <input type="file" 
onChange={handleAttachment}
accept="application/pdf"
    />
    <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;{attachment?.fileName} 
</label>
</span> {attachment?.fileName!=='' ?<span style={{cursor:'pointer'}} onClick={()=>{setAttachment({'fileName':'', 'file':null})}}> <i className="fa fa-times" aria-hidden="true"></i></span>:''} &nbsp;
<span> 
<button className='btn btn-primary'> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;
{t('key_send_note')}</button> </span>
   
</div>

    </div>
</div>

</form>





{/* ========================================================
Edit your note
======================================================== */}

<Modal show={props.openEditNoteAuto} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Autos List Communication{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >
<form onSubmit={onEditForm}>
  
    <div className="d-flex justify-content-center pt-3 pb-2 flex-row"> 

    <div className="px-4 txtarea" > 
    <textarea id="w3review" className="addTxt form-control" placeholder="add your notes here" name="w3review" rows="2" cols="50" required
     onChange={handleTextAreaEdit}
     value={editNoteBody}
     accept="application/pdf"

     >

    </textarea> 
</div>
     <div className="px-4">
   <span>
   <label className="custom-file-upload btn btn-primary" style={{marginTop:'9px'}} >
    <input type="file" 
onChange={handleEditAttachment}
accept="application/pdf"
    />
    <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;{editAttachment?.fileName} 
</label>
</span> {editAttachment?.fileName!=='' ?<span style={{cursor:'pointer'}} onClick={()=>{setEditAttachment({'fileName':'', 'file':null})}}> <i className="fa fa-times" aria-hidden="true"></i></span>:''} &nbsp;
<span> 
<button className='btn btn-primary'> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;Edit Note</button> </span>
   
</div>

</div>

</form>


          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        {/* <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={handleClose}>
            Close
          </button>

          <button className="btn-submit" >Confirm</button>
        </Modal.Footer> */}
      </Modal>





 









    </div>
  )
}


const mapStateToProps = (state) => {

    const {preDataLoadResponse } = state.preDataHandler;


    const {
      currentPage,
      loading,
      getAutoListCommunication,
      registerNoteResponse,
      textAreaValue,
      deletedSelectedNotes,
      editedSelectedNote,
      openEditNote,
      getEditAutoNoteInfo,
      attachmentValue,
      openEditNoteAuto
    } = state.AutosHandler;
  
    return {
      loading: loading,
      getAutoListCommunication:getAutoListCommunication,
      registerNoteResponse:registerNoteResponse,
      deletedSelectedNotes:deletedSelectedNotes,
      textAreaValue:textAreaValue,
      preDataLoadResponse:preDataLoadResponse,
      editedSelectedNote:editedSelectedNote,
      openEditNote:openEditNote,
      getEditAutoNoteInfo:getEditAutoNoteInfo,
      attachmentValue:attachmentValue,
      openEditNoteAuto:openEditNoteAuto,
      currentPage:currentPage


    };
  };
  
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      showEditNoteAutoModal: () => dispatch(showEditNoteAutoModal()),
      hideEditNoteAutoModal: () => dispatch(hideEditNoteAutoModal()),
        getAutoCommunication:(AutoId)=>dispatch(getAutoCommunication(AutoId)),
        AutoCommunication:(AutosId,bodyText,fileAttachment)=>dispatch(AutoCommunication(AutosId,bodyText,fileAttachment)),
        editSelectedAutoNotes:(selectedNoteId,body,fileAttachment)=>dispatch(editSelectedAutoNotes(selectedNoteId,body,fileAttachment)),
        deleteSelectedAutoNotes:(selectedNotes,AutoId)=>dispatch(deleteSelectedAutoNotes(selectedNotes,AutoId)),
        getDataEditAutoNote:(id)=>dispatch(getDataEditAutoNote(id)),
        autosList:(pageNumber)=>dispatch(autosList(pageNumber)),

    };
  };
  




export default connect(mapStateToProps,mapDispatchToProps)(AutosCommunication)