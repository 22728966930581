import React, { useState, useEffect } from "react";
import '../../../../../css/profile.css';
import { Outlet,NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import { goBack } from "../../../../utils/historyUtils";

import { useTranslation } from 'react-i18next'


import {
  userDelete,
  addDetails,
  companyEdit,
  toggleCompanyModal,
} from "../../../../../redux/actions";




const RequestCustomers = (props) => {
  const location = useLocation();
  const [isTabActive, setIsTabActive] = useState(false);
  const [id, setId] = useState('');

  const params = useParams();
  const {t}=useTranslation();

  const match = useMatch('/request-customer');

  useEffect(() => {
    setIsTabActive(match);
  }, [match]);


useEffect(()=>{
// const ls=1;
//  props.companyEdit(ls);
 
 },[])
 




  return (
  
    // <div class="shade-background"></div>

    <div className="container-request-customer">

    <div className="main-body">
    
     <div className="back-button">
         {/* <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i>
</a>  */}

     </div>
    
          <div className="gutters-sm side-row">
            <div className=" fixed-sidebar mb-3">
              <div className="card card-custom">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src={require('../../../../assets/normLogo.png')} alt="Admin" className="rounded-circle" width="150"/>
                    <div className="mt-3">
                      <h6>{process.env.REACT_APP_NAME}</h6>
                      {/* <p className="text-sub-head mb-1">{process.env.REACT_COMPANY_PHONE}</p> */}
                    <span className="icon-frame"><i className="fa fa-phone ml-1"/></span>  <span className="text-sub-head mb-1">{process.env.REACT_APP_COMPANY_PHONE}</span>

                    <p className="text-sub-head font-size-sm">{process.env.REACT_APP_COMPANY_ADDRESS}</p> 
                    <p className="text-sub-head font-size-sm">{process.env.REACT_APP_COMPANY_EMAIL}</p> 

                      {/* <button className="btn">Follow</button>
                      <button className="btn ">Message</button> */}
                    </div>
                  </div>
                </div>
              </div>



  <div className="container py-4">
        
        <div className="row">
            <div className="col-md-12">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <NavLink to="/customer-request" end replace className={ (isTabActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i className="fas fa-info mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Terms and Conditions</span></NavLink>

  <NavLink to="request-membership" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i className="fa fa-user mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Request Membership</span></NavLink>


                   
                    </div>
            </div>


            
        </div>
    </div>


            
            </div>

            <div className=" scroller  overflow-auto-cont" style={{zIndex:999}}>

              <Outlet/>
              
              </div>

              

            </div>
          </div>

        </div>
  );
};



const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    showCompanyModal,
    companyEditInfo,
  } = state.userManagementHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    showCompanyModal:showCompanyModal,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    companyEditInfo:companyEditInfo?.data,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    // userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),
    // addDetails:(inputData)=>dispatch(addDetails(inputData)),
    companyEdit:(id)=>dispatch(companyEdit(id)),
    // toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(RequestCustomers);
