import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../../css/carousel.css';
import { connect } from 'react-redux';
import {getContainerImages} from "../../../redux/actions";
import Loader from "../../elements/Loader";


function ContainerImagesCarousel(props) {
  return (
  
    <>
    <div className="carousel-outer">

{ props.containerImagesResponse?.images?
    <Carousel showArrows={false} useKeyboardArrows stopOnHover swipeable emulateTouch >
  { props.containerImagesResponse?.images?.map((img,index)=>{

return (


  <div key={index}>
  
        <img src={`${process.env.REACT_APP_CONTAINER_IMAGES}/${img.image_name}`} />

    </div>


)



  })
     
  
  }
  
  
    


</Carousel>
: <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"300px"}
            />
}
</div>
</>


  )
}


const mapStateToProps = (state) => {

    const {loading,containerImagesResponse} =  state.ContainerHandler;
  
  
    return {
   loading:loading,
   containerImagesResponse:containerImagesResponse,
      
    
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
    
        getContainerImages:(id)=>dispatch(getContainerImages(id))
  
    };
  };
  
  





export default connect(mapStateToProps,mapDispatchToProps) (ContainerImagesCarousel)