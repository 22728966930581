import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import Select from "react-select";

import { useTranslation } from 'react-i18next'

import "../../../../../css/pagination.css";
// import "../../../../../css/loginStyle.css"
import { accountTypes,countryList } from "../../../../utils/countryUtils";
import {
  requestMembership
} from "../../../../../redux/actions";

function RequestMembership(props) {

  const [id, setId] = useState('');
  const [requestData,setRequestData]=useState({
  'username':'',
  'full_name':'',
  'company_name':'',
  'address':'',
  'city':'',
  'state':'',
  'zip':'',
  'phone':'',
  'email':'',
  'passport':'',
  'account_type':{label:'Individual',value:'Individual'},
  'receiver_full_name':'',
  'receiver_company_name':'',
  'receiver_address':'',
  'receiver_city':'',
  'receiver_state':'',
  'receiver_zip':'',
  'receiver_phone':'',
  'receiver_email':'',
  'agreement_status':false,
  'country':'',
  'receiver_country':'',

})


const [countryFieldError, setCountryFieldError] = useState(null)

  const params = useParams();
  const {t}=useTranslation();




// useEffect(()=>{


// //  props.getAgreementMainPage();

 
//  },[])


 const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 58,
    baseUnit: 4,
  },
});

const menuPortal = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

  
const onInputChange = (e) => {
  setRequestData({
    ...requestData,
    [e.target.name]: e.target.value,
  });
};


const handleSelectors = (e, selectorName) => {
  let selectName = selectorName?.name.toString();
  setRequestData({ ...requestData, [selectName]: e });
};

const handleCheckboxChange = (e) => {
  const {checked}= e.target
  setRequestData({...requestData,'agreement_status':checked});
};

const onSubmitRequest = (e)=>{
  e.preventDefault();
  if(requestData.agreement_status){
  props.requestMembership(requestData)
}else{
  Swal.fire({
    position: 'top-end',
    icon: 'info',
    text: 'please, accept the terms and conditions by clicking the below checkbox. ',
    showConfirmButton: false,
    timer: 3000,
    toast: true,

  })
}
}


  return (
    <div>
   <div className="container">
   <form onSubmit={onSubmitRequest}>
<div className="row">
   <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="full_name"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.full_name || ""}
                  />
                  <label htmlFor="full_name">Full Name</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="username"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.username || ""}
                    title="Username would be used for the authentication purpose be carefull while providing your username."
                  />
                  <label htmlFor="user_name">User Name</label>
                </Form.Floating>

                {props.requestMembershipError?.error?.username ? (
                  <div className="validation-error-guest">The username has already been taken, please use another username for request!</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="passport"
                    placeholder=" "
                    required
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.passport || ""}
                  />
                  <label htmlFor="passport">EIN/SSI/Passport</label>
                </Form.Floating>
 </div>

 <div className="col-md-3 mt-3">
             
                <Select
                  options={accountTypes}
                  theme={theme}
                  placeholder="Account Type"
                  name="account_type"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={requestData?.account_type || ""}
                  onChange={(e, selector) =>
                    handleSelectors(e, selector)
                  }
                />

             
              </div>
          
</div>
<div className="row">
<div className="col-md-8 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="company_name"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.company_name || ""}
                  />
                  <label htmlFor="company_name">Company Name</label>
                </Form.Floating>
              </div>
</div>
<div className="row">
<div className="col-md-6 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.email || ""}
                  />
                  <label htmlFor="email">Email</label>
              
                </Form.Floating>

                {props.requestMembershipError?.error?.email ? (
                  <div className="validation-error-guest">The email has already been taken, please use another email for request!</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="phone"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.phone || ""}
                  />
                  <label htmlFor="company_name">Phone</label>
                </Form.Floating>
              </div>
</div>

<div className="row">
<div className="col-md-9 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="address"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.address || ""}
                  />
                  <label htmlFor="address">Address</label>
                </Form.Floating>
              </div>
            
</div>



<div className="row">
<div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="city"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.city || ""}
                  />
                  <label htmlFor="city">City</label>
                </Form.Floating>
              </div>


              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="state"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.state || ""}
                  />
                  <label htmlFor="state">State</label>
                </Form.Floating>
              </div>
            
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="zip"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.zip || ""}
                  />
                  <label htmlFor="zip">Zip Code</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mt-3">
             
             <Select
               options={countryList}
               theme={theme}
               placeholder="Country"
               name="country"
               isClearable={true}
               menuPortalTarget={document.body}
               styles={menuPortal}
               value={requestData?.country || ""}
               onChange={(e, selector) =>
                 handleSelectors(e, selector)
               }
             />

          
           </div>
</div>

<div class="line-division">
        <span>Receiver or Consignee Information</span>
      </div>


<div className="row">


<div className="row">
<div className="col-md-4 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_full_name"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_full_name || ""}
                  />
                  <label htmlFor="receiver_full_name">Receiver Full Name</label> 
                </Form.Floating>
              </div>

              <div className="col-md-8 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_company_name"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_company_name || ""}
                  />
                  <label htmlFor="receiver_company_name">Receiver Company Name</label>
                </Form.Floating>
              </div>
            
</div>
</div>

<div className="row">
<div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="email"
                    name="receiver_email"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_email || ""}
                  />
                  <label htmlFor="receiver_email">Receiver Email</label> 
                </Form.Floating>
              </div>

              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_phone"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_phone || ""}
                  />
                  <label htmlFor="receiver_phone">Receiver Phone</label> 
                </Form.Floating>
              </div>

              <div className="col-md-6 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_address"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_address || ""}
                  />
                  <label htmlFor="receiver_address">Receiver Address</label> 
                </Form.Floating>
              </div>
            
</div>

<div className="row">
<div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_city"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_city || ""}
                  />
                  <label htmlFor="receiver_city">Receiver City</label> 
                </Form.Floating>
              </div>
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_state"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_state || ""}
                  />
                  <label htmlFor="receiver_state">Receiver State</label> 
                </Form.Floating>
              </div>
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="receiver_zip"
                    placeholder=" "
                    required

                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    value={requestData.receiver_zip || ""}
                  />
                  <label htmlFor="receiver_zip">Receiver Zip</label> 
                </Form.Floating>
              </div>
              {/* <div className="col-md-3 mt-3">
             
             <Select
               options={countryList}
               theme={theme}
               placeholder="Country"
               name="receiver_country"
               isClearable={true}
               menuPortalTarget={document.body}
               styles={menuPortal}
               value={requestData?.receiver_country || ""}
               onChange={(e, selector) =>
                 handleSelectors(e, selector)
               }
             />

          
           </div> */}
</div>
<div className="row mt-3">
  <div className="col-md-6">
    
    <button className="btn btn-warning pr-4 pl-4" disabled={requestData.agreement_status?false:true}>Send Request</button>
</div>
  <div className="col-md-6">
    
  <label>
    <input 
    
    type="checkbox"
    checked={requestData.agreement_status}
    onChange={handleCheckboxChange}
     />
   <span className="agree-statment">I agree to the <Link to="/customer-request">Terms and Conditions</Link> by Alibaba Global Shipping</span> 
  </label>
  </div>

</div>


</form>
   </div>




    

              
    </div>
  )
}




const mapStateToProps = (state) => {

  const { requestMembershipResponse,loading,requestMembershipError } = state.CustomerRequestHandler;

  return {
loading:loading, 
requestMembershipResponse:requestMembershipResponse,
requestMembershipError:requestMembershipError,
  };
};






const mapDispatchToProps = (dispatch) => {
  return {


    requestMembership:(data)=>dispatch(requestMembership(data)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(RequestMembership) 