import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");



// -------------------------------------------------------------------------
// Login Action
// -------------------------------------------------------------------------
export const LoginAuth = (credentials) => {
  return (dispatch) => {
    dispatch(loginStarted());

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/login`, credentials)
      .then((response) => {
      

        if (response.data.success_message) {
          let tokenFrom = response.data.token;
          var cipherText = CryptoJS.AES.encrypt(
            JSON.stringify(tokenFrom),
            "Ghulamm@2022"
          ).toString();

          // console.log(cipherText)


          let userName = response.data.user.username;
          let name = response.data.user.name;

          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Accept: "application/json",
              Authorization: `Bearer ${tokenFrom}`,
            },
          };

          axios
            .get(`${process.env.REACT_APP_API_ADDRESS}/loginCheck`, axiosConfig)
            .then((res) => {
              //console.log(res);
              localStorage.setItem("fkey", JSON.stringify(cipherText));

              localStorage.setItem(
                "AuthUser",
                JSON.stringify({ name: name, userName: userName })
              );

              dispatch(loginSuccess(res.data));

              History.replace("/home/autos");
            })
            .catch((err) => {

              if (err.response && err.response.status === 401) {
                localStorage.removeItem("fkey");
                localStorage.removeItem("AuthUser");
                History.replace("/");
              }

              dispatch(loginFailure(err.message));
          
            });
        } else if (response.data.error_message) {
          dispatch({ type: "login_error" });

          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: response.data.error_message,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
            confirmButtonColor: "#0a4b85",
            background: "ebeced",
          });
        }
      });
  };
};

const loginStarted = () => ({
  type: "login_started",
});

const loginSuccess = (data) => ({
  type: "login_success",
  payload: {
    ...data,
  },
});

const loginFailure = (error) => ({
  type: "login_failure",
  payload: {
    error,
  },
});

// -------------------------------------------------------------------------
// Logout Action - the authenticated user would be logout here
// -------------------------------------------------------------------------

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    // //console.log(cipherToken);

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/logout`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          // //console.log(response.data);

          localStorage.removeItem("fkey");
          localStorage.removeItem("AuthUser");

          dispatch(logoutSuccess(response.data));

          History.replace("/");
        } else if (response.data.error_message) {
          dispatch({ type: "logout_error" });
        }
      })
      .catch((err) => {
        dispatch(logoutFailure(err.message));
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("fkey");
          localStorage.removeItem("AuthUser");
          History.replace("/");
        }

      });
  };
};

const logoutStarted = () => ({
  type: "logout_started",
});

const logoutSuccess = (data) => ({
  type: "logout_success",
  payload: {
    ...data,
  },
});

const logoutFailure = (error) => ({
  type: "logout_failure",
  payload: {
    error,
  },
});



// -------------------------------------------------------------------------
// for opening registration model
// -------------------------------------------------------------------------
export const showRegisterModal=()=>{
return {
  type:'showModalAction'
}

}
export const hideRegisterModal=()=>{
  return {
    type:'hideModalAction'
  }
  
  }


// -------------------------------------------------------------------------
// user registration Action
// -------------------------------------------------------------------------


export const registerUser = (name,username,email,role,contact,password,confirmPassword) => {
  return (dispatch) => {
    dispatch(registerUserStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const FormFields = new FormData();
  
    const roles= role?.map((list,index)=>{
      const rolesArr=[];
      rolesArr.push(list.value);
  return rolesArr;
  
    })



    FormFields.append("name",name);
    FormFields.append("username",username);
    FormFields.append("email",email);
    FormFields.append("email",email);
    FormFields.append("role",roles);
    FormFields.append("password",password);
    FormFields.append("confirm_password",confirmPassword);
    FormFields.append("contact",contact);


    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };


   

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/registerUser`, FormFields, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

      //  console.log(response.data)
          dispatch(registerUserSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The user has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(registerUserFailure(err.response.data.errors));
     
      });
  };
};

const registerUserStarted = () => ({
  type: "registerUser_started",
});

const registerUserSuccess = (data) => ({
  type: "registerUser_success",
  payload: {
    ...data,
  },
});

const registerUserFailure = (error) => ({
  type: "registerUser_failure",
  payload: {
    error,
  },
});