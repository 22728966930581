const InitialStates = {
    VendorTypesList:null,
  
    currentPage: 1,
   
    showVendorTypeModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    VendorTypesList:null,
    VendorTypeRegisterErrors:null,
    VendorTypeUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showVendorTypeModal":
      return { ...state, 'showWarModal':true };

      case "hideVendorTypeModal":
        return { ...state, 'showWarModal':false, 'VendorTypeRegisterErrors':'', 'name':'' };
  

        case "showEditVendorTypeModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditVendorTypeModal":
            return { ...state, 'showEditWarModal':false, 'VendorTypeUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================

            case "getVendorType_started":
              return {...state, 'loading':true}
    case "registerVendorType_started":
              return {...state, 'loading':true}

              // case "VendorTypeEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "VendorTypeUpdate_started":
              //     return {...state, 'loading':true}

// ===================end here====================

       case "getVendorTypesSettings_success":

         return { ...state, 'VendorTypesList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerVendorType_success":
    return { ...state, 'registerVendorTypeSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "VendorTypeEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "VendorTypeUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "VendorTypeDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerVendorType_failure":
    return { ...state, 'VendorTypeRegisterErrors': action.payload,  'loading':false };

case "VendorTypeUpdate_failure":
  return { ...state, 'VendorTypeUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   