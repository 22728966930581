import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import "../../../css/pagination.css";
import Swal from "sweetalert2";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ErrorNoData from '../error/NoDataError';
import NodataFound from '../error/noDataFoundMain';
import { countryList } from "../../utils/countryUtils";
import Loader from '../../elements/Loader';
import CryptoAES from 'crypto-js/aes';

import {
  vendorsList,
  vendorDelete,
  vendorEdit,
  vendorUpdate,
  inputHandler,
  searchVendorsList,
  addVendorDetails,
  vendorCompanyEdit,
  togglevendorCompanyModal,
  getSelectors,
  togglevendorCompanyEditModal
} from "../../../redux/actions";
import _, { set } from "lodash";
import {can} from '../../utils/roleUtils';
import { useNavigate } from "react-router-dom";

function Vendors(props) {

const [countryFieldError, setCountryFieldError] = useState(null)
const [vendorTypeFieldError, setVendorTypeFieldError] = useState(null)


const navigate = useNavigate();



  const [vendorCompanyDetails, setvendorCompanyDetails]=useState({
        id:"",
        company_name:"",
        contact_name:"",
        email:"",
        secondary_email:"",
        contact:"",
        secondary_contact:"",
        address:"",
        city:"",
        state:"",
        zip_code:"",
        country:null,
        type:null,
        suite:"",
  })



let countryOptions=countryList;


let vendorTypeOptions; 
if(props?.SelectorsFetch?.vendorType){
  vendorTypeOptions = props.SelectorsFetch?.vendorType.map(function (data) {
    return { value: data.vendor_type_name, label: data.vendor_type_name }
  })
}


  const [searched, setSearched] = useState(false);

  useEffect(()=>{
    props.getSelectors();
  },[])
  // useEffect(() =>{
  //   props.rolePicker();

  // },[props.currentPage, 
  //   props.vendorRegistrationResponse,
  //   props.vendorDeletionResponse,
  //   props.vendorUpdateResponse])

  useEffect(() => {
    props.fetchvendorsList(props.currentPage);
    // props.rolePicker();
  }, [
    props.vendorRegistrationResponse,
    props.vendorDeletionResponse,
    props.vendorUpdateResponse,
    props.addVendorDetailsSuccess,
  ]);


  


  
  // const can=(permission)=>(props.preDataLoadResponse?.currentvendorPermissions||[]).find((p)=>p==permission)?true:false;
  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

  const onDeletevendor = (vendors_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this vendor",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.vendorDelete(vendors_id, props.currentPage);
      }
    });
  };

  const handleClose = () => {
    props.togglevendorCompanyModal()
  };

  const handleEditClose=()=>{
    
    props.togglevendorCompanyEditModal();
  }
  // const handleShow = () => props.showRegisterModal();

  const updatevendor = (e) => {
    e.preventDefault();
    if(vendorCompanyDetails.type !== null ){
      props.vendorUpdate(vendorCompanyDetails,props.currentPage);
      setVendorTypeFieldError(null);

    }else{
      setVendorTypeFieldError('The vendor type field is required');

    }
  };

  const showEditDetails = (id) => {
    props.vendorEdit(id);
  };

  const handleSearchPages=(pageNumber)=>{
    props.searchVendorsList(props.searchvendors,pageNumber)
  
  }



  // ============================================
  // Validation purposes (granular level)
  // ============================================



  const handleChangeOptionvendorCompany = (selected) => {
    setvendorCompanyDetails({ ...vendorCompanyDetails, country: selected });
  };

  const handleVendorTypeChange = (selected) => {
    setvendorCompanyDetails({ ...vendorCompanyDetails, type: selected });
  };


  const onInputchangevendorCompanyDetails = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setvendorCompanyDetails({ ...vendorCompanyDetails, [e.target.name]: e.target.value });
  };

const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    
    props.fetchvendorsList(props.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

useEffect(()=>{
let data = props?.vendorEditResponse;
  setvendorCompanyDetails({
    id:data?.id,
    company_name:data?.vendor_name,
    contact_name:data?.vendor_contact_name,
    email:data?.vendor_email,
    contact:data?.vendor_phone,
    contact:data?.vendor_phone,
    secondary_contact:data?.vendor_secondary_phone,
    address:data?.vendor_address,
    city:data?.vendor_city,
    state:data?.vendor_state,
    zip_code:data?.vendor_zip_code,
    suite:data?.vendor_suite,
    country:data?.vendor_country?{value:data?.vendor_country,label:data?.vendor_country?.replace(/\b\w/g, (char) => char.toUpperCase())}:null,
    type:data?.vendor_type?{value:data?.vendor_type,label:data?.vendor_type?.replace(/\b\w/g, (char) => char.toUpperCase())}:null,


  })
},[props.vendorEditResponse])



const togglevendorCompanyModal=()=>{
  setvendorCompanyDetails({
    ...vendorCompanyDetails,
    id:"",
    company_name:"",
    contact_name:"",
    email:"",
    secondary_email:"",
    contact:"",
    secondary_contact:"",
    address:"",
    city:"",
    state:"",
    zip_code:"",
    country:null,
    type:null,
    suite:"",
  })

    props.togglevendorCompanyModal()

  
  
}


const onAddvendorCompanyDetails = (e)=>{
e.preventDefault();
if(vendorCompanyDetails.type !== null ){
props.addVendorDetails(vendorCompanyDetails);
setVendorTypeFieldError(null);

}else{
  setVendorTypeFieldError("The vendor type field is required");
}
}

const viewProfile=(id)=>{
  navigate(`/home/vendorsProfile/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListAli_123'))}`);

}

const onSearch=(e)=>{
e.preventDefault();

  if(props.searchvendors){
setSearched(true);
  props.searchVendorsList(props.searchvendors,props.currentPage)
  }else{
    setSearched(false);
   

  }

}
  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };



  

 

  return (
    <>
    {props?.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40vh"}
            />:
            <div className="container-vendors pt-5 pb-3">

{  can(currentUserPermissions,'Add Vendor')?

<button
                className="floating-btn"
                title="Register new vendor to system"
                onClick={togglevendorCompanyModal}
              >
                Add Vendor
              </button>:null
}
      <div className="row mt-5">
{ 
  searched && <div><p style={{color:'white'}}>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchvendors}</span></p></div>
}
{
          props.vendorsListResponse?.data?.data?.length===0 && <NodataFound notFoundText={"No vendor Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}
{/* {props.vendorsListResponse?.data.data==='no data' && <ErrorNoData imgHeight={'270px'} imgWidth={'270px'} headerTextColor={'white'} lowerTextColor={'white'}/>} */}
        {props.vendorsListResponse ?
          props.vendorsListResponse.data.data?.map((list, index) => {
            return  (
              <div kay={index}>
              <div
                className="card mb-3 "
              >
{/* 
<div className="vendorCompany-button">
<button className="buttonvendorCompany" onClick={()=>{
  props.vendorCompanyEdit(list.vendors_id)
  togglevendorCompanyModal()
  
  }} title="add vendor's vendorCompany details">Vendor Details</button>
</div> */}
<div className="card-body">
<div className="row">
<div className="col-md-3 text-uppercase">Company Name</div>
<div className="col-md-2 text-uppercase">Type</div>
<div className="col-md-3 text-uppercase">Email</div>
<div className="col-md-2 text-uppercase">Phone</div>
<hr/>
<div className="col-md-3 text-uppercase"><span className="text-description">{list.vendor_name}</span></div>
<div className="col-md-2"><span className="text-description">{list.vendor_type}</span></div>
<div className="col-md-3"><span className="text-description">{list.vendor_email}</span></div>
<div className="col-md-2"><span className="text-description">{list.vendor_phone}</span></div>

    
</div> 


  </div>


  <div className="icon-bar-2">
        
                            
        <a onClick={()=>viewProfile(list.id)}>
            <i className="fa fa-eye"></i>
          </a>
               
     { can(currentUserPermissions,'Edit Vendor')?   <a onClick={()=>{
          props.vendorEdit(list.id)

          setTimeout(() => {
            props.togglevendorCompanyEditModal()

          }, 500);
         }}>
            <i className="fa fa-edit"></i>
          </a>:null
          
          }
        
              {  can(currentUserPermissions,'Delete Vendor')?
        <a onClick={()=>onDeletevendor(list.id)}>
            <i className="fa fa-trash"></i>
          </a>:null
          }
        </div>

</div>



        </div>
    
              // card ends here
            );
          }):null
          }
      </div>
      </div>
    }
      {props.vendorsListResponse?.data?.data.length >0 ? (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.vendorsListResponse.data.current_page}
              itemsCountPerPage={props.vendorsListResponse.data.per_page}
              totalItemsCount={props.vendorsListResponse.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched==true? handleSearchPages :(pageNumber) => props.fetchvendorsList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):props.vendorsListResponse?.data==='no data' &&(
        ''  
      )}

      <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchvendors" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>





 {/* ====================================================
      add vendor details
      ==================================================== */}

<Modal show={props.showvendorCompanyModal} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add Vendor </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onAddvendorCompanyDetails}>
          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
            <div className="row">
              <div className="col-md-8 mb-3">
                <FloatingLabel label="Company name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={vendorCompanyDetails.company_name || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <Select
                  options={vendorTypeOptions}
                  theme={theme}
                  placeholder="Vendor Type"
                  name="type"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={vendorCompanyDetails.type}
                  defaultValue={vendorCompanyDetails.type}
                  onChange={(e) => handleVendorTypeChange(e)}
                  isClearable={true}
                />

         {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={vendorCompanyDetails.contact_name || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-4 mb-3">
                <FloatingLabel label="Suite">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="suite"
                    value={vendorCompanyDetails.suite || ''}
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

            </div>

            <div className="row">
            <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary Email">
                  <Form.Control
                    type="email"
                    placeholder=" "
                    name="email"
                    value={vendorCompanyDetails.email || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
            
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={vendorCompanyDetails.contact || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-4 mb-3">
                <FloatingLabel label="Secondary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_contact"
                    value={vendorCompanyDetails.secondary_contact || ''}
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
            </div>

          

            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Address</span></div>
</div>
</div>



<div className="row">

            <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={vendorCompanyDetails.address || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
         
              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={vendorCompanyDetails.city || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={vendorCompanyDetails.state || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={vendorCompanyDetails.zip_code || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>


              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={vendorCompanyDetails.country}
                  defaultValue={vendorCompanyDetails.country}
                  onChange={(e) => handleChangeOptionvendorCompany(e)}
                  isClearable={true}
                />

         {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''}
              </div>


            </div>
          
            </div> 

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>









 {/* ====================================================
      update vendor details
      ==================================================== */}

<Modal show={props.showvendorCompanyEditModal} onHide={handleEditClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Update Vendor </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={updatevendor}>
          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
            <div className="row">
              <div className="col-md-8 mb-3">
                <FloatingLabel label="Company name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={vendorCompanyDetails.company_name || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <Select
                  options={vendorTypeOptions}
                  theme={theme}
                  placeholder="Vendor Type"
                  name="type"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={vendorCompanyDetails.type}
                  defaultValue={vendorCompanyDetails.type}
                  onChange={(e) => handleVendorTypeChange(e)}
                  isClearable={true}
                />

         {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={vendorCompanyDetails.contact_name || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-4 mb-3">
                <FloatingLabel label="Suite">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="suite"
                    value={vendorCompanyDetails.suite || ''}
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

            </div>

            <div className="row">
            <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary Email">
                  <Form.Control
                    type="email"
                    placeholder=" "
                    name="email"
                    value={vendorCompanyDetails.email || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
            
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={vendorCompanyDetails.contact || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-4 mb-3">
                <FloatingLabel label="Secondary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_contact"
                    value={vendorCompanyDetails.secondary_contact || ''}
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
            </div>

          

            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Address</span></div>
</div>
</div>



<div className="row">

            <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={vendorCompanyDetails.address || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
         
              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={vendorCompanyDetails.city || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={vendorCompanyDetails.state || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={vendorCompanyDetails.zip_code || ''}
                    required
                    onChange={(e) => onInputchangevendorCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>


              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={vendorCompanyDetails.country}
                  defaultValue={vendorCompanyDetails.country}
                  onChange={(e) => handleChangeOptionvendorCompany(e)}
                  isClearable={true}
                />

         {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''}
              </div>


            </div>
          
            </div> 

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>













     




    </>
  );
}

const mapStateToProps = (state) => {
  const {
    rolePicker,
    loading,
    vendorsListResponse,
    vendorDeletionResponse,
    currentPage,
    vendorEditResponse,
    showModal,
    vendorUpdateErrors,
    vendorUpdateResponse,
    searchvendors,
    showvendorCompanyModal,
    vendorCompanyEditInfo,
    addVendorDetailsSuccess,
    updateSuccess,
    showvendorCompanyEditModal:showvendorCompanyEditModal
  } = state.VendorsHandler;
  const { vendorRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;
  const {SelectorsFetch}=state.SelectorsHandler;

  return {
    rolePickerData:rolePicker,
    SelectorsFetch:SelectorsFetch,
    loading: loading,
    showvendorCompanyModal:showvendorCompanyModal,
    vendorsListResponse: vendorsListResponse,
    vendorRegistrationResponse: vendorRegistrationResponse,
    vendorDeletionResponse: vendorDeletionResponse,
    vendorUpdateResponse: vendorUpdateResponse,
    currentPage: currentPage,
    vendorEditResponse: vendorEditResponse.data,
    vendorUpdateErrors: vendorUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,

    searchvendors: searchvendors,
    vendorCompanyEditInfo:vendorCompanyEditInfo,
    addVendorDetailsSuccess:addVendorDetailsSuccess,
    showvendorCompanyEditModal:showvendorCompanyEditModal,
    updateSuccess:updateSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectors: () => dispatch(getSelectors()),

    fetchvendorsList: (pageNumber) => dispatch(vendorsList(pageNumber)),

    vendorDelete: (id, currentPage) => dispatch(vendorDelete(id, currentPage)),

    vendorEdit: (id) => dispatch(vendorEdit(id)),


    vendorUpdate: (
      data,
      currentPage,
 
    ) =>
      dispatch(
        vendorUpdate(
          data,
          currentPage,     
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),

    addVendorDetails:(inputData)=>dispatch(addVendorDetails(inputData)),
    vendorCompanyEdit:(id)=>dispatch(vendorCompanyEdit(id)),
    togglevendorCompanyModal:()=>dispatch(togglevendorCompanyModal()),
    togglevendorCompanyEditModal:()=>dispatch(togglevendorCompanyEditModal()),
    searchVendorsList:(querry,pageNumber)=>dispatch(searchVendorsList(querry,pageNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
