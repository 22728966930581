import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");


// ---------------------------------------------------------------------------------------------------------
// searching data for users
// ---------------------------------------------------------------------------------------------------------

export const searchUsersList = (query,pageNumber) => {

    return (dispatch) => {
      dispatch(searchUsersStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };

      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchUsers?page=${pageNumber}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(searchUsersSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchUsersFailure(err.message));
        });
    };
  };
  
  const searchUsersStarted = () => ({
    type: "searchUsers_started",
  });
  
  const searchUsersSuccess = (data) => ({
    type: "searchUsers_success",
    payload: {
      ...data,
    },
  });
  
  const searchUsersFailure = (error) => ({
    type: "searchUsers_failure",
    payload: {
      error,
    },
  });
  


// ---------------------------------------------------------------------------------------------------------
// searching data of Autos
// ---------------------------------------------------------------------------------------------------------

export const searchAutosList = (query,pageNumber) => {

  return (dispatch) => {
    dispatch(searchAutosStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchAutos?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          console.log(response.data);

          dispatch(searchAutosSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchAutosFailure(err.message));
      });
  };
};

const searchAutosStarted = () => ({
  type: "searchAutos_started",
});

const searchAutosSuccess = (data) => ({
  type: "searchAutos_success",
  payload: {
    ...data,
  },
});

const searchAutosFailure = (error) => ({
  type: "searchAutos_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// searching data of Containers
// ---------------------------------------------------------------------------------------------------------

export const searchContainersList = (query,pageNumber) => {

  return (dispatch) => {
    dispatch(searchContainerStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchContainers?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchContainerSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchContainerFailure(err.message));
      });
  };
};

const searchContainerStarted = () => ({
  type: "searchContainer_started",
});

const searchContainerSuccess = (data) => ({
  type: "searchContainer_success",
  payload: {
    ...data,
  },
});

const searchContainerFailure = (error) => ({
  type: "searchContainer_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// searching containers per user
// ---------------------------------------------------------------------------------------------------------

export const searchContainersPerUser = (query,userId,pageNumber) => {

  return (dispatch) => {
    dispatch(searchContainerPerUserStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query,
      userId:userId
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchContainersPerUser?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchContainerPerUserSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchContainerPerUserFailure(err.message));
      });
  };
};

const searchContainerPerUserStarted = () => ({
  type: "searchContainerPerUser_started",
});

const searchContainerPerUserSuccess = (data) => ({
  type: "searchContainerPerUser_success",
  payload: {
    ...data,
  },
});

const searchContainerPerUserFailure = (error) => ({
  type: "searchContainerPerUser_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// searching data for notification
// ---------------------------------------------------------------------------------------------------------

export const searchNotificationList = (query,currentPage) => {
// console.log(query)
  return (dispatch) => {
    dispatch(searchNotificationStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query
     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchNotification?page=${currentPage}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchNotificationSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchNotificationFailure(err.message));
      });
  };
};

const searchNotificationStarted = () => ({
  type: "searchNotification_started",
});

const searchNotificationSuccess = (data) => ({
  type: "searchNotification_success",
  payload: {
    ...data,
  },
});

const searchNotificationFailure = (error) => ({
  type: "searchNotification_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// searching data for consignee
// ---------------------------------------------------------------------------------------------------------

export const searchConsigneeList = (id=null,query,pageNumber) => {

  return (dispatch) => {
    dispatch(searchConsigneeStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    let searchData
if(id){
  console.log(id);
     searchData={
      id:id,
      q:query
     }
    }else{
      searchData={
        q:query
       }
   }
    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchConsignee?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          //console.log(response.data);

          dispatch(searchConsigneeSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchConsigneeFailure(err.message));
      });
  };
};

const searchConsigneeStarted = () => ({
  type: "searchConsignee_started",
});

const searchConsigneeSuccess = (data) => ({
  type: "searchConsignee_success",
  payload: {
    ...data,
  },
});

const searchConsigneeFailure = (error) => ({
  type: "searchConsignee_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// search vendors 
// ---------------------------------------------------------------------------------------------------------

export const searchVendorsList = (query,currentPage) => {
  // console.log(query)
    return (dispatch) => {
      dispatch(searchVendorsListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchVendorsList?page=${currentPage}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
  
            dispatch(searchVendorsListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchVendorsListFailure(err.message));
        });
    };
  };
  
  const searchVendorsListStarted = () => ({
    type: "searchVendorsList_started",
  });
  
  const searchVendorsListSuccess = (data) => ({
    type: "searchVendorsList_success",
    payload: {
      ...data,
    },
  });
  
  const searchVendorsListFailure = (error) => ({
    type: "searchVendorsList_failure",
    payload: {
      error,
    },
  });
  



// ---------------------------------------------------------------------------------------------------------
// searching vehicle per vendor
// ---------------------------------------------------------------------------------------------------------

export const searchVehiclesPerVendor = (query,vendorId,pageNumber,vehicleStatus) => {

  return (dispatch) => {
    dispatch(searchVehiclesPerVendorStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query,
      vendorId:vendorId,
      vehicle_status:vehicleStatus

     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchVehiclesPerVendor?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchVehiclesPerVendorSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchVehiclesPerVendorFailure(err.message));
      });
  };
};

const searchVehiclesPerVendorStarted = () => ({
  type: "searchVehiclesPerVendor_started",
});

const searchVehiclesPerVendorSuccess = (data) => ({
  type: "searchVehiclesPerVendor_success",
  payload: {
    ...data,
  },
});

const searchVehiclesPerVendorFailure = (error) => ({
  type: "searchVehiclesPerVendor_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// searching container per vendor
// ---------------------------------------------------------------------------------------------------------

export const searchContainersPerVendor = (query,vendorId,pageNumber,vehicleStatus,containerCatagory) => {

  return (dispatch) => {
    dispatch(searchContainersPerVendorStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query,
      vendorId:vendorId,
      vehicle_status:vehicleStatus,
      containerCatagory:containerCatagory,

     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchContainersPerVendor?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchContainersPerVendorSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchContainersPerVendorFailure(err.message));
      });
  };
};

const searchContainersPerVendorStarted = () => ({
  type: "searchContainersPerVendor_started",
});

const searchContainersPerVendorSuccess = (data) => ({
  type: "searchContainersPerVendor_success",
  payload: {
    ...data,
  },
});

const searchContainersPerVendorFailure = (error) => ({
  type: "searchContainersPerVendor_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// searching container per vendor
// ---------------------------------------------------------------------------------------------------------

export const searchContainersPerVendorPayment = (query,pageNumber,containerCatagory) => {

  return (dispatch) => {
    dispatch(searchContainersPerVendorPaymentStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let searchData={
      q:query,
      containerCatagory:containerCatagory,

     }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/searchContainersVendorPayment?page=${pageNumber}`,searchData,axiosConfig)
      .then((response) => {
        if (response.data) {
          // console.log(response.data);

          dispatch(searchContainersPerVendorPaymentSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(searchContainersPerVendorPaymentFailure(err.message));
      });
  };
};

const searchContainersPerVendorPaymentStarted = () => ({
  type: "searchContainersPerVendorPayment_started",
});

const searchContainersPerVendorPaymentSuccess = (data) => ({
  type: "searchContainersPerVendorPayment_success",
  payload: {
    ...data,
  },
});

const searchContainersPerVendorPaymentFailure = (error) => ({
  type: "searchContainersPerVendorPayment_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// search vendors 
// ---------------------------------------------------------------------------------------------------------

export const searchActivitiesList = (query,currentPage) => {
  // console.log(query)
    return (dispatch) => {
      dispatch(searchActivitiesListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchActivitiesList?page=${currentPage}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
  
            dispatch(searchActivitiesListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchActivitiesListFailure(err.message));
        });
    };
  };
  
  const searchActivitiesListStarted = () => ({
    type: "searchActivitiesList_started",
  });
  
  const searchActivitiesListSuccess = (data) => ({
    type: "searchActivitiesList_success",
    payload: {
      ...data,
    },
  });
  
  const searchActivitiesListFailure = (error) => ({
    type: "searchActivitiesList_failure",
    payload: {
      error,
    },
  });
  






// ---------------------------------------------------------------------------------------------------------
// search customer requests
// ---------------------------------------------------------------------------------------------------------

export const searchCustomerRequests = (query,currentPage) => {
    return (dispatch) => {
      dispatch(searchCustomerRequestsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchCustomersRequests?page=${currentPage}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
  
            dispatch(searchCustomerRequestsSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchCustomerRequestsFailure(err.message));
        });
    };
  };
  
  const searchCustomerRequestsStarted = () => ({
    type: "searchCustomerRequests_started",
  });
  
  const searchCustomerRequestsSuccess = (data) => ({
    type: "searchCustomerRequests_success",
    payload: {
      ...data,
    },
  });
  
  const searchCustomerRequestsFailure = (error) => ({
    type: "searchCustomerRequests_failure",
    payload: {
      error,
    },
  });
  
