import React, { useEffect } from 'react';
import '../../css/messages.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import { columnMapping } from '../utils/activityLogsUtils';
function LogsBox(props) {

 
  if (props.details) {
    const jsonObject = JSON.parse(props.details);
    console.log(jsonObject);
  } else {
    console.error("JSON string is undefined or null.");
  }
  const jsonObject = JSON.parse(props?.details)||{};
  let changesArray = [];

if(props.logDescription != "deleted"){
    changesArray = Object.keys(jsonObject?.attributes).map((key) => {
    const oldValue = jsonObject.old?.[key];
    const newValue = jsonObject.attributes?.[key];
    const displayKey = columnMapping[key] || key; // Use the display name if it's mapped, otherwise use the original key

    const isTimestamp = (value) => moment(value, moment.ISO_8601, true).isValid();

    return {
      key:displayKey,
      oldValue:  isTimestamp(oldValue) ? moment(oldValue)?.add(5,'hours')?.format('MMMM Do YYYY, h:mm a') : oldValue,
      newValue:  isTimestamp(newValue) ? moment(newValue)?.add(5,'hours')?.format('MMMM Do YYYY, h:mm a') : newValue,
    };
  });
}else{
  changesArray = ['vin', 'container_no', 'cont_booking_no'].map((key) => ({
    key,
    oldValue: jsonObject.old?.[key] || '',
    newValue: jsonObject.attributes?.[key] || '',
  }));

}

  return (
    <>

    <div className={`container message-container   ${props.onShow?'hide':''}`}  >
{props.gap?
        <div className={`row py-${props.gap}`}></div>:''
}

        <div className="row ">
            <div className="col-lg-12">

            
                <blockquote className={`blockquote blockquote-custom bg-white shadow  rounded ${props.mediaQueryClass}`} style={{width:props.width}}>
 {props.checkboxProp?<div className="checkboxCustom">

{props.checkboxProp}

</div>:''}
              {/* minutes ago date */}
              {props.logStatus? <div className="log-status">{props.logStatus}</div>:''}

               {props.postDateRelative? <div className="blockquote-custom-relative-date bg-light-custome">{props.postDateRelative}</div>:''}

                  <div className="langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                  
                              
                                {/* normal date*/}

                   {props.postDate? <div className="blockquote-custom-date bg-light-custome shadow-sm">{props.postDate}</div>:''}
                  {props.children? <div className="blockquote-custom-menu bg-light-custome">{props.children}</div>:''}

                   {props.causer? <> 
                   <div className="pt-2"></div>
                   <div className='row'>
                    <div className='col-md-8'>
<div className='row'>
  <div className='col-md-12'>
  <p className="title-text">Caused by: {props.causer} </p> 

  </div>
  <div className='col-md-12'>
  <p className="title-text">Subject: {props.logSubject}  {props.year?',':''}  {props.year} {props.make} {props.model}  </p> 

  </div>
  <div className='col-md-12'>
  <p className="title-text">Record {props.logDescription}  </p> 

  </div>
</div>
                       </div>
                    <div className='col-md-4'>
                 {props.logSubject?<>    {
                        props.vin?
                    <Link  className='log-view' to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(props.subject_id.toString(),'autoListGhulam_123'))}`}  >
            View Details
          </Link>:
          <Link className='log-view' to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(props.subject_id.toString(),'autoListAli_123'))}`} >
             View Details
            </Link>

}</>:'' }
                    </div>

           
                 </div>
                 </> :''}
                { props.line?<hr/>:''}

            <div className='row'>
              <div className='col-md-2'>

<span  className='old-badge'>Details</span>
              </div>

             <div className='col-md-8'>
              {changesArray?.map((change, index) => (
          <div key={index}>
            <strong>{change.key}:</strong>  <span>{change?.oldValue?change?.oldValue:"null"} </span>  <span>{'=>'}</span>    <span> {change?.newValue?change?.newValue:"null"}</span> 
          </div>
        ))}
              </div> 
            

            </div>
                    {/* <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <p>Someone famous in</p>
                    </footer> */}
                   {props.closeButton?props.closeButton:''}

                </div>
                </blockquote>

           

            </div>
            
        </div>
    </div>


    </>
  )
}

export default LogsBox