import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {useParams,Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Pagination from "react-js-pagination";
import NodataFound from '../../error/noDataFoundMain';
import Swal from "sweetalert2";
import { can } from "../../../utils/roleUtils";
import {
  inputHandler,
  perVendorContainersPayment,
  searchContainersPerVendorPayment,
  paymentDelete
} from "../../../../redux/actions";

function VehiclePayment(props) {

  const [id, setId] = useState('');
  const [searched, setSearched] = useState(false);

  const params = useParams();
  const {t}=useTranslation();



  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
props.perVendorContainersPayment(decryptValue,props.currentPage,props.vendorEditResponse?.vendor_type) 
}

 },[props.paymentSaveSuccess,props.paymentDeletion])
 

 const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.perVendorContainersPayment(id,props?.currentPage,'unpaid',props.vendorEditResponse?.vendor_type);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const onSearch=(e)=>{
  e.preventDefault();
    if(props.searchPayment){
  setSearched(true);
    props.searchContainersPerVendorPayment(props.searchPayment,props.currentPage,props.vendorEditResponse?.vendor_type)
    }else{
      setSearched(false);
     
  
    }
  
  }

  const handleSearchPages=(pageNumber)=>{
    props.searchContainersPerVendorPayment(props.searchPayment,pageNumber,props.vendorEditResponse?.vendor_type)
  
  }



  const deletePayment=(id,vendorCatagory)=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this payment",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.paymentDelete(id,vendorCatagory);
      }
    });
    

  }

  

  
  return (
    <div>
        <div className='Containers-card'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-4 text-uppercase text-white'>{props.vendorEditResponse?.vendor_name} Payments </div>

</div>
</div>
  </div>




<div className="gap-Containers"></div>
<div className="scroller layer-outer-Containers mt-2">

{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchPayment}</span></p></div>


}
{props.perVendorContainersPaymentList?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}


  
{props.perVendorContainersPaymentList?.data?.data.map((item,index)=>{
    return(
      <div class="card card-custom mt-3" key={index}>
      <div class="row p-2">
        
        <div class="col-md-1">
        <div className="vehicle-status">
          {item.date}
        </div>
        
        </div>

        <div className="col-md-1"></div>

              <div className="col-md-3">
              <span className="label-car">Details</span>
              </div>
              <div className="col-md-3">
              <span className="label-car">Notes</span> 
              </div>
              <div className="col-md-3">
              <span className="label-car">Vehicles</span>
              </div>
            </div>

            <div class="row p-2">
        
        <div class="col-md-1">
        
        </div>

        <div className="col-md-1"></div>

              <div className="col-md-3">
                <div>
              <span className="label-car">Reference:</span> <span className="title-car">{item.id}</span>
              </div>
              <div>
              <span className="label-car">Payment Method:</span> <span className="title-car">{item.payment_type}</span>
              </div>
              <div>
              <span className="label-car">Paid By:</span> <span className="title-car">{item.paid_by}</span>
              </div>
              <div>
              <span className="label-car">Check:</span> <span className="title-car">{item.check}</span>
              </div>
              <div>
              <span className="label-car">Invoice:</span> <span className="title-car">{item.invoice}</span>
              </div>
              <div>
              <span className="label-car">Amount:</span> <span className="title-car">{item.amount}</span>
              </div>
              </div>

              
              <div className="col-md-3">
                <p>{item.note}</p>
              </div>
              <div className="col-md-3">
                {item?.collectionInfo?.map((list,ind)=>{
return(             
    <div key={ind} className="payment-items">
              <Link  to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(list.id.toString(),'autoListGhulam_123'))}`} title={`LOT: ${list.lot}`}> {list.vin } </Link> 
              </div>

)
                })}
              </div>
            </div>

            {/* <Link  to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(item.id.toString(),'autoListAli_123'))}`}  className="details-button">View Details</Link> */}
            {can(currentUserPermissions,'Delete Payment')?

<div className="delete_button" onClick={()=>deletePayment(item.id,props.vendorEditResponse?.vendor_type)}>
  <a>  
      <i className="fas fa-trash"></i>
</a>
</div>:null}
           
          </div>


    )
  })}
 


 <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchPayment" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

</div>


{ props.perVendorContainersPaymentList?.data?.data.length===0 ? '':

<div>
      {props.perVendorContainersPaymentList?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.perVendorContainersPaymentList?.data.current_page}
              itemsCountPerPage={props.perVendorContainersPaymentList?.data.per_page}
              totalItemsCount={props.perVendorContainersPaymentList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched===true?handleSearchPages:
               (pageNumber) => props.perVendorContainersPayment(id,pageNumber,'unpaid')
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}

    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    confirm_password,
    vendorEditResponse,
    rolePicker,
    searchPayment,
    perVendorContainersPaymentList,
    paymentSaveSuccess,
    paymentDeletion
  } = state.VendorsHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
  
    currentPage: currentPage,
  
    searchPayment:searchPayment,

    preDataLoadResponse: preDataLoadResponse,
 
    confirm_password: confirm_password,
    vendorEditResponse:vendorEditResponse?.data,
    perVendorContainersPaymentList:perVendorContainersPaymentList,
    paymentSaveSuccess:paymentSaveSuccess,
    paymentDeletion:paymentDeletion
  };
};






const mapDispatchToProps = (dispatch) => {
  return {
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    perVendorContainersPayment:(id,pageNumber,containerCatagory)=>dispatch(perVendorContainersPayment(id,pageNumber,containerCatagory)),
    searchContainersPerVendorPayment:(querry,pageNumber,containerCatagory)=>dispatch(searchContainersPerVendorPayment(querry,pageNumber,containerCatagory)),
    paymentDelete:(id,vendorCatagory)=>dispatch(paymentDelete(id,vendorCatagory))
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(VehiclePayment) 