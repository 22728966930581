import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import "../../../../css/pagination.css";
import Swal from "sweetalert2";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { countryList } from "../../../utils/countryUtils";

import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker,
  addDetails,
  companyEdit,
  toggleCompanyModal,
  toggleCompanyEditModal
} from "../../../../redux/actions";

function PersonalInformation(props) {

  const [id, setId] = useState('');
  const [companyDetails, setCompanyDetails]=useState({
    id:"",
    company_name:"",
    contact_name:"",
    email:"",
    secondary_email:"",
    other_email:"",
    contact:"",
    secondary_contact:"",
    other_contact:"",
    address:"",
    line_2:"",
    city:"",
    state:"",
    zip_code:"",
    country:"",
    tax_id:"",
    iaai:"",
    corporate_buyer:"",

})


const [countryFieldError, setCountryFieldError] = useState(null)

  const params = useParams();
  const {t}=useTranslation();


  let countryOptions=countryList;


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.companyEdit(decryptValue);
 }
 },[])

useEffect(()=>{
  if(props.addDetailsSuccess){
   props.companyEdit(id);
  }
},[
  props.addDetailsSuccess
])
 

 useEffect(()=>{
  let data = props?.companyEditInfo;
  setCompanyDetails({
      id:data?.id,
      company_name:data?.company_name,
      contact_name:data?.contact_name,
      email:data?.email,
      secondary_email:data?.secondary_email,
      other_email:data?.other_email,
      contact:data?.contact,
      secondary_contact:data?.secondary_contact,
      other_contact:data?.other_contact,
      address:data?.address,
      line_2:data?.line_2,
      city:data?.city,
      state:data?.state,
      zip_code:data?.zip_code,
      country:data?.country?{value:data?.country,label:data?.country?.replace(/\b\w/g, (char) => char.toUpperCase())}:null,
      tax_id:data?.tax_id,
      iaai:data?.iaai,
      corporate_buyer:data?.corporate_buyer,
    })
  },[props?.companyEditInfo])
  
  






 const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 55,
    baseUnit: 4,
  },
});

const menuPortal = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};


const handleClose = () => {
  // setUserUpdateData({...userUpdateData,contact:''})
  props.toggleCompanyEditModal()
};

 const handleChangeOptionCompany = (selected) => {
  setCompanyDetails({ ...companyDetails, country: selected });
};


const onInputchangeCompanyDetails = (e) => {
  setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
};




const onAddCompanyDetails = (e)=>{
  e.preventDefault();
  if(companyDetails.country !== null){
  props.addDetails(companyDetails);
  setCountryFieldError(null);
  
  }else{
  setCountryFieldError("The country field is required");
  }
  }

  
  return (
    <div>
        <div className='card card-custom'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Profile Details</div>
</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Name</div>
  <div className='col-md-8 text-uppercase'> {props.companyEditInfo?.name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Username</div>
  <div className='col-md-8'>{props.companyEditInfo?.username}</div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Primary Email</div>
  <div className='col-md-8 text-uppercase'> {props.companyEditInfo?.email} </div>

</div>


          </div>
        </div>



        <div className='card card-custom mt-3'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-10'>Company Details</div>
<div className='col-md-2'>


<div className="company-button">
<button className="buttonCompany" onClick={()=>{
  // props.companyEdit(id);
setTimeout(() => {
  props.toggleCompanyEditModal()

}, 1000);
  
  }} title="add user's company details">Edit Details</button>
</div>

</div>

</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Company Name</div>
  <div className='col-md-8 text-uppercase'> {props.companyEditInfo?.company_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Company Name</div>
  <div className='col-md-8 text-uppercase'> {props.companyEditInfo?.contact_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>Primary Contact</div>
  <div className='col-md-4 text-uppercase'>{props.companyEditInfo?.contact}</div>
  <div className='col-md-2 text-uppercase'>Secondary Contact</div>
  <div className='col-md-4 text-uppercase'>{props.companyEditInfo?.secondary_contact}</div>
  <div className='col-md-2 text-uppercase'>0ther Contact</div>
  <div className='col-md-4 text-uppercase'>{props.companyEditInfo?.other_contact}</div>

</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>Secondary Email</div>
  <div className='col-md-4 text-uppercase'>{props.companyEditInfo?.secondary_email}</div>
  <div className='col-md-2 text-uppercase'>0ther Email</div>
  <div className='col-md-4 text-uppercase'>{props.companyEditInfo?.other_email} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Address</div>
  <div className='col-md-8 text-uppercase'>
    <div>{props.companyEditInfo?.address} </div>
    <div>{props.companyEditInfo?.zip_code} , {props.companyEditInfo?.state} , {props.companyEditInfo?.country} </div>
   </div>

</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Other Details</div>
  <div className='col-md-8 text-uppercase'>
    <div className='row border-bottom'>
      <div className='col-md-4'>IAAI:</div>
      <div className='col-md-8'>{props.companyEditInfo?.iaai}</div>

    </div>
    <div className='row border-bottom'>
    <div className='col-md-4'>Buyer Corporate:</div>
      <div className='col-md-8'>{props.companyEditInfo?.corporate_buyer}</div>

    </div>


    <div className='row'>
    <div className='col-md-4'>Tax Id:</div>
      <div className='col-md-8'>{props.companyEditInfo?.tax_id}</div>

    </div>

   </div>

</div>


          </div>
        </div>




      {/* ====================================================
      add and update company details
      ==================================================== */}

<Modal show={props.showCompanyEditModal} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Company Details </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onAddCompanyDetails}>
          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={companyDetails.company_name || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

            </div>
            <div className="row">
              <div className="col-md-8 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={companyDetails.contact_name || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

            </div>

            <div className="row">
            <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary Email">
                  <Form.Control
                    type="email"
                    placeholder=" "
                    name="email"
                    value={companyDetails.email || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Secondary Email">
                  <Form.Control
                    type="email"
                    placeholder=" "
                    name="secondary_email"
                    value={companyDetails.secondary_email || ''}
                    
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>


              <div className="col-md-4 mb-3">
                <FloatingLabel label="Other Email">
                  <Form.Control
                    type="email"
                    placeholder=" "
                    name="other_email"
                    value={companyDetails.other_email || ''}
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
            </div>

            

        
<div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Phone Details</span></div>
</div>
</div>


<div className="row">

            <div className="col-md-4 mb-3">
                <FloatingLabel label="Primary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={companyDetails.contact || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Secondary contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_contact"
                    value={companyDetails.secondary_contact || ''}
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Other contact">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="other_contact"
                    value={companyDetails.other_contact || ''}
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              

            </div>


            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Address</span></div>
</div>
</div>



<div className="row">

            <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={companyDetails.address || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={companyDetails.line_2 || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={companyDetails.city || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={companyDetails.state || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={companyDetails.zip_code || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>


              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={companyDetails.country}
                  defaultValue={companyDetails.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''}
              </div>


            </div>



            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Additional Attributes</span></div>
</div>
</div>


<div className="row">

            <div className="col-md-4 mb-3">
                <FloatingLabel label="Tax Id#">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="tax_id"
                    value={companyDetails.tax_id || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <FloatingLabel label="IAAI#">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="iaai"
                    value={companyDetails.iaai || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Corporate buyer#">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="corporate_buyer"
                    value={companyDetails.corporate_buyer || ''}
                    required
                    onChange={(e) => onInputchangeCompanyDetails(e)}
                  />
                </FloatingLabel>
              </div>
              

            </div>
          
            </div> 

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>



              
    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    usersListResponse,
    userDeletionResponse,
    currentPage,
    userEditResponse,
    showModal,
    userUpdateErrors,
    userUpdateResponse,
    name,
    username,
    email,
    password,
    confirm_password,
    searchUsers,
    showCompanyModal,
    companyEditInfo,
    rolePicker,
    showCompanyEditModal,
    addDetailsSuccess,
  } = state.userManagementHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    showCompanyModal:showCompanyModal,
    usersListResponse: usersListResponse,
    userRegistrationResponse: userRegistrationResponse,
    userDeletionResponse: userDeletionResponse,
    userUpdateResponse: userUpdateResponse,
    currentPage: currentPage,
    userEditResponse: userEditResponse.data,
    userUpdateErrors: userUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,
    name: name,
    username: username,
    email: email,
    password: password,
    confirm_password: confirm_password,
    searchUsers: searchUsers,
    companyEditInfo:companyEditInfo?.data,
    showCompanyEditModal:showCompanyEditModal,
    addDetailsSuccess:addDetailsSuccess,
  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),

    userEdit: (id) => dispatch(userEdit(id)),

    hideUpdateModal: () => dispatch(hideUpdateModal()),

    userUpdate: (
      id,
      currentPage,
      name,
      username,
      email,
      contact,
      role,
      password,
      confirmPassword
    ) =>
      dispatch(
        userUpdate(
          id,
          currentPage,
          name,
          username,
          email,
          contact,
          role,
          password,
          confirmPassword
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchUsersList:(querry,currentPage)=>dispatch(searchUsersList(querry,currentPage)),
    rolePicker:()=>dispatch(rolePicker()),
    addDetails:(inputData)=>dispatch(addDetails(inputData)),
    companyEdit:(id)=>dispatch(companyEdit(id)),
    toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
    toggleCompanyEditModal:()=>dispatch(toggleCompanyEditModal()),
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(PersonalInformation) 