const InitialStates = {
    preDataLoadResponse:null,
  
     laoding: false,
     currentPage:1,
   
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

         case "requestMembership_success":
        
         return { ...state, 'requestMembershipResponse': action.payload, 'loading':false };
   
   case "requestMembership_failure":
    return { ...state, 'requestMembershipError': action.payload, 'loading':false };

    case "requestMembershipList_success":
      return { ...state, 'requestListResponse': action.payload, 'loading':false };

    case "acceptOrReject_success":
      return { ...state, 'rejectAcceptResponse': action.payload, 'loading':false };

  case "requestCount_success":
    return { ...state, 'noOfRequests': action.payload, 'loading':false };


      case "searchCustomerRequests_success":
        return { ...state, 'requestListResponse': action.payload, 'loading':false };

        case "acceptOrRejectMultiple_success":
          return { ...state, 'rejectAcceptResponseMultiple': action.payload, 'loading':false };
     case "agreementVerification_success":
      return { ...state, 'agreementResponse': action.payload, 'loading':false };

       default:
         return state;
     }
   };
   