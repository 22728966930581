import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing AutoType settings model
// -------------------------------------------------------------------------
export const hideAutoTypeModal=()=>{
    return {
      type:'hideAutoTypeModal'
    }
    
    }
    export const showAutoTypeModal=()=>{
  
      return {
        type:'showAutoTypeModal'
      }
      
      }
  
  
      export const showEditAutoTypeModal=()=>{
        return {
          type:'showEditAutoTypeModal'
        }
        
        }
      
        export const hideEditAutoTypeModal=()=>{
          return {
            type:'hideEditAutoTypeModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all AutoTypes for settings
// ---------------------------------------------------------------------------------------------------------

export const getAutoTypes = (currentPage) => {



    return (dispatch) => {
        dispatch(getAutoTypeStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/AutoTypes?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getAutoTypeSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getAutoTypeFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getAutoTypeStarted = () => ({
      type: "getAutoType_started",
    });
    
    
    const getAutoTypeSuccess = (data,currentPage) => ({
      type: "getAutoTypesSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getAutoTypeFailure = (error) => ({
      type: "getAutoType_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerAutoType = (name) => {
  
  return (dispatch) => {
    dispatch(registerAutoTypeStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let AutoTypeData={
     type_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/AutoTypeRegister`, AutoTypeData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerAutoTypeSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auto Type has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerAutoTypeFailure(err.response.data.errors));
     
      });
  };
};

const registerAutoTypeStarted = () => ({
  type: "registerAutoType_started",
});

const registerAutoTypeSuccess = (data) => ({
  type: "registerAutoType_success",
  payload: {
    ...data,
  },
});

const registerAutoTypeFailure = (error) => ({
  type: "registerAutoType_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the AutoType edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const AutoTypeEdit = (id) => {

  return (dispatch) => {
    dispatch(AutoTypeEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editAutoType/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AutoTypeEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AutoTypeEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const AutoTypeEditDetailsStarted = () => ({
  type: "AutoTypeEditDetails_started",
});

const AutoTypeEditDetailsSuccess = (data) => ({
  type: "AutoTypeEditDetails_success",
  payload: {
    ...data,
  },
});

const AutoTypeEditDetailsFailure = (error) => ({
  type: "AutoTypeEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the AutoType update 
// ---------------------------------------------------------------------------------------------------------

export const AutoTypeUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(AutoTypeUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let autoTypeData={
        type_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateAutoType/`+id,autoTypeData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(AutoTypeUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Auto Type has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(AutoTypeUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const AutoTypeUpdateStarted = () => ({
    type: "AutoTypeUpdate_started",
  });
  
  
  const AutoTypeUpdateSuccess = (data,currentPage) => ({
    type: "AutoTypeUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const AutoTypeUpdateFailure = (error) => ({
    type: "AutoTypeUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// AutoTypes Deletion
// ---------------------------------------------------------------------------------------------------------

export const AutoTypeDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(AutoTypeDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAutoType/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(AutoTypeDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auto Type has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(AutoTypeDeletionFailure(err.message));
      });
  };
};

const AutoTypeDeletionStarted = () => ({
  type: "AutoTypeDeletion_started",
});

const AutoTypeDeletionSuccess = (data,currentPage) => ({
  type: "AutoTypeDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const AutoTypeDeletionFailure = (error) => ({
  type: "AutoTypeDeletion_failure",
  payload: {
    error,
  },
});
