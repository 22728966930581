const InitialStates = {
  functionalLoader:false,

    preDataLoadResponse:null,
  
     loading: false,
     openConfirmListModal:false,
     openEditLoadListModal:false,
     openRegConsgineeModal:false,
     addConsigneeErrors:null,
     currentPage:1,
     getListCommunication:null,
     openContainerNo:false,
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'openConfirmModal':
        return { ...state, 'openConfirmListModal':true };

        case 'hideConfirmModal':
          return { ...state, 'openConfirmListModal':false };


          case 'openEditLoadingListModal':
            return { ...state, 'openEditLoadListModal':true };
    
            case 'hideEditLoadingListModal':
              return { ...state, 'openEditLoadListModal':false };



              case 'openRegConsigneeModal':
                return { ...state, 'openRegConsgineeModal':true };
        
                case 'hideRegConsigneeModal':
                  return { ...state, 'openRegConsgineeModal':false };



                  case 'openListComunicationModal':
                    return { ...state, 'loadingListCommunication':true };
            
                    case 'hideListComunicationModal':
                      return { ...state, 'loadingListCommunication':false };
    
    
    
                      case 'openEditNoteModal':
                        return { ...state, 'openEditNote':true };
                
                        case 'hideEditNoteModal':
                          return { ...state, 'openEditNote':false };
        
                          case 'openContainerNoModal':
                            return { ...state, 'openContainerNo':true };
                    
                            case 'hideContainerNoModal':
                              return { ...state, 'openContainerNo':false };



//=============================               
//for loading purposes
//=============================
case "getAutos_started":
        
  return { ...state, 'loading':true };

  case "makeLoading_started":
        
  return { ...state, 'functionalLoader':true };


  case "switchLoading_started":
        
    return { ...state, 'loading':true };


    case "confirmOrReject_started":
        
      return { ...state, 'functionalLoader':true };

      case "loadingListDeletion_started":
        
        return { ...state, 'loading':true };

        // case "addConsignee_started":
        
        // return { ...state, 'functionalLoader':true };

        case "getCommunicationLoadingList_started":
        
          return { ...state, 'functionalLoader':true };

          case "editSelectedNotes_started":
        
            return { ...state, 'functionalLoader':true };

            case "listCommunication_started":
        
              return { ...state, 'functionalLoader':true };


            case "deleteSelectedNotes_started":
        
              return { ...state, 'functionalLoader':true };


            case "editContainer_started":
        
              return { ...state, 'functionalLoader':true };

              case "addContainerNumber_started":
        
                return { ...state, 'functionalLoader':true };


// =========ends here ===============




              case "getConsigneePickers_success":
                return { ...state, 'consigneePicker': action.payload, 'loading':false ,'functionalLoader':false};


 
      case "getAutos_started":
        return { ...state, 'loading':true };

        case "getAutosCountLists_success":
          return { ...state, 'countLoadLists': action.payload, 'loading':false };



     
      case "getAutos_success":
        
         return { ...state, 'autosDataByWarehouse': action.payload, 'loading':false };
   case "getSelectedAutos_success":

            return { ...state, 'selectedAutosConfirm': action.payload, 'loading':false };

   case "makeLoading_success":
    return { ...state, 'loadingListCreated': action.payload, 'loading':false , 'openConfirmListModal':false};

    case "switchLoading_success":
    return { ...state, 'catagoryLists': action.payload, 'loading':false ,'functionalLoader':false};

    case"confirmOrReject_success":
    return { ...state, 'confirmedOrRejectStatus': action.payload, 'loading':false ,'functionalLoader':false};

    case "loadingListDeletion_success":
      return { ...state, 'loadingListdeleted': action.payload, 'loading':false ,'functionalLoader':false};


      case"registerconsignee_success":
      return { ...state, 'consigneeRegistered': action.payload,'currentPage':action.payload.currentPage, 'loading':false ,'functionalLoader':false, 'openRegConsgineeModal':false,'addConsigneeErrors':null};
   
      // case "registerconsignee_success":
      //   return { ...state, 'registerconsigneeSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
        
       

case"addConsignee_failure":
return { ...state, 'addConsigneeErrors': action.payload, 'loading':false ,'functionalLoader':false };

case "listAutoDeletion_success":
return { ...state, 'deleteAutoFromLoadingListS': action.payload, 'loading':false ,'functionalLoader':false };

case "addAutosToList_success":
return { ...state, 'addAutosToListEdit': action.payload, 'loading':false,'openEditLoadListModal':false,'openConfirmListModal':false ,'functionalLoader':false };


case "getCommunicationLoadingList_success":
  return { ...state, 'getListCommunication': action.payload, 'loading':false ,'functionalLoader':false};


case "listCommunication_success":
  return { ...state, 'listCommunication_success': action.payload, 'loading':false, 'textAreaValue':'' ,'functionalLoader':false};


case "deleteSelectedNotes_success":
  return { ...state, 'deletedSelectedNotes': action.payload, 'loading':false, 'textAreaValue':'' ,'functionalLoader':false};
case "getEditNoteDetails_success":
  return { ...state, 'getEditNoteInfo': action.payload, 'loading':false, 'functionalLoader':false};


case "editSelectedNotes_success":
  return { ...state, 'editedSelectedNote': action.payload, 'loading':false,'openEditNote':false, 'attachmentValue':''  ,'functionalLoader':false};

case "editContainer_success":
  return { ...state, 'containerNoInfo': action.payload, 'loading':false ,'functionalLoader':false, };

case "addContainerNumber_success":
  return { ...state, 'addContNo': action.payload, 'loading':false,'openContainerNo':false ,'functionalLoader':false, };




       default:
         return state;
     }
   };
   