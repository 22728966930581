import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing biller settings model
// -------------------------------------------------------------------------
export const hidebillerModal=()=>{
    return {
      type:'hidebillerModal'
    }
    
    }
    export const showbillerModal=()=>{
  
      return {
        type:'showbillerModal'
      }
      
      }
  
  
      export const showEditbillerModal=()=>{
        return {
          type:'showEditbillerModal'
        }
        
        }
      
        export const hideEditbillerModal=()=>{
          return {
            type:'hideEditbillerModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all billers for settings
// ---------------------------------------------------------------------------------------------------------

export const getbillers = (currentPage) => {



    return (dispatch) => {
        dispatch(getbillerStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/billers?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getbillerSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getbillerFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getbillerStarted = () => ({
      type: "getbiller_started",
    });
    
    
    const getbillerSuccess = (data,currentPage) => ({
      type: "getbillersSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getbillerFailure = (error) => ({
      type: "getbiller_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerbiller = (name,id,email,address,contact) => {
  //console.log(id);
  
  return (dispatch) => {
    dispatch(registerbillerStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let billerData={
     biller_name:name,
     customers_id:id,
     email:email,
     address:address,
     contact:contact
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/billerRegister`, billerData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerbillerSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The biller has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerbillerFailure(err.response.data.errors));
     
      });
  };
};

const registerbillerStarted = () => ({
  type: "registerbiller_started",
});

const registerbillerSuccess = (data) => ({
  type: "registerbiller_success",
  payload: {
    ...data,
  },
});

const registerbillerFailure = (error) => ({
  type: "registerbiller_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// the biller edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const billerUsersSelector = (q) => {

  return (dispatch) => {
    dispatch(billerUsersSelectorStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   
let data={
  q:q
}

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/billerUsersSelector`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(billerUsersSelectorSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(billerUsersSelectorFailure(err.response.data.errors));
     
      });
  };
};

const billerUsersSelectorStarted = () => ({
  type: "billerUsersSelector_started",
});

const billerUsersSelectorSuccess = (data) => ({
  type: "billerUsersSelector_success",
  payload: {
    ...data,
  },
});

const billerUsersSelectorFailure = (error) => ({
  type: "billerUsersSelector_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// the biller edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const billerEdit = (id) => {

  return (dispatch) => {
    dispatch(billerEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editbiller/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(billerEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(billerEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const billerEditDetailsStarted = () => ({
  type: "billerEditDetails_started",
});

const billerEditDetailsSuccess = (data) => ({
  type: "billerEditDetails_success",
  payload: {
    ...data,
  },
});

const billerEditDetailsFailure = (error) => ({
  type: "billerEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the biller update 
// ---------------------------------------------------------------------------------------------------------

export const billerUpdate = (id,name,customerId,currentPage,contact,email,address) => {
    return (dispatch) => {
      dispatch(billerUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        customers_id:customerId,
        biller_name:name,
        contact:contact,
        email:email,
        address:address
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updatebiller/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(billerUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The biller has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(billerUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const billerUpdateStarted = () => ({
    type: "billerUpdate_started",
  });
  
  
  const billerUpdateSuccess = (data,currentPage) => ({
    type: "billerUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const billerUpdateFailure = (error) => ({
    type: "billerUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// billers Deletion
// ---------------------------------------------------------------------------------------------------------

export const billerDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(billerDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deletebiller/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(billerDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The biller has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(billerDeletionFailure(err.message));
      });
  };
};

const billerDeletionStarted = () => ({
  type: "billerDeletion_started",
});

const billerDeletionSuccess = (data,currentPage) => ({
  type: "billerDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const billerDeletionFailure = (error) => ({
  type: "billerDeletion_failure",
  payload: {
    error,
  },
});
