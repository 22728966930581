import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import { loadUsersOptions } from "../../utils/asyncOptionsUtils";
import {consigneeUsersSelector,consigneeUpdate,hideconsigneeModal,showconsigneeModal,showEditconsigneeModal,hideEditconsigneeModal,getconsignees,consigneeEdit,inputHandler,registerconsignee, consigneeDelete,searchConsigneeList} from "../../../redux/actions";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import { countryList } from "../../utils/countryUtils";
import NodataFound from '../error/noDataFoundMain';
import axios from 'axios';
import { can } from "../../utils/roleUtils";
var CryptoJS = require("crypto-js");

function Consignees(props) {

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

// const [addconsigneeData,setAddconsigneeData] = useState({name:''});
const [consigneeData,setconsigneeData] = useState({onId:'',company_name:'',contact_name:'',email:'',secondary_email:'',phone:'', secondary_phone:'',address:'',line_2:'',city:'',state:'',zip_code:'',country:null });
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);
const [value, onChange] = useState([]);
const [editValue, setEditValue] = useState([]);
const [searched, setSearched] = useState(false);


  useEffect(() => {
    props.getconsignees(props.currentPage);
    if(props.registerconsigneeSuccess){
      onChange(null)
    }
  }, [props.registerconsigneeSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
  let data=props.editDetails?.data
setconsigneeData({...consigneeData,
  'company_name':data?.company_name||'',
  'contact_name':data?.contact_name||'',
  'email':data?.email||'',
  'secondary_email':data?.secondary_email||'',
  'phone':data?.phone||'',
  'secondary_phone':data?.secondary_phone||'',
  'address':data?.address||'',
  'line_2':data?.line_2||'',
  'city':data?.city||'',
  'state':data?.state||'',
  'zip_code':data?.zip_code||'',
  'country':data?.country?{value:data?.country,label:data?.country?.replace(/\b\w/g, (char) => char.toUpperCase())}:null,


})
setEditValue({label:props.editDetails?.data?.customerName, value:props.editDetails?.data?.userId})
},[props.editDetails])



let countryOptions=countryList;



const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 55,
    baseUnit: 4,
  },
});

const menuPortal = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};



const handleChangeOptionCompany = (selected) => {
  setconsigneeData({ ...consigneeData, country: selected });
};



  const onInputchange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setconsigneeData({ ...consigneeData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setconsigneeData({...consigneeData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hideconsigneeModal()

  };
  const handleShow = () => {
    setconsigneeData({...consigneeData,company_name:'',contact_name:'',email:'',secondary_email:'',phone:'', secondary_phone:'',address:'',line_2:'',city:'',state:'',zip_code:'',country:null })

    props.showconsigneeModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditconsigneeModal();

  };

  const handleEditShow=()=>{
    setTimeout(() => {
      props.showEditconsigneeModal()

    }, 500);

  }






  const defaultAdditional = {
    page: 1
  };
  
  




const deleteconsignee = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this consignee",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.consigneeDelete(id, props.currentPage);
    }
  });
};



const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.getconsignees(props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}



const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchConsignee){
  setSearched(true);
    props.searchConsigneeList(null,props.searchConsignee,props.currentPage)
    }else{
      setSearched(false);
     
    }
  
  }


const submitconsignee = (e) => {
  e.preventDefault();

    props.registerconsignee(value.value,consigneeData)
  
}


const Updateconsignee = (e) => {
  e.preventDefault();

  let currentPage=props.consigneesList?.data?.current_page;

  props.consigneeUpdate(editValue.value,currentPage,consigneeData)

 

  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>
<div className="header-outer">
      <div className="row">
      <div className="col-md-7"> 
        
     <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add consignee</a></div> 
      </div>
    

     
        </div>
        </div>

        { 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchConsignee}</span></p></div>


}
{props.consigneesList?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}




        <div className="row text-center pt-5" style={{marginTop:'12px'}}>
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.consigneesList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-12 col-sm-12 col-md-12 mb-4 card-outer"
                  key={index}
                >
                              <div className="user-title text-uppercase">{list?.username}</div>

            <div
              className="shadow-sm py-2 px-4 settings-list-card "
            >
              <div className="row">
                <div className="col-md-3">
                <span className="label-car">Company Name:</span>  


                </div>
                <div className="col-md-3">

                <span className="title-car">{list?.company_name}</span>
                </div>

                <div className="col-md-3">
                <span className="label-car">Contact Name:</span>  


                </div>
                <div className="col-md-3">

                <span className="title-car">{list?.contact_name}</span>
                </div>

              </div>
              <hr/>
              <div className="row mt-2">
             

                <div className="col-md-3">
                <span className="label-car">Email:</span>  


                </div>
                <div className="col-md-3">

                <span className="title-car">{list?.email}</span>
                </div>
                
                <div className="col-md-3">
                <span className="label-car">Secondary Email:</span>  


                </div>
                <div className="col-md-3">

                <span className="title-car">{list.secondary_email}</span>
                </div>


              </div>
             
<hr/>
              <div className="row mt-2">
             

             <div className="col-md-3">
             <span className="label-car">Contact:</span>  


             </div>
             <div className="col-md-3">

             <span className="title-car">{list.phone}</span>
             </div>


             <div className="col-md-3">
             <span className="label-car">Secondary Contact:</span>  


             </div>
             <div className="col-md-3">

             <span className="title-car">{list.secondary_phone}</span>
             </div>
             

           </div>

           <hr/>
             <div className="row mt-2">
           <div className="col-md-3">
Address
           </div>

           <div className="col-md-9">
<div className="row">
  <div className="col-md-12">
<span className="title-car">{list.address}</span>
  </div>
  <div className="col-md-12">
<span className="title-car">{list.line_2}</span>
  </div>
  <div className="col-12">
    <span className="title-car">{list.state}</span> {list.city?',':null} <span className="title-car">{list.city}</span> {list.country?',':null} <span className="title-car">{list.country}</span>
  </div>
</div>

           </div>
             </div>
             
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
            
      
              <ul className="social mb-0 list-inline mt-3">
                
              { can(currentUserPermissions,'Allow Consignee Edit')? <li className="list-inline-item settings-links">
                  <a onClick={
                    ()=>{
                      setconsigneeData({...consigneeData,'onId':list.id})
                      props.consigneeEdit(list.id)
                      setTimeout(()=>{
                        handleEditShow()

                      },190)

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>:null}
                {can(currentUserPermissions,'Allow Consignee Delete')?
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deleteconsignee(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>:null}
              </ul>
            </div>





          </div>
              );
            })}

          
            {props.consigneesList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.consigneesList?.data?.current_page}
              itemsCountPerPage={props.consigneesList?.data?.per_page}
              totalItemsCount={props.consigneesList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getconsignees(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
          
        </div>
      )}

        </div>

        <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchConsignee" autoComplete="off" className="form-control" required title='Search by Company Name or Email or Phone'
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

      
      </div>


{/* ======================
Add consignee Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitconsignee}>
          <Modal.Body 
           style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          >
            <div className="row">
{    can(currentUserPermissions,'Allow Customer Consignee Registration')?

            <div className="col-md-6 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this consignee
                </label>
         <AsyncPaginate
           className="async-paginate-inner-container"

         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadUsersOptions}
        onChange={onChange}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>:null
}

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={consigneeData?.company_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.consigneeRegisterErrors?.error?.company_name?true:false}
                  />

                </FloatingLabel>

                {props.consigneeRegisterErrors?.error?.company_name ? (
                  <div className="validation-error">
                    The consignee name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>





              <div className="col-md-12 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={consigneeData?.contact_name}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>




              <div className="col-md-6 mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_email"
                    value={consigneeData?.secondary_email}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData?.phone}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_phone"
                    value={consigneeData?.secondary_phone}
                    
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={consigneeData?.line_2}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={consigneeData?.state}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={consigneeData?.city}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={consigneeData?.zip_code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={consigneeData.country}
                  defaultValue={consigneeData.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {/* {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''} */}
              </div>



            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit consignee Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updateconsignee}>
          <Modal.Body 
           style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          >
            <div className="row">

            <div className="col-md-6 mb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Customer to whom you want to add this consignee
                </label>
         <AsyncPaginate
         className="async-paginate-inner-container"
         debounceTimeout={300}
        additional={defaultAdditional}
        value={editValue}
        loadOptions={loadUsersOptions}
        onChange={setEditValue}
        // closeMenuOnSelect={false}
        isClearable
      />


            </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={consigneeData.company_name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.consigneeUpdateErrors?.error?.company_name ?true:false}
                  />

                </FloatingLabel>

                {props.consigneeUpdateErrors?.error?.company_name ? (
                  <div className="validation-error">
                    The consignee name already exists, you can't update it with this consignee name !
                  </div>
                ) : (
                  ""
                )}

              </div>



              <div className="col-md-12 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={consigneeData.contact_name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData.email||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>
                </div>


                <div className="col-md-6 mb-3">
                <FloatingLabel label="Consignee Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_email"
                    value={consigneeData.secondary_email||''}
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>
                </div>
              <div className="col-md-6 mb-3">
                <FloatingLabel label="Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData.phone||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>
</div>
                <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_phone"
                    value={consigneeData.secondary_phone||''}
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData.address||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={consigneeData.line_2||''}
                    
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>

            


              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={consigneeData.state||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              

              </div>


              <div className="col-md-3 mb-3">
                <FloatingLabel label="city">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={consigneeData.city||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              </div>

              

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={consigneeData.zip_code||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

              </div>

              

              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={consigneeData.country}
                  defaultValue={consigneeData.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {/* {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''} */}
              </div>




            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {address,country,phone,email,consigneeCustomersSelector,loading,consigneesList,showWarModal,showEditWarModal,name,registerconsigneeSuccess,consigneeRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,consigneeUpdateErrors} = state.SettingsConsigneeHandler;
  const {searchConsignee}=state.userManagementHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    name:name,
    address:address,
    phone:phone,
    country:country,
    email:email,
    consigneeRegisterErrors:consigneeRegisterErrors,
    currentPage:currentPage,
    consigneesList:consigneesList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerconsigneeSuccess:registerconsigneeSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    consigneeUpdateErrors:consigneeUpdateErrors,
    consigneeCustomersSelector:consigneeCustomersSelector,
    searchConsignee:searchConsignee,
    preDataLoadResponse:preDataLoadResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getconsignees:(currentPage)=>dispatch(getconsignees(currentPage)),
      showconsigneeModal:()=>dispatch(showconsigneeModal()),
      hideconsigneeModal:()=>dispatch(hideconsigneeModal()),
      showEditconsigneeModal:()=>dispatch(showEditconsigneeModal()),
      hideEditconsigneeModal:()=>dispatch(hideEditconsigneeModal()),
      registerconsignee:(usersId,data)=>dispatch(registerconsignee(usersId,data)),
      consigneeEdit:(id)=>dispatch(consigneeEdit(id)),
      consigneeUpdate:(usersId,currentPage,data)=>dispatch(consigneeUpdate(usersId,currentPage,data)),
      consigneeDelete:(id,currentPage)=>dispatch(consigneeDelete(id,currentPage)),
      consigneeUsersSelector:(q)=>dispatch(consigneeUsersSelector(q)),
      searchConsigneeList:(id,q,pageNumber)=>dispatch(searchConsigneeList(id,q,pageNumber)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Consignees);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================