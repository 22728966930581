



import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");







// ---------------------------------------------------------------------------------------------------------
// pdf Customs
// ---------------------------------------------------------------------------------------------------------

export const customsPdf = (id) => {



    return (dispatch) => {
        dispatch(customsPdfStarted());
  
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       


    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/customsPdfInfo/`+id,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              console.log(response.data);
              dispatch(customsPdfSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(customsPdfFailure(err.response.data.errors));
  
          });
      };
    };
    
    const customsPdfStarted = () => ({
      type: "customsPdf_started",
    });
    
    
    const customsPdfSuccess = (data) => ({
      type: "customsPdf_success",
      payload: {
        ...data,
      },
    });
    
    const customsPdfFailure = (error) => ({
      type: "customsPdf_failure",
      payload: {
        error,
      }, 
    });
  
  
  
  
  


// ---------------------------------------------------------------------------------------------------------
// pdf Customs
// ---------------------------------------------------------------------------------------------------------

export const nonHazardousPdf = (id) => {



  return (dispatch) => {
      dispatch(nonHazardousPdfStarted());

  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     


  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/nonHazardousPdfInfo/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(nonHazardousPdfSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(nonHazardousPdfFailure(err.response.data.errors));

        });
    };
  };
  
  const nonHazardousPdfStarted = () => ({
    type: "nonHazardousPdf_started",
  });
  
  
  const nonHazardousPdfSuccess = (data) => ({
    type: "nonHazardousPdf_success",
    payload: {
      ...data,
    },
  });
  
  const nonHazardousPdfFailure = (error) => ({
    type: "nonHazardousPdf_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// pdf Customs
// ---------------------------------------------------------------------------------------------------------

export const bLInstructionPdf = (id) => {



  return (dispatch) => {
      dispatch(bLInstructionPdfStarted());

  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     


  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/blConstructionPdfInfo/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(bLInstructionPdfSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(bLInstructionPdfFailure(err.response.data.errors));

        });
    };
  };
  
  const bLInstructionPdfStarted = () => ({
    type: "bLInstructionPdf_started",
  });
  
  
  const bLInstructionPdfSuccess = (data) => ({
    type: "bLInstructionPdf_success",
    payload: {
      ...data,
    },
  });
  
  const bLInstructionPdfFailure = (error) => ({
    type: "bLInstructionPdf_failure",
    payload: {
      error,
    }, 
  });



