const InitialStates = {
    AutoTypesList:null,
  
    currentPage: 1,
   
    showAutoTypeModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    AutoTypesList:null,
    AutoTypeRegisterErrors:null,
    AutoTypeUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showAutoTypeModal":
      return { ...state, 'showWarModal':true };

      case "hideAutoTypeModal":
        return { ...state, 'showWarModal':false, 'AutoTypeRegisterErrors':'', 'name':'' };
  

        case "showEditAutoTypeModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditAutoTypeModal":
            return { ...state, 'showEditWarModal':false, 'AutoTypeUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================

            case "getAutoType_started":
              return {...state, 'loading':true}
    case "registerAutoType_started":
              return {...state, 'loading':true}

              // case "AutoTypeEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "AutoTypeUpdate_started":
              //     return {...state, 'loading':true}

// ===================end here====================

       case "getAutoTypesSettings_success":

         return { ...state, 'AutoTypesList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerAutoType_success":
    return { ...state, 'registerAutoTypeSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "AutoTypeEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "AutoTypeUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "AutoTypeDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerAutoType_failure":
    return { ...state, 'AutoTypeRegisterErrors': action.payload,  'loading':false };

case "AutoTypeUpdate_failure":
  return { ...state, 'AutoTypeUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   