import React, { useState, useEffect } from "react";

import { Outlet,NavLink,Link,useLocation,useParams,useMatch, } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next'
import {activityPerSubject,selectedActivitiesDelete} from '../../../../redux/actions';
import { calculateTotal } from "../../../utils/chargesUtils";
import SimpleCourousel from "../../../elements/SimpleCourousel";
import moment from 'moment'
import _ from 'lodash';
import {goBack} from '../../../utils/historyUtils'
import PerSubjectLogBox from "../../../elements/PerSubjectLogBox";
import Checkbox from '../../../elements/Checkbox';
import {can} from '../../../utils/roleUtils';

function AutoLog(props) {

  const [id, setId] = useState('');
  
  const [isCheck, setIsCheck] = useState([]);



  const params = useParams();
  const {t}=useTranslation();


  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
props.activityPerSubject(decryptValue,props.currentPage)

if(props){
    setIsCheck([]);
}

   }
   },[props.ActivityListDeletionSuccess])




   const handleClick = e => {
    const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
   
  };

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;








const autoDetails = props.getAdditionalRegDataSuccess;






const onSelectedDelete=()=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the selected logs",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.selectedActivitiesDelete(isCheck)
        }
    });
    
    
    }
    
  
  return (
    <div>
        
        { isCheck.length>0?<span className="deleteSelectedOuterLayer"><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={onSelectedDelete}>Delete ({(isCheck.length)})</a></span>:''}

        {props.ActivityListPerSubjectSuccess?.data?.data.map((list,index)=>{
  return (

    <PerSubjectLogBox key={index} postDateRelative={moment(list.created_at).add(5,'hours').fromNow()}
      postDate={moment(list.created_at).add(5,'hours').format('dddd DD-MM-YYYY')} 
      title={list.subject} 
      gap={3}
      line={true} 
      status={list.log_name}
      bodyText={list.body}
      causer={list.causer_name}
      logSubject={list.identifier}
      make={list.make}
      year={list.year}
      model={list.model}
      vin={list.vin} //to check for view
      details={list.properties}
      subject_id={list.subject_id}


      // mediaQueryClass={'handleMedia'}
      checkboxProp={
        can(currentUserPermissions,'Delete Vehicle Logs')?
<Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />:''

            }

      width={   'calc(100% - 23px)'}
      >



      </PerSubjectLogBox>

  )
})
}

    
     
    </div>
  )
}




const mapStateToProps = (state) => {

    const {loading,currentPage } = state.AutosHandler;
 const{ActivityListPerSubjectSuccess,ActivityListDeletionSuccess}= state.LogsActivityHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading:loading,
    currentPage:currentPage,
    ActivityListPerSubjectSuccess:ActivityListPerSubjectSuccess,
    preDataLoadResponse:preDataLoadResponse,
    ActivityListDeletionSuccess:ActivityListDeletionSuccess,
 
  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    activityPerSubject:(id,currentPage)=>dispatch(activityPerSubject(id,currentPage)),
    selectedActivitiesDelete:(ids)=>dispatch(selectedActivitiesDelete(ids)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(AutoLog) 