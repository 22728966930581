import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {useParams,Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Pagination from "react-js-pagination";
import NodataFound from '../../error/noDataFoundMain';
import {goBack} from '../../../utils/historyUtils'

import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker,
  addDetails,
  companyEdit,
  toggleCompanyModal,
  perUserContainers,
  searchContainersPerUser,
} from "../../../../redux/actions";

function UserContainers(props) {

  const [id, setId] = useState('');
  const [searched, setSearched] = useState(false);

  const params = useParams();
  const {t}=useTranslation();



  

useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
props.perUserContainers(decryptValue,props.currentPage) }
 },[])
 

 const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.perUserContainers(id,props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchContainers){
  setSearched(true);
    props.searchContainersPerUser(props.searchContainers,id,props.currentPage)
    }else{
      setSearched(false);
     
  
    }
  
  }


  const handleSearchPages=(pageNumber)=>{
    props.searchContainersPerUser(props.searchContainers,id,pageNumber)
  
  }

  
  return (
    <div>
        <div className='vehicle-card'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-4 text-uppercase'>{props.companyEditInfo?.name} Containers </div>

</div>
</div>
  </div>




<div className="gap-vehicle"></div>
<div className="scroller layer-outer-vehicle mt-2">

{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchContainers}</span></p></div>


}
{props.userBasedContainers?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}


  {props.userBasedContainers?.data?.data.map((item,index)=>{
    return(
      <div class="card card-custom mt-3" key={index}>
      <div class="row no-gutters">
        
        <div class="col-md-1">
        <div className="vehicle-status">
          {item.cont_catagory}
        </div>
        
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <div className="row">

              <div className="col-md-6">
              <span className="label-car">Container No:</span>  <span className="title-car"> {item?.container_no}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Booking No:</span> <span className="title-car">{item?.cont_booking_no}</span>
              </div>
              </div>
              <div className="row">
              <div className="col-md-6">
              <span className="label-car">Container Size:</span> <span className="title-car">{item?.cont_container_size}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Paid Status:</span> <span className="title-car">{item?.cont_paid_status}</span>
              </div>
              </div>
              <div className="row">
              <div className="col-md-6">
              <span className="label-car">Port:</span> <span className="title-car">{item?.cont_port} {item.cont_country_port}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Port Discharge:</span> <span className="title-car">{item?.cont_port_discharge} {item?.cont_country_discharge}</span>
              </div>
            </div>

            <Link  to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(item?.id?.toString(),'autoListAli_123'))}`}  className="details-button">View Details</Link>


           
          </div>
        </div>
      </div>


    </div>
    )
  })}
 


 <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchContainers" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

</div>


{ props.userBasedContainers?.data?.data.length===0 ? '':

<div>
      {props.userBasedContainers?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.userBasedContainers?.data.current_page}
              itemsCountPerPage={props.userBasedContainers?.data.per_page}
              totalItemsCount={props.userBasedContainers?.data.total}
              //   pageRangeDisplayed={5}
              onChange={
                searched==true?handleSearchPages:
               (pageNumber) => props.perUserContainers(id,pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}

    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    usersListResponse,
    userDeletionResponse,
    currentPage,
    userEditResponse,
    showModal,
    userUpdateErrors,
    userUpdateResponse,
    name,
    username,
    email,
    password,
    confirm_password,
    searchUsers,
    showCompanyModal,
    companyEditInfo,
    rolePicker,
    userBasedContainers,
    searchContainers,
  } = state.userManagementHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    showCompanyModal:showCompanyModal,
    usersListResponse: usersListResponse,
    userRegistrationResponse: userRegistrationResponse,
    userDeletionResponse: userDeletionResponse,
    userUpdateResponse: userUpdateResponse,
    currentPage: currentPage,
    userEditResponse: userEditResponse.data,
    userUpdateErrors: userUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,
    name: name,
    searchContainers:searchContainers,
    username: username,
    email: email,
    password: password,
    confirm_password: confirm_password,
    searchUsers: searchUsers,
    companyEditInfo:companyEditInfo?.data,
    userBasedContainers:userBasedContainers
  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),

    userEdit: (id) => dispatch(userEdit(id)),

    hideUpdateModal: () => dispatch(hideUpdateModal()),

    userUpdate: (
      id,
      currentPage,
      name,
      username,
      email,
      contact,
      role,
      password,
      confirmPassword
    ) =>
      dispatch(
        userUpdate(
          id,
          currentPage,
          name,
          username,
          email,
          contact,
          role,
          password,
          confirmPassword
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchUsersList:(querry,currentPage)=>dispatch(searchUsersList(querry,currentPage)),
    rolePicker:()=>dispatch(rolePicker()),
    addDetails:(inputData)=>dispatch(addDetails(inputData)),
    companyEdit:(id)=>dispatch(companyEdit(id)),
    toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
    perUserContainers:(id,pageNumber)=>dispatch(perUserContainers(id,pageNumber)),
    searchContainersPerUser:(querry,userId,currentPage)=>dispatch(searchContainersPerUser(querry,userId,currentPage)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(UserContainers) 