import React,{useState,useEffect} from 'react';
import RequestBox from '../../../../elements/RequestsBox';
import { useTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Logo from "../../../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Loader from "../../../../elements/Loader";
import { connect } from "react-redux";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Pagination from "react-js-pagination";
import moment from 'moment';
import Swal from 'sweetalert2';
import { AsyncPaginate } from "react-select-async-paginate";
import NodataFound from '../../../../pages/error/noDataFoundMain';
import {loadUsersOptions} from '../../../../utils/asyncOptionsUtils';
import {
    inputHandler,
    requestMembershipList,
    acceptOrReject,
    searchCustomerRequests,
    acceptOrRejectMultiple,
    getRequestsCount,
  } from "../../../../../redux/actions";

import Checkbox from '../../../../elements/Checkbox';
import {can} from '../../../../utils/roleUtils';

function RequestList(props) {
    const {t} =useTranslation();




    const [isCheck, setIsCheck] = useState([]);
    const [headerSearch, setHeaderSearch] = useState('notificationSearchHeaderSticky');
    const [searched, setSearched] = useState(false);

    // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
    const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



useEffect(()=>{
props.requestMembershipList();
if(props?.rejectAcceptResponse || props?.rejectAcceptResponseMultiple){
  setIsCheck([])
  props.getRequestsCount();
}
},[props.rejectAcceptResponse,props.rejectAcceptResponseMultiple])



const listenScrollEvent = () => {
  if (window.scrollY > 99) {
    return setHeaderSearch("notificationSearchHeaderFixed");
  } else if (window.scrollY < 80) {
    return setHeaderSearch("notificationSearchHeaderSticky");
  }
};

useEffect(() => {
  window.removeEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEvent);
  return () => window.removeEventListener("scroll", listenScrollEvent);
}, []);




const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    props.requestMembershipList(props?.currentPage);
    setSearched(false);

  }
  else{
    // console.log(e.target.value)

    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

const onSearch=(e)=>{
e.preventDefault();
  if(props.searchRequests){
setSearched(true);
  props.searchCustomerRequests(props.searchRequests,props.currentPage)
  }else{
    setSearched(false);
   

  }

}




const defaultAdditional = {
  page: 1
};







const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};




const handleSearchPages=(pageNumber)=>{
  props.searchCustomerRequests(props.searchRequests,pageNumber)

}




const onSelectedDelete=()=>{


Swal.fire({
  title: "Are you sure?",
  text: "You want to delete the selected notifications",
  icon: "warning",
  width: 400,
  showCancelButton: true,
  timer: 4000,
  timerProgressBar: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
}).then((result) => {
  if (result.isConfirmed) {
    props.selectedNotificationsDelete(isCheck)
    }
});


}




const onAccept=(id)=>{


    Swal.fire({
      title: "Accept Customer?",
      text: "It will create an account for this customer, which can be access through Users Management",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
            props.acceptOrReject(id,'accepted',process.env.REACT_APP_LINK_ADDRESS)
        }
    });
    
    
    }





    const onReject=(id)=>{


        Swal.fire({
          title: "Reject Customer?",
          text: "It will reject the customer.",
          icon: "warning",
          width: 400,
          showCancelButton: true,
          timer: 4000,
          timerProgressBar: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            props.acceptOrReject(id,'rejected',process.env.REACT_APP_LINK_ADDRESS)
            }
        });
        
        
        }
    



        const onAcceptMultiple=()=>{


          Swal.fire({
            title: "Accept Customers?",
            text: "It will create accounts for these customers, which can be access through Users Management",
            icon: "warning",
            width: 400,
            showCancelButton: true,
            timer: 4000,
            timerProgressBar: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
                  props.acceptOrRejectMultiple(isCheck,'accepted',process.env.REACT_APP_LINK_ADDRESS)
              }
          });
          
          
          }
      
      


        const onRejectMultiple=()=>{


          Swal.fire({
            title: "Reject Customers?",
            text: "It will reject these customers.",
            icon: "warning",
            width: 400,
            showCancelButton: true,
            timer: 4000,
            timerProgressBar: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              props.acceptOrRejectMultiple(isCheck,'rejected',process.env.REACT_APP_LINK_ADDRESS)
              }
          });
          
          
          }




  return (

<>

<div className={headerSearch}>
       <form onSubmit={onSearch}>
        <div className="row">
        <div className="col-md-2"></div>

       
          <div className="col-md-8  searchOuterLayer">
      
      <div className="input-group" >
  <div className="form-outline searchOutline">
   <span><input type="search" name="searchRequests" className="form-control search" onChange={(e)=>onSearchInputChange(e)} placeholder="Seach by username or phone" autoComplete="off" required/></span> 
   { isCheck.length>0?<span className="deleteSelectedOuterLayer" ><a className="delete-all-autos" style={{textDecoration:'none',background:'green',border:'1px solid green'}} onClick={onAcceptMultiple}>Accept ({(isCheck.length)})</a></span>:''}
   { isCheck.length>0?<span className="deleteSelectedOuterLayer2" ><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={onRejectMultiple}>Reject ({(isCheck.length)})</a></span>:''}

  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>

          </div>
          
        </div>
        </form>

        </div>





<div className='py-3'></div>
{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchRequests}</span></p></div>
}
{props.requestListResponse?.data?.data?.length===0 && <NodataFound notFoundText={"No Notification Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}

{props.requestListResponse?.data?.data.map((list,index)=>{
  return (

    <RequestBox key={index} postDateRelative={moment(list.created_at).add(5,'hours').fromNow()}
      postDate={moment(list.created_at).add(5,'hours').format('dddd DD-MM-YYYY')} 
      title={list.subject} 
      gap={3}
      line={true} 
      dataObject={list}
      messageImage={"GA-01.png"}
      // mediaQueryClass={'handleMedia'}
      checkboxProp={
        can(currentUserPermissions,'Allow Requests Approval Selection')?
<Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />:''

            }

      width={   'calc(100% - 23px)'}
      >

{can(currentUserPermissions,'Allow Requests Approval')?
<ul>
  {/* <li><i className="fas fa-eye"></i></li> */}

  <li style={{cursor:'pointer'}} onClick={()=>{onAccept(list.id)}}>
    <i className="fas fa-check"></i>
</li>
<li style={{cursor:'pointer'}}  onClick={()=>{onReject(list.id)}}>
    <i className="fas fa-times"></i>
</li>

</ul>:null}


      </RequestBox>

  )
})
}





{ props.requestListResponse?.data?.data?.length===0 ? '':
<div>
      {props.requestListResponse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.requestListResponse?.data.current_page}
              itemsCountPerPage={props.requestListResponse?.data.per_page}
              totalItemsCount={props.requestListResponse?.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched==true? handleSearchPages:(pageNumber) => props.NotificationsList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}





</>



    )
}




const mapStateToProps = (state) => {

 
    const { preDataLoadResponse } = state.preDataHandler;
    const { loading,requestListResponse,rejectAcceptResponse, noOfRequests,searchRequests,rejectAcceptResponseMultiple,currentPage} = state.CustomerRequestHandler;

  
    return {
   preDataLoadResponse:preDataLoadResponse,
   loading,
   requestListResponse:requestListResponse,
   rejectAcceptResponse:rejectAcceptResponse,
   noOfRequests:noOfRequests,
   searchRequests:searchRequests,
   currentPage:currentPage,
   rejectAcceptResponseMultiple:rejectAcceptResponseMultiple,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        searchCustomerRequests:(querry,currentPage)=>dispatch(searchCustomerRequests(querry,currentPage)),
        inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
        requestMembershipList:()=>dispatch(requestMembershipList()),
        acceptOrReject:(id,status,link_address)=>dispatch(acceptOrReject(id,status,link_address)),
        acceptOrRejectMultiple:(ids,status,link_address)=>dispatch(acceptOrRejectMultiple(ids,status,link_address)),
        getRequestsCount:()=>dispatch(getRequestsCount()),
    };
  };
  





export default connect(mapStateToProps,mapDispatchToProps) (RequestList)