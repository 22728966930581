import React from 'react';
import '../../css/messages.css';

function MessageBox(props) {





  return (
    <>

    <div className={`container message-container  ${props.onShow?'hide':''}`}  >
{props.gap?
        <div className={`row py-${props.gap}`}></div>:''
}

        <div className="row">
            <div className="col-lg-12">

            
                <blockquote className={`blockquote blockquote-custom bg-white shadow rounded ${props.mediaQueryClass}`} style={{width:props.width}}>
 {props.checkboxProp?<div className="checkboxCustom">

{props.checkboxProp}

</div>:''}
              {/* minutes ago date */}
               {props.postDateRelative? <div className="blockquote-custom-relative-date bg-light-custome">{props.postDateRelative}</div>:''}
               {props.messageImage? <div className="blockquote-custom-icon bg-light-custome shadow-sm"><img src={require(`../assets/${props.messageImage}`)} width="40px"></img></div>:''}

                  <div className="langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                  
                              
                                {/* normal date*/}

                   {props.postDate? <div className="blockquote-custom-date bg-light-custome shadow-sm">{props.postDate}</div>:''}
                  {props.children? <div className="blockquote-custom-menu bg-light-custome">{props.children}</div>:''}

                   {props.title? <> 
                   <div className="pt-2"></div>
                 <p className="title-text">{props.title}</p>
                 </> :''}
                { props.line?<hr/>:''}

            <p className="mb-0 mt-2 font-italic justifyText">
                    {props.bodyText}
                    </p>
                    {/* <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <p>Someone famous in</p>
                    </footer> */}
                   {props.closeButton?props.closeButton:''}

                   {props.attachment? <>


                   <hr/>
                <div><span>Attachment: </span><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_ATTACHMENT_NOTIFICATIONS}/${props.attachment}`} target={"_blank"}>{props.attachment? props.attachment.substring(0,6).concat('...'):''}</a></div>
              
                </>:''}
                </div>
                </blockquote>

           

            </div>
            
        </div>
    </div>


    </>
  )
}

export default MessageBox