
export const permissionsKeys=['super-admin','Autos','Container','Settings','LoadingList','Notification','Vendors','Requests','Logs','pdf']
//the below are mapped names for above array and below is object;
export const permissionsHeaders = {
  'super-admin': 'super admin permissions',
  'LoadingList': 'loading list permissions',
  'Autos':'auto permissions',
  'Container':'container permissions',
  'Settings':'setting permissions',
  'Notification':'notification permissions',
  'Vendors':'vendor permissions',
  'Requests':'request permissions',
  'Logs':'log permissions',
  'pdf':'pdf permissions',


};