import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing warehouse settings model
// -------------------------------------------------------------------------
export const hideWarehouseModal=()=>{
    return {
      type:'hideWarehouseModal'
    }
    
    }
    export const showWarehouseModal=()=>{
  
      return {
        type:'showWarehouseModal'
      }
      
      }
  
  
      export const showEditWarehouseModal=()=>{
        return {
          type:'showEditWarehouseModal'
        }
        
        }
      
        export const hideEditWarehouseModal=()=>{
          return {
            type:'hideEditWarehouseModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all Warehouses for settings
// ---------------------------------------------------------------------------------------------------------

export const getWarehouses = (currentPage) => {



    return (dispatch) => {
        dispatch(getWarehouseStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/warehouses?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getWarehouseSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getWarehouseFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getWarehouseStarted = () => ({
      type: "getWarehouse_started",
    });
    
    
    const getWarehouseSuccess = (data,currentPage) => ({
      type: "getWarehousesSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getWarehouseFailure = (error) => ({
      type: "getWarehouse_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerWarehouse = (name) => {
  
  return (dispatch) => {
    dispatch(registerWarehouseStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let warehouseData={
     warehouse_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/warehouseRegister`, warehouseData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerWarehouseSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The warehouse has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerWarehouseFailure(err.response.data.errors));
     
      });
  };
};

const registerWarehouseStarted = () => ({
  type: "registerWarehouse_started",
});

const registerWarehouseSuccess = (data) => ({
  type: "registerWarehouse_success",
  payload: {
    ...data,
  },
});

const registerWarehouseFailure = (error) => ({
  type: "registerWarehouse_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the warehouse edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const warehouseEdit = (id) => {

  return (dispatch) => {
    dispatch(warehouseEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editWare/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(warehouseEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(warehouseEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const warehouseEditDetailsStarted = () => ({
  type: "warehouseEditDetails_started",
});

const warehouseEditDetailsSuccess = (data) => ({
  type: "warehouseEditDetails_success",
  payload: {
    ...data,
  },
});

const warehouseEditDetailsFailure = (error) => ({
  type: "warehouseEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the warehouse update 
// ---------------------------------------------------------------------------------------------------------

export const warehouseUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(warehouseUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        warehouse_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateWarehouse/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(warehouseUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The warehouse has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(warehouseUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const warehouseUpdateStarted = () => ({
    type: "warehouseUpdate_started",
  });
  
  
  const warehouseUpdateSuccess = (data,currentPage) => ({
    type: "warehouseUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const warehouseUpdateFailure = (error) => ({
    type: "warehouseUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// Warehouses Deletion
// ---------------------------------------------------------------------------------------------------------

export const warehouseDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(warehouseDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteWarehouse/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(warehouseDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The warehouse has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(warehouseDeletionFailure(err.message));
      });
  };
};

const warehouseDeletionStarted = () => ({
  type: "warehouseDeletion_started",
});

const warehouseDeletionSuccess = (data,currentPage) => ({
  type: "warehouseDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const warehouseDeletionFailure = (error) => ({
  type: "warehouseDeletion_failure",
  payload: {
    error,
  },
});
