import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {PortUpdate,hidePortModal,showPortModal,showEditPortModal,hideEditPortModal,getPorts,PortEdit,inputHandler,registerPort, PortDelete} from "../../../redux/actions";

function Ports(props) {


// const [addPortData,setAddPortData] = useState({name:''});
const [PortData,setPortData] = useState({name:'',onId:'',port_address:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getPorts(props.currentPage);
  }, [props.registerPortSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setPortData({...PortData,
  'name':props.editDetails?.data?.port_name||'',
  'port_address':props.editDetails?.data?.port_address||'',

})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setPortData({...PortData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hidePortModal()

  };
  const handleShow = () => {

    props.showPortModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditPortModal();

  };

  const handleEditShow=()=>{
    props.showEditPortModal()

  }




const deletePort = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this Port",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.PortDelete(id, props.currentPage);
    }
  });
};





const submitPort = (e) => {
  e.preventDefault();
  let name=props?.name;
  let port_address=props?.port_address;
  if(name){
    props.registerPort(name,port_address)
  }
}


const UpdatePort = (e) => {
  e.preventDefault();

  let currentPage=props.PortsList?.data?.current_page;
  props.PortUpdate(PortData,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7">   
      <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add Port</a></div>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.PortsList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-6 col-sm-6 col-md-6 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="settings-data-cards shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.port_name}</p>
              <p className="mt-2 text-uppercase" style={{fontSize:'12px',color:'rgb(240, 206, 17)'}}>{list.port_address}</p>

              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      setPortData({...PortData,'onId':list.id})
                      props.PortEdit(list.id)
                      setTimeout(()=>{
                        handleEditShow()

                      },190)

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a   onClick={()=>deletePort(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.PortsList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.PortsList?.data?.current_page}
              itemsCountPerPage={props.PortsList?.data?.per_page}
              totalItemsCount={props.PortsList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getPorts(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="lg" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Port </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitPort}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Port Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.PortRegisterErrors?.error?.port_name?true:false}
                  />

                </FloatingLabel>

                {props.PortRegisterErrors?.error?.port_name ? (
                  <div className="validation-error">
                    The Port name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Port Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="port_address"
                    value={props?.port_address}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

                {props.PortRegisterErrors?.error?.port_address ? (
                  <div className="validation-error">
                    The Port name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="lg" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Port </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={UpdatePort}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Port Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={PortData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.PortUpdateErrors?.error?.port_name ?true:false}
                  />

                </FloatingLabel>

                {props.PortUpdateErrors?.error?.port_name ? (
                  <div className="validation-error">
                    The port name already exists, you can't update it with this port name !
                  </div>
                ) : (
                  ""
                )}

              </div>


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Port Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="port_address"
                    value={PortData.port_address||''}
                    onChange={(e) => onInputUpdatechange(e)}
                  />

                </FloatingLabel>

                {props.PortRegisterErrors?.error?.port_address ? (
                  <div className="validation-error">
                    The Port name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>



            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,PortsList,showWarModal,showEditWarModal,name,registerPortSuccess,PortRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,PortUpdateErrors,port_address} = state.SettingsPortHandler;

  return {
    loading: loading,
    name:name,
    PortRegisterErrors:PortRegisterErrors,
    currentPage:currentPage,
    PortsList:PortsList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerPortSuccess:registerPortSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    PortUpdateErrors:PortUpdateErrors,
    port_address:port_address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getPorts:(currentPage)=>dispatch(getPorts(currentPage)),
      showPortModal:()=>dispatch(showPortModal()),
      hidePortModal:()=>dispatch(hidePortModal()),
      showEditPortModal:()=>dispatch(showEditPortModal()),
      hideEditPortModal:()=>dispatch(hideEditPortModal()),
      registerPort:(name,port_address)=>dispatch(registerPort(name,port_address)),
      PortEdit:(id)=>dispatch(PortEdit(id)),
      PortUpdate:(data,currentPage)=>dispatch(PortUpdate(data,currentPage)),
      PortDelete:(id,currentPage)=>dispatch(PortDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Ports);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================