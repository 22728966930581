import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {useParams,Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Pagination from "react-js-pagination";
import NodataFound from '../../error/noDataFoundMain';


import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker,
  addDetails,
  companyEdit,
  toggleCompanyModal,
  perUserVehicles,
  searchAutosList,
} from "../../../../redux/actions";

function UserVehicles(props) {

  const [id, setId] = useState('');
  const [searched, setSearched] = useState(false);

  const params = useParams();
  const {t}=useTranslation();



  

useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
props.perUserVehicles(decryptValue,props.currentPage) }
 },[])
 

 const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.perUserVehicles(id,props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchAutos){
  setSearched(true);
    props.searchAutosList(props.searchAutos,props.currentPage)
    }else{
      setSearched(false);
     
  
    }
  
  }
  

  const handleSearchPages=(pageNumber)=>{
    props.searchAutosList(props.searchAutos,pageNumber)
  }


  
  return (
    <div>
        <div className='vehicle-card'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-4 text-uppercase'>{props.companyEditInfo?.name} vehicles </div>

</div>
</div>
  </div>




<div className="gap-vehicle"></div>
<div className="scroller layer-outer-vehicle mt-2">

{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchAutos}</span></p></div>


}
{props.userBasedVehicles?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}


  {props.userBasedVehicles?.data?.data.map((item,index)=>{
    return(
      <div class="card card-custom mt-3" key={index}>
      <div class="row no-gutters">
        
        <div class="col-md-4">
        <div className="vehicle-status">
          {item.auto_catagory}
        </div>
          {props.userBasedVehicles?.images.filter(image=>image.autos_id===item.id).map((img,ind)=>{return(
          <img src={`${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`} key={ind} class="card-img" alt="..."/>
          )})}
          {/* if no image is there */}
          {props.userBasedVehicles?.images.filter(image => image.autos_id === item.id).length === 0 && (
          <img
            src={require('../../../assets/no_image.jpg')}
            className="card-img"
            alt="..."
          />
        )}
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <div className="row">

              <div className="col-md-6">
              <span className="label-car">Vehicle:</span>  <span className="title-car"> {item?.year} {item?.make} {item?.model}  {item.color}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">VIN:</span> <span className="title-car">{item?.vin}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Loading Point:</span> <span className="title-car">{item?.point_loading}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Port:</span> <span className="title-car">{item?.port}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Delivered on:</span> <span className="title-car">{item?.delivered_warehouse}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Arrived:</span> <span className="title-car">{item?.arrival_date}</span>
              </div>
              <div className="col-md-7">
              </div>
             
              
            </div>

            <Link  to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(item?.real_auto_id?.toString(),'autoListGhulam_123'))}`}  className="details-button">View Details</Link>



          </div>
        </div>
      </div>


    </div>
    )
  })}
 


 <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchAutos" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

</div>


{ props.userBasedVehicles?.data?.data.length===0 ? '':

<div>
      {props.userBasedVehicles?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.userBasedVehicles?.data.current_page}
              itemsCountPerPage={props.userBasedVehicles?.data.per_page}
              totalItemsCount={props.userBasedVehicles?.data.total}
              //   pageRangeDisplayed={5}
              onChange={ searched==true?handleSearchPages:
               (pageNumber) => props.perUserVehicles(id,pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}

    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    usersListResponse,
    userDeletionResponse,
    currentPage,
    userEditResponse,
    showModal,
    userUpdateErrors,
    userUpdateResponse,
    name,
    username,
    email,
    password,
    confirm_password,
    searchUsers,
    showCompanyModal,
    companyEditInfo,
    rolePicker,
    userBasedVehicles,
    searchAutos,
  } = state.userManagementHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    showCompanyModal:showCompanyModal,
    usersListResponse: usersListResponse,
    userRegistrationResponse: userRegistrationResponse,
    userDeletionResponse: userDeletionResponse,
    userUpdateResponse: userUpdateResponse,
    currentPage: currentPage,
    userEditResponse: userEditResponse.data,
    userUpdateErrors: userUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,
    name: name,
    searchAutos:searchAutos,
    username: username,
    email: email,
    password: password,
    confirm_password: confirm_password,
    searchUsers: searchUsers,
    companyEditInfo:companyEditInfo?.data,
    userBasedVehicles:userBasedVehicles
  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),

    userEdit: (id) => dispatch(userEdit(id)),

    hideUpdateModal: () => dispatch(hideUpdateModal()),

    userUpdate: (
      id,
      currentPage,
      name,
      username,
      email,
      contact,
      role,
      password,
      confirmPassword
    ) =>
      dispatch(
        userUpdate(
          id,
          currentPage,
          name,
          username,
          email,
          contact,
          role,
          password,
          confirmPassword
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchUsersList:(querry,currentPage)=>dispatch(searchUsersList(querry,currentPage)),
    rolePicker:()=>dispatch(rolePicker()),
    addDetails:(inputData)=>dispatch(addDetails(inputData)),
    companyEdit:(id)=>dispatch(companyEdit(id)),
    toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
    perUserVehicles:(id,pageNumber)=>dispatch(perUserVehicles(id,pageNumber)),
    searchAutosList:(querry,currentPage)=>dispatch(searchAutosList(querry,currentPage)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(UserVehicles) 