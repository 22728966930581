import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from  "../styles"
import { calculateTotal } from '../../../utils/chargesUtils';
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});


function BLInstructionDesign(props) {

  // const totalWeight = props.data?.containerAutos?.reduce((accumulator, item) => {
  //   const weight = parseFloat(item.weight);
  //   if (!isNaN(weight)) {
  //     return accumulator + weight;
  //   }
  //   return accumulator;
  // }, 0);

  const totalWeight=calculateTotal(props.data?.containerAutos,'weight');



  return (
    <Document title="BL.Instruction"  >
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.headerText}>{process.env.REACT_APP_NAME}</Text>

          <Text style={styles.infoText}>{process.env.REACT_APP_COMPANY_ADDRESS} TEL: {process.env.REACT_APP_COMPANY_PHONE}  </Text>
       

          <Text style={[styles.headerText, { fontWeight: '800', fontSize: '14px' }]}>HOUSE BILL OF LADING</Text>

          <View style={styles.gap}></View>
          <View style={styles.gap}></View>

          <View style={styles.solidLine}></View>
          <View style={styles.gap}></View>

<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>EXPORT REF:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.cont_booking_no}</Text>

  </View>

</View>

<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>SHIPPER:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.forCustomer?.company_name}</Text>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.forCustomer?.address}</Text>

  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.forCustomer?.city}, {props.data?.forCustomer?.state}, {props.data?.forCustomer?.zip_code},  {props.data?.forCustomer?.contact}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>CONSIGNEE:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.consignee_company_name}</Text>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.consignee_address}</Text>

  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.consignee_city}, {props.data?.data?.consignee_state}, {props.data?.data?.consignee_zip_code},  {props.data?.data?.consignee_phone}</Text>

  </View>

</View>



<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>VESSEL VOYAGE#:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.cont_vessel_name} {props.data?.data?.cont_vessel_voyage}</Text>

  </View>

</View>



<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>CONTAINER#:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.container_no}</Text>

  </View>

</View>




<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>CONTAINER SIZE:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.cont_container_size}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>SEAL#:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.cont_seal}</Text>

  </View>

</View>
        


<View style={styles.row}>
  <View style={styles.column}>
    <Text style={[styles. normalTextCapatiliseLargerSize]}>ITN#:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles. normalTextCapatiliseLargerSize]}>{props.data?.data?.cont_itn}</Text>

  </View>

</View>

<View style={styles.gap}></View>
<View style={styles.gap}></View>


<View style={styles.row}>
  <View style={styles.columnFull}>
  <Text style={styles.infoText}>
              Vehicles
            </Text>
          </View>
  </View>
            


  <View style={styles.table}>
            {/* Table Headers */}
            <View style={styles.tableRow}>
              <View style={styles.tableHeader}>
                <Text>Year</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Make</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Model</Text>
              </View>
              <View style={styles.tableHeaderVin}>
                <Text>VIN/PIN</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Weight</Text>
              </View>
             
            </View>
            <View style={styles.solidLine}></View>

            {props.data?.containerAutos.map((item,index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{item.year}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.make}  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.model}  </Text>
                </View>
                <View style={styles.tableCellVin2}>
                  <Text>{item.vin} </Text>
                </View>
           
              
                <View style={styles.tableCell}>
                  <Text>{item.weight} KGs  </Text>
                </View>
              </View>
            ))}

          </View>

          <View style={styles.gap}></View>
          <View style={styles.gap}></View>
          <View style={styles.gap}></View>



<View style={[styles.row,{border:1,borderStyle:'dashed'}]}>
  <View style={[styles.columnFull,styles.dashedBorder]}>
  <Text style={styles.textBoldAndCapatiliseLeft} >GROSS WT(KGS): {totalWeight} KGs</Text>


  </View>
</View>


        </View>
        
      </Page>
    </Document>
  );
}

export default BLInstructionDesign;
