import React, { useState, useEffect } from "react";
import { PDFViewer} from '@react-pdf/renderer';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import BL_InstructionDesign from "../PdfDesigns/BL_InstructionDesign";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {bLInstructionPdf} from '../../../../redux/actions';



function Bl_Instruction(props) {
  
  const [id, setId] = useState('');
  const params = useParams();

  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
   
   props.bLInstructionPdf(decryptValue);
   }
   },[])
   

  return (
    <div>
   
      <PDFViewer  style={{height:'100vh',width:'100vw'}}   >
   
            <BL_InstructionDesign data={props.PdfBLInstructionResponse} />
     
      </PDFViewer>


  </div>
  )
}



const mapStateToProps = (state) => {
  const {
    loading,
    PdfBLInstructionResponse
  } = state.PdfHandler;

  return {
    loading: loading,
    PdfBLInstructionResponse:PdfBLInstructionResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    bLInstructionPdf:(id)=>dispatch(bLInstructionPdf(id))
  
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(Bl_Instruction)