const InitialStates = {
    warehousesList:null,
  
    currentPage: 1,
   
    showWarehouseModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    warehousesList:null,
    warehouseRegisterErrors:null,
    warehouseUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showWarehouseModal":
      return { ...state, 'showWarModal':true };

      case "hideWarehouseModal":
        return { ...state, 'showWarModal':false, 'warehouseRegisterErrors':'', 'name':'' };
  

        case "showEditWarehouseModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditWarehouseModal":
            return { ...state, 'showEditWarModal':false, 'warehouseUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getWarehouse_started":
              return {...state, 'loading':true}
    case "registerWarehouse_started":
              return {...state, 'loading':true}

              // case "warehouseEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "warehouseUpdate_started":
              //     return {...state, 'loading':true}
// ================ends here=======================
       case "getWarehousesSettings_success":

         return { ...state, 'warehousesList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerWarehouse_success":
    return { ...state, 'registerWarehouseSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "warehouseEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "warehouseUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "warehouseDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerWarehouse_failure":
    return { ...state, 'warehouseRegisterErrors': action.payload,  'loading':false };

case "warehouseUpdate_failure":
  return { ...state, 'warehouseUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   