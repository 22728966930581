// ---------------------------------------------------------------------------------------------------------
// for users input form Handling
// ---------------------------------------------------------------------------------------------------------

export const inputHandler=({key,value})=>{
    return{
  type:"inputHandler",
  payload:{key,value}
    }

}