import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from  "../styles"
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});


function NonHazardousDesign(props) {

  return (
    <Document title="Non Hazardous"  >
      <Page size="A4">
        <View style={styles.container}>
        <Text style={styles.headerText}>{process.env.REACT_APP_NAME}</Text>

       <Text style={styles.infoText}>{process.env.REACT_APP_COMPANY_ADDRESS} TEL: {process.env.REACT_APP_COMPANY_PHONE}  </Text>
       

          <Text style={[styles.headerText, { fontWeight: '800', fontSize: '14px' }]}>NON-HAZARDOUS DECLARATION</Text>

          <View style={styles.gap}></View>
          <View style={styles.gap}></View>

          <View style={styles.solidLine}></View>
          <View style={styles.gap}></View>

<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>CARRIER:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_steamship_line}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>VESSEL/VOYAGE: </Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_vessel_name}  {props.data?.data?.cont_vessel_voyage}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>ORIGIN:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_port}, {props.data?.data?.cont_country_port}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>DESTINATION: </Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_port_discharge}, {props.data?.data?.cont_country_discharge}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>BOOKING NUMBER:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_booking_no}</Text>

  </View>

</View>

<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>CONTAINER NUMBER:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.container_no}</Text>

  </View>

</View>


<View style={styles.row}>
  <View style={styles.columnDouble}>
    <Text style={[styles.normalTextCapatilise,styles.belowLineDoted]}>SEAL NUMBER:</Text>
  </View>
  <View style={styles.column50}>
  <Text style={[styles.normalTextCapatilise]}>{props.data?.data?.cont_seal}</Text>

  </View>

</View>

<View style={styles.gap}></View>
<View style={styles.gap}></View>
<View style={styles.gap}></View>
<View style={styles.gap}></View>

<View style={styles.row}>
<Text style={styles.textBoldAndCapatilise}>
THIS IS TO CERTIFY THAT ALL VEHICLES INCLUDED IN THIS CONTAINER HAVE BEEN COMPLETELY DRAINED
OF FUEL AND RUN UNTIL STALLED. BATTERIES ARE DISCONNECTED AND TAPED BACK AND ARE PROPERLY
SECURED TO PREVENT MOVEMENT IN ANY DIRECTION. NO UNDECLARED HAZARDOUS MATERIALS ARE
CONTAINERIZED, SECURED TO, OR STOWED IN THESE VEHICLE.
</Text>

</View>
<View style={styles.gap}></View>

<View style={styles.solidLine}></View>



        
        </View>
      </Page>
    </Document>
  );
}

export default NonHazardousDesign;
