import * as React from "react";
import ReactToPrint from "react-to-print";
import Loader from "../../elements/PrintLoader";
import { connect } from 'react-redux';
import Swal from "sweetalert2";

import {

  AutoInvoiceDelete,
  invoicePrint,
  getAutoInvoices,
  hideInvoiceInfoEditModal,
  showInvoiceInfoEditModal,
  AutoInvoiceEditInfo,
  showInvoiceBillerEditModal,
  hideInvoiceBillerEditModal,
  AutoInvoiceUpdateInfo,
  AutoInvoiceEditBillerInfo,
  AutoInvoiceBillerUpdateInfo,
  AutoInvoiceOriginDelete
 
} from "../../../redux/actions";
import  {BillToPrint}  from "./Bill";
import {can} from './../../utils/roleUtils';

const BillView = (props) => {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);



  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    props.invoicePrint(props.autoID);
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);


  // React.useEffect(()=>{
  //   if(props.autoInvoiceResponse){
  //   props.getAutoInvoices(props.autoInvoiceResponse?.AutoDetail?.id);
  //   }
  // },[props.autoDeleteInfoBill])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);





  const deleteInvoice=(id)=>{



    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Auto Invoice",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
    props.AutoInvoiceOriginDelete(props.autoInvoiceResponse?.invoice_info?.realID)
        }
    });
    
    
    
      }



  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return(<div>
    <div style={{height:'10px'}}></div>
     <span><button className="btn-print">Print</button> </span> 
     </div>);
  }, []);

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle={props.autoInvoiceResponse?.AutoDetail?.lot}
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      {loading &&
      <div>
        <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />
            </div>}
           {can(currentUserPermissions,'Delete Shipping Invoice')? <span><button className="btn-delete-invoice" onClick={()=>deleteInvoice()}>Delete Invoice</button> </span>:""}


      <div style={{height:'14px'}}></div>
      <BillToPrint ref={componentRef} 
      responseAutoInvoice={props.autoInvoiceResponse} 
      invoiceInfoDelete={props.AutoInvoiceDelete} 
      getAutoInvoices={props.getAutoInvoices} 
      autoDeleteInfoBill={props.autoDeleteInfoBill} 
      ID={props.autoID} 
      autoPrintResponse={props.autoPrintResponse}
      showEditInfo={props.showInvoiceInfoEditModal} 
      hideEditInfo={props.hideInvoiceInfoEditModal}
      openInvoiceEditInfo={props.openInvoiceEditInfo} 
      invoiceEditInfo={props.invoiceEditInfo}
      AutoInvoiceEditInfo={props.AutoInvoiceEditInfo}
      SelectorsFetch={props.SelectorsFetch}
      openInvoiceEditBillerResponse={props.openInvoiceEditBiller}
      showInvoiceBillerEditModal={props.showInvoiceBillerEditModal}
      hideInvoiceBillerEditModal={props.hideInvoiceBillerEditModal}
      AutoInvoiceUpdateInfo={props.AutoInvoiceUpdateInfo}
      invoiceInfoUpdationResponse={props.invoiceInfoUpdationResponse}
      AutoInvoiceEditBillerInfo={props.AutoInvoiceEditBillerInfo}
      invoiceBillerEditResponse={props.invoiceBillerEditResponse}
      AutoInvoiceBillerUpdateInfo={props.AutoInvoiceBillerUpdateInfo}
      invoiceBillerUpdateResponse={props.invoiceBillerUpdateResponse}
      preDataLoadResponse={props.preDataLoadResponse}
      />
    </div>
  );
};


const mapStateToProps = (state) => {
  const {autoInvoiceResponse,loading,autoDeleteInfoBill,autoPrintResponse,openInvoiceEditInfo,invoiceEditInfo,openInvoiceEditBiller,invoiceInfoUpdationResponse,invoiceBillerEditResponse,invoiceBillerUpdateResponse} = state.AutoInvoiceHandler;
  const { SelectorsFetch } = state.SelectorsHandler;
  const { preDataLoadResponse } = state.preDataHandler;


  return {
    loading: loading,
    autoInvoiceResponse:autoInvoiceResponse,
    autoDeleteInfoBill:autoDeleteInfoBill,
    autoPrintResponse:autoPrintResponse,
    openInvoiceEditInfo:openInvoiceEditInfo,
    invoiceEditInfo:invoiceEditInfo,
    SelectorsFetch:SelectorsFetch,
    openInvoiceEditBiller:openInvoiceEditBiller,
    invoiceInfoUpdationResponse:invoiceInfoUpdationResponse,
    invoiceBillerEditResponse:invoiceBillerEditResponse,
    invoiceBillerUpdateResponse:invoiceBillerUpdateResponse,
    preDataLoadResponse: preDataLoadResponse,


    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    AutoInvoiceUpdateInfo:(id,invoiceCatory,notes,amount)=>dispatch(AutoInvoiceUpdateInfo(id,invoiceCatory,notes,amount)),
    showInvoiceInfoEditModal:()=>dispatch(showInvoiceInfoEditModal()),
    hideInvoiceInfoEditModal:()=>dispatch(hideInvoiceInfoEditModal()),
    showInvoiceBillerEditModal:()=>dispatch(showInvoiceBillerEditModal()),
    hideInvoiceBillerEditModal:()=>dispatch(hideInvoiceBillerEditModal()),


    invoicePrint:(id)=>dispatch(invoicePrint(id)),
    AutoInvoiceDelete:(id)=>dispatch(AutoInvoiceDelete(id)),
    getAutoInvoices:(id)=>dispatch(getAutoInvoices(id)),
    AutoInvoiceEditInfo:(id)=>dispatch(AutoInvoiceEditInfo(id)),
    AutoInvoiceEditBillerInfo:(id)=>dispatch(AutoInvoiceEditBillerInfo(id)),
    AutoInvoiceBillerUpdateInfo:(id,biller)=>dispatch(AutoInvoiceBillerUpdateInfo(id,biller)),
    AutoInvoiceOriginDelete:(id)=>dispatch(AutoInvoiceOriginDelete(id))
  };
};





export default connect(mapStateToProps,mapDispatchToProps)(BillView);