import React, { useState, useEffect } from "react";
// import '../../../../css/profile.css';
import { Outlet,NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import NodataFound from '../../../pages/error/noDataFoundMain';
import Logo from "../../../assets/normLogo.png";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Loader from "./../../../elements/Loader";

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { loadContainersOptionsPerVendor, loadUsersOptions,loadVehiclesOptionsPerVendor } from "../../../utils/asyncOptionsUtils";
import {
  userDelete,
  vendorEdit,
  toggleVendorMakePaymentModal,
  storePayment,
} from "../../../../redux/actions";
import { goBack } from "../../../utils/historyUtils";
import { paymentTypes } from "../../../utils/countryUtils";
import { calculateSum, calculateTotal } from "../../../utils/chargesUtils";
import { can } from "../../../utils/roleUtils";




const VendorProfile = (props) => {
  const location = useLocation();
  const [isTabActive, setIsTabActive] = useState(false);
  const [paymentData,setPaymentData]=useState({'payment_type':null,'invoice':'','date':null,'amount':'','paid_by':'','check':'','note':'','payment_for':[]})
  const [id, setId] = useState('');

  const params = useParams();
  const {t}=useTranslation();

  const match = useMatch('/home/vendorsProfile/:id');

  useEffect(() => {
    setIsTabActive(match && match.params.id !== undefined);
  }, [match]);


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.vendorEdit(decryptValue);
 }
 },[])
 
 useEffect(()=>{
  setPaymentData({'payment_type':null,'invoice':'','date':null,'amount':'','paid_by':'','check':'','note':'','payment_for':[]});
 },[props.paymentSaveSuccess])

//  const sum = paymentData.payment_for.reduce((total, currentItem) => {
//   const number = parseInt(currentItem.label.split('$')[1]);

//   return total + number;
// }, 0);
const sum = calculateSum(paymentData.payment_for,'$')


useEffect(()=>{
setPaymentData({...paymentData,amount:sum})
},[sum])


 const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 40,
    baseUnit: 4,
  },
});
const menuPortal={
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

 const handleToggleModal = () => props.toggleVendorMakePaymentModal();
 

 const handleChangeSelectors = (e,selectorName) => {
let selectName = selectorName.name.toString();
  setPaymentData({ ...paymentData, [selectName]:e });
};



 const storePaymentInfo=(e)=>{
  e.preventDefault();
  if(paymentData.payment_for.length>0){
  props.storePayment(id,paymentData,props.vendorEditResponse?.vendor_type)
  }else{
    Swal.fire({
      position: 'center',
      icon: 'warning',
      text: 'Please choose containers or vehicles in order to be paid ',
      showConfirmButton: false,
      timer: 1200,
      toast: true,

    })
  }
  }


  const handleDateInputChange=(e,dateSelectorName)=>{
    // let formated = moment(e).format('YYYY-MM-DD')
      setPaymentData({ ...paymentData, [dateSelectorName]:e});
    
    }
    

    const onInputChange = (e) => {
      setPaymentData({
        ...paymentData,
        [e.target.name]: e.target.value,
      });
    };
  

    const changedSelectorData = (selectedOption,field) => {
      let selectorName=field;
      setPaymentData({
        ...paymentData,
        [selectorName]: selectedOption,
    });
    };

    const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



  return (
    <div className="container">
    <div className="main-body">
    
     <div className="back-button">
         <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i>
</a> 

     </div>
    
          <div className="gutters-sm side-row">
            <div className=" fixed-sidebar mb-3">
              <div className="card card-custom">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    {/* <img src={require('../../../assets/male.jpg')} alt="Admin" className="rounded-circle" width="150"/> */}
                    <div className="mt-3">
                      <h6>{props.vendorEditResponse?.vendor_contact_name}</h6>
                      <p className="text-sub-head mb-1">{props.vendorEditResponse?.vendor_name}</p>
                    <span className="icon-frame"><i className="fa fa-phone ml-1"/></span>  <span className="text-sub-head mb-1">{props.vendorEditResponse?.vendor_phone}</span>

                      {/* <p className="text-muted custom-text font-size-sm">Bay Area, San Francisco, CA</p> */}
                      {/* <button className="btn">Follow</button>
                      <button className="btn ">Message</button> */}
                    </div>
                  </div>
                </div>
              </div>



  <div className="container py-4">
        
        <div className="row">
            <div className="col-md-12">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <NavLink to={`/home/vendorsProfile/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListAli_123'))}`} end className={ (isTabActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i className="fa fa-info mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Vendor Information</span></NavLink>


{/* paid unpaid starts from here */}
{props.vendorEditResponse?.vendor_type==="Towing"?
   <>
              {  can(currentUserPermissions,'Show Paid Unpaid Vehicles')?
              <>
  <NavLink to="unpaid-vehicles" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
  <i className="fa fa-car mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Unpaid Vehicles</span></NavLink>      

  <NavLink to="paid-vehicles" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
  <i className="fa fa-check mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Paid Vehicles</span></NavLink>
                
                </>:null}
                {  can(currentUserPermissions,'Show Payments')?

       <NavLink to="vehicle-payment" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i className="fa fa-money mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Payments</span></NavLink>:null
                        
                      
                      }
      </>
              :
    <>
                            {  can(currentUserPermissions,'Show Paid Unpaid Containers')?
                      <>
                    <NavLink to="unpaid-containers" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i className="fa fa-truck mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Unpaid Containers</span></NavLink>

                    <NavLink to="paid-containers" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i className="fa fa-check mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Paid Containers</span></NavLink>
                        </>:null}

                        {  can(currentUserPermissions,'Show Payments')?

                        <NavLink to="container-payment" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i className="fa fa-money mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Payments</span></NavLink>:null
                        
                        }
   </>            

}
                    </div>


            </div>


            
        </div>
    </div>


<div className="card card-custom scroller">
<div className="card-body">
<ul className="list-group  list-group-flush">
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-envelope ml-1"/></span><span className="header-font">Primary Email</span></h6>
                    <span className="text-sub-head">{props.vendorEditResponse?.vendor_email?.toUpperCase()}</span>
                  </li>

               
                </ul>

</div>
</div>
{can(currentUserPermissions,'Allow Make Payment')?
  <div className="card card-cursor" onClick={handleToggleModal}>

<div className="card-body color-white">
  Make Payment
</div>
</div>:null
}
            
            </div>
            <div className=" scroller  overflow-auto-cont">
               
              <Outlet/>
              
              </div>

              

            </div>
          </div>






{/* 
========================================================
Modal Auto Charges
======================================================== 
*/}

<Modal show={props.showMakePayment} onHide={handleToggleModal} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Make Payment &nbsp; <i className="fa fa-money"></i> </Modal.Title>
          <CloseButton variant={"white"} onClick={handleToggleModal}></CloseButton>
        </Modal.Header>

        <form onSubmit={storePaymentInfo} >
          {props.loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
          style={{
            maxHeight: "calc(110vh - 210px)",
            overflowY: "auto",
         
          }}
            // className="modal-body"
          >
           
            

           <div className="row">
        { props.vendorEditResponse?.vendor_type==="Towing"?
<div className="col-md-12 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select Vehicles for vendors to be paid
                </label>
         <AsyncPaginate
         className="async-paginate-inner-container"
         debounceTimeout={500}
        value={paymentData.payment_for}
        loadOptions={(search, options, { page }) =>
          loadVehiclesOptionsPerVendor(search, options, { page }, id,props.vendorEditResponse?.vendor_type , 'unpaid')
        }

        onChange={(selectedOption)=>changedSelectorData(selectedOption,'payment_for')}
isMulti
        isClearable
        additional={{
          page: 1,
        }}
      />


            </div>:

<div className="col-md-12 pb-3">
<label htmlFor="auction" className="picker-inputs-lebel">
     Select Containers for vendors to be paid
    </label>
<AsyncPaginate
className="async-paginate-inner-container"
debounceTimeout={500}
value={paymentData.payment_for}
loadOptions={(search, options, { page }) =>
loadContainersOptionsPerVendor(search, options, { page }, id,props.vendorEditResponse?.vendor_type , 'unpaid')
}
onChange={(selectedOption)=>changedSelectorData(selectedOption,'payment_for')}
isMulti
isClearable
additional={{
page: 1,
}}
/>


</div>
            
            }
</div>






<div className="row">
<div className="col-md-4 mb-3">
<label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Payment Type
                </label>

              <Select
                  options={paymentTypes}
                  theme={theme}
                  isClearable={true}
                  placeholder="Payment Type"
                  name="payment_type"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  setValue={paymentData.payment_type}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
             
               
              </div>

              <div className=" col-md-4 mb-2">
              <label htmlFor="invoice" className="picker-inputs-lebel">
                  Invoice
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="invoice"
                placeholder="Invoice"
                value={paymentData.invoice}
                required
                onChange={(e) => {
                  onInputChange(e);
                }}
              />
            </div>

           

            <div className="col-md-4 mb-2">
                <label htmlFor="date" className="picker-inputs-lebel">
                  Date
                </label>

                <DatePicker
                  selected={paymentData.date}
                  onChange={(e) => handleDateInputChange(e,"date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Date"
                  name="date"
                  required={true}
                  autoComplete="off"            
                      />
              </div>

             
</div>
             
      <div className="row">
     
      <div className=" col-md-4 mb-2">
              <label htmlFor="check" className="picker-inputs-lebel">
                  Check
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="check"
                placeholder="Check"
                value={paymentData.check}
                required
                onChange={(e) => {
                  onInputChange(e);
                }}
              />
            </div>


              <div className=" col-md-4 mb-2">
              <label htmlFor="paid_by" className="picker-inputs-lebel">
                  Paid By
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="paid_by"
                placeholder="Paid By"
                value={paymentData.paid_by}
                onChange={(e) => {
                  onInputChange(e);
                }}
              />
            </div>




            <div className=" col-md-3 mb-2">
              <label htmlFor="amount" className="picker-inputs-lebel">
                  Amount
                </label>
              <input
                type="number"
                className="form form-control inputWidth"
                name="amount"
                placeholder="Amount"
                required
                value={paymentData.amount}
                onChange={(e) => {
                  onInputChange(e);
                }}
              />
            </div>

           

      </div>


     


      <div className="row">
        <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="note"
                    className="form-label picker-inputs-lebel"
                  >
                    Note
                  </label>
                  <textarea
                    className="form-control"
                    name="note"
                    style={{height:'100px'}}
                    onChange={onInputChange}
                  ></textarea>
                </div>
      </div>
</div>


<div className="row pt-3">
</div>
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
           Save Payment
            </button>
          </Modal.Footer>

        </form>
      </Modal>










        </div>
  );
};



const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    vendorEditResponse,
    showMakePayment,
    paymentSaveSuccess
  } = state.VendorsHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    vendorEditResponse:vendorEditResponse?.data,
    showMakePayment:showMakePayment,
    paymentSaveSuccess:paymentSaveSuccess,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),
    vendorEdit:(id)=>dispatch(vendorEdit(id)),
    toggleVendorMakePaymentModal:()=>dispatch(toggleVendorMakePaymentModal()),
    storePayment:(vendorId,data,vendorCatagory)=>dispatch(storePayment(vendorId,data,vendorCatagory))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(VendorProfile);
