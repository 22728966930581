import React from "react";
import {downloadImagesZip,filterListForWarhouses} from '../../redux/actions';
import { connect } from "react-redux";
import { NavLink,Link, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next'

function RightSidebar(props) {


const filterListOnWarehouses=(warehouse,placement)=>{

  props.filterListForWarhouses(warehouse,placement);
return props.filterListForWarhouses(warehouse,placement);

}


const { t } = useTranslation() 


  return (
    <>
     
        <div className="sidebarRight">
   {
    props.autosListCatagories?.allAutos.map((autoWare,wareInd)=>{return (

      
  
     <div key={wareInd}>
  {

    props.SelectorsWarehouse?.warehouse.filter(i=>i.warehouse_name===autoWare.point_loading).map((warehouse,index)=>{
    
return(
<div key={index}> 
  <div className="outerLayer">

            <div id="related_links">
            <div><h3 className="header-text-righ-bar">{warehouse.warehouse_name} {t('key_warehouse')}</h3></div>
         
            

        <ul>
        {props.autosListCatagories?.allAutos.filter(ware=>ware.point_loading===warehouse.warehouse_name).map((auto,ind)=>{return(
          <li key={ind}>
     {/* <a alt="all" onClick={()=>filterListOnWarehouses(auto.point_loading,'All')}> All <span>{auto.totalAutos}</span></a> */}
     <NavLink alt="all" to={`/home/autos/${auto.point_loading}/All`} className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')} onClick={()=>filterListOnWarehouses(auto.point_loading,'All')}> All <span>{auto.totalAutos}</span></NavLink>

   </li>
        )})
     
        }
   {
    props.SelectorsWarehouse?.autoCatagory.map((catagory,indexCat)=>{return(<div key={indexCat}>
{props.autosListCatagories?.autos_catagory.filter(loadingPoint => loadingPoint.auto_catagory===catagory.catagory_name && loadingPoint.point_loading===warehouse.warehouse_name).map((list,newIndex)=>{return(
  <li key={newIndex}>
     <NavLink alt="catagory"  to={`/home/autos/5675${list.point_loading}/${catagory.catagory_name.replace(/\s+/g, '-')}`} className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}  onClick={()=>filterListOnWarehouses(warehouse.warehouse_name,catagory.catagory_name)}>{catagory.catagory_name}  <span>{list.totalAutos}</span></NavLink>
   </li>
)})
         
}






    </div>)})
    
    }
      
  
 </ul>
       

            </div>
</div>
<br/>
</div>

)

    
  })
  
  
  }
       
</div>
  )})
   }
 {/* this div is the clossing of all */}

          </div>
   
        
        

    </>
  );
}



const mapStateToProps = (state) => {

  const {loading, getAdditionalRegDataSuccess, autosListResponse } = state.AutosHandler;
  const { SelectorsFetch } = state.SelectorsHandler;


  return {
  
    loading:loading,
   
    autosListCatagories:autosListResponse,
    SelectorsWarehouse:SelectorsFetch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    downloadImagesZip:(id)=>dispatch(downloadImagesZip(id)),
    filterListForWarhouses:(warehouse,placement)=>dispatch(filterListForWarhouses(warehouse,placement)),
  };
};




export default connect(mapStateToProps,mapDispatchToProps)(RightSidebar);
