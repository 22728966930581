import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {AutoTypeUpdate,hideAutoTypeModal,showAutoTypeModal,showEditAutoTypeModal,hideEditAutoTypeModal,getAutoTypes,AutoTypeEdit,inputHandler,registerAutoType, AutoTypeDelete } from "../../../redux/actions";

function AutoTypes(props) {


// const [addAutoTypeData,setAddAutoTypeData] = useState({name:''});
const [AutoTypeData,setAutoTypeData] = useState({name:'',onId:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getAutoTypes(props.currentPage);
  }, [props.registerAutoTypeSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setAutoTypeData({...AutoTypeData,'name':props.editDetails?.data?.type_name||''})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setAutoTypeData({...AutoTypeData, name:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hideAutoTypeModal()

  };
  const handleShow = () => {

    props.showAutoTypeModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditAutoTypeModal();

  };

  const handleEditShow=()=>{
    props.showEditAutoTypeModal()

  }




const deleteAutoType = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this AutoType",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.AutoTypeDelete(id, props.currentPage);
    }
  });
};





const submitAutoType = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(name){
    props.registerAutoType(name)
  }
}


const UpdateAutoType = (e) => {
  e.preventDefault();
  let name=AutoTypeData.name;
  let id=AutoTypeData.onId;
  let currentPage=props.AutoTypesList?.data?.current_page;
  props.AutoTypeUpdate(id,name,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7">   
      <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add AutoType</a></div>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.AutoTypesList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-3 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.type_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      setAutoTypeData({...AutoTypeData,'onId':list.id})
                      props.AutoTypeEdit(list.id)
                      setTimeout(()=>{handleEditShow()},190)
                                   

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a onClick={()=>deleteAutoType(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.AutoTypesList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.AutoTypesList?.data?.current_page}
              itemsCountPerPage={props.AutoTypesList?.data?.per_page}
              totalItemsCount={props.AutoTypesList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getAutoTypes(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New AutoType </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitAutoType}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="AutoType Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.AutoTypeRegisterErrors?.error?.type_name?true:false}
                  />

                </FloatingLabel>

                {props.AutoTypeRegisterErrors?.error?.type_name ? (
                  <div className="validation-error">
                    The Auto Type name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit AutoType </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={UpdateAutoType}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="AutoType Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="editAutoTypeName"
                    value={AutoTypeData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.AutoTypeUpdateErrors?.error?.type_name ?true:false}
                  />

                </FloatingLabel>

                {props.AutoTypeUpdateErrors?.error?.type_name ? (
                  <div className="validation-error">
                    The Auto Type name already exists, you can't update it with this AutoType name !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,AutoTypesList,showWarModal,showEditWarModal,name,registerAutoTypeSuccess,AutoTypeRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,AutoTypeUpdateErrors} = state.SettingsAutoTypeHandler;

  return {
    loading: loading,
    name:name,
    AutoTypeRegisterErrors:AutoTypeRegisterErrors,
    currentPage:currentPage,
    AutoTypesList:AutoTypesList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerAutoTypeSuccess:registerAutoTypeSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    AutoTypeUpdateErrors:AutoTypeUpdateErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


     inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
      getAutoTypes:(currentPage)=>dispatch(getAutoTypes(currentPage)),
      showAutoTypeModal:()=>dispatch(showAutoTypeModal()),
      hideAutoTypeModal:()=>dispatch(hideAutoTypeModal()),
      showEditAutoTypeModal:()=>dispatch(showEditAutoTypeModal()),
      hideEditAutoTypeModal:()=>dispatch(hideEditAutoTypeModal()),
      registerAutoType:(name)=>dispatch(registerAutoType(name)),
      AutoTypeEdit:(id)=>dispatch(AutoTypeEdit(id)),
      AutoTypeUpdate:(id,name,currentPage)=>dispatch(AutoTypeUpdate(id,name,currentPage)),
      AutoTypeDelete:(id,currentPage)=>dispatch(AutoTypeDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(AutoTypes);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================