import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");


export const showAutoChargesModal=()=>{
  return {
    type:'showAutoChargesModal'
  }
  }

export const showAutoRegModal=()=>{
  return {
    type:'openAutoModal'
  }
  }




  export const hideAutoRegModal=()=>{
    return {
      type:'hideAutoModal'
    }
    }



export const showAutoEditModal=()=>{
  return {
    type:'openAutoEditModal'
  }
  }

  export const hideAutoEditModal=()=>{
    return {
      type:'hideAutoEditModal'
    }
    }


    export const showAutoAdditionalModal=()=>{
      return {
        type:'openAutoAdditionalModal'
      }
      }
    
      export const hideAutoAdditionalModal=()=>{
        return {
          type:'hideAutoAdditionalModal'
        }
        }
    

     


        export const showEditNoteAutoModal=()=>{
          return {
            type:'openEditNoteAutoModal'
          }
          }
        
        
        
        
          export const hideEditNoteAutoModal=()=>{
            return {
              type:'hideEditNoteAutoModal'
            }
            }



            export const showAutoCommuncationNotesModal=()=>{
              return {
                type:'openAutoCommuncationModal'
              }
              }
            
            
            
            
              export const hideAutoCommuncationNotesModal=()=>{
                return {
                  type:'hideAutoCommuncationModal'
                }
                }
    

// ---------------------------------------------------------------------------------------------------------
// this function will update point of landing directly of an auto
// ---------------------------------------------------------------------------------------------------------

export const changePointOfLoading = (pointObj,currentPage) => {
//console.log(pointObj.onId)

  return (dispatch) => {
      dispatch(changePointOfLoadingStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
      let loadingData={
        point_of_loading:pointObj.point_of_loading.value||'',
        auto_catagory:pointObj.auto_catagory.value||'',

      
       }

  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/changePointOfLoadingStore/${pointObj.onId}`,loadingData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(changePointOfLoadingSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The point of landing has been changed for this auto.  ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(changePointOfLoadingFailure(err.response.data.errors));

        });
    };
  };
  
  const changePointOfLoadingStarted = () => ({
    type: "changePointOfLoading_started",
  });
  
  
  const changePointOfLoadingSuccess = (data,currentPage) => ({
    type: "changePointOfLoading_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const changePointOfLoadingFailure = (error) => ({
    type: "changePointOfLoading_failure",
    payload: {
      error,
    }, 
  });




  



// ---------------------------------------------------------------------------------------------------------
// Download zip
// ---------------------------------------------------------------------------------------------------------



        export const downloadImagesZip = (id) => {
          return (dispatch) => {
            dispatch(zipImagesStarted());
        
            const cipherToken = JSON.parse(localStorage.getItem("fkey"));
        
            var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
            var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        
            // //console.log(decryptedToken);
        
            let axiosConfig = {
              headers: {
                Accept: "application/zip",
                'Content-type': 'application/zip',
                Authorization: `Bearer ${decryptedToken}`,
            
              },
            };

            
              fetch(`${process.env.REACT_APP_API_ADDRESS}/zipFileDownload/${id}`, axiosConfig)
           
                    .then(res => res.blob())
                    .then(blob => saveAs(blob, 'Auto Photos.zip'))


                 
              .catch((err) => {
                dispatch(zipImagesFailure(err.message));
              });
          };
        };
        
        const zipImagesStarted = () => ({
          type: "zipImages_started",
        });
        
        const zipImagesSuccess = (data) => ({
          type: "zipImages_success",
          payload: {
            ...data,
          },
        });
        
        
        const zipImagesFailure = (error) => ({
          type: "zipImages_failure",
          payload: {
            error,
          },
        });





// ---------------------------------------------------------------------------------------------------------
// autos List in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const autosList = (pageNumber) => {
  return (dispatch) => {
    dispatch(autosListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/autoList?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          // console.log(response.data);

          dispatch(autosListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(autosListFailure(err.message));
      });
  };
};

const autosListStarted = () => ({
  type: "autos_fetch_started",
});

const autosListSuccess = (data) => ({
  type: "autos_fetch_success",
  payload: {
    ...data,
  },
});


const autosListFailure = (error) => ({
  type: "autos_fetch_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// this function will update or add data
// ---------------------------------------------------------------------------------------------------------

export const autoAdditionalRegistration = (registrationData,currentPage,selector) => {


  const autoRegAdditionalData = new FormData();

  for (let i = 0; i < registrationData?.images.length; i++) {
    autoRegAdditionalData.append("images[]", registrationData?.images[i]);
  }

  let pickUp= registrationData.pickUp?moment(registrationData?.pickUp).format('YYYY-MM-DD'):'';
  let toWarehouse=registrationData.toWarehouse? moment(registrationData?.toWarehouse).format('YYYY-MM-DD'):'';
  let arrival_date=registrationData.arrival_date? moment(registrationData?.arrival_date).format('YYYY-MM-DD'):'';
  let unloading_date= registrationData.unloading_date? moment(registrationData?.unloading_date).format('YYYY-MM-DD'):'';
  let title_received= registrationData.title_received? moment(registrationData?.title_received).format('YYYY-MM-DD'):'';
  let purchase_date= registrationData.purchase_date? moment(registrationData?.purchase_date).format('YYYY-MM-DD'):'';
  let payment_to_auction= registrationData.payment_to_auction? moment(registrationData?.payment_to_auction).format('YYYY-MM-DD'):'';

  autoRegAdditionalData.append("customerId", selector?.value||'');
  autoRegAdditionalData.append("vendors_id", registrationData?.vendors_id?.value||'');

  autoRegAdditionalData.append("for_whom", selector?.label||'');

  autoRegAdditionalData.append("id", registrationData.onId);
  autoRegAdditionalData.append("containerNoBooking", registrationData?.containerNoBooking||'');
  autoRegAdditionalData.append("pickUp", pickUp||'');
  autoRegAdditionalData.append("toWarehouse", toWarehouse||'');
  autoRegAdditionalData.append("arrival_date", arrival_date||'');
  autoRegAdditionalData.append("unloading_date", unloading_date||'');
  autoRegAdditionalData.append("title_received", title_received||'');
  autoRegAdditionalData.append("title_status", registrationData.title_status?.value||'');
  autoRegAdditionalData.append("auto_catagory", registrationData.auto_catagory?.value||'');
  autoRegAdditionalData.append("keys", registrationData?.keys||'');
  autoRegAdditionalData.append("terminal_notes", registrationData?.terminal_notes||'');
  

// ======================
// non admin info update
// ======================
  autoRegAdditionalData.append("purchase_date", purchase_date||'');
  autoRegAdditionalData.append("payment_to_auction", payment_to_auction||'');
  autoRegAdditionalData.append("vin", registrationData?.vin||'');
  autoRegAdditionalData.append("lot", registrationData?.lot||'');
  autoRegAdditionalData.append("cone", registrationData?.cone||'');
  autoRegAdditionalData.append("stock", registrationData?.stock||'');
  autoRegAdditionalData.append("weight", registrationData?.weight||'');
  autoRegAdditionalData.append("pick_up_location", registrationData?.pick_up_location||'');

  autoRegAdditionalData.append("invoice_customer", registrationData?.invoice_customer||'');

  autoRegAdditionalData.append("model", registrationData?.model||'');
  autoRegAdditionalData.append("make", registrationData?.make||'');
  autoRegAdditionalData.append("year", registrationData?.year||'');
  autoRegAdditionalData.append("color", registrationData?.color||'');
  autoRegAdditionalData.append("autoType", registrationData.autoType?.value||'');
  autoRegAdditionalData.append("auction", registrationData.auction?.value||'');

  autoRegAdditionalData.append("buyer_no", registrationData.buyer_no?.value||'');
  autoRegAdditionalData.append("city", registrationData.city?.value||'');
  autoRegAdditionalData.append("customer_notes", registrationData.customer_notes||'');
  autoRegAdditionalData.append("port", registrationData.port?.value||'');
  autoRegAdditionalData.append("point_of_loading", registrationData.point_of_loading?.value||'');
  autoRegAdditionalData.append("dismantle", registrationData.dismantle||'');
  autoRegAdditionalData.append("self_delivered", registrationData.self_delivered||'');
  autoRegAdditionalData.append("invoice", registrationData.invoice||'');
  autoRegAdditionalData.append("auto_price", registrationData.auto_price||'');



  return (dispatch) => {
      dispatch(autoAdditionalRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

        onUploadProgress: function(progressEvent) {
         const {loaded,total}=progressEvent;
          let percentage = Math.floor( loaded / total* 100 );
          if(percentage< 100){
         dispatch(onUploadProgress(percentage))
          }
          //console.log(`${loaded}kb of ${total}kb  ${percentage} %`);
        }
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/autoAdditionalDataStore`,autoRegAdditionalData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(autoAdditionalRegSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The details have been successfully created ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } else if(response.data.error_message){


            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: 'The invoice that you are uploading is already in our system, please upload another invoice',
              showConfirmButton: false,
              timer: 3000,
              toast: true,
  
            })


          }
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(autoAdditionalRegFailure(err.response.data.errors));

        });
    };
  };
  
  const autoAdditionalRegStarted = () => ({
    type: "autoAdditionalReg_started",
  });
  
  
  const autoAdditionalRegSuccess = (data,currentPage) => ({
    type: "autoAdditionalReg_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const autoAdditionalRegFailure = (error) => ({
    type: "autoAdditionalReg_failure",
    payload: {
      error,
    }, 
  });


  const onUploadProgress = (progressPercent) => ({
    type: "upload_progress",
    payload: {
      progressPercent,
    },
  });

  export const resetProgressBar=()=>{
    return {
      type:'resetProgressBar'
    }
    }
  





// ---------------------------------------------------------------------------------------------------------
// the get data to edit it on additional modal
// ---------------------------------------------------------------------------------------------------------

export const getAutoAdditionalDetails = (id) => {



  return (dispatch) => {
      dispatch(getAutoAdditionalRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getAdditionalRegData/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getAutoAdditionalRegSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getAutoAdditionalRegFailure(err.response.data.errors));

        });
    };
  };
  
  const getAutoAdditionalRegStarted = () => ({
    type: "getAutoAdditionalReg_started",
  });
  
  
  const getAutoAdditionalRegSuccess = (data) => ({
    type: "getAutoAdditionalReg_success",
    payload: {
      ...data,
    },
  });
  
  const getAutoAdditionalRegFailure = (error) => ({
    type: "getAutoAdditionalReg_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// All data would be store in the autos table of database
// ---------------------------------------------------------------------------------------------------------

export const storeAutoData = (autoData,CustomerId) => {
    return (dispatch) => {
      dispatch(storeDataStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          //console.log(percentCompleted);
        }



      };
let purchase_date_formatted= moment(autoData.purchase_date).format('YYYY-MM-DD');
let auction_payment= moment(autoData.payment_to_auction).format('YYYY-MM-DD');

const autoRegistrationData = new FormData();
autoRegistrationData.append("vin", autoData.vin);
autoRegistrationData.append("lot", autoData.lot);
autoRegistrationData.append("autoType", autoData.autoType?.value||'');
autoRegistrationData.append("customerId", CustomerId?.value||'');
autoRegistrationData.append("vendors_id", autoData?.vendors_id?.value||'');

autoRegistrationData.append("for_whom", CustomerId?.label||'');

autoRegistrationData.append("model", autoData.model||'');
autoRegistrationData.append("make", autoData.make||'');
autoRegistrationData.append("year", autoData.year||'');
autoRegistrationData.append("color", autoData.color||'');
autoRegistrationData.append("purchase_date", purchase_date_formatted||'');
autoRegistrationData.append("auction", autoData.auction?.value||'');
autoRegistrationData.append("buyer_no", autoData.buyer_no?.value||'');
autoRegistrationData.append("city", autoData.city?.value||'');
autoRegistrationData.append("customer_notes", autoData.customer_notes||'');
autoRegistrationData.append("payment_to_auction", auction_payment||'');
autoRegistrationData.append("port", autoData.port?.value||'');
autoRegistrationData.append("point_of_loading", autoData.point_of_loading?.value||'');
autoRegistrationData.append("dismantle", autoData.dismantle||'');
autoRegistrationData.append("self_delivered", autoData.self_delivered||'');
autoRegistrationData.append("invoice", autoData.invoice||'');
autoRegistrationData.append("auto_price", autoData.auto_price||'');
autoRegistrationData.append("cone", autoData.cone||'');
autoRegistrationData.append("stock", autoData.stock||'');
autoRegistrationData.append("weight", autoData.weight||'');
autoRegistrationData.append("invoice_customer", autoData.invoice_customer||'');
autoRegistrationData.append("pick_up_location", autoData.pick_up_location||'');

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/autoDataStore`, autoRegistrationData, axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
  
            dispatch(storeDataSuccess(response.data));

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Auto has succesfully registered',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } else if(response.data.error_message){


            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: 'The invoice that you are uploading is already in our system, please upload another invoice',
              showConfirmButton: false,
              timer: 3000,
              toast: true,
  
            })


          }
        })
        .catch((err) => {
          //console.log(err.response.data.errors)
          dispatch(storeDataFailure(err.response.data.errors));
        });
    };
  };
  
  const storeDataStarted = () => ({
    type: "storeAutoData_started",
  });
  
  const storeDataSuccess = (data) => ({
    type: "storeAutoData_success",
    payload: {
      ...data,
    },
  });
  
  const storeDataFailure = (error) => ({
    type: "storeAutoData_failure",
    payload: {
      error,
    },
  });
  




// ---------------------------------------------------------------------------------------------------------
// getImages from auto_images table for each auto 
// ---------------------------------------------------------------------------------------------------------

export const getImages = (id) => {
  return (dispatch) => {
    dispatch(imagesListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/imagesList/`+id, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(imagesListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(imagesListFailure(err.message));
      });
  };
};

const imagesListStarted = () => ({
  type: "images_fetch_started",
});

const imagesListSuccess = (data) => ({
  type: "images_fetch_success_autos",
  payload: {
    ...data,
  },
});


const imagesListFailure = (error) => ({
  type: "images_fetch_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// Admin can delete images so this funciton will help to delete images for each auto multiple images
// ---------------------------------------------------------------------------------------------------------

export const imagesDelete = (ids,autoId) => {


  return (dispatch) => {
    dispatch(imagesDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteImages/`+ids+'/'+autoId,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // console.log(response.data)

          dispatch(imagesDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(imagesDeletionFailure(err.message));
      });
  };
};

const imagesDeletionStarted = () => ({
  type: "imagesDeletion_started",
});

const imagesDeletionSuccess = (data) => ({
  type: "imagesDeletion_success",
  payload: {
    ...data,
  },
});

const imagesDeletionFailure = (error) => ({
  type: "imagesDeletion_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// filtering warehouses
// ---------------------------------------------------------------------------------------------------------

export const filterListForWarhouses = (warehouse,placement,pageNumber) => {
  // console.log(pageNumber)

    return (dispatch) => {
        dispatch(filterListsForWarehosuesStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
        // let loadingData={
        //   warehouse:warehouse||'',
        //   auto_placement:placement||'',
  
        
        //  }

        //  let loadingData = {
        //   params: {
        //     warehouse:warehouse||'',
        //     auto_placement:placement||'',

        //   }
        // }

        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/filterWarehouses/${warehouse}/${placement}?page=${pageNumber}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              // console.log(response.data);
              dispatch(filterListsForWarehosuesSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(filterListsForWarehosuesFailure(err.response.data.errors));
  
          });
      };
    };
    
    const filterListsForWarehosuesStarted = () => ({
      type: "filterOnWarehouses_started",
    });
    
    
    const filterListsForWarehosuesSuccess = (data) => ({
      type: "filterOnWarehouses_success",
      payload: {
        ...data,
      },
    });
    
    const filterListsForWarehosuesFailure = (error) => ({
      type: "filterOnWarehouses_failure",
      payload: {
        error,
      }, 
    });
  
  
  





// ---------------------------------------------------------------------------------------------------------
// Auto multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedAutosDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedAutosStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedAutos/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedAutosSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected autos has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedAutosFailure(err.message));
      });
  };
};

const selectedAutosStarted = () => ({
  type: "selectedAutos_started",
});

const selectedAutosSuccess = (data) => ({
  type: "selectedAutos_success",
  payload: {
    ...data,
  },
});

const selectedAutosFailure = (error) => ({
  type: "selectedAutos_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// Auto Delete
// ---------------------------------------------------------------------------------------------------------

export const autoDelete = (id) => {


  return (dispatch) => {
    dispatch(autoDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAuto/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(autoDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The auto has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(autoDeleteFailure(err.message));
      });
  };
};

const autoDeleteStarted = () => ({
  type: "autoDelete_started",
});

const autoDeleteSuccess = (data) => ({
  type: "autoDelete_success",
  payload: {
    ...data,
  },
});

const autoDeleteFailure = (error) => ({
  type: "autoDelete_failure",
  payload: {
    error,
  },
});



  // **********************************************************************************************************
  // **********************************************************************************************************

// Communication on notes, actions starts from here ....................

  // **********************************************************************************************************
  // **********************************************************************************************************




// ---------------------------------------------------------------------------------------------------------
// get communication of the autos, or get all notes
// ---------------------------------------------------------------------------------------------------------

export const getAutoCommunication = (AutoId) => {


  return (dispatch) => {
      dispatch(getCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
      let data={
        AutoId:AutoId,
      }
    

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/fetchAutoCommunication/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getCommunicationSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const getCommunicationStarted = () => ({
    type: "getCommunicationAuto_started",
  });
  
  
  const getCommunicationSuccess = (data) => ({
    type: "getCommunicationAuto_success",
    payload: {
      ...data,
    },
  });
  
  const getCommunicationFailure = (error) => ({
    type: "getCommunicationAuto_failure",
    payload: {
      error,
    }, 
  });










// ---------------------------------------------------------------------------------------------------------
// register comment for adding the notes
// ---------------------------------------------------------------------------------------------------------

export const AutoCommunication = (AutoId,bodyText,fileAttachment) => {


  return (dispatch) => {
      dispatch(autoCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };



      const noteData = new FormData();
      noteData.append("AutoId", AutoId);
      noteData.append("body", bodyText);
      noteData.append("fileAttachment", fileAttachment);

      
     
  // let data={
  //   AutoId:AutoId,
  //   body:bodyText,
  //   fileAttachment:fileAttachment,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/commentAutoRegister/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(autoCommunicationSuccess(response.data));
          
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(autoCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const autoCommunicationStarted = () => ({
    type: "autoCommunication_started",
  });
  
  
  const autoCommunicationSuccess = (data) => ({
    type: "autoCommunication_success",
    payload: {
      ...data,
    },
  });
  
  const autoCommunicationFailure = (error) => ({
    type: "autoCommunication_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// get the data of the edit 
// ---------------------------------------------------------------------------------------------------------

export const getDataEditAutoNote = (id) => {


  return (dispatch) => {
      dispatch(getEditAutoNoteStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getEditAutoNoteDetails/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getEditAutoNoteSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getEditAutoNoteFailure(err.response.data.errors));

        });
    };
  };
  
  const getEditAutoNoteStarted = () => ({
    type: "getEditAutoNoteDetails_started",
  });
  
  
  const getEditAutoNoteSuccess = (data) => ({
    type: "getEditAutoNoteDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getEditAutoNoteFailure = (error) => ({
    type: "getEditAutoNoteDetails_failure",
    payload: {
      error,
    }, 
  });











// ---------------------------------------------------------------------------------------------------------
// delete selected autos notes
// ---------------------------------------------------------------------------------------------------------

export const editSelectedAutoNotes = (selectedNoteId,body,fileAttachment) => {


  return (dispatch) => {
      dispatch(editSelectedAutoNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      const noteData = new FormData();
      noteData.append("selectedNoteId", selectedNoteId);
      noteData.append("body", body);
      noteData.append("fileAttachment", fileAttachment);


  // let data={
  //   selectedNoteId:selectedNoteId,
  //   body:body,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/updateAutoNote/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(editSelectedAutoNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The note has been successfully edited',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(editSelectedAutoNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const editSelectedAutoNotesStarted = () => ({
    type: "editSelectedAutoNotes_started",
  });
  
  
  const editSelectedAutoNotesSuccess = (data) => ({
    type: "editSelectedAutoNotes_success",
    payload: {
      ...data,
    },
  });
  
  const editSelectedAutoNotesFailure = (error) => ({
    type: "editSelectedAutoNotes_failure",
    payload: {
      error,
    }, 
  });











// ---------------------------------------------------------------------------------------------------------
// delete selected autos notes
// ---------------------------------------------------------------------------------------------------------

export const deleteSelectedAutoNotes = (selectedNotes,AutoId) => {


  return (dispatch) => {
      dispatch(deleteSelectedAutoNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
    selectedNotes:selectedNotes,
    AutoId:AutoId,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/deleteAutosNotes/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(deleteSelectedAutoNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The selected notes are deleted successfully.',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(deleteSelectedAutoNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const deleteSelectedAutoNotesStarted = () => ({
    type: "deleteSelectedAutoNotes_started",
  });
  
  
  const deleteSelectedAutoNotesSuccess = (data) => ({
    type: "deleteSelectedAutoNotes_success",
    payload: {
      ...data,
    },
  });
  
  const deleteSelectedAutoNotesFailure = (error) => ({
    type: "deleteSelectedAutoNotes_failure",
    payload: {
      error,
    }, 
  });






  








// ---------------------------------------------------------------------------------------------------------
// users selector
// ---------------------------------------------------------------------------------------------------------

export const usersSelectorList = (query,page) => {
  return (dispatch) => {
    dispatch(usersSelectorListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
// //console.log(query)
if(query){
  let data={
    q:query
  }
    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/userSelectorListQuery`, data, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(usersSelectorListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(usersSelectorListFailure(err.message));
      });
    }else{
      axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/usersSelectorListPaginate?page=${page}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(usersSelectorListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(usersSelectorListFailure(err.message));
      });
    }
  };
};

const usersSelectorListStarted = () => ({
  type: "usersSelectorList_started",
});

const usersSelectorListSuccess = (data) => ({
  type: "usersSelectorList_success",
  payload: {
    ...data,
  },
});


const usersSelectorListFailure = (error) => ({
  type: "usersSelectorList_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// towing comapany or vendor selector
// ---------------------------------------------------------------------------------------------------------

export const towingSelectorList = (query,page) => {
  return (dispatch) => {
    dispatch(towingSelectorListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
console.log(query)

      axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/towingSelectorListPaginate?search=${query}?page=${page}`, axiosConfig)
      .then((response) => {
        console.log(response.data);

        if (response.data.success_message) {

          dispatch(towingSelectorListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(towingSelectorListFailure(err.message));
      });
    
  };
};

const towingSelectorListStarted = () => ({
  type: "towingSelectorList_started",
});

const towingSelectorListSuccess = (data) => ({
  type: "towingSelectorList_success",
  payload: {
    ...data,
  },
});


const towingSelectorListFailure = (error) => ({
  type: "towingSelectorList_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// to store only images of the container
// ---------------------------------------------------------------------------------------------------------

export const storeImagesAutos = (AutosData) => {
  return (dispatch) => {
    dispatch(storeImagesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },

    };

const autosImagesData = new FormData();

autosImagesData.append("id", AutosData?.onId);

  for (let i = 0; i < AutosData?.images.length; i++) {
    autosImagesData.append("images[]", AutosData?.images[i]);
  }




    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/storeAutoImages`, autosImagesData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(storeImagesSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully uploaded',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors)
        dispatch(storeImagesFailure(err.response.data.errors));
      });
  };
};

const storeImagesStarted = () => ({
  type: "storeAutosImages_started",
});

const storeImagesSuccess = (data) => ({
  type: "storeAutosImages_success",
  payload: {
    ...data,
  },
});

const storeImagesFailure = (error) => ({
  type: "storeAutosImages_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// get the data of vehical charges
// ---------------------------------------------------------------------------------------------------------

export const getChargesVehicleRows = (id) => {

console.log(id)
  return (dispatch) => {
      dispatch(getChargesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getChargesVehicleRows/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(getChargesSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getChargesFailure(err.response.data.errors));

        });
    };
  };
  
  const getChargesStarted = () => ({
    type: "getChargesDetails_started",
  });
  
  
  const getChargesSuccess = (data) => ({
    type: "getChargesDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getChargesFailure = (error) => ({
    type: "getChargesDetails_failure",
    payload: {
      error,
    }, 
  });







// ---------------------------------------------------------------------------------------------------------
// to store charges of the autos
// ---------------------------------------------------------------------------------------------------------

export const storeAutoCharges = (data,id,totalAmount,totalCost) => {
  console.log(storeAutoCharges);
  return (dispatch) => {
    dispatch(storeAutosChargesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },

    };

    let autoChargesData={
  autos_id:id,
  data:data,
  total_charges:totalAmount,
  total_cost:totalCost
    }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/storeAutoCharges`, autoChargesData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(storeAutosChargesSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The charges has been successfully stored',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors)
        dispatch(storeAutoChargesFailure(err.response.data.errors));
      });
  };
};

const storeAutosChargesStarted = () => ({
  type: "storeAutoChargesAmount_started",
});

const storeAutosChargesSuccess = (data) => ({
  type: "storeAutoChargesAmount_success",
  payload: {
    ...data,
  },
});

const storeAutoChargesFailure = (error) => ({
  type: "storeAutoChargesAmount_failure",
  payload: {
    error,
  },
});