import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");




export const showAndHideActivityModal=()=>{
  return {
    type:'showAndHideNotifModal'
  }
  }
  export const showAndHideEditActivityModal=()=>{
    return {
      type:'showAndHideEditNotifModal'
    }
    }


          


// ---------------------------------------------------------------------------------------------------------
// to fetch Activitys data
// ---------------------------------------------------------------------------------------------------------

export const ActivityList = (pageNumber) => {
  return (dispatch) => {
    dispatch(ActivitysListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/getActivities?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          console.log(response.data);

          dispatch(ActivitysListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(ActivitysListFailure(err.message));
      });
  };
};

const ActivitysListStarted = () => ({
  type: "Activity_fetch_started",
});

const ActivitysListSuccess = (data) => ({
  type: "Activity_fetch_success",
  payload: {
    ...data,
  },
});



const ActivitysListFailure = (error) => ({
  type: "Activity_fetch_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// to fetch logs per subject
// ---------------------------------------------------------------------------------------------------------

export const activityPerSubject = (id,pageNumber) => {
  console.log(id)
  return (dispatch) => {
    dispatch(activityPerSubjectStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/getPerSubject/${id}?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          console.log(response.data);

          dispatch(activityPerSubjectSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(activityPerSubjectFailure(err.message));
      });
  };
};

const activityPerSubjectStarted = () => ({
  type: "activity_per_subject_fetch_started",
});

const activityPerSubjectSuccess = (data) => ({
  type: "activity_per_subject_fetch_success",
  payload: {
    ...data,
  },
});



const activityPerSubjectFailure = (error) => ({
  type: "activity_per_subject_fetch_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// Activity multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedActivitiesDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedActivitysStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedActivities/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedActivitysSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected log activities have been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedActivitysFailure(err.message));
      });
  };
};

const selectedActivitysStarted = () => ({
  type: "selectedActivitiesDeletion_started",
});

const selectedActivitysSuccess = (data) => ({
  type: "selectedActivitiesDeletion_success",
  payload: {
    ...data,
  },
});

const selectedActivitysFailure = (error) => ({
  type: "selectedActivitiesDeletion_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// Activity Delete
// ---------------------------------------------------------------------------------------------------------

export const ActivityDelete = (id) => {


  return (dispatch) => {
    dispatch(ActivityDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteActivity/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(ActivityDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Activity has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(ActivityDeleteFailure(err.message));
      });
  };
};

const ActivityDeleteStarted = () => ({
  type: "ActivityDelete_started",
});

const ActivityDeleteSuccess = (data) => ({
  type: "ActivityDelete_success",
  payload: {
    ...data,
  },
});

const ActivityDeleteFailure = (error) => ({
  type: "ActivityDelete_failure",
  payload: {
    error,
  },
});





