import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing consignee settings model
// -------------------------------------------------------------------------
export const hideconsigneeModal=()=>{
    return {
      type:'hideconsigneeModal'
    }
    
    }
    export const showconsigneeModal=()=>{
  
      return {
        type:'showconsigneeModal'
      }
      
      }
  
  
      export const showEditconsigneeModal=()=>{
        return {
          type:'showEditconsigneeModal'
        }
        
        }
      
        export const hideEditconsigneeModal=()=>{
          return {
            type:'hideEditconsigneeModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all consignees for settings
// ---------------------------------------------------------------------------------------------------------

export const getconsignees = (currentPage) => {



    return (dispatch) => {
        dispatch(getconsigneeStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/consignees?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getconsigneeSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getconsigneeFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getconsigneeStarted = () => ({
      type: "getconsignee_started",
    });
    
    
    const getconsigneeSuccess = (data,currentPage) => ({
      type: "getconsigneesSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getconsigneeFailure = (error) => ({
      type: "getconsignee_failure",
      payload: {
        error,
      }, 
    });
  
  



export const perUserConsignees = (id,currentPage) => {
console.log(currentPage)
  return (dispatch) => {
    dispatch(perUserConsigneeStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/consigneesListPerUser/${id}?page=${currentPage}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(perUserConsigneeStartedSuccess(response.data,currentPage));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(perUserConsigneeStartedFailure(err.response.data.errors));
     
      });
  };
};

const perUserConsigneeStarted = () => ({
  type: "perUserConsigneeStarted_started",
});

const perUserConsigneeStartedSuccess = (data,currentPage) => ({
  type: "perUserConsigneeStarted_success",
  payload: {
    ...data, 'currentPage':currentPage,
  },
});

const perUserConsigneeStartedFailure = (error) => ({
  type: "perUserConsigneeStarted_failure",
  payload: {
    error,
  },
});




  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerconsignee = (users_id,data) => {
  
  return (dispatch) => {
    dispatch(registerconsigneeStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let consigneeData={
      customers_id:users_id,
      company_name:data.company_name,
      contact_name:data.contact_name,
      email:data.email,
      secondary_email:data.secondary_email,
      phone:data.phone,
      secondary_phone:data.secondary_phone,
      address:data.address,
      line_2:data.line_2,
      city:data.city,
      state:data.state,
      zip_code:data.zip_code,
      country:data?.country.value
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/consigneeRegister`, consigneeData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerconsigneeSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The consignee has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerconsigneeFailure(err.response.data.errors));
     
      });
  };
};

const registerconsigneeStarted = () => ({
  type: "registerconsignee_started",
});

const registerconsigneeSuccess = (data) => ({
  type: "registerconsignee_success",
  payload: {
    ...data,
  },
});

const registerconsigneeFailure = (error) => ({
  type: "registerconsignee_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// the consignee edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const consigneeUsersSelector = (q) => {

  return (dispatch) => {
    dispatch(consigneeUsersSelectorStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   
let data={
  q:q
}

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/consigneeUsersSelector`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(consigneeUsersSelectorSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(consigneeUsersSelectorFailure(err.response.data.errors));
     
      });
  };
};

const consigneeUsersSelectorStarted = () => ({
  type: "consigneeUsersSelector_started",
});

const consigneeUsersSelectorSuccess = (data) => ({
  type: "consigneeUsersSelector_success",
  payload: {
    ...data,
  },
});

const consigneeUsersSelectorFailure = (error) => ({
  type: "consigneeUsersSelector_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// the consignee edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const consigneeEdit = (id) => {

  return (dispatch) => {
    dispatch(consigneeEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editconsignee/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(consigneeEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(consigneeEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const consigneeEditDetailsStarted = () => ({
  type: "consigneeEditDetails_started",
});

const consigneeEditDetailsSuccess = (data) => ({
  type: "consigneeEditDetails_success",
  payload: {
    ...data,
  },
});

const consigneeEditDetailsFailure = (error) => ({
  type: "consigneeEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the consignee update 
// ---------------------------------------------------------------------------------------------------------

export const consigneeUpdate = (users_id,currentPage,data) => {
  console.log(users_id);
  console.log(currentPage);
  console.log(data);
    return (dispatch) => {
      dispatch(consigneeUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      let dataConsignee={
        customers_id:users_id,
      company_name:data.company_name,
      contact_name:data.contact_name,
      email:data.email,
      secondary_email:data.secondary_email,
      phone:data.phone,
      secondary_phone:data.secondary_phone,
      address:data.address,
      line_2:data.line_2,
      city:data.city,
      state:data.state,
      zip_code:data.zip_code,
      country:data?.country?.value
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateconsignee/`+data.onId,dataConsignee,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(consigneeUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The consignee has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(consigneeUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const consigneeUpdateStarted = () => ({
    type: "consigneeUpdate_started",
  });
  
  
  const consigneeUpdateSuccess = (data,currentPage) => ({
    type: "consigneeUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const consigneeUpdateFailure = (error) => ({
    type: "consigneeUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// consignees Deletion
// ---------------------------------------------------------------------------------------------------------

export const consigneeDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(consigneeDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteconsignee/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(consigneeDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The consignee has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(consigneeDeletionFailure(err.message));
      });
  };
};

const consigneeDeletionStarted = () => ({
  type: "consigneeDeletion_started",
});

const consigneeDeletionSuccess = (data,currentPage) => ({
  type: "consigneeDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const consigneeDeletionFailure = (error) => ({
  type: "consigneeDeletion_failure",
  payload: {
    error,
  },
});
