const InitialStates = {
    chargesehousesList:null,
  
    currentPage: 1,
   
    showchargesModal:false,
    showEditchargesModal:false,
    loading:false,
    name:'',
  chargessList:null,
    chargesRegisterErrors:null,
    chargesUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showchargesModal":
      return { ...state, 'showWarModal':true };

      case "hidechargesModal":
        return { ...state, 'showWarModal':false, 'chargesRegisterErrors':'', 'name':'' };
  

        case "showEditchargesModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditchargesModal":
            return { ...state, 'showEditWarModal':false, 'chargesUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getcharges_started":
              return {...state, 'loading':true}
    case "registercharges_started":
              return {...state, 'loading':true}

              // case "chargesEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "chargesUpdate_started":
              //     return {...state, 'loading':true}
// ==================ends here=====================
       case "getchargessSettings_success":

         return { ...state, 'chargessList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registercharges_success":
    return { ...state, 'registerchargesSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "chargesEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "chargesUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "chargesDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registercharges_failure":
    return { ...state, 'chargesRegisterErrors': action.payload,  'loading':false };

case "chargesUpdate_failure":
  return { ...state, 'chargesUpdateErrors': action.payload,  'loading':false,  };

case 'chargesUsersSelector_success':

  return { ...state, 'chargesCustomersSelector': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   