const InitialStates = {
    preDataLoadResponse: null,
    loading: false,
    showActivityModal: false,
    currentPage: 1,
    showEditActivityModal: false,
    searchActivitysText: "",
  
    ActivitysRegisteredSucess:[]
  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {
   
  
      case "inputHandler":
        return { ...state, [action.payload.key]: action.payload.value };
  

      case "searchActivity_started":
       return { ...state, loading: true };

       case "Activity_fetch_success":
       return {  ...state, ActivityListSuccess: action.payload, loading: false};

      

      case "searchActivity_success":
        return {
          ...state,
          ActivitysListSuccess: action.payload,
          loading: false,
        };
  
        
        case "selectedActivitiesDeletion_success":
          return {...state, ActivityListDeletionSuccess: action.payload, loading:false};

          case "searchActivitiesList_success":
            return  {  ...state, ActivityListSuccess: action.payload, loading: false};
       
            case "activity_per_subject_fetch_success":
              return {  ...state, ActivityListPerSubjectSuccess: action.payload, loading: false};

  
      default:
        return state;
    }
  };
  