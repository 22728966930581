import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {VendorTypeUpdate,hideVendorTypeModal,showVendorTypeModal,showEditVendorTypeModal,hideEditVendorTypeModal,getVendorTypes,VendorTypeEdit,inputHandler,registerVendorType, VendorTypeDelete } from "../../../redux/actions";

function VendorTypes(props) {


// const [addVendorTypeData,setAddVendorTypeData] = useState({name:''});
const [VendorTypeData,setVendorTypeData] = useState({name:'',onId:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getVendorTypes(props.currentPage);
  }, [props.registerVendorTypeSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setVendorTypeData({...VendorTypeData,'name':props.editDetails?.data?.vendor_type_name||''})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setVendorTypeData({...VendorTypeData, name:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hideVendorTypeModal()

  };
  const handleShow = () => {

    props.showVendorTypeModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditVendorTypeModal();

  };

  const handleEditShow=()=>{
    props.showEditVendorTypeModal()

  }




const deleteVendorType = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this VendorType",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.VendorTypeDelete(id, props.currentPage);
    }
  });
};





const submitVendorType = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(name){
    props.registerVendorType(name)
  }
}


const UpdateVendorType = (e) => {
  e.preventDefault();
  let name=VendorTypeData.name;
  let id=VendorTypeData.onId;
  let currentPage=props.VendorTypesList?.data?.current_page;
  props.VendorTypeUpdate(id,name,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7">   
      <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add Vendor Type</a></div>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.VendorTypesList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-3 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.vendor_type_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      setVendorTypeData({...VendorTypeData,'onId':list.id})
                      props.VendorTypeEdit(list.id)
                      setTimeout(()=>{handleEditShow()},190)
                                   

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a onClick={()=>deleteVendorType(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.VendorTypesList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.VendorTypesList?.data?.current_page}
              itemsCountPerPage={props.VendorTypesList?.data?.per_page}
              totalItemsCount={props.VendorTypesList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getVendorTypes(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add vendor Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Vendor Type </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitVendorType}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Vendor Type Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.VendorTypeRegisterErrors?.error?.type_name?true:false}
                  />

                </FloatingLabel>

                {props.VendorTypeRegisterErrors?.error?.type_name ? (
                  <div className="validation-error">
                    The Vendor Type name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit vendor Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Vendor Type </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={UpdateVendorType}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Vendor Type Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="editVendorTypeName"
                    value={VendorTypeData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.VendorTypeUpdateErrors?.error?.type_name ?true:false}
                  />

                </FloatingLabel>

                {props.VendorTypeUpdateErrors?.error?.type_name ? (
                  <div className="validation-error">
                    The Vendor Type name already exists, you can't update it with this VendorType name !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,VendorTypesList,showWarModal,showEditWarModal,name,registerVendorTypeSuccess,VendorTypeRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,VendorTypeUpdateErrors} = state.SettingsVendorTypeHandler;

  return {
    loading: loading,
    name:name,
    VendorTypeRegisterErrors:VendorTypeRegisterErrors,
    currentPage:currentPage,
    VendorTypesList:VendorTypesList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerVendorTypeSuccess:registerVendorTypeSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    VendorTypeUpdateErrors:VendorTypeUpdateErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


     inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
      getVendorTypes:(currentPage)=>dispatch(getVendorTypes(currentPage)),
      showVendorTypeModal:()=>dispatch(showVendorTypeModal()),
      hideVendorTypeModal:()=>dispatch(hideVendorTypeModal()),
      showEditVendorTypeModal:()=>dispatch(showEditVendorTypeModal()),
      hideEditVendorTypeModal:()=>dispatch(hideEditVendorTypeModal()),
      registerVendorType:(name)=>dispatch(registerVendorType(name)),
      VendorTypeEdit:(id)=>dispatch(VendorTypeEdit(id)),
      VendorTypeUpdate:(id,name,currentPage)=>dispatch(VendorTypeUpdate(id,name,currentPage)),
      VendorTypeDelete:(id,currentPage)=>dispatch(VendorTypeDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(VendorTypes);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================