import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");



export const toggleCompanyModal=()=>{
  return {
    type:"toggleModalAction"
  }
  
  }

  export const toggleCompanyEditModal=()=>{
    return {
      type:"toggleModalEditAction"
    }
    
    }

    export const toggleConsigneeModal=()=>{
      return {
        type:"toggleConsigneeModal"
      }
      
      }


      export const toggleConsigneeEditModal=()=>{
        return {
          type:"toggleConsigneeEditModal"
        }
        
        }
  




// ---------------------------------------------------------------------------------------------------------
// Users List in the system with pagination Action - the authenticated user would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------


export const usersList = (pageNumber) => {
    return (dispatch) => {
      dispatch(fetchUsersListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/list?page=${pageNumber}`, axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(fetchUsersListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(fetchUsersListFailure(err.message));
        });
    };
  };
  
  const fetchUsersListStarted = () => ({
    type: "fetch_started",
  });
  
  const fetchUsersListSuccess = (data) => ({
    type: "fetch_success",
    payload: {
      ...data,
    },
  });
  
  const fetchUsersListFailure = (error) => ({
    type: "fetch_failure",
    payload: {
      error,
    },
  });
  





// ---------------------------------------------------------------------------------------------------------
// Users List in the system with pagination Action - the authenticated user would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------


export const usersFilterList = (pageNumber,userRole) => {
  return (dispatch) => {
    dispatch(userFilterStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/filterUserList?page=${pageNumber}&userRole=${userRole}`, axiosConfig)
      .then((response) => {
        if (response.data) {
          //console.log(response.data);

          dispatch(userFilterSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(userFilterFailure(err.message));
      });
  };
};

const userFilterStarted = () => ({
  type: "user_filter_started",
});

const userFilterSuccess = (data) => ({
  type: "user_filter_success",
  payload: {
    ...data,
  },
});

const userFilterFailure = (error) => ({
  type: "user_filter_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// the user edit function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const userEdit = (id) => {

  return (dispatch) => {
    dispatch(userEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetails/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(userEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(userEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const userEditDetailsStarted = () => ({
  type: "userEditDetails_started",
});

const userEditDetailsSuccess = (data) => ({
  type: "userEditDetails_success",
  payload: {
    ...data,
  },
});

const userEditDetailsFailure = (error) => ({
  type: "userEditDetails_failure",
  payload: {
    error,
  },
});








// ---------------------------------------------------------------------------------------------------------
// for downloading terms and conditions pdf 
// ---------------------------------------------------------------------------------------------------------

export const downloadTermsPdf = (id) => {
  return (dispatch) => {
    dispatch(downloadTermsPdfStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/downloadAgreementPdf/${id}`, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          const { pdf } = response.data;

          const decodedData = atob(pdf);
          const uint8Array = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
          }

          const blob = new Blob([uint8Array], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Terms and Conditions Signed.pdf';

          link.click();

          dispatch(downloadTermsPdfSuccess(response.data));
        } else {
          // Handle unexpected status code
          dispatch(downloadTermsPdfFailure('Unexpected status code'));
        }
      })
      .catch((err) => {
        // Handle network errors or server errors
        dispatch(downloadTermsPdfFailure(err.response ? err.response.data.errors : 'Network error'));
      });
  };
};


const downloadTermsPdfStarted = () => ({
  type: "downloadTermsPdf_started",
});

const downloadTermsPdfSuccess = (data) => ({
  type: "downloadTermsPdf_success",
  payload: {
    ...data,
  },
});

const downloadTermsPdfFailure = (error) => ({
  type: "downloadTermsPdf_failure",
  payload: {
    error,
  },
});











// -------------------------------------------------------------------------
// for opening registration model
// -------------------------------------------------------------------------
export const hideUpdateModal=()=>{
  return {
    type:'hideModalAction'
  }
  
  }






// ---------------------------------------------------------------------------------------------------------
// the user update function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const userUpdate = (id,currentPage,name,username,email,contact,role,password,confirmPassword) => {
//console.log(name)
  return (dispatch) => {
    dispatch(userUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    let userData={
      name:name,
      username:username,
      email:email,
      role:role,
      contact:contact,
      password:password,
      confirm_password:confirmPassword,
 
     }


    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateUser/`+id,userData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(userUpdateSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The user has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(userUpdateFailure(err.response.data.errors));
     
      });
  };
};

const userUpdateStarted = () => ({
  type: "userUpdate_started",
});


const userUpdateSuccess = (data,currentPage) => ({
  type: "userUpdate_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const userUpdateFailure = (error) => ({
  type: "userUpdate_failure",
  payload: {
    error,
  }, 
});






// ---------------------------------------------------------------------------------------------------------
// the user edit function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const companyEdit = (id) => {

  return (dispatch) => {
    dispatch(companyEditStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetailsCompany/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(companyEditSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(companyEditFailure(err.response.data.errors));
     
      });
  };
};

const companyEditStarted = () => ({
  type: "companyEdit_started",
});

const companyEditSuccess = (data) => ({
  type: "companyEdit_success",
  payload: {
    ...data,
  },
});

const companyEditFailure = (error) => ({
  type: "companyEdit_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// the user edit function will only fetch the data of a single user 
// ---------------------------------------------------------------------------------------------------------

export const perUserVehicles = (id,pageNumber) => {

  return (dispatch) => {
    dispatch(perUserVehiclesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/autoListPerUser/${id}?page=${pageNumber}`,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(perUserVehiclesSuccess(response.data,pageNumber));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(perUserVehiclesFailure(err.response.data.errors));
     
      });
  };
};

const perUserVehiclesStarted = () => ({
  type: "perUserVehicles_started",
});

const perUserVehiclesSuccess = (data,currentPage) => ({
  type: "perUserVehicles_success",
  payload: {
    ...data,
    'currentPage':currentPage
    },
});

const perUserVehiclesFailure = (error) => ({
  type: "perUserVehicles_failure",
  payload: {
    error,
  },
});








export const addDetails = (inputData) => {
  //console.log(name)
    return (dispatch) => {
      dispatch(addDetailsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let companyData={
        company_name:inputData.company_name,
        contact_name:inputData.contact_name,
        email:inputData.email,
        secondary_email:inputData.secondary_email,
        other_email:inputData.other_email,
        contact:inputData.contact,
        secondary_contact:inputData.secondary_contact,
        other_contact:inputData.other_contact,
        address:inputData.address,
        line_2:inputData.line_2,
        city:inputData.city,
        state:inputData.state,
        zip_code:inputData.zip_code,
        country:inputData.country?.value,
        tax_id:inputData.tax_id,
        iaai:inputData.iaai,
        corporate_buyer:inputData.corporate_buyer,
   
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/addDetailsUser/`+inputData.id,companyData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(addDetailsSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The user details has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(addDetailsFailure(err.response.data.errors));
       
        });
    };
  };
  
  const addDetailsStarted = () => ({
    type: "addDetails_started",
  });
  
  
  const addDetailsSuccess = (data) => ({
    type: "addDetails_success",
    payload: {
      ...data,
    },
  });
  
  const addDetailsFailure = (error) => ({
    type: "addDetails_failure",
    payload: {
      error,
    }, 
  });
  
  
  
  
  








// ---------------------------------------------------------------------------------------------------------
// Users deletion in the system with pagination Action - the authenticated user would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const userDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(userDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteUser/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(userDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The user has been successfully deleted',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(userDeletionFailure(err.message));
      });
  };
};

const userDeletionStarted = () => ({
  type: "userDeletion_started",
});

const userDeletionSuccess = (data,currentPage) => ({
  type: "userDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const userDeletionFailure = (error) => ({
  type: "userDeletion_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// container list of user 
// ---------------------------------------------------------------------------------------------------------

export const perUserContainers = (id,currentPage) => {

  return (dispatch) => {
    dispatch(perUserContainersStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerListPerUser/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          console.log(response.data)

          dispatch(perUserContainersSuccess(response.data,currentPage));
      
        } 
      })
      .catch((err) => {
        dispatch(perUserContainersFailure(err.message));
      });
  };
};

const perUserContainersStarted = () => ({
  type: "perUserContainers_started",
});

const perUserContainersSuccess = (data,currentPage) => ({
  type: "perUserContainers_success",
  payload: {
    ...data, "currentPage":currentPage,
  },
});

const perUserContainersFailure = (error) => ({
  type: "perUserContainers_failure",
  payload: {
    error,
  },
});




