const InitialStates = {
    vesselehousesList:null,
  
    currentPage: 1,
   
    showvesselModal:false,
    showEditvesselModal:false,
    loading:false,
    name:'',
  vesselsList:null,
    vesselRegisterErrors:null,
    vesselUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showvesselModal":
      return { ...state, 'showWarModal':true };

      case "hidevesselModal":
        return { ...state, 'showWarModal':false, 'vesselRegisterErrors':'', 'name':'' };
  

        case "showEditvesselModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditvesselModal":
            return { ...state, 'showEditWarModal':false, 'vesselUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================

            case "getvessel_started":
              return {...state, 'loading':true}
    case "registervessel_started":
              return {...state, 'loading':true}

              // case "vesselEditDetails_started":
              //   return {...state, 'loading':true}

                // case "vesselUpdate_started":
                //   return {...state, 'loading':true}

// ===============Endshere==================




       case "getvesselsSettings_success":

         return { ...state, 'vesselsList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registervessel_success":
    return { ...state, 'registervesselSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "vesselEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "vesselUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "vesselDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registervessel_failure":
    return { ...state, 'vesselRegisterErrors': action.payload,  'loading':false };

case "vesselUpdate_failure":
  return { ...state, 'vesselUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   