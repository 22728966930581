import React, { useState, useEffect } from "react";
import { PDFViewer} from '@react-pdf/renderer';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import CustomDesign from "../PdfDesigns/CustomDesign";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {customsPdf} from '../../../../redux/actions';
function Customs(props) {
  
  const [id, setId] = useState('');
  const params = useParams();

  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
   
   props.customsPdf(decryptValue);
   }
   },[])
   

  return (
    <div>
    {props.loading ? (
      <div style={{color:'white',justifyContent:'center',alignItems:'center',display:'flex'}}>Loading...</div>
    ) : (
      <PDFViewer  style={{height:'100vh',width:'100vw'}}   >
   
            <CustomDesign data={props?.pdfCustomsResponse}/>
     
      </PDFViewer>


    )}
  </div>
  )
}



const mapStateToProps = (state) => {
  const {
    loading,
    pdfCustomsResponse
  } = state.PdfHandler;

  return {
    loading: loading,
    pdfCustomsResponse:pdfCustomsResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    customsPdf:(id)=>dispatch(customsPdf(id))
  
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(Customs)