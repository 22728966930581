import React,{useEffect} from 'react'
import {connect} from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import {agreementVerification} from '../../../../../redux/actions'

function VerificationMessage(props) {
  const params = useParams();

const navigate= useNavigate();
const goHome=()=>{
  navigate('/customer-request')
}


useEffect(()=>{

props.agreementVerification(params.id,params.token)

 },[])

  return (
    <div>
     
        <div style={{ textAlign: 'center', padding: '60px',color:'white' }}>
        <h1>Ahan, great, you are one step away!</h1>

      <h4>Your membership request is now submitted to administration approval !</h4>
      <p> After Approval, you will be notified via email in which your username and password would be given in order to login to the system.</p>
      <p style={{color:'yellow'}}>Note: You won't be able to submit membership request on the same email until it is rejected.</p>

      <span role="img" aria-label="Happy face" style={{ fontSize: '2em' }}>
        😀
      </span>
      <div><button className='btn btn-warning' onClick={goHome}>Back Home</button></div>

    </div>
    
    </div>
  )
}


const mapStateToProps = (state) => {

  const { requestMembershipResponse,loading } = state.CustomerRequestHandler;

  return {
loading:loading, 
requestMembershipResponse:requestMembershipResponse,
  };
};


const mapDispatchToProps = (dispatch)=>{
  return {
    agreementVerification:(id,token)=>dispatch(agreementVerification(id,token)),
  }
}



export default connect(mapStateToProps,mapDispatchToProps)(VerificationMessage) 