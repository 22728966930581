import React,{useState,useEffect} from 'react'
import MessageBox from '../../elements/MessageBox'
import { useTranslation } from 'react-i18next'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Loader from "../../elements/Loader";
import { connect } from "react-redux";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Pagination from "react-js-pagination";
import moment from 'moment';
import Swal from 'sweetalert2';
import { AsyncPaginate } from "react-select-async-paginate";
import NodataFound from '../../pages/error/noDataFoundMain';
import {loadUsersOptions} from '../../utils/asyncOptionsUtils';
import {

    getSelectors,
    NotificationsList,
    showAndHideNotificationModal,
    showAndHideEditNotificationModal,
    NotificationRegistration,
    getNotificationDetails,
    NotificationUpdate,
    selectedNotificationsDelete,
    usersSelectorList,
    searchNotificationList,
    inputHandler,
    getLatestNotificationDetails
  
  } from "../../../redux/actions";

import Checkbox from '../../elements/Checkbox';
import {can} from '../../utils/roleUtils';

function Notification(props) {
    const {t} =useTranslation();

    const [Loading, setLoading] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState({onId:'',subject:'',body:''});


    const [attachment,setAttachment]= new useState({fileName:'',file:null})
    const [bodyTextCon,setBodyTextCon]=new useState(false)
    const [isCheck, setIsCheck] = useState([]);
    const [broadcastNotification, setBroadCastNotification]=useState(true)
    const [headerSearch, setHeaderSearch] = useState('notificationSearchHeaderSticky');
    const [searched, setSearched] = useState(false);
    const [value, onChange] = useState(null);

    // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
    const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


useEffect(()=>{
props.getLatestNotificationDetails();
props.NotificationsList(props.currentPage);
if(props.notificationsRegisteredSucess||props.notificationsUpdateSuccess){
  setNotificationDetails({...notificationDetails,'subject':'','body':''})
  setAttachment({'fileName':'','file':null})
  onChange(null)
}

if(props.notificationsDeletedSuccess){
  setIsCheck([]);
}



},[props.notificationsRegisteredSucess,props.notificationsUpdateSuccess,props.notificationsDeletedSuccess])

useEffect(()=>{
  if(props.notificationsEditInfoDetails){
    let subject = props.notificationsEditInfoDetails?.NotificationInfo?.subject;
    let body = props.notificationsEditInfoDetails?.NotificationInfo?.body;
    
    setNotificationDetails({...notificationDetails,'subject':subject,'body':body})
    setAttachment({'fileName':'','file':null})

  }

},[props.notificationsEditInfoDetails])


const changeTypeNotification=()=>{
  setBroadCastNotification(!broadcastNotification)
}




const listenScrollEvent = () => {
  if (window.scrollY > 99) {
    return setHeaderSearch("notificationSearchHeaderFixed");
  } else if (window.scrollY < 80) {
    return setHeaderSearch("notificationSearchHeaderSticky");
  }
};

useEffect(() => {
  window.removeEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEvent);
  return () => window.removeEventListener("scroll", listenScrollEvent);
}, []);




const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    props.NotificationsList(props?.currentPage);
    setSearched(false);

  }
  else{
    // console.log(e.target.value)

    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

const onSearch=(e)=>{
e.preventDefault();

  if(props.searchNotificationsText){
setSearched(true);
  props.searchNotificationList(props.searchNotificationsText,props.currentPage)
  }else{
    setSearched(false);
   

  }

}




const defaultAdditional = {
  page: 1
};



const openAndcloseNotificationModal=()=>{
  setNotificationDetails({...notificationDetails,'subject':'','body':''})
  setAttachment({'fileName':'','file':null})

    props.showAndHideNotificationModal()
}

const openAndcloseEditNotificationModal=()=>{
  props.showAndHideEditNotificationModal()
}


const inputChange=(e)=>{
    setNotificationDetails({...notificationDetails,[e.target.name]:e.target.value})
}

const handleAttachment=(e)=>{
    if(e.target?.files[0]?.name==undefined || e.target?.files[0]==undefined ){
      setAttachment({'fileName':'', 'file':null})

    }else{
    setAttachment({'fileName':e.target?.files[0]?.name, 'file':e.target?.files[0]})
    }
  }

const onPublish=(e)=>{
e.preventDefault();
if(broadcastNotification==true){
props.NotificationRegistration(notificationDetails,attachment,props.currentPage,broadcastNotification,value)
}else if(broadcastNotification==false && value!=null){
  props.NotificationRegistration(notificationDetails,attachment,props.currentPage,broadcastNotification,value)
}else{

  Swal.fire({
    position: 'center',
    icon: 'warning',
    text: 'Please select a customer to whom you want to send the notification ! ',
    showConfirmButton: false,
    timer: 1200,
    toast: true,

  })

}
}

const onUpdate = (e)=>{
  e.preventDefault()
  props.NotificationUpdate(notificationDetails,attachment,props.currentPage)
}

// useEffect(() => {
//   const interval = setInterval(() => {
//     props.NotificationsList(props.currentPage);

//   }, 60000);

//   return () => clearInterval(interval);
// }, []);


const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};


// const loadOptions = async (search, page) => {
//   // await sleep(1000);
//   const optionsPerPage = 10;

//     const responseJSON = await props.usersSelectorListResponse.data;
//   // console.log(responseJSON.data)
//   // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)

//     let options; 
//     if(responseJSON.data){
//         options = responseJSON.data.map(function (data) {
//         return { value: data.id, label: data.username}
//       })
//     }
//     // inshallah will iterate it from back end
// // console.log(options)
//   let filteredOptions;
//   if (!search) {
//     filteredOptions = options;
//   } else {
//     const searchLower = search.toLowerCase();

//     filteredOptions = options.filter(({ label }) =>
//       label.toLowerCase().includes(searchLower)
//     );
//   }

//   const hasMore = responseJSON.last_page > page;

//   const slicedOptions = filteredOptions.slice(
//     (page - 1) * optionsPerPage,
//     page * optionsPerPage
//   );
//   // console.log(page)
//   return {
//     options: slicedOptions,
//     hasMore
//   };

// }

// const loadPageOptions = async (q, prevOptions, { page }) => {
//   props.usersSelectorList(q,page)

//   const { options, hasMore } = await loadOptions(q, page);

//   return {
//     options,
//     hasMore,

//     additional: {
//       page:  page + 1,
//     }
//   };
// };




const handleSearchPages=(pageNumber)=>{
  props.searchNotificationList(props.searchNotificationsText,pageNumber)

}




const onSelectedDelete=()=>{


Swal.fire({
  title: "Are you sure?",
  text: "You want to delete the selected notifications",
  icon: "warning",
  width: 400,
  showCancelButton: true,
  timer: 4000,
  timerProgressBar: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
}).then((result) => {
  if (result.isConfirmed) {
    props.selectedNotificationsDelete(isCheck)
    }
});


}

  return (

<>

<div className={headerSearch}>
       <form onSubmit={onSearch}>
        <div className="row">
        <div className="col-md-2"></div>

       
          {/* <div className="col-md-2 ">
          <OverlayTrigger
      key={'forSearch'}
      placement={'bottom'}
      delay={{ show: 3000, hide: 0 }}
      overlay={
        TooltipSearchFor
      }
    >

            <div className="selectBox">
              <select className="searchIn" name="">
                <option disabled>Search Catagory</option>
                <option>VIN</option>
                <option>LOT</option>
              </select>
            </div>
           </OverlayTrigger>
          </div> */}
          <div className="col-md-8  searchOuterLayer">
      
      <div className="input-group" >
  <div className="form-outline searchOutline">
   <span><input type="search" name="searchNotificationsText" className="form-control search" onChange={(e)=>onSearchInputChange(e)} placeholder="Seach by title" autoComplete="off" required/></span> 
   { isCheck.length>0?<span className="deleteSelectedOuterLayer"><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={onSelectedDelete}>Delete ({(isCheck.length)})</a></span>:''}

  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>

          </div>
          
        </div>
        </form>

        </div>





<div className='py-3'></div>
{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchNotificationsText}</span></p></div>
}
{props.notificationsListSuccess?.data?.data.length===0 && <NodataFound notFoundText={"No Notification Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}

{props.notificationsListSuccess?.data?.data.map((list,index)=>{
  return (

    <MessageBox key={index} postDateRelative={moment(list.created_at).add(5,'hours').fromNow()}
      postDate={moment(list.created_at).add(5,'hours').format('dddd DD-MM-YYYY')} 
      title={list.subject} 
      gap={3}
      line={true} 
      bodyText={list.body}
      attachment={list.attachment}
      messageImage={"GA-01.png"}
      // mediaQueryClass={'handleMedia'}
      checkboxProp={
        can(currentUserPermissions,'Delete Notification')?
<Checkbox
          key={list.id}
          type="checkbox"
          name={list.id}
          id={list.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===list.id)}
            />:''

            }

      width={   'calc(100% - 23px)'}
      >
{ can(currentUserPermissions,'Edit Notification')?

<ul>
  {/* <li><i className="fas fa-eye"></i></li> */}
  <li onClick={()=>{
    

            props.getNotificationDetails(list.id)

            setNotificationDetails({...notificationDetails,'onId':list.id})


            setTimeout(()=>{
    openAndcloseEditNotificationModal()
            },550)

  }}><i className="fas fa-edit"></i></li>

</ul>:''}


      </MessageBox>

  )
})
}

{/* 
========================================================
Modal of publishing notification
======================================================== 
*/}


<div className='py-4'></div>


<Modal show={props.showNotificationModal} onHide={openAndcloseNotificationModal} size="lg">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Publish Notification</Modal.Title>
          <CloseButton variant={"white"} onClick={openAndcloseNotificationModal} ></CloseButton>
        </Modal.Header>

        <form onSubmit={onPublish}>
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}



          <Modal.Body
            className="modal-body"
          >
        <div className="row">
    

            <div className="col-md-7 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                {broadcastNotification? 'The notification would be sent to all customers with option broadcast': 'Select Customer for whom you want to publish this notification'}
             </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadUsersOptions}
        onChange={onChange}
        isClearable
        isDisabled={broadcastNotification?true:false}
        isMulti
    
      />

            </div>

            <div className="col-md-5 d-flex pt-4">
      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Broadcast Notification"
        checked={broadcastNotification}
      onChange={changeTypeNotification}
      />
      </div>

          </div>

          <div className="row">
           
           <div className="col-md-12 mb-2">
           <Form.Label htmlFor="subject">Subject</Form.Label>
      <Form.Control
        type="text"
        id="subject"
        name="subject"
        aria-describedby="subject"
        required
        value={notificationDetails.subject}
        onChange={inputChange}
      />
                

              </div>


              <div className="col-md-12 mb-3">

            <FloatingLabel controlId="floatingTextarea2" label="Message">
        <Form.Control
          as="textarea"
          placeholder=' '
          name="body"
          style={{ height: '290px' }}
          value={notificationDetails.body}
          required
          onChange={inputChange}
        />
      </FloatingLabel>
              </div>
</div>



            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
           
            <span><button className='btn btn-primary' type="submit">  &nbsp;Publish</button> </span>

            <div className="px-4">

   <span title="Attachment">
   <label className="custom-file-upload btn btn-primary" style={{marginTop:'9px'}} >
    <input type="file" 
onChange={handleAttachment}
accept="application/pdf"
    />
    <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;{attachment?.fileName} 
</label>
</span> {attachment?.fileName!=='' ?<span style={{cursor:'pointer'}} onClick={()=>{setAttachment({'fileName':'', 'file':null})}}> <i className="fa fa-times" aria-hidden="true"></i></span>:''} &nbsp;

   
</div>


          </Modal.Footer>
        </form>
      </Modal>







{/* 
========================================================
Modal of editing notification
======================================================== 
*/}

      <Modal show={props.showEditNotificationModal} onHide={openAndcloseEditNotificationModal} size="lg">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Notification</Modal.Title>
          <CloseButton variant={"white"} onClick={openAndcloseEditNotificationModal} ></CloseButton>
        </Modal.Header>

        <form onSubmit={onUpdate}>
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
            className="modal-body"
          >
          <div className="row">
           
           <div className="col-md-12 mb-2">
           <Form.Label htmlFor="subject">Subject</Form.Label>
      <Form.Control
        type="text"
        id="subject"
        name="subject"
        aria-describedby="subject"
        required
        value={notificationDetails.subject}
        onChange={inputChange}
      />
                

              </div>


              <div className="col-md-12 mb-3">

            <FloatingLabel controlId="floatingTextarea2" label="Message">
        <Form.Control
          as="textarea"
          placeholder=' '
          name="body"
          style={{ height: '290px' }}
          value={notificationDetails.body}
          required
          onChange={inputChange}
        />
      </FloatingLabel>
              </div>
</div>



            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
           
            <span><button className='btn btn-primary' type="submit">  &nbsp;Publish</button> </span>

            <div className="px-4">

   <span title="Attachment">
   <label className="custom-file-upload btn btn-primary" style={{marginTop:'9px'}} >
    <input type="file" 
onChange={handleAttachment}
accept="application/pdf"
    />
    <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;{attachment?.fileName} 
</label>
</span> {attachment?.fileName!=='' ?<span style={{cursor:'pointer'}} onClick={()=>{setAttachment({'fileName':'', 'file':null})}}> <i className="fa fa-times" aria-hidden="true"></i></span>:''} &nbsp;

   
</div>


          </Modal.Footer>
        </form>
      </Modal>






{ can(currentUserPermissions,'Add Notification')?
 <button className="floating-btn" onClick={openAndcloseNotificationModal}>
       {t('key_publish_notification')}
</button>:''
}


{ props.notificationsListSuccess?.data?.data?.length===0 ? '':
<div>
      {props.notificationsListSuccess?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.notificationsListSuccess?.data.current_page}
              itemsCountPerPage={props.notificationsListSuccess?.data.per_page}
              totalItemsCount={props.notificationsListSuccess?.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched==true? handleSearchPages:(pageNumber) => props.NotificationsList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}





</>



    )
}




const mapStateToProps = (state) => {

 
    const {loading,searchNotificationsText,showNotificationModal,notificationsListSuccess,currentPage,showEditNotificationModal,notificationsRegisteredSucess,notificationsEditInfoDetails,notificationsUpdateSuccess,notificationsDeletedSuccess } = state.NotificationHandler;
    const { preDataLoadResponse } = state.preDataHandler;
    const {usersSelectorListResponse} = state.AutosHandler;

  
    return {
      preDataLoadResponse:preDataLoadResponse,
      usersSelectorListResponse:usersSelectorListResponse,
      loading:loading,
      showNotificationModal:showNotificationModal,
      notificationsListSuccess:notificationsListSuccess,
      currentPage:currentPage,
      showEditNotificationModal:showEditNotificationModal,
      notificationsRegisteredSucess:notificationsRegisteredSucess,
      notificationsEditInfoDetails:notificationsEditInfoDetails,
      notificationsUpdateSuccess:notificationsUpdateSuccess,
      notificationsDeletedSuccess:notificationsDeletedSuccess,
      searchNotificationsText:searchNotificationsText,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
      getSelectors: () => dispatch(getSelectors()),
      NotificationsList:(pageNumeber)=>dispatch(NotificationsList(pageNumeber)),
      showAndHideNotificationModal:()=>dispatch(showAndHideNotificationModal()),
      NotificationRegistration:(registrationData,attachment,currentPage,messageType,customers)=>dispatch(NotificationRegistration(registrationData,attachment,currentPage,messageType,customers)),
      showAndHideEditNotificationModal:()=>dispatch(showAndHideEditNotificationModal()),
      getNotificationDetails:(id)=>dispatch(getNotificationDetails(id)),
      NotificationUpdate:(registrationData,attachment,currentPage)=>dispatch(NotificationUpdate(registrationData,attachment,currentPage)),
      selectedNotificationsDelete:(ids)=>dispatch(selectedNotificationsDelete(ids)),
      usersSelectorList:(query,page)=>dispatch(usersSelectorList(query,page)),
      searchNotificationList:(querry,currentPage)=>dispatch(searchNotificationList(querry,currentPage)),
      inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
      getLatestNotificationDetails:()=>dispatch(getLatestNotificationDetails())
    };
  };
  





export default connect(mapStateToProps,mapDispatchToProps) (Notification)