import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import Select from "react-select";

import {chargesUsersSelector,chargesUpdate,hidechargesModal,showchargesModal,showEditchargesModal,hideEditchargesModal,getchargess,chargesEdit,inputHandler,registercharges, chargesDelete,getSelectors} from "../../../redux/actions";

function Charges(props) {


// const [addchargesData,setAddchargesData] = useState({name:''});
const [chargesData,setchargesData] = useState({onId:'',charge_name:'',code:'',description:'',vendor_type:null,unit:null,per_unit_price:'',per_unit_cost:'',charge_status:true});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);
const [value, onChange] = useState([]);
const [editValue, setEditValue] = useState([]);
const [vendorTypeFieldError, setVendorTypeFieldError] = useState(null)
const [vendorUnitError, setVendorUnitError] = useState(null)


  useEffect(() => {
    props.getchargess(props.currentPage);
    if(props.registerchargesSuccess){
      onChange(null)
    }
    if(props.registerchargesSuccess){
      setchargesData({onId:'',charge_name:'',code:'',description:'',vendor_type:null,unit:null,per_unit_price:'',per_unit_cost:'',charge_status:true})
    }
  }, [props.registerchargesSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
  props.getSelectors();

},[])


useEffect(()=>{
  let data= props.editDetails?.data
setchargesData({...chargesData,
  'onId':data?.id||'',
  'charge_name':data?.charge_name||'',
  'code':data?.code||'',
  'vendor_type':{label:data?.vendor_type, value:data?.vendor_type}||null,
  'per_unit_price':data?.per_unit_price||'',
  'per_unit_cost':data?.per_unit_cost||'',
  'unit':{label:data?.unit, value:data?.unit}||null,
  'charge_status':data?.charge_status==1?true:false||'',
  'description':data?.description||'',
})
},[props.editDetails])



const changeChargeStatus=()=>{
  setchargesData({...chargesData,charge_status:!chargesData.charge_status})
}



  let vendorTypeOptions; 
  if(props?.SelectorsFetch?.vendorType){
    vendorTypeOptions = props.SelectorsFetch?.vendorType.map(function (data) {
      return { value: data.vendor_type_name, label: data.vendor_type_name }
    })
  }
  

  let unitOptions =[
  {label:'Each',value:'Each'},
  {label:'Day',value:'Day'}
]; 
  



  const onInputchange = (e) => {
    setchargesData({...chargesData, [e.target.name]:e.target.value  });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setchargesData({...chargesData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hidechargesModal()

  };
  const handleShow = () => {
    setchargesData({onId:'',charge_name:'',code:'',description:'',vendor_type:null,unit:null,per_unit_price:'',per_unit_cost:'',charge_status:true})
    setVendorTypeFieldError('')

    props.showchargesModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditchargesModal();

  };

  const handleEditShow=()=>{
    setVendorTypeFieldError('')

    props.showEditchargesModal()

  }






const deletecharges = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this charges",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.chargesDelete(id, props.currentPage);
    }
  });
};





const submitcharges = (e) => {
  e.preventDefault();
if(chargesData.vendor_type !=null){
    props.registercharges(chargesData);
    setVendorTypeFieldError('')

}else{
  setVendorTypeFieldError('The vendor type field is required')
}
}


const Updatecharges = (e) => {
  e.preventDefault();
  if(chargesData.vendor_type !=null){
  props.chargesUpdate(chargesData,props.currentPage);
  setVendorTypeFieldError('')

  }else{
    setVendorTypeFieldError('The vendor type field is required')
  }
  
}

const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 55,
    baseUnit: 4,
  },
});

const menuPortal = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};



const handleVendorTypeChange = (selected) => {
  setchargesData({ ...chargesData, vendor_type: selected });
};

const handleUnitChange = (selected) => {
  setchargesData({ ...chargesData, unit: selected });
};

  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7"> 
        
     <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add charges</a></div> 
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className=" text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.chargessList?.data?.data.map((list, index) => {
              return (
                <div
                  className=" mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <div className="row">
                <div className="col-md-2">

            <span className="header-text">Charge Name</span>  

                </div>
            <div className="col-md-1">

          <span className="header-text">Code</span>  

              </div>

              <div className="col-md-3">

          <span className="header-text">description</span>  

              </div>

              <div className="col-md-2">

<span className="header-text">Vendor Type</span>  

    </div>
    <div className="col-md-1">

<span className="header-text">Unit</span>  

    </div>

    <div className="col-md-1">

<span className="header-text">Amount</span>  

    </div>

    <div className="col-md-1">

<span className="header-text">cost</span>  

    </div>

    <div className="col-md-1">

<span className="header-text">status</span>  

    </div>
                
              </div>
             

              <div className="row">
                <div className="col-md-2">

            <span className="text-info-charges">{list.charge_name}</span>  

                </div>
            <div className="col-md-1">

          <span className="text-info-charges">{list.code}</span>  

              </div>

              <div className="col-md-3">

          <span className="text-info-charges">{list.description}</span>  

              </div>

              <div className="col-md-2">

<span className="text-info-charges">{list.vendor_type}</span>  

    </div>
    <div className="col-md-1">

<span className="text-info-charges">{list.unit}</span>  

    </div>

    <div className="col-md-1">

<span className="text-info-charges">{list.per_unit_price}</span>  

    </div>

    <div className="col-md-1">

<span className="text-info-charges">{list.per_unit_cost}</span>  

    </div>

    <div className="col-md-1">

<span className="text-info-charges">{list.charge_status==1?'active':'non-active'}</span>  

    </div>
                
              </div>
             
             
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a onClick={
                    ()=>{
                      setchargesData({...chargesData,'onId':list.id})
                      props.chargesEdit(list.id)
                      setTimeout(()=>{     
                          handleEditShow() 
                      },190)
                            

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deletecharges(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.chargessList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.chargessList?.data?.current_page}
              itemsCountPerPage={props.chargessList?.data?.per_page}
              totalItemsCount={props.chargessList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getchargess(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Charges Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Charge </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitcharges}>
          <Modal.Body
             style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }} >
            <div className="row">

            <div className="col-md-4 mb-3">
        
            <FloatingLabel label="Charge Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="charge_name"
                    value={chargesData.charge_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.chargesRegisterErrors?.error?.charge_name?true:false}
                  />

                </FloatingLabel>

                {props.chargesRegisterErrors?.error?.charge_name ? (
                  <div className="validation-error">
                    The charge name already exists !
                  </div>
                ) : (
                  ""
                )}

            </div>


            


              <div className="col-md-4 mb-3">
              <Select
                  options={vendorTypeOptions}
                  theme={theme}
                  placeholder="Vendor Type"
                  name="vendor_type"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={chargesData.vendor_type}
                  defaultValue={chargesData.vendor_type}
                  onChange={(e) => handleVendorTypeChange(e)}
                  isClearable={true}
                />

         {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''}
              </div>

</div>
<div className="row">
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="code"
                    value={chargesData.code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>


              </div>

              <div className="col-md-4 mb-3">
              <Select
                  options={unitOptions}
                  theme={theme}
                  placeholder="Unit"
                  name="unit"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={chargesData.unit}
                  defaultValue={chargesData.unit}
                  onChange={(e) => handleUnitChange(e)}
                  isClearable={true}
                />

         {/* {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''} */}
              </div>


              
           
              <div className="col-md-4 d-flex mt-3 mb-3">
      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Active"
        name="charge_status"
        checked={chargesData.charge_status}
      onChange={changeChargeStatus}
      />
      </div>

            </div>
            <div className="row">

            <div className="col-md-6 mb-3">
                <FloatingLabel label="Amount Per Unit">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="per_unit_price"
                    value={chargesData.per_unit_price}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Cost Per Unit">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="per_unit_cost"
                    value={chargesData.per_unit_cost}
                    
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>


            </div>


            <div className="row">

            <div className="col-md-12 mb-3">
            <label
                    htmlFor="description"
                    className="form-label picker-inputs-lebel"
                  >
                  Description
                  </label>

            <textarea
                    className="form-control"
                    name="description"
                    value={chargesData.description}
                    onChange={(e) => onInputchange(e)}
                    style={{height:'100px'}}
                  ></textarea>

              </div>




            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>







{/* ======================
edit Charges Modal
====================== */}
      <Modal show={props.showEditWarModal} onHide={handleEditClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Update Charge </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updatecharges}>
          <Modal.Body
             style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }} >
            <div className="row">

            <div className="col-md-4 mb-3">
        
            <FloatingLabel label="Charge Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="charge_name"
                    value={chargesData.charge_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.chargesUpdateErrors?.error?.charge_name?true:false}
                  />

                </FloatingLabel>

                {props.chargesUpdateErrors?.error?.charge_name ? (
                  <div className="validation-error">
                    The charge name already exists !
                  </div>
                ) : (
                  ""
                )}

            </div>


            


              <div className="col-md-4 mb-3">
              <Select
                  options={vendorTypeOptions}
                  theme={theme}
                  placeholder="Vendor Type"
                  name="vendor_type"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={chargesData.vendor_type}
                  defaultValue={chargesData.vendor_type}
                  onChange={(e) => handleVendorTypeChange(e)}
                  isClearable={true}
                />

         {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''}
              </div>

</div>
<div className="row">
              <div className="col-md-4 mb-3">
                <FloatingLabel label="Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="code"
                    value={chargesData.code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>


              </div>

              <div className="col-md-4 mb-3">
              <Select
                  options={unitOptions}
                  theme={theme}
                  placeholder="Unit"
                  name="unit"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={chargesData.unit}
                  defaultValue={chargesData.unit}
                  onChange={(e) => handleUnitChange(e)}
                  isClearable={true}
                />

         {/* {vendorTypeFieldError?<div className="validation-error">{vendorTypeFieldError} !</div>:''} */}
              </div>

              
           
              <div className="col-md-4 d-flex mt-3 mb-3">
      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Active"
        checked={chargesData.charge_status}
      onChange={changeChargeStatus}
      />
      </div>

            </div>
            <div className="row">

            <div className="col-md-6 mb-3">
                <FloatingLabel label="Amount Per Unit">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="per_unit_price"
                    value={chargesData.per_unit_price}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Cost Per Unit">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="per_unit_cost"
                    value={chargesData.per_unit_cost}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>



              </div>


            </div>


            <div className="row">

            <div className="col-md-12 mb-3">
            <label
                    htmlFor="description"
                    className="form-label picker-inputs-lebel"
                  >
                  Description
                  </label>

            <textarea
                    className="form-control"
                    name="description"
                    value={chargesData.description}
                    onChange={(e) => onInputchange(e)}
                    style={{height:'100px'}}
                  ></textarea>

              </div>




            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>










    </>
  );
}

const mapStateToProps = (state) => {
  const {chargesCustomersSelector,loading,chargessList,showWarModal,showEditWarModal,name,
    registerchargesSuccess,chargesRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,chargesUpdateErrors,
    email,
    contact,
    address,
  } = state.SettingsChargesHandler;
  const {SelectorsFetch}=state.SelectorsHandler;


  return {
    loading: loading,
    name:name,
    email:email,
    contact:contact,
    address:address,
    chargesRegisterErrors:chargesRegisterErrors,
    currentPage:currentPage,
    chargessList:chargessList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerchargesSuccess:registerchargesSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    chargesUpdateErrors:chargesUpdateErrors,
    chargesCustomersSelector:chargesCustomersSelector,
    SelectorsFetch:SelectorsFetch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getchargess:(currentPage)=>dispatch(getchargess(currentPage)),
      showchargesModal:()=>dispatch(showchargesModal()),
      hidechargesModal:()=>dispatch(hidechargesModal()),
      showEditchargesModal:()=>dispatch(showEditchargesModal()),
      hideEditchargesModal:()=>dispatch(hideEditchargesModal()),
      registercharges:(data)=>dispatch(registercharges(data)),
      chargesEdit:(id)=>dispatch(chargesEdit(id)),
      chargesUpdate:(data,currentPage)=>dispatch(chargesUpdate(data,currentPage)),
      chargesDelete:(id,currentPage)=>dispatch(chargesDelete(id,currentPage)),
      chargesUsersSelector:(q)=>dispatch(chargesUsersSelector(q)),
      getSelectors: () => dispatch(getSelectors()),

  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Charges);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================