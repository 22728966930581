import axios from 'axios';

var CryptoJS = require("crypto-js");




// ---------------------------------------------------------------------------------------------------------------------------------
// Autos Based Async Functions -remember these functions are re-used in many places so while changing keep this factor in mind
// ---------------------------------------------------------------------------------------------------------------------------------


export const loadUsersOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/usersSelectorListPaginate?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };



// ---------------------------------------------------------------------------------------------------------------------------------
// Autos Based Async Functions -remember these functions are re-used in many places so while changing keep this factor in mind
// ---------------------------------------------------------------------------------------------------------------------------------


export const loadCustomersOptions = async (search, loadedOptions, { page }) => {
  try {
    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    console.log(page);
    const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/customerSelectorList?search=${search}&page=${page}`,axiosConfig);

    console.log(response.data);

    return {
      options: response.data.results,
      hasMore: response.data.has_more,
      additional: {
        page: page + 1,
      },
    };

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error to handle it at a higher level if needed
  }
};









 export const loadTowingOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/towingSelectorListPaginate?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };










// ---------------------------------------------------------------------------------------------------------------------------------
// Containers Based Async Functions
// ---------------------------------------------------------------------------------------------------------------------------------


export const loadContainerOptions = async (search, loadedOptions,filterBy,containerType, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(filterBy);
      console.log(page);

      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/vinsNumberFetchQuery?search=${search}&filterBy=${filterBy}&containerType=${containerType}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };





export const loadForwarderOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/forwardersQuery?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };





  export const loadTruckerOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/truckersQuery?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };



  export const loadConsigneeOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/consigneeQuery?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };




  export const loadVesselOptions = async (search, loadedOptions, { page }) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(page);
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/vesselQuery?search=${search}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };




  export const loadVehiclesOptionsPerVendor = async (search, loadedOptions, { page },vendorId,Catagory,vendorStatus) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(vendorStatus);
      console.log(vendorId)
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/getVendorPerVehiclesAsyncList?search=${search}&vendorId=${vendorId}&vendorCatagory=${Catagory}&vendorStatus=${vendorStatus}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };




  export const loadContainersOptionsPerVendor = async (search, loadedOptions, { page },vendorId,Catagory,vendorStatus) => {
    try {
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
      console.log(vendorStatus);
      console.log(vendorId)
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/getVendorPerContainerAsyncList?search=${search}&vendorId=${vendorId}&vendorCatagory=${Catagory}&vendorStatus=${vendorStatus}&page=${page}`,axiosConfig);
  
      console.log(response.data);

      return {
        options: response.data.results,
        hasMore: response.data.has_more,
        additional: {
          page: page + 1,
        },
      };
  
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  };
