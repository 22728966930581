const InitialStates = {
    billerehousesList:null,
  
    currentPage: 1,
   
    showbillerModal:false,
    showEditbillerModal:false,
    loading:false,
    name:'',
  billersList:null,
    billerRegisterErrors:null,
    billerUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showbillerModal":
      return { ...state, 'showWarModal':true };

      case "hidebillerModal":
        return { ...state, 'showWarModal':false, 'billerRegisterErrors':'', 'name':'' };
  

        case "showEditbillerModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditbillerModal":
            return { ...state, 'showEditWarModal':false, 'billerUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================
            case "getbiller_started":
              return {...state, 'loading':true}
    case "registerbiller_started":
              return {...state, 'loading':true}

              // case "billerEditDetails_started":
              //   return {...state, 'loading':true}

              //   case "billerUpdate_started":
              //     return {...state, 'loading':true}
// ==================ends here=====================
       case "getbillersSettings_success":

         return { ...state, 'billersList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerbiller_success":
    return { ...state, 'registerbillerSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "billerEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "billerUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "billerDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerbiller_failure":
    return { ...state, 'billerRegisterErrors': action.payload,  'loading':false };

case "billerUpdate_failure":
  return { ...state, 'billerUpdateErrors': action.payload,  'loading':false,  };

case 'billerUsersSelector_success':

  return { ...state, 'billerCustomersSelector': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   