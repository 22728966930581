import React, { useState, useEffect } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,ImageWithZoom,Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
function SimpleCourousel(props) {
    const [currentSlide, setCurrentSlide] = useState(0);

const handleSlideChange = (currentSlide) => {
  setCurrentSlide(currentSlide);
};

let slides;
if(props?.images){
slides = props?.images?.map((img, index) => {
    return { id: index, imageSrc: `${props.imagesLocation}/${img.image_name}` } || { id:0, imageSrc: `` } ;
});
}
  
  return (
    
    <div className="cr">

    <CarouselProvider
naturalSlideWidth={props.width}
naturalSlideHeight={props.height}
totalSlides={slides?.length}
isPlaying={props.play} // Autoplay
currentSlide={currentSlide}
dragEnabled={props.dragEnable} // Disable dragging for simplicity
onChange={handleSlideChange}
>
<Slider>
{slides.map((slide) => (
<Slide key={slide.id} index={slide.id}>
<ImageWithZoom src={slide.imageSrc} />
</Slide>
))}
</Slider>

<ButtonBack className="back-courousel-button"><i className="fas fa-arrow-alt-circle-left"></i></ButtonBack>
<ButtonNext className="next-courousel-button"><i className="fas fa-arrow-alt-circle-right"></i></ButtonNext>

<div className="custom-dots-container">
{slides.map((slide) => (
<Dot key={slide.id} slide={slide.id} className={`custom-dot ${currentSlide === slide.id ? 'active' : ''}`}>
<img
className="custom-dot-image"
src={slide.imageSrc}
alt={`Dot ${slide.id}`}
/>
</Dot>
))}
</div>
</CarouselProvider>
  </div>
  
  )
}

export default SimpleCourousel