import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");





export const showConfirmListModal=()=>{
  return {
    type:'openConfirmModal'
  }
  }




  export const hideConfirmListModal=()=>{
    return {
      type:'hideConfirmModal'
    }
    }



export const showEditLoadingModal=()=>{
  return {
    type:'openEditLoadingListModal'
  }
  }




  export const hideEditLoadingModal=()=>{
    return {
      type:'hideEditLoadingListModal'
    }
    }



    export const showAddConsigneeModal=()=>{
      return {
        type:'openRegConsigneeModal'
      }
      }
    
    
    
    
      export const hideAddConsigneeModal=()=>{
        return {
          type:'hideRegConsigneeModal'
        }
        }
    



        export const showLoadingCommunicationModal=()=>{
          return {
            type:'openListComunicationModal'
          }
          }
        
        
        
        
          export const hideLoadingCommunicationModal=()=>{
            return {
              type:'hideListComunicationModal'
            }
            }





            export const showEditNoteModal=()=>{
              return {
                type:'openEditNoteModal'
              }
              }
            
            
            
            
              export const hideEditNoteModal=()=>{
                return {
                  type:'hideEditNoteModal'
                }
                }




                export const showAddContianerNoModal=()=>{
                  return {
                    type:'openContainerNoModal'
                  }
                  }
                
                
                
                
                  export const hideAddContianerNoModal=()=>{
                    return {
                      type:'hideContainerNoModal'
                    }
                    }
    
// ---------------------------------------------------------------------------------------------------------
// get information for consignee selector
// ---------------------------------------------------------------------------------------------------------

export const consigneeSelector = () => {


  return (dispatch) => {
      dispatch(getConsigneeSelectorStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/consigneeSelector/`,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getConsigneeSelectorSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getConsigneeSelectorFailure(err.response.data.errors));

        });
    };
  };
  
  const getConsigneeSelectorStarted = () => ({
    type: "getConsigneePickers_started",
  });
  
  
  const getConsigneeSelectorSuccess = (data) => ({
    type: "getConsigneePickers_success",
    payload: {
      ...data,
    },
  });
  
  const getConsigneeSelectorFailure = (error) => ({
    type: "getConsigneePickers_failure",
    payload: {
      error,
    }, 
  });







// ---------------------------------------------------------------------------------------------------------
// get count of loading lists by catagories
// ---------------------------------------------------------------------------------------------------------

export const countLoadingLists = () => {


  return (dispatch) => {
      dispatch(getCountListsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/countCatagories/`,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getCountListsSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getCountListsFailure(err.response.data.errors));

        });
    };
  };
  
  const getCountListsStarted = () => ({
    type: "getAutosCountLists_started",
  });
  
  
  const getCountListsSuccess = (data) => ({
    type: "getAutosCountLists_success",
    payload: {
      ...data,
    },
  });
  
  const getCountListsFailure = (error) => ({
    type: "getAutosCountLists_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// get details of cars in the loading list by picking the warehouse
// ---------------------------------------------------------------------------------------------------------

export const getAutosByWarehouse = (warehouse,pageNumber) => {


    return (dispatch) => {
        dispatch(getStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    let data={
warehouse:warehouse,
    }

        axios
          .post(`${process.env.REACT_APP_API_ADDRESS}/filterCars/?page=${pageNumber}`,data,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getStarted = () => ({
      type: "getAutos_started",
    });
    
    
    const getSuccess = (data) => ({
      type: "getAutos_success",
      payload: {
        ...data,
      },
    });
    
    const getFailure = (error) => ({
      type: "getAutos_failure",
      payload: {
        error,
      }, 
    });
  
  
  





// ---------------------------------------------------------------------------------------------------------
// get detials of selected autos
// ---------------------------------------------------------------------------------------------------------

export const getSelectedAutosConfirm = (autoIds) => {


    return (dispatch) => {
        dispatch(getSelectedAutosStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    let data={
ids:autoIds,
    }

        axios
          .post(`${process.env.REACT_APP_API_ADDRESS}/getSelectedAutos/`,data,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getSelectedAutosSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getSelectedAutosFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getSelectedAutosStarted = () => ({
      type: "getSelectedAutos_started",
    });
    
    
    const getSelectedAutosSuccess = (data) => ({
      type: "getSelectedAutos_success",
      payload: {
        ...data,
      },
    });
    
    const getSelectedAutosFailure = (error) => ({
      type: "getSelectedAutos_failure",
      payload: {
        error,
      }, 
    });
  
  
  



// ---------------------------------------------------------------------------------------------------------
// to make loading list of the autos 
// ---------------------------------------------------------------------------------------------------------

export const makeLoadingList = (autos,consignee) => {


  return (dispatch) => {
      dispatch(makeStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
autos:autos,
consignee_name:consignee,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/makeLoadingLists/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(makeSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(makeFailure(err.response.data.errors));

        });
    };
  };
  
  const makeStarted = () => ({
    type: "makeLoading_started",
  });
  
  
  const makeSuccess = (data) => ({
    type: "makeLoading_success",
    payload: {
      ...data,
    },
  });
  
  const makeFailure = (error) => ({
    type: "makeLoading_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// switch between loading lists catagories
// ---------------------------------------------------------------------------------------------------------

export const switchLoadingList = (catagory,pageNumber) => {


  return (dispatch) => {
      dispatch(switchStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
catagory:catagory,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/switchLoadingLists/?page=${pageNumber}`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(switchSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(switchFailure(err.response.data.errors));

        });
    };
  };
  
  const switchStarted = () => ({
    type: "switchLoading_started",
  });
  
  
  const switchSuccess = (data) => ({
    type: "switchLoading_success",
    payload: {
      ...data,
    },
  });
  
  const switchFailure = (error) => ({
    type: "switchLoading_failure",
    payload: {
      error,
    }, 
  });







// ---------------------------------------------------------------------------------------------------------
// switch between loading lists catagories
// ---------------------------------------------------------------------------------------------------------

export const confirmOrRejectList = (status,id) => {


  return (dispatch) => {
      dispatch(confirmOrRejectStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
    id:id,
status:status,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/confirmOrRejectLists/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(confirmOrRejectSuccess(response.data));
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The action has been successfully performed',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(confirmOrRejectFailure(err.response.data.errors));

        });
    };
  };
  
  const confirmOrRejectStarted = () => ({
    type: "confirmOrReject_started",
  });
  
  
  const confirmOrRejectSuccess = (data) => ({
    type: "confirmOrReject_success",
    payload: {
      ...data,
    },
  });
  
  const confirmOrRejectFailure = (error) => ({
    type: "confirmOrReject_failure",
    payload: {
      error,
    }, 
  });




// ---------------------------------------------------------------------------------------------------------
// delete loading list
// ---------------------------------------------------------------------------------------------------------

export const loadingListDelete= (id) => {


  return (dispatch) => {
    dispatch(loadingDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/loadingListDelete/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(loadingDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The loading list has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(loadingDeletionFailure(err.message));
      });
  };
};

const loadingDeletionStarted = () => ({
  type: "loadingListDeletion_started",
});

const loadingDeletionSuccess = (data) => ({
  type: "loadingListDeletion_success",
  payload: {
    ...data,
  },
});

const loadingDeletionFailure = (error) => ({
  type: "loadingListDeletion_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// delete autos from the loading list
// ---------------------------------------------------------------------------------------------------------

export const autoDeleteFromLoadingList= (autoId,loadingListId) => {


  return (dispatch) => {
    dispatch(autoDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   
    let data={
      autoId:autoId,
      loadingListId:loadingListId,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteAutoFromList/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(autoDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The auto has been successfully deleted from the loading list ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(autoDeletionFailure(err.message));
      });
  };
};

const autoDeletionStarted = () => ({
  type: "listAutoDeletion_started",
});

const autoDeletionSuccess = (data) => ({
  type: "listAutoDeletion_success",
  payload: {
    ...data,
  },
});

const autoDeletionFailure = (error) => ({
  type: "listAutoDeletion_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// add autos to the loading list
// ---------------------------------------------------------------------------------------------------------

export const addAutosToLoadingList= (autoIds,loadingListId,consignee_name) => {


  return (dispatch) => {
    dispatch(addAutosStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   
    let data={
      autoIds:autoIds,
      loadingListId:loadingListId,
      consignee_name:consignee_name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/addAutosToLoadingList/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(addAutosSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The autos has been successfully added to the loading list ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(addAutosFailure(err.message));
      });
  };
};

const addAutosStarted = () => ({
  type: "addAutosToList_started",
});

const addAutosSuccess = (data) => ({
  type: "addAutosToList_success",
  payload: {
    ...data,
  },
});

const addAutosFailure = (error) => ({
  type: "addAutosToList_failure",
  payload: {
    error,
  },
});

// ---------------------------------------------------------------------------------------------------------
// for registering consignee by the client
// ---------------------------------------------------------------------------------------------------------

export const registerConsignee = (consigneeDetails) => {


  return (dispatch) => {
      dispatch(consigneeRegisterStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
consignee_name:consigneeDetails.consignee_name,
address:consigneeDetails.address,
country:consigneeDetails.country,
phone:consigneeDetails.phone,
email:consigneeDetails.email,

  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/addConsigneeByClient/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(consigneeRegisterSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The consignee has been successfully registered',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(consigneeRegisterFailure(err.response.data.errors));

        });
    };
  };
  
  const consigneeRegisterStarted = () => ({
    type: "addConsignee_started",
  });
  
  
  const consigneeRegisterSuccess = (data) => ({
    type: "addConsignee_success",
    payload: {
      ...data,
    },
  });
  
  const consigneeRegisterFailure = (error) => ({
    type: "addConsignee_failure",
    payload: {
      error,
    }, 
  });






  // **********************************************************************************************************
// ---------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------
// Communication on notes actions starts from here ....................
// ---------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------
  // **********************************************************************************************************





// ---------------------------------------------------------------------------------------------------------
// get communication of the loading lists
// ---------------------------------------------------------------------------------------------------------

export const getLoadingListCommunication = (loadingListId) => {


  return (dispatch) => {
      dispatch(getCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
      let data={
        loadingListId:loadingListId,
      }
    

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/fetchLoadingListCommunication/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getCommunicationSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const getCommunicationStarted = () => ({
    type: "getCommunicationLoadingList_started",
  });
  
  
  const getCommunicationSuccess = (data) => ({
    type: "getCommunicationLoadingList_success",
    payload: {
      ...data,
    },
  });
  
  const getCommunicationFailure = (error) => ({
    type: "getCommunicationLoadingList_failure",
    payload: {
      error,
    }, 
  });










// ---------------------------------------------------------------------------------------------------------
// register comment for adding the notes
// ---------------------------------------------------------------------------------------------------------

export const loadingListCommunication = (loadingListId,bodyText,fileAttachment) => {


  return (dispatch) => {
      dispatch(loadListCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };



      const noteData = new FormData();
      noteData.append("loadingListId", loadingListId);
      noteData.append("body", bodyText);
      noteData.append("fileAttachment", fileAttachment);

      
     
  // let data={
  //   loadingListId:loadingListId,
  //   body:bodyText,
  //   fileAttachment:fileAttachment,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/commentLoadingList/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(loadListCommunicationSuccess(response.data));
          
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(loadListCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const loadListCommunicationStarted = () => ({
    type: "listCommunication_started",
  });
  
  
  const loadListCommunicationSuccess = (data) => ({
    type: "listCommunication_success",
    payload: {
      ...data,
    },
  });
  
  const loadListCommunicationFailure = (error) => ({
    type: "listCommunication_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// get the data of the edit 
// ---------------------------------------------------------------------------------------------------------

export const getDataEditNote = (id) => {


  return (dispatch) => {
      dispatch(getEditNoteStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getEditNoteDetails/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getEditNoteSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getEditNoteFailure(err.response.data.errors));

        });
    };
  };
  
  const getEditNoteStarted = () => ({
    type: "getEditNoteDetails_started",
  });
  
  
  const getEditNoteSuccess = (data) => ({
    type: "getEditNoteDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getEditNoteFailure = (error) => ({
    type: "getEditNoteDetails_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// delete selected loading lists notes
// ---------------------------------------------------------------------------------------------------------

export const editSelectedNotes = (selectedNoteId,body,fileAttachment) => {


  return (dispatch) => {
      dispatch(editSelectedNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      const noteData = new FormData();
      noteData.append("selectedNoteId", selectedNoteId);
      noteData.append("body", body);
      noteData.append("fileAttachment", fileAttachment);


  // let data={
  //   selectedNoteId:selectedNoteId,
  //   body:body,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/editLoadingList/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(editSelectedNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The note has been successfully edited',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(editSelectedNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const editSelectedNotesStarted = () => ({
    type: "editSelectedNotes_started",
  });
  
  
  const editSelectedNotesSuccess = (data) => ({
    type: "editSelectedNotes_success",
    payload: {
      ...data,
    },
  });
  
  const editSelectedNotesFailure = (error) => ({
    type: "editSelectedNotes_failure",
    payload: {
      error,
    }, 
  });











// ---------------------------------------------------------------------------------------------------------
// delete selected loading lists notes
// ---------------------------------------------------------------------------------------------------------

export const deleteSelectedNotes = (selectedNotes,loadingListId) => {


  return (dispatch) => {
      dispatch(deleteSelectedNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
    selectedNotes:selectedNotes,
    loadingListId:loadingListId,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/deleteLoadingListsNotes/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(deleteSelectedNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The selected notes are deleted successfully.',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(deleteSelectedNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const deleteSelectedNotesStarted = () => ({
    type: "deleteSelectedNotes_started",
  });
  
  
  const deleteSelectedNotesSuccess = (data) => ({
    type: "deleteSelectedNotes_success",
    payload: {
      ...data,
    },
  });
  
  const deleteSelectedNotesFailure = (error) => ({
    type: "deleteSelectedNotes_failure",
    payload: {
      error,
    }, 
  });



// ---------------------------------------------------------------------------------------------------------
// loadingList information per list to edit container number
// ---------------------------------------------------------------------------------------------------------

export const editContainerInfo = (loadingListId) => {


  return (dispatch) => {
      dispatch(editContainerStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     


      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/editContainerNoInList/`+loadingListId,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(editContainerSuccess(response.data));
       
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(editContainerFailure(err.response.data.errors));

        });
    };
  };
  
  const editContainerStarted = () => ({
    type: "editContainer_started",
  });
  
  
  const editContainerSuccess = (data) => ({
    type: "editContainer_success",
    payload: {
      ...data,
    },
  });
  
  const editContainerFailure = (error) => ({
    type: "editContainer_failure",
    payload: {
      error,
    }, 
  });


  
// ---------------------------------------------------------------------------------------------------------
// add details of container no
// ---------------------------------------------------------------------------------------------------------

export const addContainerNumber = (loadingListId,container_no) => {


  return (dispatch) => {
      dispatch(addContainerNumberStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
    loadingListId:loadingListId,
    container_no:container_no,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/addContainerNo`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(addContainerNumberSuccess(response.data));
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The container number updated successfully',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(addContainerNumberFailure(err.response.data.errors));

        });
    };
  };
  
  const addContainerNumberStarted = () => ({
    type: "addContainerNumber_started",
  });
  
  
  const addContainerNumberSuccess = (data) => ({
    type: "addContainerNumber_success",
    payload: {
      ...data,
    },
  });
  
  const addContainerNumberFailure = (error) => ({
    type: "addContainerNumber_failure",
    payload: {
      error,
    }, 
  });

