import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");





export const showContainerChargesModal=()=>{
  return {
    type:'openContainerChargesModal'
  }
  }


  export const showContainerInvoiceModal=()=>{
    return {
      type:'openContainerInvoiceModal'
    }
    }
  



export const showContainerRegModal=()=>{
  return {
    type:'openContainerRegModal'
  }
  }




  export const hideContainerRegModal=()=>{
    return {
      type:'hideContainerRegModal'
    }
    }



export const showContainerEditModal=()=>{
  return {
    type:'openContainerEditModal'
  }
  }

  export const hideContainerEditModal=()=>{
    return {
      type:'hideContainerEditModal'
    }
    }


  
export const showContainerAutoAdditionModal=()=>{
  return {
    type:'openContainerAutoAdditionModal'
  }
  }

  export const hideContainerAutoAdditionModal=()=>{
    return {
      type:'hideContainerAutoAdditionModal'
    }
    }

     





          


// ---------------------------------------------------------------------------------------------------------
// container invoice information
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoice = (id) => {
  return (dispatch) => {
    dispatch( ContainerInvoiceStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerInvoice/${id}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(ContainerInvoiceSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(ContainerInvoiceFailure(err.message));
      });
  };
};

const ContainerInvoiceStarted = () => ({
  type: "Containers_invoice_fetch_started",
});

const ContainerInvoiceSuccess = (data) => ({
  type: "Containers_invoice_fetch_success",
  payload: {
    ...data,
  },
});


const ContainerInvoiceFailure = (error) => ({
  type: "Containers_invoice_fetch_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// container invoice update
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceUpdate = (data) => {
  return (dispatch) => {
    dispatch( ContainerInvoiceUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let invoiceDate= data.invoice_date? moment(data?.invoice_date).format('YYYY-MM-DD'):'';


    let formData={
      container_id:data.container_id,
      invoice_date:invoiceDate,
      invoice_no:data.invoice_no,
    }
    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/containerInvoiceUpdate`,formData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(ContainerInvoiceUpdateSuccess(response.data));
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The details have been successfully updateds',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(ContainerInvoiceUpdateFailure(err.message));
      });
  };
};

const ContainerInvoiceUpdateStarted = () => ({
  type: "Containers_invoice_update_started",
});

const ContainerInvoiceUpdateSuccess = (data) => ({
  type: "Containers_invoice_update_success",
  payload: {
    ...data,
  },
});


const ContainerInvoiceUpdateFailure = (error) => ({
  type: "Containers_invoice_update_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// vin numbers fetch for selector of containers 
// ---------------------------------------------------------------------------------------------------------

export const VinNumberFetchForContainers = (query) => {
    return (dispatch) => {
      dispatch(VinNumberFetchContainerStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  // //console.log(query)
  if(query){
    let data={
      q:query
    }
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/vinsNumberFetchQuery`, data, axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
  
            dispatch(VinNumberFetchContainerSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(VinNumberFetchContainerFailure(err.message));
        });
      }
    };
  };
  
  const VinNumberFetchContainerStarted = () => ({
    type: "VinNumberFetch_started",
  });
  
  const VinNumberFetchContainerSuccess = (data) => ({
    type: "VinNumberFetch_success",
    payload: {
      ...data,
    },
  });
  
  
  const VinNumberFetchContainerFailure = (error) => ({
    type: "VinNumberFetch_failure",
    payload: {
      error,
    },
  });
  






  



// ---------------------------------------------------------------------------------------------------------
// Download zip
// ---------------------------------------------------------------------------------------------------------



        export const downloadImagesContainerZip = (id) => {
          return (dispatch) => {
            dispatch(zipImagesStarted());
        
            const cipherToken = JSON.parse(localStorage.getItem("fkey"));
        
            var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
            var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        
            // //console.log(decryptedToken);
        
            let axiosConfig = {
              headers: {
                Accept: "application/zip",
                'Content-type': 'application/zip',
                Authorization: `Bearer ${decryptedToken}`,
            
              },
            };

            
              fetch(`${process.env.REACT_APP_API_ADDRESS}/zipContainterFilesDownload/${id}`, axiosConfig)
           
                    .then(res => res.blob())
                    .then(blob => saveAs(blob, 'Container Photos.zip'))


                 
              .catch((err) => {
                dispatch(zipImagesFailure(err.message));
              });
          };
        };
        
        const zipImagesStarted = () => ({
          type: "zipImages_started",
        });
        
        const zipImagesSuccess = (data) => ({
          type: "zipImages_success",
          payload: {
            ...data,
          },
        });
        
        
        const zipImagesFailure = (error) => ({
          type: "zipImages_failure",
          payload: {
            error,
          },
        });





// ---------------------------------------------------------------------------------------------------------
// to fetch containers data
// ---------------------------------------------------------------------------------------------------------

export const ContainersList = (pageNumber) => {
  return (dispatch) => {
    dispatch(ContainersListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/ContainersList?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(ContainersCatagoryListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(ContainersCatagoryListFailure(err.message));
      });
  };
};

const ContainersCatagoryListStarted = () => ({
  type: "Containers_catagory_fetch_started",
});

const ContainersCatagoryListSuccess = (data) => ({
  type: "Containers_catagory_fetch_success",
  payload: {
    ...data,
  },
});


const ContainersCatagoryListFailure = (error) => ({
  type: "Containers_catagory_fetch_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// to fetch containers data
// ---------------------------------------------------------------------------------------------------------

export const containersCatagoryList = (pageNumber,catagory) => {
  console.log('im')
  return (dispatch) => {
    dispatch(ContainersCatagoryListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/getCatagory/${catagory}?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          console.log(response.data);

          dispatch(ContainersListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(ContainersListFailure(err.message));
      });
  };
};

const ContainersListStarted = () => ({
  type: "Containers_catagory_fetch_started",
});

const ContainersListSuccess = (data) => ({
  type: "Containers_catagory_fetch_success",
  payload: {
    ...data,
  },
});


const ContainersListFailure = (error) => ({
  type: "Containers_catagory_fetch_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// this function will or add data
// ---------------------------------------------------------------------------------------------------------

export const ContainerRegistration = (registrationData,vinValues,customerValue,currentPage) => {

  const ContainerRegContainerData = new FormData();

  for (let i = 0; i < registrationData?.images.length; i++) {
    ContainerRegContainerData.append("images[]", registrationData?.images[i]);
  }
  const vinNumbers= vinValues?.map((list,index)=>{
    const vins=[];
    vins.push(list.value);
return vins;

  })
  
  ContainerRegContainerData.append("vins",vinNumbers);
  let expected_arrival_date= registrationData.expected_arrival_date? moment(registrationData?.expected_arrival_date).format('YYYY-MM-DD'):'';
  let loading_date=registrationData.loading_date? moment(registrationData?.loading_date).format('YYYY-MM-DD'):'';
  let container_arrival_date=registrationData.container_arrival_date? moment(registrationData?.container_arrival_date).format('YYYY-MM-DD'):'';
  let container_unloading_date=registrationData.container_unloading_date? moment(registrationData?.container_unloading_date).format('YYYY-MM-DD'):'';
  let document_cut_date=registrationData.document_cut_date? moment(registrationData?.document_cut_date).format('YYYY-MM-DD'):'';
  let port_cut_date=registrationData.port_cut_date? moment(registrationData?.port_cut_date).format('YYYY-MM-DD'):'';
  let departure_date=registrationData.departure_date? moment(registrationData?.departure_date).format('YYYY-MM-DD'):'';
  let eta=registrationData.eta? moment(registrationData?.eta).format('YYYY-MM-DD'):'';
  let cpb_completed=registrationData.cpb_completed? moment(registrationData?.cpb_completed).format('YYYY-MM-DD'):'';
  let si_submission_date=registrationData.si_submission_date? moment(registrationData?.si_submission_date).format('YYYY-MM-DD'):'';
  let completed_date=registrationData.completed_date? moment(registrationData?.completed_date).format('YYYY-MM-DD'):'';
  let validation_date=registrationData.validation_date? moment(registrationData?.validation_date).format('YYYY-MM-DD'):'';

  

  ContainerRegContainerData.append("catagory", registrationData.catagory?.value||'');
  ContainerRegContainerData.append("customer_id", customerValue?.value||'');

  ContainerRegContainerData.append("consignees_id", registrationData.consignee?.value||'');

  ContainerRegContainerData.append("forwarders_id", registrationData.forwarder?.value||'');
  ContainerRegContainerData.append("truckers_id", registrationData.trucker?.value||'');
  ContainerRegContainerData.append("exporter_name", registrationData?.exporter_name||'');
  ContainerRegContainerData.append("vessel_name", registrationData.vessel_name?.value||'');
  ContainerRegContainerData.append("paid_status", registrationData?.paid_status?.value||'');
  ContainerRegContainerData.append("container_size", registrationData?.container_size?.value||'');
  ContainerRegContainerData.append("port", registrationData.port?.value||'');
  ContainerRegContainerData.append("country_port", registrationData.country_port?.value||'');
  ContainerRegContainerData.append("port_discharge", registrationData.port_discharge?.value||'');
  ContainerRegContainerData.append("country_discharge", registrationData.country_discharge?.value||'');

  ContainerRegContainerData.append("vessel_voyage", registrationData?.vessel_voyage||'');
  ContainerRegContainerData.append("seal", registrationData?.seal||'');
  ContainerRegContainerData.append("itn", registrationData?.itn||'');
  ContainerRegContainerData.append("xtn", registrationData?.xtn||'');
  ContainerRegContainerData.append("terminal", registrationData?.terminal||'');
  ContainerRegContainerData.append("steamship_line", registrationData?.steamship_line||'');
  

  ContainerRegContainerData.append("loading_date", loading_date||'');
  ContainerRegContainerData.append("document_cut_date", document_cut_date||'');
  ContainerRegContainerData.append("port_cut_date", port_cut_date||'');
  ContainerRegContainerData.append("departure_date", departure_date||'');
  ContainerRegContainerData.append("eta", eta||'');
  ContainerRegContainerData.append("cpb_completed", cpb_completed||'');
  ContainerRegContainerData.append("si_submission_date", si_submission_date||'');
  ContainerRegContainerData.append("completed_date", completed_date||'');
  ContainerRegContainerData.append("validation_date", validation_date||'');




  ContainerRegContainerData.append("point_of_loading", registrationData.point_of_loading?.value||'');
  ContainerRegContainerData.append("container_number", registrationData?.container_number||'');
  ContainerRegContainerData.append("booking_number", registrationData?.booking_number||'');
  ContainerRegContainerData.append("lines", registrationData?.lines||'');
  ContainerRegContainerData.append("expected_arrival_date", expected_arrival_date||'');
  ContainerRegContainerData.append("container_arrival_date", container_arrival_date||'');
  ContainerRegContainerData.append("container_unloading_date", container_unloading_date||'');


  ContainerRegContainerData.append("dock_receipt", registrationData.dock_receipt||'');
  ContainerRegContainerData.append("bill_copy", registrationData.bl_copy||'');
  ContainerRegContainerData.append("telex_release", registrationData.telex_release||'');
  ContainerRegContainerData.append("validations", registrationData.telex_release||'');

  // for checking container first then deciding you want to add or not on the same container number



  return (dispatch) => {
      dispatch(ContainerRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

        onUploadProgress: function(progressEvent) {
         const {loaded,total}=progressEvent;
          let percentage = Math.floor( loaded / total* 100 );
          if(percentage< 100){
         dispatch(onUploadProgress(percentage))
          }
          //console.log(`${loaded}kb of ${total}kb  ${percentage} %`);
        }
      };

      const contaner_number = registrationData?.container_number?registrationData?.container_number:1;


      axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerCheck/${contaner_number}`,axiosConfig).then((containerChecked) => {

        if (containerChecked.data.data){

       
          // console.log(containerChecked.data)
          dispatch(
            container_exists_alert()
          )
          
          Swal.fire({
            title: 'Container number already available!',
            text: 'Do you want to proceed with the container registration?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, proceed!',
          }).then((result) => {
          
            if (result.isConfirmed) {
             

              axios
              .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerStore`,ContainerRegContainerData,axiosConfig)
              .then((response) => {
      
                if (response.data.success_message) {
                  dispatch(ContainerRegSuccess(response.data,currentPage));
        
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    text: 'The details have been successfully created ',
                    showConfirmButton: false,
                    timer: 800,
                    toast: true,
        
                  })
              
                }else if(response.data.failure_message){
                              Swal.fire({
                                position: 'center',
                                icon: 'warning',
                                text:response.data.failure_message,
                                showConfirmButton: false,
                                timer: 3000,
                                toast: true,
                    
                              })
                  
                             dispatch(autoAlreadyContainer_failure())
                 } else if(response.data.error_message){
      
      
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    text: 'The invoice that you are uploading is already in our system, please upload another invoice',
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true,
        
                  })
      
      
                }
              })
              .catch((err) => {
                dispatch(ContainerRegFailure(err.response.data.errors));
      
              });

            }
          });






          
        } else {
         

          axios
          .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerStore`,ContainerRegContainerData,axiosConfig)
          .then((response) => {
  
            if (response.data.success_message) {
              dispatch(ContainerRegSuccess(response.data,currentPage));
    
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: 'The details have been successfully created ',
                showConfirmButton: false,
                timer: 800,
                toast: true,
    
              })
          
            }else if(response.data.failure_message){
                          Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            text:response.data.failure_message,
                            showConfirmButton: false,
                            timer: 3000,
                            toast: true,
                
                          })
              
                         dispatch(autoAlreadyContainer_failure())
             } else if(response.data.error_message){
  
  
              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                text: 'The invoice that you are uploading is already in our system, please upload another invoice',
                showConfirmButton: false,
                timer: 3000,
                toast: true,
    
              })
  
  
            }
          })
          .catch((err) => {
            dispatch(ContainerRegFailure(err.response.data.errors));
  
          });
          
        }
      })
  
      
      // the outer return ends here
    };
  };
  
  const ContainerRegStarted = () => ({
    type: "ContainerReg_started",
  });
  
  
  const ContainerRegSuccess = (data,currentPage) => ({
    type: "ContainerReg_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const ContainerRegFailure = (error) => ({
    type: "ContainerReg_failure",
    payload: {
      error,
    }, 
  });


  const onUploadProgress = (progressPercent) => ({
    type: "upload_progress",
    payload: {
      progressPercent,
    },
  });

  const autoAlreadyContainer_failure = (error) => ({
    type: "autoAlreadyContainer_failure",
  
  });


  export const resetContainerProgressBar=()=>{
    return {
      type:'resetProgressBar'
    }
    }
  
    export const container_exists_alert=()=>{
      return {
        type:'container_exists_alert'
      }
      }
    







// ---------------------------------------------------------------------------------------------------------
// this function will update the container details
// ---------------------------------------------------------------------------------------------------------

export const ContainerUpdate = (registrationData,vinValues,customerValue,currentPage) => {


  const ContainerUpdateData = new FormData();

  for (let i = 0; i < registrationData?.images.length; i++) {
    ContainerUpdateData.append("images[]", registrationData?.images[i]);
  }
  const vinNumbers= vinValues?.map((list,index)=>{
    const vins=[];
    vins.push(list.value);
return vins;

  })

  ContainerUpdateData.append("vins",vinNumbers);


  let expected_arrival_date= registrationData.expected_arrival_date? moment(registrationData?.expected_arrival_date).format('YYYY-MM-DD'):'';
  let loading_date=registrationData.loading_date? moment(registrationData?.loading_date).format('YYYY-MM-DD'):'';
  let container_arrival_date=registrationData.container_arrival_date? moment(registrationData?.container_arrival_date).format('YYYY-MM-DD'):'';
  let container_unloading_date=registrationData.container_unloading_date? moment(registrationData?.container_unloading_date).format('YYYY-MM-DD'):'';

  let document_cut_date=registrationData.document_cut_date? moment(registrationData?.document_cut_date).format('YYYY-MM-DD'):'';
  let port_cut_date=registrationData.port_cut_date? moment(registrationData?.port_cut_date).format('YYYY-MM-DD'):'';
  let departure_date=registrationData.departure_date? moment(registrationData?.departure_date).format('YYYY-MM-DD'):'';
  let eta=registrationData.eta? moment(registrationData?.eta).format('YYYY-MM-DD'):'';
  let cpb_completed=registrationData.cpb_completed? moment(registrationData?.cpb_completed).format('YYYY-MM-DD'):'';
  let si_submission_date=registrationData.si_submission_date? moment(registrationData?.si_submission_date).format('YYYY-MM-DD'):'';
  let completed_date=registrationData.completed_date? moment(registrationData?.completed_date).format('YYYY-MM-DD'):'';
  let validation_date=registrationData.validation_date? moment(registrationData?.validation_date).format('YYYY-MM-DD'):'';

  




  ContainerUpdateData.append("containerId", registrationData.onId||'');

  ContainerUpdateData.append("catagory", registrationData.catagory?.value||'');
  ContainerUpdateData.append("customer_id", customerValue?.value||'');

console.log(customerValue?.value);
  ContainerUpdateData.append("point_of_loading", registrationData.point_of_loading?.value||'');
  // ContainerUpdateData.append("port", registrationData.port?.value||'');
  ContainerUpdateData.append("container_number", registrationData?.container_number||'');
  ContainerUpdateData.append("booking_number", registrationData?.booking_number||'');
  ContainerUpdateData.append("lines", registrationData?.lines||'');
  // ContainerUpdateData.append("loading_date", loading_date||'');
  ContainerUpdateData.append("expected_arrival_date", expected_arrival_date||'');
  ContainerUpdateData.append("container_arrival_date", container_arrival_date||'');
  ContainerUpdateData.append("container_unloading_date", container_unloading_date||'');

  ContainerUpdateData.append("dock_receipt", registrationData.dock_receipt||'');
  ContainerUpdateData.append("bill_copy", registrationData.bl_copy||'');
  ContainerUpdateData.append("telex_release", registrationData.telex_release||'');
  ContainerUpdateData.append("validations", registrationData.telex_release||'');
// console.log(registrationData.consignee?.value)

  ContainerUpdateData.append("consignees_id", registrationData.consignee?.value||'');
  ContainerUpdateData.append("forwarders_id", registrationData.forwarder?.value||'');
  ContainerUpdateData.append("truckers_id", registrationData.trucker?.value||'');
  ContainerUpdateData.append("exporter_name", registrationData?.exporter_name||'');
  ContainerUpdateData.append("vessel_name", registrationData.vessel_name?.value||'');
  ContainerUpdateData.append("paid_status", registrationData?.paid_status?.value||'');
  ContainerUpdateData.append("container_size", registrationData?.container_size?.value||'');
  ContainerUpdateData.append("port", registrationData.port?.value||'');
  ContainerUpdateData.append("country_port", registrationData.country_port?.value||'');
  ContainerUpdateData.append("port_discharge", registrationData.port_discharge?.value||'');
  ContainerUpdateData.append("country_discharge", registrationData.country_discharge?.value||'');

  ContainerUpdateData.append("vessel_voyage", registrationData?.vessel_voyage||'');
  ContainerUpdateData.append("seal", registrationData?.seal||'');
  ContainerUpdateData.append("itn", registrationData?.itn||'');
  ContainerUpdateData.append("xtn", registrationData?.xtn||'');
  ContainerUpdateData.append("terminal", registrationData?.terminal||'');
  ContainerUpdateData.append("steamship_line", registrationData?.steamship_line||'');
  

  ContainerUpdateData.append("loading_date", loading_date||'');
  ContainerUpdateData.append("document_cut_date", document_cut_date||'');
  ContainerUpdateData.append("port_cut_date", port_cut_date||'');
  ContainerUpdateData.append("departure_date", departure_date||'');
  ContainerUpdateData.append("eta", eta||'');
  ContainerUpdateData.append("cpb_completed", cpb_completed||'');
  ContainerUpdateData.append("si_submission_date", si_submission_date||'');
  ContainerUpdateData.append("completed_date", completed_date||'');
  ContainerUpdateData.append("validation_date", validation_date||'');






  return (dispatch) => {
      dispatch(ContainerUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

        onUploadProgress: function(progressEvent) {
         const {loaded,total}=progressEvent;
          let percentage = Math.floor( loaded / total* 100 );
          if(percentage< 100){
         dispatch(onUploadUpdateProgress(percentage))
          }
          //console.log(`${loaded}kb of ${total}kb  ${percentage} %`);
        }
      };
     
  
      axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerCheckUpdate/${registrationData?.container_number}/${registrationData.onId}`,axiosConfig) .then((containerChecked) => {

        if (containerChecked.data.data){

       
          // console.log(containerChecked.data)
          dispatch(
            container_exists_alert()
          )
          
          Swal.fire({
            title: 'Container number already available!',
            text: 'Do you want to proceed with the container registration?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, proceed!',
          }).then((result) => {
          
            if (result.isConfirmed) {

              axios
              .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerUpdate`,ContainerUpdateData,axiosConfig)
              .then((response) => {
                if (response.data.success_message) {
                  //console.log(response.data);
                  dispatch(ContainerUpdateSuccess(response.data,currentPage));
        
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    text: 'The details have been successfully updated ',
                    showConfirmButton: false,
                    timer: 800,
                    toast: true,
        
                  })
              
                } 
              })
              .catch((err) => {
                //console.log(err.response.data);
                dispatch(ContainerUpdateFailure(err.response.data));
      
              });


            }})
          } else{
            axios
            .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerUpdate`,ContainerUpdateData,axiosConfig)
            .then((response) => {
              if (response.data.success_message) {
                //console.log(response.data);
                dispatch(ContainerUpdateSuccess(response.data,currentPage));
      
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'The details have been successfully updated ',
                  showConfirmButton: false,
                  timer: 800,
                  toast: true,
      
                })
            
              } 
            })
            .catch((err) => {
              console.log(err.response.data);
              dispatch(ContainerUpdateFailure(err.response.data));
    
            });

            }
          
          });




      
    };
  };
  
  const ContainerUpdateStarted = () => ({
    type: "ContainerUpdate_started",
  });
  
  
  const ContainerUpdateSuccess = (data,currentPage) => ({
    type: "ContainerUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const ContainerUpdateFailure = (error) => ({
    type: "ContainerUpdate_failure",
    payload: {
      error,
    }, 
  });


  const onUploadUpdateProgress = (progressPercent) => ({
    type: "upload_progress",
    payload: {
      progressPercent,
    },
  });

  export const resetContainerProgressBarUpdate=()=>{
    return {
      type:'resetProgressBar'
    }
    }
  











// ---------------------------------------------------------------------------------------------------------
// this function add autos to the container
// ---------------------------------------------------------------------------------------------------------

export const containerAddAutos = (contID,vinValues,currentPage) => {


  const containerAddAutosContainerData = new FormData();


  const vinNumbers= vinValues?.map((list)=>{
    const vins=[];
    vins.push(list.value);
return vins;

  })

  containerAddAutosContainerData.append("vins",vinNumbers);
  containerAddAutosContainerData.append("contId",contID);


  return (dispatch) => {
      dispatch(containerAddAutosStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerAddAutos`,containerAddAutosContainerData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            console.log(response.data);
            dispatch(containerAddAutosSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The vehicles have been successfully created ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          }else if(response.data?.failure_message){
            console.log(response.data?.failure_message)
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: response.data?.failure_message,
              showConfirmButton: false,
              timer: 2000,
              toast: true,
  
            })

            dispatch(autoAlreadyExistsFailure());

        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(containerAddAutosFailure(err.response.data.errors));

        });
    };
  };
  
  const containerAddAutosStarted = () => ({
    type: "containerAddAutos_started",
  });
  
  
  const containerAddAutosSuccess = (data,currentPage) => ({
    type: "containerAddAutos_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const containerAddAutosFailure = (error) => ({
    type: "containerAddAutos_failure",
    payload: {
      error,
    }, 
  });

  const autoAlreadyExistsFailure = (error) => ({
    type: "autoAlreadyExists_failure",
  
  });


 



// ---------------------------------------------------------------------------------------------------------
// this function to get information of container for editing
// ---------------------------------------------------------------------------------------------------------

export const getContainerDetails = (id) => {



  return (dispatch) => {
      dispatch(getContainerDetailsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/ContainerEditInfo/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getContainerDetailsSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getContainerDetailsFailure(err.response.data.errors));

        });
    };
  };
  
  const getContainerDetailsStarted = () => ({
    type: "getContainerDetails_started",
  });
  
  
  const getContainerDetailsSuccess = (data) => ({
    type: "getContainerDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getContainerDetailsFailure = (error) => ({
    type: "getContainerDetails_failure",
    payload: {
      error,
    }, 
  });








// ---------------------------------------------------------------------------------------------------------
// All data would be store in the Containers table of database
// ---------------------------------------------------------------------------------------------------------

// export const storeContainerData = (ContainerData,CustomerId) => {
//     return (dispatch) => {
//       dispatch(storeDataStarted());
  
//       const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
//       var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
//       var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
  
//       let axiosConfig = {
//         headers: {
//           "Content-Type": "application/json;charset=UTF-8",
//           Accept: "application/json",
//           Authorization: `Bearer ${decryptedToken}`,
//         },
//         onUploadProgress: function(progressEvent) {
//           var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
//         }



//       };
// let purchase_date_formatted= moment(ContainerData.purchase_date).format('YYYY-MM-DD');
// let auction_payment= moment(ContainerData.payment_to_auction).format('YYYY-MM-DD');

// const ContainerRegistrationData = new FormData();
// ContainerRegistrationData.append("vin", ContainerData.vin);
// ContainerRegistrationData.append("lot", ContainerData.lot);
// ContainerRegistrationData.append("ContainerType", ContainerData.ContainerType?.value||'');
// ContainerRegistrationData.append("customerId", CustomerId?.value||'');
// ContainerRegistrationData.append("for_whom", CustomerId?.label||'');

// ContainerRegistrationData.append("model", ContainerData.model||'');
// ContainerRegistrationData.append("make", ContainerData.make||'');
// ContainerRegistrationData.append("year", ContainerData.year||'');
// ContainerRegistrationData.append("color", ContainerData.color||'');
// ContainerRegistrationData.append("purchase_date", purchase_date_formatted||'');
// ContainerRegistrationData.append("auction", ContainerData.auction?.value||'');
// ContainerRegistrationData.append("buyer_no", ContainerData.buyer_no?.value||'');
// ContainerRegistrationData.append("city", ContainerData.city?.value||'');
// ContainerRegistrationData.append("customer_notes", ContainerData.customer_notes||'');
// ContainerRegistrationData.append("payment_to_auction", auction_payment||'');
// ContainerRegistrationData.append("port", ContainerData.port?.value||'');
// ContainerRegistrationData.append("point_of_loading", ContainerData.point_of_loading?.value||'');
// ContainerRegistrationData.append("dismantle", ContainerData.dismantle||'');
// ContainerRegistrationData.append("self_delivered", ContainerData.self_delivered||'');
// ContainerRegistrationData.append("invoice", ContainerData.invoice||'');
// ContainerRegistrationData.append("Container_price", ContainerData.Container_price||'');





//       axios
//         .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerDataStore`, ContainerRegistrationData, axiosConfig)
//         .then((response) => {
//           if (response.data.success_message) {
//             //console.log(response.data);
  
//             dispatch(storeDataSuccess(response.data));

//             Swal.fire({
//               position: 'top-end',
//               icon: 'success',
//               text: 'The Container has succesfully registered',
//               showConfirmButton: false,
//               timer: 800,
//               toast: true,
  
//             })
        
//           } else if(response.data.error_message){


//             Swal.fire({
//               position: 'top-end',
//               icon: 'warning',
//               text: 'The invoice that you are uploading is already in our system, please upload another invoice',
//               showConfirmButton: false,
//               timer: 3000,
//               toast: true,
  
//             })


//           }
//         })
//         .catch((err) => {
//           //console.log(err.response.data.errors)
//           dispatch(storeDataFailure(err.response.data.errors));
//         });
//     };
//   };
  
//   const storeDataStarted = () => ({
//     type: "storeContainerData_started",
//   });
  
//   const storeDataSuccess = (data) => ({
//     type: "storeContainerData_success",
//     payload: {
//       ...data,
//     },
//   });
  
//   const storeDataFailure = (error) => ({
//     type: "storeContainerData_failure",
//     payload: {
//       error,
//     },
//   });
  






// ---------------------------------------------------------------------------------------------------------
// to store only images of the container
// ---------------------------------------------------------------------------------------------------------

export const storeImagesContainer = (ContainerData) => {
  return (dispatch) => {
    dispatch(storeImagesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },

    };

const contrainerImagesData = new FormData();

contrainerImagesData.append("id", ContainerData?.onId);

  for (let i = 0; i < ContainerData?.images.length; i++) {
    contrainerImagesData.append("images[]", ContainerData?.images[i]);
  }




    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/storeContainerImages`, contrainerImagesData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(storeImagesSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully uploaded',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors)
        dispatch(storeImagesFailure(err.response.data.errors));
      });
  };
};

const storeImagesStarted = () => ({
  type: "storeContainerImages_started",
});

const storeImagesSuccess = (data) => ({
  type: "storeContainerImages_success",
  payload: {
    ...data,
  },
});

const storeImagesFailure = (error) => ({
  type: "storeContainerImages_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// getImages from Container_images table for each Container 
// ---------------------------------------------------------------------------------------------------------

export const getContainerImages = (id) => {
  return (dispatch) => {
    dispatch(imagesListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/containerImagesList/`+id, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(imagesListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(imagesListFailure(err.message));
      });
  };
};

const imagesListStarted = () => ({
  type: "images_container_fetch_started",
});

const imagesListSuccess = (data) => ({
  type: "images_container_fetch_success",
  payload: {
    ...data,
  },
});


const imagesListFailure = (error) => ({
  type: "images_container_fetch_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// Admin can delete images so this funciton will help to delete images for each Container multiple images
// ---------------------------------------------------------------------------------------------------------

export const imagesContainerDelete = (ids) => {


  return (dispatch) => {
    dispatch(imagesDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteContainerImages/`+ids,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(imagesDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(imagesDeletionFailure(err.message));
      });
  };
};

const imagesDeletionStarted = () => ({
  type: "containerImagesDeletion_started",
});

const imagesDeletionSuccess = (data) => ({
  type: "containterImagesDeletion_success",
  payload: {
    ...data,
  },
});

const imagesDeletionFailure = (error) => ({
  type: "imagesDeletion_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// filtering warehouses
// ---------------------------------------------------------------------------------------------------------

export const filterListContainers = (warehouse,placement) => {
  
    return (dispatch) => {
        dispatch(filterListsForWarehosuesStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
        let loadingData={
          warehouse:warehouse||'',
          Container_placement:placement||'',
  
        
         }
  
    
        axios
          .post(`${process.env.REACT_APP_API_ADDRESS}/filterWarehouses`,loadingData,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(filterListsForWarehosuesSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(filterListsForWarehosuesFailure(err.response.data.errors));
  
          });
      };
    };
    
    const filterListsForWarehosuesStarted = () => ({
      type: "filterOnWarehouses_started",
    });
    
    
    const filterListsForWarehosuesSuccess = (data) => ({
      type: "filterOnWarehouses_success",
      payload: {
        ...data,
      },
    });
    
    const filterListsForWarehosuesFailure = (error) => ({
      type: "filterOnWarehouses_failure",
      payload: {
        error,
      }, 
    });
  
  
  





// ---------------------------------------------------------------------------------------------------------
// Container multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedContainersDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedContainersStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedContainers/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedContainersSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected Containers have been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedContainersFailure(err.message));
      });
  };
};

const selectedContainersStarted = () => ({
  type: "selectedContainers_started",
});

const selectedContainersSuccess = (data) => ({
  type: "selectedContainers_success",
  payload: {
    ...data,
  },
});

const selectedContainersFailure = (error) => ({
  type: "selectedContainers_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// Container multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedAutosInContainersDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedAutosContainersDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedContainerAutos/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // console.log(response.data)

          dispatch(selectedAutosContainersDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected autos in container has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedAutosContainersDeleteFailure(err.message));
      });
  };
};

const selectedAutosContainersDeleteStarted = () => ({
  type: "selectedAutosContainersDelete_started",
});

const selectedAutosContainersDeleteSuccess = (data) => ({
  type: "selectedAutosContainersDelete_success",
  payload: {
    ...data,
  },
});

const selectedAutosContainersDeleteFailure = (error) => ({
  type: "selectedAutosContainersDelete_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// Container Delete
// ---------------------------------------------------------------------------------------------------------

export const ContainerDelete = (id) => {


  return (dispatch) => {
    dispatch(ContainerDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteContainer/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(ContainerDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Container has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(ContainerDeleteFailure(err.message));
      });
  };
};

const ContainerDeleteStarted = () => ({
  type: "ContainerDelete_started",
});

const ContainerDeleteSuccess = (data) => ({
  type: "ContainerDelete_success",
  payload: {
    ...data,
  },
});

const ContainerDeleteFailure = (error) => ({
  type: "ContainerDelete_failure",
  payload: {
    error,
  },
});



  // **********************************************************************************************************
  // **********************************************************************************************************

// Communication on notes, actions starts from here ....................

  // **********************************************************************************************************
  // **********************************************************************************************************







// ---------------------------------------------------------------------------------------------------------
// users selector
// ---------------------------------------------------------------------------------------------------------

export const VinNumberFetchContainer = (query,page) => {
  return (dispatch) => {
    dispatch(VinNumberFetchStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
// //console.log(query)

  let data={
    q:query
  }
    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/userSelectorListQuery`, data, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(VinNumberFetchSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(VinNumberFetchFailure(err.message));
      });
  
  };
};

const VinNumberFetchStarted = () => ({
  type: "VinNumberFetch_started",
});

const VinNumberFetchSuccess = (data) => ({
  type: "VinNumberFetch_success",
  payload: {
    ...data,
  },
});


const VinNumberFetchFailure = (error) => ({
  type: "VinNumberFetch_failure",
  payload: {
    error,
  },
});








// ---------------------------------------------------------------------------------------------------------
// get the data of vehical charges
// ---------------------------------------------------------------------------------------------------------

export const getChargesContainersRows = (id) => {

  console.log(id)
    return (dispatch) => {
        dispatch(getContainerChargesStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
  
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/getChargesContainerRows/`+id,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              console.log(response.data);
              dispatch(getContainerChargesSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getContainerChargesFailure(err.response.data.errors));
  
          });
      };
    };

    const getContainerChargesStarted = () => ({
      type: "getContainerCharges_started",
    });
    
    
    const getContainerChargesSuccess = (data) => ({
      type: "getContainerCharges_success",
      payload: {
        ...data,
      },
    });
    
    const getContainerChargesFailure = (error) => ({
      type: "getContainerCharges_failure",
      payload: {
        error,
      }, 
    });
  
  
  
  
  
  
  
  // ---------------------------------------------------------------------------------------------------------
  // to store charges of the autos
  // ---------------------------------------------------------------------------------------------------------
  
  export const storeContainerCharges = (data,id,totalAmount,totalCost) => {
    return (dispatch) => {
      dispatch(storeContainerChargesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
  
      };
  
      let autoChargesData={
    containers_id:id,
    data:data,
   total_charges:totalAmount,
  total_cost:totalCost,
      }
  
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/storeContainerCharges`, autoChargesData, axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
  
            dispatch(storeContainerChargesSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The charges has been successfully stored',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors)
          dispatch(storeContainerChargesFailure(err.response.data.errors));
        });
    };
  };
  
  const storeContainerChargesStarted = () => ({
    type: "storeContainerCharges_started",
  });
  
  const storeContainerChargesSuccess = (data) => ({
    type: "storeContainerCharges_success",
    payload: {
      ...data,
    },
  });
  
  const storeContainerChargesFailure = (error) => ({
    type: "storeContainerCharges_failure",
    payload: {
      error,
    },
  });








