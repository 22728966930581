//this is for the purpose to give your own names to database fields for activity logs
export const columnMapping = {
    // First set of columns
    users_id: 'User ID',
    container_no: 'Container Number',
    cont_booking_no: 'Booking Number',
    cont_line: 'Line',
    cont_size: 'Size',
    cont_doc_receipt: 'Document Receipt',
    cont_bill_copy: 'Bill Copy',
    cont_telex_release: 'Telex Release',
    cont_point_loading: 'Point Loading',
    cont_date_loading: 'Date Loading',
    cont_expect_date_arrival: 'Expected Date Arrival',
    cont_arrival_date: 'Arrival Date',
    cont_validation: 'Validation',
    container_flag: 'Container Flag',
    cont_catagory: 'Category',
    consignees_id: 'Consignee ID',
    farwarders_id: 'Forwarder ID',
    truckers_id: 'Trucker ID',
    cont_exporter_name: 'Exporter Name',
    cont_port: 'Port',
    cont_country_port: 'Country Port',
    cont_port_discharge: 'Port Discharge',
    cont_country_discharge: 'Country Discharge',
    cont_seal: 'Seal',
    cont_container_size: 'Container Size',
    cont_vessel_name: 'Vessel Name',
    cont_vessel_voyage: 'Vessel Voyage',
    cont_document_cut_date: 'Document Cut Date',
    cont_port_cut_date: 'Port Cut Date',
    cont_departure_date: 'Departure Date',
    cont_eta: 'ETA',
    cont_cbp_completed: 'CBP Completed',
    cont_si_submission_date: 'SI Submission Date',
    cont_loading_date: 'Loading Date',
    cont_completed_date: 'Completed Date',
    cont_validation_date: 'Validation Date',
    cont_itn: 'ITN',
    cont_xtn: 'XTN',
    cont_steamship_line: 'Steamship Line',
    cont_terminal: 'Terminal',
    cont_paid_status: 'Paid Status',
    cont_container_status: 'Container Status',
    vendor_paid_status: 'Vendor Paid Status',
    vendor_paid_charges: 'Vendor Paid Charges',
    total_charges: 'Total Charges',
    total_cost: 'Total Cost',
    paid_charges: 'Paid Charges',
    vendor_payment_check: 'Vendor Payment Check',
    vendor_payment_reference: 'Vendor Payment Reference',
  
    // Second set of columns
    vendors_id: 'Vendor ID',
    container_id: 'Container ID',
    auto_catagory: 'Auto Category',
    vin: 'VIN',
    lot: 'Lot',
    auto_name: 'Auto Name',
    auto_type: 'Auto Type',
    model: 'Model',
    make: 'Make',
    city: 'City',
    auto_price: 'Auto Price',
    year: 'Year',
    color: 'Color',
    auction: 'Auction',
    buyer_no: 'Buyer Number',
    port: 'Port',
    point_loading: 'Point Loading',
    purchase_date: 'Purchase Date',
    payment_auction: 'Payment Auction',
    customer_notes: 'Customer Notes',
    yourself_notes: 'Yourself Notes',
    terminal_notes: 'Terminal Notes',
    to_dismantle: 'To Dismantle',
    self_delivered: 'Self Delivered',
    keys: 'Keys',
    Action: 'Action',
    promise_pickup: 'Promise Pickup',
    delieveredwarehouse: 'Delivered Warehouse',
    title_status: 'Title Status',
    title_received: 'Title Received',
    container_num_booking: 'Container Number Booking',
    unloading_date: 'Unloading Date',
    arrival_date: 'Arrival Date',
    cone: 'Cone',
    stock: 'Stock',
    pick_up_location: 'Pickup Location',
    invoice_customer: 'Invoice Customer',
    weight: 'Weight',
    branch: 'Branch',
    role: 'Role',
    created_by: 'Created By',
    flag: 'Flag',
    auto_status: 'Auto Status',
    for_customer: 'For Customer',
    invoice_print_date: 'Invoice Print Date',
    updated_at:'Updated at'
    // Additional columns from the second set
    // ...
  };
  