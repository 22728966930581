import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");




export const showAndHideVehicleTrackerModal=()=>{

  return {
    type:'showAndHideNotifModal'
  }
  }
  export const showAndHidePublicTrackerModal=()=>{
    return {
      type:'showAndHideVehiclePublicModal'
    }
    }


  
  





// ---------------------------------------------------------------------------------------------------------
// searching data of vehicles through tracker 
// ---------------------------------------------------------------------------------------------------------

export const searchVehicleTrackerList = (query,currentPage) => {
  // console.log(query)
    return (dispatch) => {
      dispatch(searchVehicleTrackerStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchCustomersTrackerStatus?page=${currentPage}`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
  
            dispatch(searchVehicleTrackerSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchVehicleTrackerFailure(err.message));
        });
    };
  };
  
  const searchVehicleTrackerStarted = () => ({
    type: "searchVehicleTracker_started",
  });
  
  const searchVehicleTrackerSuccess = (data) => ({
    type: "searchVehicleTracker_success",
    payload: {
      ...data,
    },
  });
  
  const searchVehicleTrackerFailure = (error) => ({
    type: "searchVehicleTracker_failure",
    payload: {
      error,
    },
  });
  
  





// ---------------------------------------------------------------------------------------------------------
// searching data of vehicles through tracker 
// ---------------------------------------------------------------------------------------------------------

export const searchVehicleTrackerPublic = (query) => {
  // console.log(query)
    return (dispatch) => {
      dispatch(searchVehicleTrackerPublicStarted());
  
    
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
        },
      };
  
      let searchData={
        q:query
       }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/searchPublicVehicleTracker`,searchData,axiosConfig)
        .then((response) => {
          if (response.data) {
            // console.log(response.data.success_message);
            if(response.data.success_message){

              dispatch(searchVehicleTrackerPublicSuccess(response.data));

            }else {

              dispatch({
                type:'vehicle_failure'
              })
              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                text: 'Opps, the vehicle might not be present in the system or not published by the administration',
                showConfirmButton: false,
                timer: 3000,
                toast: true,
    
              })



            }
  
  
        
          } 
        })
        .catch((err) => {
          dispatch(searchVehicleTrackerPublicFailure(err.message));
        });
    };
  };
  
  const searchVehicleTrackerPublicStarted = () => ({
    type: "searchVehicleTrackerPublic_started",
  });
  
  const searchVehicleTrackerPublicSuccess = (data) => ({
    type: "searchVehicleTrackerPublic_success",
    payload: {
      ...data,
    },
  });
  
  const searchVehicleTrackerPublicFailure = (error) => ({
    type: "searchVehicleTrackerPublic_failure",
    payload: {
      error,
    },
  });
  
  













// ---------------------------------------------------------------------------------------------------------
// to fetch VehicleTrackers data
// ---------------------------------------------------------------------------------------------------------

export const VehicleTrackersList = (pageNumber) => {
  return (dispatch) => {
    dispatch(VehicleTrackersListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/VehicleTrackersList?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          // console.log(response.data);

          dispatch(VehicleTrackersListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(VehicleTrackersListFailure(err.message));
      });
  };
};

const VehicleTrackersListStarted = () => ({
  type: "VehicleTrackers_fetch_started",
});

const VehicleTrackersListSuccess = (data) => ({
  type: "VehicleTrackers_fetch_success",
  payload: {
    ...data,
  },
});



const VehicleTrackersListFailure = (error) => ({
  type: "VehicleTrackers_fetch_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// this function will or add data
// ---------------------------------------------------------------------------------------------------------

export const VehicleTrackerRegistration = (status) => {


  const VehicleTrackerRegVehicleTrackerData = new FormData();


  VehicleTrackerRegVehicleTrackerData.append("VehicleTrackerStatus",status||'');


  return (dispatch) => {
      dispatch(VehicleTrackerRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/VehicleTrackerStore`,VehicleTrackerRegVehicleTrackerData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(VehicleTrackerRegSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The status has been successfully changed to '+status,
              showConfirmButton: false,
              timer: 1200,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          // console.log(err.response.data.errors);
          dispatch(VehicleTrackerRegFailure(err.response.data.errors));

        });
    };
  };
  
  const VehicleTrackerRegStarted = () => ({
    type: "VehicleTrackerReg_started",
  });
  
  
  const VehicleTrackerRegSuccess = (data) => ({
    type: "VehicleTrackerReg_success",
    payload: {
      ...data,
    },
  });
  
  const VehicleTrackerRegFailure = (error) => ({
    type: "VehicleTrackerReg_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// vehicleTrackerMultiple registration
// ---------------------------------------------------------------------------------------------------------

export const vehicleTrackerMultipleRegistration = (status,customers,currentPage) => {


  const vehicleTrackerMultipleRegData = new FormData();

  const userIds= customers?.map((list,index)=>{
    const ids=[];
    ids.push(list.value);
return ids;

  })

  vehicleTrackerMultipleRegData.append("userIds",userIds);
  vehicleTrackerMultipleRegData.append("VehicleTrackerStatus",status||'');







  return (dispatch) => {
      dispatch(vehicleTrackerMultipleRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/vehicleTrackerMultipleStore`,vehicleTrackerMultipleRegData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(vehicleTrackerMultipleRegSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The status of the customer has been successfully changed to public!',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          // console.log(err.response.data.errors);
          dispatch(vehicleTrackerMultipleRegFailure(err.response.data.errors));

        });
    };
  };
  
  const vehicleTrackerMultipleRegStarted = () => ({
    type: "vehicleTrackerMultipleReg_started",
  });
  
  
  const vehicleTrackerMultipleRegSuccess = (data,currentPage) => ({
    type: "vehicleTrackerMultipleReg_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const vehicleTrackerMultipleRegFailure = (error) => ({
    type: "vehicleTrackerMultipleReg_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// this function will update the VehicleTracker details
// ---------------------------------------------------------------------------------------------------------

export const VehicleTrackerUpdate = (registrationData,attachment,currentPage) => {


  const VehicleTrackerUpdateData = new FormData();


  VehicleTrackerUpdateData.append("id", registrationData.onId||'');
  VehicleTrackerUpdateData.append("subject", registrationData.subject||'');
  VehicleTrackerUpdateData.append("body", registrationData.body||'');
  VehicleTrackerUpdateData.append("attachment", attachment.file||'');






  return (dispatch) => {
      dispatch(VehicleTrackerUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/VehicleTrackerUpdate`,VehicleTrackerUpdateData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(VehicleTrackerUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The details have been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(VehicleTrackerUpdateFailure(err.response.data.errors));

        });
    };
  };
  
  const VehicleTrackerUpdateStarted = () => ({
    type: "VehicleTrackerUpdate_started",
  });
  
  
  const VehicleTrackerUpdateSuccess = (data,currentPage) => ({
    type: "VehicleTrackerUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const VehicleTrackerUpdateFailure = (error) => ({
    type: "VehicleTrackerUpdate_failure",
    payload: {
      error,
    }, 
  });


 
  











 



// ---------------------------------------------------------------------------------------------------------
// this function to get information for a customer to see the status is on or off
// ---------------------------------------------------------------------------------------------------------

export const TrackerStatusDetailsForCustomer = () => {



  return (dispatch) => {
      dispatch(TrackerCustomerStatusStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/VehicleTrackerStatusForUser`,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(TrackerCustomerStatusSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(TrackerCustomerStatusFailure(err.response.data.errors));

        });
    };
  };
  
  const TrackerCustomerStatusStarted = () => ({
    type: "TrackerCustomerStatus_started",
  });
  
  
  const TrackerCustomerStatusSuccess = (data) => ({
    type: "TrackerCustomerStatus_success",
    payload: {
      ...data,
    },
  });
  
  const TrackerCustomerStatusFailure = (error) => ({
    type: "TrackerCustomerStatus_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// VehicleTracker multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedVehicleTrackersDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedVehicleTrackersStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedVehicleTrackers/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedVehicleTrackersSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The status of the selected customers have been successfully changed unpublic ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedVehicleTrackersFailure(err.message));
      });
  };
};

const selectedVehicleTrackersStarted = () => ({
  type: "selectedVehicleTrackers_started",
});

const selectedVehicleTrackersSuccess = (data) => ({
  type: "selectedVehicleTrackers_success",
  payload: {
    ...data,
  },
});

const selectedVehicleTrackersFailure = (error) => ({
  type: "selectedVehicleTrackers_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// VehicleTracker Delete
// ---------------------------------------------------------------------------------------------------------

export const VehicleTrackerDelete = (id) => {


  return (dispatch) => {
    dispatch(VehicleTrackerDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteVehicleTracker/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(VehicleTrackerDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The VehicleTracker has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(VehicleTrackerDeleteFailure(err.message));
      });
  };
};

const VehicleTrackerDeleteStarted = () => ({
  type: "VehicleTrackerDelete_started",
});

const VehicleTrackerDeleteSuccess = (data) => ({
  type: "VehicleTrackerDelete_success",
  payload: {
    ...data,
  },
});

const VehicleTrackerDeleteFailure = (error) => ({
  type: "VehicleTrackerDelete_failure",
  payload: {
    error,
  },
});





