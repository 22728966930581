const InitialStates = {
    vendorsListResponse:null,
      loading: false,
      vendorDeletionResponse:[],
      vendorEditResponse:[],
      vendorUpdateErrors:null,
      currentPage:1,
      showModal:false,
      account_name:'',
      name:'',
      vendorname:'',
      email:'',
      password:'',
      confirm_password:'',
      searchvendors:'',
      rolePicker:[],
      showvendorCompanyModal:false,
      vendorCompanyEditInfo:[],
      showvendorCompanyEditModal:false,
      showVendorModal:false,
      showVendorEditModal:false,
      showMakePayment:false,
    };
    
    export default (state = InitialStates, action) => {
      switch (action.type) {
        case 'inputHandler':  
        return  {...state, [action.payload.key]: action.payload.value}
  
  // case "hideModalAction":
  //   return { ...state, 'showModal': false, 'contact':''  };
  
  
    case "toggleModalAction":
    return { ...state, 'showvendorCompanyModal': !state.showvendorCompanyModal };
  
  
    case "toggleModalEditAction":
      return { ...state, 'showvendorCompanyEditModal': !state.showvendorCompanyEditModal };

    case "toggleModalMakePayment":
      return { ...state, 'showMakePayment': !state.showMakePayment };

      
      case "toggleVendorModal":
        return { ...state, 'showVendorModal': !state.showVendorModal };
        case "toggleVendorEditModal":
          return { ...state, 'showVendorEditModal': !state.showVendorEditModal };
      
    case "searchvendors_started":
       
      return { ...state, 'loading': true,  };
  
        case "fetch_vendors_started":
       
          return { ...state, 'loading': true,  };
    
          case "fetch_vendors_failure":
            return { ...state, 'loading': false,};
  
            case "rolePicker_success":
              return { ...state,  'rolePicker':action.payload , 'loading':false,  };
        
          case "fetch_vendors_success":
       
          return { ...state, 'vendorsListResponse': action.payload , 'loading':false, 'currentPage':action.payload.data.current_page };
          case "searchVendorsList_success":
            return { ...state, 'vendorsListResponse': action.payload , 'loading':false,  };

    
    case "vendorDeletion_success":
     
          return { ...state, 'vendorDeletionResponse': action.payload, 'loading':false, 'currentPage':action.payload.currentPage };
  
    case "vendorEditDetails_success":
    
    return { ...state, 'vendorEditResponse': action.payload, 'loading':false, 'showModal':true };
  
    case "vendorUpdate_success":
  
      return { ...state, 'vendorUpdateResponse': action.payload, 'loading':false, 'showvendorCompanyEditModal':false,'currentPage':action.payload.currentPage,'vendorUpdateErrors':null };
  
  
    
      case "vendorUpdate_failure":
  
        return { ...state, 'vendorUpdateErrors': action.payload, 'loading':false, };
  
  
  //for searching vendors list 
        case "searchvendors_success":
         
          return { ...state, 'vendorsListResponse': action.payload, 'loading':false };
          
  
  
          case "vendorCompanyEdit_success":
  
          return { ...state, 'vendorCompanyEditInfo': action.payload, 'loading':false, };
      
  case "pervendorVehicles_success":
    return { ...state, 'vendorBasedVehicles': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  
  case "searchAutos_success":
    return { ...state, 'vendorBasedVehicles': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  
    case "addVendorDetails_success":
      return { ...state, 'addVendorDetailsSuccess': action.payload, 'loading':false, 'showvendorCompanyModal':false, 'showvendorCompanyEditModal':false };
  
    case "pervendorVendorStarted_success":
      return { ...state, 'pervendorList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  
      case "searchVendor_success":
      return { ...state, 'pervendorList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  
      case "registerVendor_success":
        return { ...state, 'registerVendorSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false,showvendorCompanyModal:false};
        


  case "perVendorVehiclesUnpaid_success":
    return { ...state, 'perVendorVehicleList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };


case "searchVehiclesPerVendor_success":
  return { ...state, 'perVendorVehicleList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };

  case "perVendorContainers_success":
    return { ...state, 'perVendorContainersList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  
    case "searchContainersPerVendor_success":
      return { ...state, 'perVendorContainersList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };


      
        case "VendorEditDetails_success":
          return { ...state, 'editDetails': action.payload,  'loading':false };
  
          case "VendorUpdate_success":
            return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showvendorCompanyEditModal':false  };
         
            case "VendorDeletion_success":
              return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };

case "StorePayment_success":

return { ...state, 'paymentSaveSuccess': action.payload,  'loading':false, 'showMakePayment':false  };

 case "perVendorContainersPayment_success" :
  return { ...state, 'perVendorContainersPaymentList': action.payload,  'loading':false, 'showMakePayment':false  };
case "searchContainersPerVendorPayment_success":
  return { ...state, 'perVendorContainersPaymentList': action.payload,  'loading':false, 'showMakePayment':false  };

case "paymentDeletion_success":
  return { ...state, 'paymentDeletion': action.payload,  'loading':false, 'showMakePayment':false  };



        default:
          return state;
      }
    };
    