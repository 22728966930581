import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';
import {vesselUpdate,hidevesselModal,showvesselModal,showEditvesselModal,hideEditvesselModal,getvessels,vesselEdit,inputHandler,registervessel, vesselDelete} from "../../../redux/actions";

function Vessel(props) {


// const [addvesselData,setAddvesselData] = useState({name:''});
const [vesselData,setvesselData] = useState({name:'',onId:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getvessels(props.currentPage);

  }, [props.registervesselSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setvesselData({...vesselData,'name':props.editDetails?.data?.vessel_name||''})
},[props.editDetails])



  //   useEffect(()=>{

  //     setRoleNameEdit({name:props.showPermissions?.role.name?props.showPermissions?.role.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });
    // setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setvesselData({...vesselData, name:e.target.value  });
  };

// ============================================
//  to rigister role modal
// ============================================
  const handleClose = () => {
  
    props.hidevesselModal()

  };
  const handleShow = () => {

    props.showvesselModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditvesselModal();

  };

  const handleEditShow=()=>{
    props.showEditvesselModal()

  }




const deletevessel = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this vessel",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.vesselDelete(id, props.currentPage);
    }
  });
};





const submitvessel = (e) => {
  e.preventDefault();
  let name=props?.name;
  if(name){
    props.registervessel(name)
  }
}


const Updatevessel = (e) => {
  e.preventDefault();
  let name=vesselData.name;
  let id=vesselData.onId;
  let currentPage=props.vesselsList?.data?.current_page;
  props.vesselUpdate(id,name,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7"> 
        
     <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add vessel</a></div> 
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row text-center pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.vesselsList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-3 col-sm-4 col-md-4 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <p className="mt-2 text-uppercase">{list.vessel_name}</p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}
              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      props.vesselEdit(list.id)
                      setvesselData({...vesselData,'onId':list.id})
                      setTimeout(()=>{
                        handleEditShow()

                      },190)
                          

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deletevessel(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.vesselsList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.vesselsList?.data?.current_page}
              itemsCountPerPage={props.vesselsList?.data?.per_page}
              totalItemsCount={props.vesselsList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getvessels(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Role Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New vessel </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitvessel}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="vessel Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props?.name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.vesselRegisterErrors?.error?.vessel_name?true:false}
                  />

                </FloatingLabel>

                {props.vesselRegisterErrors?.error?.vessel_name ? (
                  <div className="validation-error">
                    The vessel name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Role Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit vessel </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updatevessel}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="vessel Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="editvesselName"
                    value={vesselData.name||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.vesselUpdateErrors?.error?.vessel_name ?true:false}
                  />

                </FloatingLabel>

                {props.vesselUpdateErrors?.error?.vessel_name ? (
                  <div className="validation-error">
                    The vessel name already exists, you can't update it with this vessel name !
                  </div>
                ) : (
                  ""
                )}

              </div>
            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Done
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,vesselsList,showWarModal,showEditWarModal,name,registervesselSuccess,vesselRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,vesselUpdateErrors} = state.SettingsVesselHandler;

  return {
    loading: loading,
    name:name,
    vesselRegisterErrors:vesselRegisterErrors,
    currentPage:currentPage,
    vesselsList:vesselsList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registervesselSuccess:registervesselSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    vesselUpdateErrors:vesselUpdateErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
 
      getvessels:(currentPage)=>dispatch(getvessels(currentPage)),
      showvesselModal:()=>dispatch(showvesselModal()),
      hidevesselModal:()=>dispatch(hidevesselModal()),
      showEditvesselModal:()=>dispatch(showEditvesselModal()),
      hideEditvesselModal:()=>dispatch(hideEditvesselModal()),
      registervessel:(name)=>dispatch(registervessel(name)),
      vesselEdit:(id)=>dispatch(vesselEdit(id)),
      vesselUpdate:(id,name,currentPage)=>dispatch(vesselUpdate(id,name,currentPage)),
      vesselDelete:(id,currentPage)=>dispatch(vesselDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Vessel);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================