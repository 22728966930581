const InitialStates = {
  loading:false,
    openInvoiceInfo:false,
    openInvoiceReg:false,
    noInvoicePresent:false,
    autoDeleteInfoBill:null,
    autoPrintResponse:null,
    openInvoiceEditInfo:false,
    openInvoiceEditBiller:false,
  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {
    
        case 'openInvoiceInformationModal':
            return { ...state, 'openInvoiceInfo':true };
    
            case 'hideInvoiceInformationModal':
              return { ...state, 'openInvoiceInfo':false };


          case 'openinvoiceRegistrationModal':
            return { ...state, 'openInvoiceReg':true };
    
            case 'hideinvoiceRegistrationModal':
              return { ...state, 'openInvoiceReg':false };

              case 'openInvoiceInfoEditModal':
                return { ...state, 'openInvoiceEditInfo':true };
        
                case 'hideInvoiceInfoEditModal':
                  return { ...state, 'openInvoiceEditInfo':false };

                  case 'openInvoiceBillerEditModal':
                    return { ...state, 'openInvoiceEditBiller':true };
            
                    case 'hideInvoiceBillerEditModal':
                      return { ...state, 'openInvoiceEditBiller':false };
      
  
      case "getAutoInvoices_success":
         if(action.payload.error_message){
          return {...state,'autoInvoiceResponse': action.payload, 'noInvoicePresent': true, 'loading': false };

         }else{
          return {...state, 'autoInvoiceResponse': action.payload, 'loading': false,'noInvoicePresent': false };

         }

        case "AutoInvoiceDeletion_success":
          console.log(action.payload);
          return {...state, 'autoDeleteInfoBill': action.payload, 'loading': false };

          case "registerAutoInvoice_success":
            return {...state, 'registrationSuccessAutoInvoice': action.payload, 'loading': false,'openInvoiceReg':false };
  
            case "invoicePrint_success":
              return {...state, 'autoPrintResponse': action.payload, 'loading': false };

case "AutoInvoiceEditInfoDetails_success":
  return {...state, 'invoiceEditInfo': action.payload, 'loading': false };

case "AutoInvoiceInfoUpdate_success":
  return {...state, 'invoiceInfoUpdationResponse': action.payload, 'loading': false ,'openInvoiceEditInfo':false};

case "AutoInvoiceEditBiller_success":
  return {...state, 'invoiceBillerEditResponse': action.payload, 'loading': false ,'openInvoiceEditInfo':false};

  case "AutoInvoiceBillerUpdate_success":
    return {...state, 'invoiceBillerUpdateResponse': action.payload, 'loading': false ,'openInvoiceEditBiller':false};
case "AutoInvoiceOriginDeletion_success":
  return {...state, 'invoiceOriginDeletionResponse': action.payload, 'loading': false ,'openInvoiceInfo':false};

      default:
        return state;
    }
  };
  