import React, { useState, useEffect } from "react";

import { Outlet,NavLink,Link,useLocation,useParams,useMatch,useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {can} from '../../../utils/roleUtils';
import CloseButton from "react-bootstrap/esm/CloseButton";
import ContainerImagesCarousel from '../ContainerImagesCarousel'
import Swal from "sweetalert2";
import { goBack } from "../../../utils/historyUtils";
import {
  getContainerDetails,
  downloadImagesContainerZip,
  getChargesContainersRows,
} from "../../../../redux/actions";
import SimpleCourousel from "../../../elements/SimpleCourousel";
import {calculateTotal} from '../../../utils/chargesUtils';
import moment from 'moment';



const ContainerView = (props) => {
  const location = useLocation();
  const [imagesModal, setImagesModal] = useState(false);

  const [isTabActive, setIsTabActive] = useState(false);
  const [id, setId] = useState('');
  const [onId, setOnId] = useState(null);

  const params = useParams();
  const {t}=useTranslation();

  const match = useMatch('/home/userProfile/:id');

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.getContainerDetails(decryptValue);
 props.getChargesContainersRows(decryptValue);

 }
 },[])
 


 const downloadZipImages=(id)=>{


  Swal.fire({
    title: "Are you sure?",
    text: "You want to download images of container",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, download it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.downloadImagesContainerZip(id);
    }
  });
  

}
 const navigate=  useNavigate();



const viewAuto=(id)=>{
  navigate( `/home/autoView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListGhulam_123'))}`);
}



const totalCost = calculateTotal(props.getContainerChargesRows?.data, 'cost');
  const totalQuantity = calculateTotal(props.getContainerChargesRows?.data, 'quantity');
  const totalAmount = calculateTotal(props.getContainerChargesRows?.data, 'total');


const containerDetails = props.containerDetailsResponse;

  return (

    
          <div>
           
    
  <div className="back-button">
        <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i> </a> 

 </div>


            <div className='card card-custom'>

          <div className='card-body'>

          <div className='row mb-2'>

<div className='col-md-10'>Container Details</div>
<div className='col-md-2'> 
</div>

</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Container Number</div>
  <div className='col-md-8 text-uppercase'> {containerDetails?.containerInfo?.container_no} </div>

</div>






<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Booking Number</div>
  <div className='col-md-8'>{containerDetails?.containerInfo?.cont_booking_no}</div>

</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Container Status</div>
  <div className='col-md-8 text-uppercase'> { containerDetails?.containerInfo?.cont_paid_status==="paid"?<div className="status-paid-inside" >PAID</div>:<div className="status-unpaid-inside">UNPAID</div>}
 </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Container Size</div>
  <div className='col-md-8 '>{containerDetails?.containerInfo?.cont_container_size} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Consignee</div>
  <div className='col-md-8 '>{containerDetails?.consignee?.company_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Trucker</div>
  <div className='col-md-8 '> {containerDetails?.truckers?.vendor_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Forwarder</div>
  <div className='col-md-8 '>{containerDetails?.forwarders?.vendor_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Seal</div>
  <div className='col-md-8 '>{containerDetails?.containerInfo?.cont_seal} </div>

</div>

          </div>
        </div>  





        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Container Loading  Information</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Exporter Name</div>
  <div className='col-md-8'>{containerDetails?.containerInfo?.cont_exporter_name} </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Point of Loading</div>
  <div className='col-md-8 '> {containerDetails?.containerInfo?.cont_point_loading}  </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Port of Loading</div>
  <div className='col-md-8 '> {containerDetails?.containerInfo?.cont_port}, {containerDetails?.containerInfo?.cont_country_port}   </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Port of Discharge</div>
  <div className='col-md-8 '> {containerDetails?.containerInfo?.cont_port_discharge}, {containerDetails?.containerInfo?.cont_country_discharge}   </div>

</div>





          </div>
        </div>         





        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Vessel & Port Details</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Vessel Name</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_vessel_name} </div>
  <div className='col-md-3 text-uppercase'> Vessel Voyage  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_vessel_voyage} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Doc Cut Date</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_document_cut_date?moment(containerDetails?.containerInfo?.cont_document_cut_date).format('DD-MM-YYYY'):null} </div>
  <div className='col-md-3 text-uppercase'> Port Cut Date  </div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_port_cut_date?moment(containerDetails?.containerInfo?.cont_port_cut_date).format('DD-MM-YYYY'):null} </div>

</div>


<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Departure Date</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_departure_date?moment(containerDetails?.containerInfo?.cont_departure_date).format('DD-MM-YYYY'):null} </div>
  <div className='col-md-3 text-uppercase'>  ETA  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_eta?moment(containerDetails?.containerInfo?.cont_eta).format('DD-MM-YYYY'):null}  </div>

</div>


<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>CPB Completed</div>
  <div className='col-md-3'>  {containerDetails?.containerInfo?.cont_cpb_completed? moment(containerDetails?.containerInfo?.cont_cpb_completed).format('DD-MM-YYYY'):null} </div>
  <div className='col-md-3 text-uppercase'> SI-Submission Date  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_si_submission_date?moment(containerDetails?.containerInfo?.cont_si_submission_date).format('DD-MM-YYYY'):null} </div>

</div>

<div className='row main-font-size'>

<div className='col-md-3 text-uppercase'>Loading Date</div>
<div className='col-md-3'> {containerDetails?.containerInfo?.cont_loading_date?moment(containerDetails?.containerInfo?.cont_loading_date).format('DD-MM-YYYY'):null} </div>
<div className='col-md-3 text-uppercase'> Completed  </div>
<div className='col-md-3 '> {containerDetails?.containerInfo?.cont_completed_date?moment(containerDetails?.containerInfo?.cont_completed_date).format('DD-MM-YYYY'):null} </div>

</div>




          </div>
        </div>         

      

       


        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Container Validations Information</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Validation Date</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_validation_date?moment(containerDetails?.containerInfo?.cont_validation_date).format('DD-MM-YYYY'):null} </div>
  <div className='col-md-3 text-uppercase'> ITN  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_itn} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>XTN</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_xtn} </div>
  <div className='col-md-3 text-uppercase'> Steamship Line  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_steamship_line}  </div>

</div>

<div className='row main-font-size'>

<div className='col-md-3 text-uppercase'>Shipping Lines</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_line}  </div>
  <div className='col-md-3 text-uppercase'> Terminal  </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_terminal} </div>


</div>


          </div>
        </div>         



        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Container Arrivals Information</div>
</div>

<div className='row main-font-size'>
<div className='col-md-3 text-uppercase'>Container Expected </div>
  <div className='col-md-3 '> {containerDetails?.containerInfo?.cont_expect_date_arrival?moment(containerDetails?.containerInfo?.cont_expect_date_arrival).format('DD-MM-YYYY'):null}  </div>

  <div className='col-md-3 text-uppercase'>Container Arrival</div>
  <div className='col-md-3'> {containerDetails?.containerInfo?.cont_arrival_date?moment(containerDetails?.containerInfo?.cont_arrival_date).format('DD-MM-YYYY'):null}  </div>
 
</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Unloading Date</div>
  <div className='col-md-8'> {containerDetails?.containerInfo?.cont_unloading_date?moment(containerDetails?.containerInfo?.cont_unloading_date).format('DD-MM-YYYY'):null} </div>


</div>



          </div>
        </div>         




        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Container Charges</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>Charge Name</div>
  <div className='col-md-2 text-uppercase'>Description</div>
  <div className='col-md-2 text-uppercase'>Amount</div>
  <div className='col-md-2 text-uppercase'>Days</div>
  <div className='col-md-2 text-uppercase'>Cost</div>
  <div className='col-md-2 text-uppercase'>Total</div>


</div>
{props.getContainerChargesRows?.data.map((item,index)=>{
  return (
<div className='row main-font-size' key={index}>

<div className='col-md-2 text-uppercase'>{item.charge_name}</div>
<div className='col-md-2 text-uppercase'>{item.charge_description}</div>
<div className='col-md-2 text-uppercase'>{item.amount}</div>
<div className='col-md-2 text-uppercase'>{item.quantity}</div>
<div className='col-md-2 text-uppercase'>{item.cost}</div>
<div className='col-md-2 text-uppercase'>{item.total}</div>


</div>
  )
})

}
<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Total Chares</div>
  <div className='col-md-3 '>{totalAmount}</div>

  <div className='col-md-3 text-uppercase'>Total Cost</div>
  <div className='col-md-3 '>{totalCost}</div>


</div>

          </div>
        </div>         



        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Files</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Dock Receipt</div>
  <div className='col-md-8'> 
   { <a style={{textDecoration:'none',color:'yellow'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${containerDetails?.containerInfo?.cont_doc_receipt}`} target={"_blank"}>{containerDetails?.containerInfo?.cont_doc_receipt}</a>}
</div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>BL Copy</div>
  <div className='col-md-8 text-uppercase'>    { <a style={{textDecoration:'none',color:'yellow'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${containerDetails?.containerInfo?.cont_bill_copy}`} target={"_blank"}>{containerDetails?.containerInfo?.cont_bill_copy}</a>}
 </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Telex Release</div>
  <div className='col-md-8 text-uppercase'>    { <a style={{textDecoration:'none',color:'yellow'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${containerDetails?.containerInfo?.cont_telex_release}`} target={"_blank"}>{containerDetails?.containerInfo?.cont_telex_release}</a>}
 </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Validations</div>
  <div className='col-md-8'>
      { <a style={{textDecoration:'none',color:'yellow'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${containerDetails?.containerInfo?.cont_validation}`} target={"_blank"}>{containerDetails?.containerInfo?.cont_validation}</a>}
  </div>

</div>





          </div>
        </div>         








              

            </div>










  );
};



const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    containerDetailsResponse,
    getContainerChargesRows
  } = state.ContainerHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    containerDetailsResponse:containerDetailsResponse,
    getContainerChargesRows:getContainerChargesRows
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
  
    getContainerDetails:(id)=>dispatch(getContainerDetails(id)),
    downloadImagesContainerZip:(id)=>dispatch(downloadImagesContainerZip(id)),
    getChargesContainersRows:(id)=>dispatch(getChargesContainersRows(id)),

  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ContainerView);
