import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing charges settings model
// -------------------------------------------------------------------------
export const hidechargesModal=()=>{
    return {
      type:'hidechargesModal'
    }
    
    }
    export const showchargesModal=()=>{
  
      return {
        type:'showchargesModal'
      }
      
      }
  
  
      export const showEditchargesModal=()=>{
        return {
          type:'showEditchargesModal'
        }
        
        }
      
        export const hideEditchargesModal=()=>{
          return {
            type:'hideEditchargesModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all chargess for settings
// ---------------------------------------------------------------------------------------------------------

export const getchargess = (currentPage) => {



    return (dispatch) => {
        dispatch(getchargesStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/chargess?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getchargesSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getchargesFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getchargesStarted = () => ({
      type: "getcharges_started",
    });
    
    
    const getchargesSuccess = (data,currentPage) => ({
      type: "getchargessSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getchargesFailure = (error) => ({
      type: "getcharges_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registercharges = (data) => {
  //console.log(id);
  
  return (dispatch) => {
    dispatch(registerchargesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    console.log(data.charge_status);

    let chargesData={
     charge_name:data.charge_name,
     code:data.code,
     description:data.description,
     vendor_type:data.vendor_type?.value,
     unit:data.unit?.value,
     per_unit_price:data.per_unit_price,
     per_unit_cost:data.per_unit_cost,
     charge_status:data.charge_status,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/chargesRegister`, chargesData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerchargesSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The charges has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerchargesFailure(err.response.data.errors));
     
      });
  };
};

const registerchargesStarted = () => ({
  type: "registercharges_started",
});

const registerchargesSuccess = (data) => ({
  type: "registercharges_success",
  payload: {
    ...data,
  },
});

const registerchargesFailure = (error) => ({
  type: "registercharges_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// the charges edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const chargesUsersSelector = (q) => {

  return (dispatch) => {
    dispatch(chargesUsersSelectorStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   
let data={
  q:q
}

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/chargesUsersSelector`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(chargesUsersSelectorSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(chargesUsersSelectorFailure(err.response.data.errors));
     
      });
  };
};

const chargesUsersSelectorStarted = () => ({
  type: "chargesUsersSelector_started",
});

const chargesUsersSelectorSuccess = (data) => ({
  type: "chargesUsersSelector_success",
  payload: {
    ...data,
  },
});

const chargesUsersSelectorFailure = (error) => ({
  type: "chargesUsersSelector_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// the charges edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const chargesEdit = (id) => {

  return (dispatch) => {
    dispatch(chargesEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editcharges/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(chargesEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(chargesEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const chargesEditDetailsStarted = () => ({
  type: "chargesEditDetails_started",
});

const chargesEditDetailsSuccess = (data) => ({
  type: "chargesEditDetails_success",
  payload: {
    ...data,
  },
});

const chargesEditDetailsFailure = (error) => ({
  type: "chargesEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the charges update 
// ---------------------------------------------------------------------------------------------------------

export const chargesUpdate = (data,currentPage) => {
    return (dispatch) => {
      dispatch(chargesUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let chargesData={
        charge_name:data.charge_name,
        code:data.code,
        description:data.description,
        vendor_type:data.vendor_type?.value,
        unit:data.unit?.value,
        per_unit_price:data.per_unit_price,
        per_unit_cost:data.per_unit_cost,
        charge_status:data.charge_status,
       }
      
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updatecharges/`+data.onId,chargesData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(chargesUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The charges has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(chargesUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const chargesUpdateStarted = () => ({
    type: "chargesUpdate_started",
  });
  
  
  const chargesUpdateSuccess = (data,currentPage) => ({
    type: "chargesUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const chargesUpdateFailure = (error) => ({
    type: "chargesUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// chargess Deletion
// ---------------------------------------------------------------------------------------------------------

export const chargesDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(chargesDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deletecharges/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(chargesDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The charges has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(chargesDeletionFailure(err.message));
      });
  };
};

const chargesDeletionStarted = () => ({
  type: "chargesDeletion_started",
});

const chargesDeletionSuccess = (data,currentPage) => ({
  type: "chargesDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const chargesDeletionFailure = (error) => ({
  type: "chargesDeletion_failure",
  payload: {
    error,
  },
});
