const InitialStates = {
  preDataLoadResponse: null,
  loading: false,
  showNotificationModal: false,
  currentPage: 1,
  showEditNotificationModal: false,
  // lastNotification: true,
  searchNotificationsText: "",
  // notificationIndicationSuccess:'noNotification',
  notificationIndicationSuccess:[],
  notificationsRegisteredSucess:[]
};

export default (state = InitialStates, action) => {
  switch (action.type) {
    case "showAndHideNotifModal":
      return { ...state, showNotificationModal: !state.showNotificationModal };

    case "showAndHideEditNotifModal":
      return {
        ...state,
        showEditNotificationModal: !state.showEditNotificationModal,
      };

    case "inputHandler":
      return { ...state, [action.payload.key]: action.payload.value };

    case "showRoleModal":
      return { ...state, showModal: true };

    case "searchNotification_started":
     return { ...state, loading: true };

    case "NotificationReg_started":
      return { ...state, loading: true };

    case "NotificationUpdate_started":
      return { ...state, loading: true };

    case "selectedNotifications_started":
      return { ...state, loading: true };

    case "NotificationReg_started":
      return { ...state, loading: true };

    case "Notifications_fetch_success":
      return {
        ...state,
        notificationsListSuccess: action.payload,
        loading: false,
      };

    case "getNotificationDetails_success":
      return {
        ...state,
        notificationsEditInfoDetails: action.payload,
        loading: false,
      };

    case "NotificationReg_success":
      return {
        ...state,
        notificationsRegisteredSucess: action.payload,
        currentPage: action.payload.currentPage,
        loading: false,
        showNotificationModal: false,
      };

    case "NotificationUpdate_success":
      return {
        ...state,
        notificationsUpdateSuccess: action.payload,
        currentPage: action.payload.currentPage,
        loading: false,
        showEditNotificationModal: false,
      };

    case "selectedNotifications_success":
      return {
        ...state,
        notificationsDeletedSuccess: action.payload,
        loading: false,
      };

    case "roleDeletion_success":
      return {
        ...state,
        roleDeletedResponse: action.payload,
        currentPage: action.payload.currentPage,
      };

    case "getLatestNotificationDetails_success":
      return { ...state, latestNotification: action.payload, loading: false };

    case "statusViewed":
      return { ...state, lastNotification: false };

    case "searchNotification_success":
      return {
        ...state,
        notificationsListSuccess: action.payload,
        loading: false,
      };

      case "notificationIndication_success":
        return {
          ...state,
          notificationIndicationSuccess: action.payload,
          loading: false,
        };


case "NotificationRead_success":

return {
  ...state,
  notificationReaded: action.payload,
  loading: false,
};


    default:
      return state;
  }
};
