import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {useParams,Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Pagination from "react-js-pagination";
import NodataFound from '../../error/noDataFoundMain';


import {
  inputHandler,
  perVendorContainers,
  searchContainersPerVendor,
} from "../../../../redux/actions";

function PaidContainer(props) {

  const [id, setId] = useState('');
  const [searched, setSearched] = useState(false);

  const params = useParams();
  const {t}=useTranslation();



  

useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
props.perVendorContainers(decryptValue,props.currentPage,'paid',props.vendorEditResponse?.vendor_type) 
}

 },[props.paymentSaveSuccess])
 

 const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.perVendorContainers(id,props?.currentPage,'paid',props.vendorEditResponse?.vendor_type);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const onSearch=(e)=>{
  e.preventDefault();
    if(props.searchVendorContainers){
  setSearched(true);
    props.searchContainersPerVendor(props.searchVendorContainers,id,props.currentPage,'paid',props.vendorEditResponse?.vendor_type)
    }else{
      setSearched(false);
     
  
    }
  
  }

  const handleSearchPages=(pageNumber)=>{
    props.searchContainersPerVendor(props.searchVendorContainers,id,pageNumber,'paid',props.vendorEditResponse?.vendor_type)
  
  }
  
  return (
    <div>
        <div className='Containers-card'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-4 text-uppercase text-white'>{props.vendorEditResponse?.vendor_name} Containers </div>

</div>
</div>
  </div>




<div className="gap-Containers"></div>
<div className="scroller layer-outer-Containers mt-2">

{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchVendorContainers}</span></p></div>


}
{props.perVendorContainersList?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}


  
{props.perVendorContainersList?.data?.data.map((item,index)=>{
    return(
      <div class="card card-custom mt-3" key={index}>
      <div class="row no-gutters">
        
        <div class="col-md-1">
        <div className="vehicle-status">
          {item.cont_catagory}
        </div>
        
        </div>
        <div class="col-md-11">
          <div class="card-body">
            <div className="row">

              <div className="col-md-6 pt-2">
              <span className="label-car">Container No:</span>  <span className="title-car"> {item?.container_no}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Booking No:</span> <span className="title-car">{item?.cont_booking_no}</span>
              </div>
              </div>
              <div className="row">
              <div className="col-md-6">
              <span className="label-car">Container Size:</span> <span className="title-car">{item?.cont_container_size}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Paid Status:</span> <span className="title-car">{item?.cont_paid_status}</span>
              </div>
              </div>
              <div className="row">
              <div className="col-md-6">
              <span className="label-car">Port:</span> <span className="title-car">{item?.cont_port} {item.cont_country_port}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Port Discharge:</span> <span className="title-car">{item?.cont_port_discharge} {item?.cont_country_discharge}</span>
              </div>
              <div className="row">
              <div className="col-md-3">
              <span className="label-car">ETA:</span> <span className="title-car">{item?.cont_eta}</span>
              </div>
              <div className="col-md-5">
              <span className="label-car">Payment Reference:</span> <span className="title-car">{item?.vendor_payment_reference}</span>
              </div>
              <div className="col-md-4">
              <span className="label-car">Payment Check:</span> <span className="title-car">{item?.vendor_payment_check}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Amount:</span> <span className="title-car">$ {item?.vendor_paid_charges}</span>
              </div>
              </div>
            </div>

            <Link  to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(item.id.toString(),'autoListAli_123'))}`}  className="details-button">View Details</Link>


           
          </div>
        </div>
      </div>


    </div>
    )
  })}
 


 <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchVendorContainers" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

</div>


{ props.perVendorContainersList?.data?.data.length===0 ? '':

<div>
      {props.perVendorContainersList?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.perVendorContainersList?.data.current_page}
              itemsCountPerPage={props.perVendorContainersList?.data.per_page}
              totalItemsCount={props.perVendorContainersList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={searched===true?handleSearchPages:
               (pageNumber) => props.perVendorContainers(id,pageNumber,'paid')
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}

    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    confirm_password,
    searchUsers,
    vendorEditResponse,
    rolePicker,
    searchVendorContainers,
    perVendorContainersList,
    paymentSaveSuccess
  } = state.VendorsHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
  
    currentPage: currentPage,
  
    searchVendorContainers:searchVendorContainers,

    preDataLoadResponse: preDataLoadResponse,
 
    confirm_password: confirm_password,
    searchUsers: searchUsers,
    vendorEditResponse:vendorEditResponse?.data,
    perVendorContainersList:perVendorContainersList,
    paymentSaveSuccess:paymentSaveSuccess
  };
};






const mapDispatchToProps = (dispatch) => {
  return {
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    perVendorContainers:(id,pageNumber,status,containerCatagory)=>dispatch(perVendorContainers(id,pageNumber,status,containerCatagory)),
    searchContainersPerVendor:(querry,vendorId,pageNumber,status,containerCatagory)=>dispatch(searchContainersPerVendor(querry,vendorId,pageNumber,status,containerCatagory))
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(PaidContainer) 