import React, { useState, useEffect } from "react";
import "../../css/searchBox.css";
import i18next from 'i18next'
import cookies from 'js-cookie'

import "../../css/sideBar.css";
import Logo from "../assets/normLogo.png";
import { connect } from "react-redux";
import {
   logout,
  autosList,
  getNotificationIndication,
  ContainersList,
  getRequestsCount,
} from "../../redux/actions";
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown';
import { CircleFlag } from 'react-circle-flags'


import { NavLink,Link, Outlet } from "react-router-dom";
import _ from "lodash";
import {can} from '../utils/roleUtils';

function Sidebar(props) {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(true);

  const [authName, setAuthName] = useState('not defined');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API



      const value = localStorage.getItem("sidebarToggle");
   


      if(value=='true'){
      setShowSideBar(true);
      }else{
      setShowSideBar(false);

      }

      const AuthInfo=JSON.parse(localStorage.getItem('AuthUser'));
    
      if(AuthInfo){
      
        setAuthName(AuthInfo.name)
      
      }
     


    
  },[]);


  useEffect(()=>{
    props.getNotificationIndication()
    
  },[props.notificationReaded,props.notificationsRegisteredSucess,props.notificationsDeletedSuccess])


  useEffect(()=>{
    props.getRequestsCount();
    
  },[props.rejectAcceptResponse])

  const sideBarToggle = () => {
    if (showSideBar == true) {
      setShowSideBar(false);
      localStorage.setItem("sidebarToggle", false);
    } else {
      setShowSideBar(true);
      localStorage.setItem("sidebarToggle", true);
    }
  };





  const languages = [
  
    {
      code: 'en',
      name: 'English',
      country_code: 'us',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ]




  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation() 




  useEffect(() => {
    // console.log('Setting page stuff')
    // document.body.dir = currentLanguage.dir || 'ltr'
    let directionOfLanguage= currentLanguage.dir || 'ltr';
 
  
    const el = document.querySelectorAll('.langDirection')
    // console.log(el)
    if(el!=null){
    var div_array = [...el]; 
    div_array.forEach(div_array => {

     div_array.setAttribute('dir',directionOfLanguage.toString());
     localStorage.setItem('lang_dir', JSON.stringify(directionOfLanguage));

      });
    }
    // if(el!=null){
    //   document.querySelector('.langDirection').dir=directionOfLanguage.toString();
    //   localStorage.setItem('lang_dir', JSON.stringify(directionOfLanguage));

    // }

    // console.log(el)

    document.title = t('app_title')
  }, [currentLanguage, t])


// ================================================================
// permissions functions
// ================================================================

// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;






  const subMenuToggle = () => {
    if (showSubMenu == true) {
      setShowSubMenu(false);
    } else {
      setShowSubMenu(true);
    }
  };







  return (
    <>




      <div className={showSideBar ? "sidebar" : "sidebar close"}>
        <div className="icon" style={{cursor:'pointer'}} onClick={sideBarToggle}>
         {showSideBar? <i className="fas fa-times" style={{ color: "white" }}></i>: <i className="fas fa-bars" style={{ color: "white" }}></i>}
        </div>

        <div className="logo-details">
          <img
            src={Logo}
            alt=""
            style={{ width: "30px", height: "30px", paddingLeft: "10px" }}
          />
          {/* <i className='bx bxl-c-plus-plus'></i> */}
          <span className="logo_name">
            <span style={{ color: "white" }}> {process.env.REACT_APP_NAME}  </span>
          </span>
        </div>
        <ul className="nav-links">
          <li onClick={()=>{
if(props.catagoryValuesFetched===true){
            props.sidebarAutosList(props.currentPage)
} 
            }}>
            <NavLink to="autos"  className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')} >
            <i className="bx bx-car"></i>
              <span className="link_name">{t("key_auto")}</span>
            </NavLink>
            <div>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name"  >
                  {t("key_auto")}
                  </a>
                </li>
              </ul>
            </div>
          </li>

{/* note that this is drop down menu not in use yet */}
          {/* <li className={showSubMenu ? "" : "showMenu"}>
            <div className="iocn-link">
              <a  >
                <i className="bx bx-collection"></i>
                <span className="link_name" style={{fontSize:'12px'}}>Loading Lists</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={subMenuToggle}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name"  >
                  Category
                </a>
              </li>
              <li>
                <NavLink to="Other"   className={({ isActive }) => (isActive ? 'sideSubMenuActiveTab' : '')}>
                  <span>page</span>
                </NavLink>
              </li>
              <li>
                <a   className="sub-info">
                <span> JavaScript</span>
                </a>
              </li>
              <li>
                <a   className="sub-info">
                <span> PHP & MySQL</span>
                </a>
              </li>
            </ul>
          </li> */}

          
          {/* <li className={showSubMenu ? "":"showMenu"}>
        <div className="iocn-link">
          <a  >
            <i className='bx bx-book-alt' ></i>
            <span className="link_name">Posts</span>
          </a>
          <i className='bx bxs-chevron-down arrow' onClick={subMenuToggle} ></i>
        </div>
        <ul className="sub-menu">
          <li><a className="link_name"  >Posts</a></li>
          <li><a   className="sub-info">Web Design</a></li>
          <li><a   className="sub-info">Login Form</a></li>
          <li><a   className="sub-info">Card Design</a></li>
        </ul>
      </li> */}
      <li>
            <NavLink to="LoadingListsSection" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')} >
            <i className="bx bx-collection"></i>
              <span className="link_name"> {t('key_loading_lists')}</span>
            </NavLink>
            <div>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name"  >
                  {t('key_loading_lists')}
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li onClick={()=>{
if(props.containerCatagoryValue===true){
      props.ContainersList(props.currentPage)
} 
            }}>
            <NavLink to="containerslist" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')} >
            <i className="bi bi-truck"></i>
                                      <span className="link_name">{t('key_containers')}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name"  >
                {t('key_containers')}
                </a>
              </li>
            </ul>
          </li>


          <li>
            <NavLink to="vendors" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')} >
            <i className='bx bx-receipt'></i>
                                                  <span className="link_name">{t('key_vendors')}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name"  >
                {t('key_vendors')}
                </a>
              </li>
            </ul>
          </li>


          {/* <li>
        <div className="iocn-link">
          <a  >
            <i className='bx bx-plug'  ></i>
            <span className="link_name">Plugins</span>
          </a>
          <i className='bx bxs-chevron-down arrow' ></i>
        </div>
        <ul className="sub-menu">
          <li><a className="link_name"  >Plugins</a></li>
          <li><a   className="sub-info">UI Face</a></li>
          <li><a   className="sub-info">Pigments</a></li>
          <li><a   className="sub-info">Box Icons</a></li>
        </ul>
      </li> */}
          {/* <li>
            <a  >
              <i className="bx bx-compass"></i>
              <span className="link_name">Explore</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name"  >
                  Explore
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a  >
              <i className="bx bx-history"></i>
              <span className="link_name">History</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name"  >
                  History
                </a>
              </li>
            </ul>
          </li> */}


{/* 
          <li>
            <NavLink to="MainBalancesTabs"  className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>
              <i className="bx bx-money"></i>
              <span className="link_name">{t('key_balances')}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" >
                {t('key_balances')}
                </a>
              </li>
            </ul>
          </li> */}

          {can(currentUserPermissions,'Allow Notification')?

<li>
  <NavLink to="notifications" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>

    <i className="bx bx-bell" style={{position:'relative'}}>  
    {props.notificationIndicationSuccess?.data===null?'': <span className="circle3"></span>}
  </i>
  
    <span className="link_name">{t('key_notifications')}</span>
  </NavLink>
  <ul className="sub-menu blank">
    <li>
      <a className="link_name" >
      {t('key_notifications')}
      </a>
    </li>
  </ul>
</li>:''}


{can(currentUserPermissions,'Allow Log Activities')?

<li>
  <NavLink to="activityLogs" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>

    <i className="bx bx-history" style={{position:'relative'}}>   </i>
  
    <span className="link_name">{t('key_logs')}</span>
  </NavLink>
  <ul className="sub-menu blank">
    <li>
      <a className="link_name" >
      {t('key_logs')}
      </a>
    </li>
  </ul>
</li>:''}


          {can(currentUserPermissions,'Allow Settings')?

          <li>
            <NavLink to="settings" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>
              <i className="bx bx-cog"></i>
              <span className="link_name">{t('key_settings')}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name"  >
                {t('key_settings')}
                </a>
              </li>
            </ul>
          </li>:""
          }


{can(currentUserPermissions,'Allow Requests')?

<li>
  <NavLink to="requestsList" className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>

  <i class="fas fa-tasks">
 {props.noOfRequests?.data===0?'': <span className="circle3" style={{left:'42px',top:'10px', width:'14px',height:'14px'}}>{props.noOfRequests?.data}</span>}

  </i>
  
    <span className="link_name">{t('key_requests')}</span>
  </NavLink>
  <ul className="sub-menu blank">
    <li>
      <a className="link_name" >
      {t('key_requests')}
      </a>
    </li>
  </ul>
</li>:''}



{
can(currentUserPermissions,'Manage Users')?
          <li>
            <NavLink to="RoleManagement"  className={({ isActive }) => (isActive ? 'sideMenuActiveTab' : '')}>
              <i className="bx bx-user-pin"></i>
              <span className="link_name">{t('key_user_management')}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" >
                {t('key_user_management')}
                </a>
              </li>
            </ul>
          </li>
          :''
}


          <li>
            <div className="profile-details">
              <div className="profile-content">
                {/* <img src="image/profile.jpg" alt="profileImg"> */}
              </div>
              <div className="name-job">
              <a onClick={()=>props.logout()}>

                <div className="profile_name" style={{cursor:'pointer'}}>{t('key_log_out')}</div>
                </a>
                <div className="job">{authName}</div>
            
              </div>
              <a onClick={()=>props.logout()}>
              <i className="bx bx-log-out"></i>
              </a>
            
            </div>
          </li>
{
      !showSideBar?    <li>
            <a onClick={()=>props.logout()}>
       
              <i className="fas fa-sign-out-alt"></i>
              <span className="link_name">{t('Log out')}</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" >
                {t('Log Out')}
                </a>
              </li>
            </ul>
          </li>:''
}
        </ul>


        





        <div style={{position:'fixed',left:'4px', bottom:'10px', zIndex:'99999'}}>
<Dropdown drop={'up'}>
      <Dropdown.Toggle variant="success" id="dropdown-basic"  >
      <i className="fas fa-globe-europe"></i>
            </Dropdown.Toggle>

      <Dropdown.Menu>
      {languages.map((list,index)=>{
        return (
          <Dropdown.Item  key={index} onClick={ ()=>i18next.changeLanguage(list.code)}   className={currentLanguageCode===list.code? 'active':''} >
          <span ><CircleFlag countryCode={list.country_code} height="20" /></span>

         <span> {list.name} </span>
          </Dropdown.Item>

        )
      })}
    
      </Dropdown.Menu>
    </Dropdown>
    </div>








      </div>

     
    </>
  );
}
const mapStateToProps = (state) => {
  const { logoutResponse, isAuthenticated } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;
  const { currentPage,catagoryValue } = state.AutosHandler;
  const { containerCatagoryValue } = state.ContainerHandler;
  const { noOfRequests,rejectAcceptResponse} = state.CustomerRequestHandler;

  const {notificationIndicationSuccess,latestNotification,notificationsRegisteredSucess,lastNotification,notificationReaded,notificationsDeletedSuccess } = state.NotificationHandler;


  return {
    preDataLoadResponse:preDataLoadResponse,
    logoutResponse:logoutResponse,
    isAuthenticated:isAuthenticated,
    currentPage:currentPage,
    catagoryValuesFetched:catagoryValue,
    containerCatagoryValue:containerCatagoryValue,
    notificationIndicationSuccess:notificationIndicationSuccess,
    latestNotification:latestNotification,
    notificationsRegisteredSucess:notificationsRegisteredSucess,
    lastNotification:lastNotification,
    notificationReaded:notificationReaded,
    notificationsDeletedSuccess:notificationsDeletedSuccess,
    noOfRequests:noOfRequests,
    rejectAcceptResponse:rejectAcceptResponse,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch(logout()),
      sidebarAutosList:(pageNumber)=>dispatch(autosList(pageNumber)),
      getNotificationIndication:()=>dispatch(getNotificationIndication()),
      ContainersList:(page)=>dispatch(ContainersList(page)),
      getRequestsCount:()=>dispatch(getRequestsCount()),
  };
};



export default connect(mapStateToProps,mapDispatchToProps) (Sidebar);
