import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");




export const showAndHideNotificationModal=()=>{
  return {
    type:'showAndHideNotifModal'
  }
  }
  export const showAndHideEditNotificationModal=()=>{
    return {
      type:'showAndHideEditNotifModal'
    }
    }


          


// ---------------------------------------------------------------------------------------------------------
// this function to get information of latest notification
// ---------------------------------------------------------------------------------------------------------

export const getLatestNotificationDetails = () => {



  return (dispatch) => {
      dispatch(getLatestNotificationDetailsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/NotificationLatestInfo`,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
       
            // console.log(response.data)
            if(response.data.data.readStatus=="viewed"){
              dispatch({
                type:'statusViewed'
              })
            }
            dispatch(getLatestNotificationDetailsSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getLatestNotificationDetailsFailure(err.response));

        });
    };
  };
  
  const getLatestNotificationDetailsStarted = () => ({
    type: "getLatestNotificationDetails_started",
  });
  
  
  const getLatestNotificationDetailsSuccess = (data) => ({
    type: "getLatestNotificationDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getLatestNotificationDetailsFailure = (error) => ({
    type: "getLatestNotificationDetails_failure",
    payload: {
      error,
    }, 
  });





// ---------------------------------------------------------------------------------------------------------
// this function to get information of new notification exists or not 
// ---------------------------------------------------------------------------------------------------------

export const getNotificationIndication = () => {



  return (dispatch) => {
      dispatch(notificationIndicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/notificationIndicator`,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
      //  console.log(response.data)
         
            dispatch(notificationIndicationSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(notificationIndicationFailure(err.response.data.errors));

        });
    };
  };
  
  const notificationIndicationStarted = () => ({
    type: "notificationIndication_started",
  });
  
  
  const notificationIndicationSuccess = (data) => ({
    type: "notificationIndication_success",
    payload: {
      ...data,
    },
  });
  
  const notificationIndicationFailure = (error) => ({
    type: "notificationIndication_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// to fetch Notifications data
// ---------------------------------------------------------------------------------------------------------

export const NotificationsList = (pageNumber) => {
  return (dispatch) => {
    dispatch(NotificationsListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/NotificationsList?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          // console.log(response.data);

          dispatch(NotificationsListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(NotificationsListFailure(err.message));
      });
  };
};

const NotificationsListStarted = () => ({
  type: "Notifications_fetch_started",
});

const NotificationsListSuccess = (data) => ({
  type: "Notifications_fetch_success",
  payload: {
    ...data,
  },
});



const NotificationsListFailure = (error) => ({
  type: "Notifications_fetch_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// this function will or add data
// ---------------------------------------------------------------------------------------------------------

export const NotificationRegistration = (registrationData,attachment,currentPage,messageType,customers) => {


  const NotificationRegNotificationData = new FormData();

  const userIds= customers?.map((list,index)=>{
    const ids=[];
    ids.push(list.value);
return ids;

  })

  NotificationRegNotificationData.append("userIds",userIds);
  NotificationRegNotificationData.append("notificationType", messageType||false);


  NotificationRegNotificationData.append("subject", registrationData.subject||'');
  NotificationRegNotificationData.append("body", registrationData.body||'');

  NotificationRegNotificationData.append("attachment", attachment.file||'');





  return (dispatch) => {
      dispatch(NotificationRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/NotificationStore`,NotificationRegNotificationData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(NotificationRegSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The notification has been successfully created!',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          // console.log(err.response.data.errors);
          dispatch(NotificationRegFailure(err.response.data.errors));

        });
    };
  };
  
  const NotificationRegStarted = () => ({
    type: "NotificationReg_started",
  });
  
  
  const NotificationRegSuccess = (data,currentPage) => ({
    type: "NotificationReg_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const NotificationRegFailure = (error) => ({
    type: "NotificationReg_failure",
    payload: {
      error,
    }, 
  });







// ---------------------------------------------------------------------------------------------------------
// this function will or add data
// ---------------------------------------------------------------------------------------------------------

export const NotificationRead = (id) => {
 const formInfo = new FormData();
 formInfo.append('id',id);

  return (dispatch) => {
      dispatch(NotificationReadStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/NotificationViewed`,formInfo,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(NotificationReadSuccess(response.data));
          } 
        })
        .catch((err) => {
          // console.log(err.response.data.errors);
          dispatch(NotificationReadFailure(err.response.data.errors));

        });
    };
  };
  
  const NotificationReadStarted = () => ({
    type: "NotificationReg_started",
  });
  
  
  const NotificationReadSuccess = (data) => ({
    type: "NotificationRead_success",
    payload: {
      ...data,
    },
  });
  
  const NotificationReadFailure = (error) => ({
    type: "NotificationRead_failure",
    payload: {
      error,
    }, 
  });










// ---------------------------------------------------------------------------------------------------------
// this function will update the Notification details
// ---------------------------------------------------------------------------------------------------------

export const NotificationUpdate = (registrationData,attachment,currentPage) => {


  const NotificationUpdateData = new FormData();


  NotificationUpdateData.append("id", registrationData.onId||'');
  NotificationUpdateData.append("subject", registrationData.subject||'');
  NotificationUpdateData.append("body", registrationData.body||'');
  NotificationUpdateData.append("attachment", attachment.file||'');






  return (dispatch) => {
      dispatch(NotificationUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/NotificationUpdate`,NotificationUpdateData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(NotificationUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The details have been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(NotificationUpdateFailure(err.response.data.errors));

        });
    };
  };
  
  const NotificationUpdateStarted = () => ({
    type: "NotificationUpdate_started",
  });
  
  
  const NotificationUpdateSuccess = (data,currentPage) => ({
    type: "NotificationUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const NotificationUpdateFailure = (error) => ({
    type: "NotificationUpdate_failure",
    payload: {
      error,
    }, 
  });


 
  











 



// ---------------------------------------------------------------------------------------------------------
// this function to get information of Notification for editing
// ---------------------------------------------------------------------------------------------------------

export const getNotificationDetails = (id) => {



  return (dispatch) => {
      dispatch(getNotificationDetailsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/NotificationEditInfo/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            // console.log(response.data);
            dispatch(getNotificationDetailsSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getNotificationDetailsFailure(err.response.data.errors));

        });
    };
  };
  
  const getNotificationDetailsStarted = () => ({
    type: "getNotificationDetails_started",
  });
  
  
  const getNotificationDetailsSuccess = (data) => ({
    type: "getNotificationDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getNotificationDetailsFailure = (error) => ({
    type: "getNotificationDetails_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// Notification multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedNotificationsDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedNotificationsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedNotifications/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedNotificationsSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected Notifications have been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedNotificationsFailure(err.message));
      });
  };
};

const selectedNotificationsStarted = () => ({
  type: "selectedNotifications_started",
});

const selectedNotificationsSuccess = (data) => ({
  type: "selectedNotifications_success",
  payload: {
    ...data,
  },
});

const selectedNotificationsFailure = (error) => ({
  type: "selectedNotifications_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// Notification Delete
// ---------------------------------------------------------------------------------------------------------

export const NotificationDelete = (id) => {


  return (dispatch) => {
    dispatch(NotificationDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteNotification/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(NotificationDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Notification has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(NotificationDeleteFailure(err.message));
      });
  };
};

const NotificationDeleteStarted = () => ({
  type: "NotificationDelete_started",
});

const NotificationDeleteSuccess = (data) => ({
  type: "NotificationDelete_success",
  payload: {
    ...data,
  },
});

const NotificationDeleteFailure = (error) => ({
  type: "NotificationDelete_failure",
  payload: {
    error,
  },
});





