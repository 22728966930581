import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import LeftSidebar from '../elements/Sidebar';
import PreDataLoader from "../elements/PreDataLoader";
import { preDataLoad,logout,getLatestNotificationDetails,TrackerStatusDetailsForCustomer } from "../../redux/actions";
import Swal from "sweetalert2";
import Dropdown from 'react-bootstrap/Dropdown';

import { connect } from "react-redux";
function Home(props) {

useEffect(()=>{

// localStorage.clear()
props.fetchDataBeforeLoadingPage();
props.getLatestNotificationDetails();

props.TrackerStatusDetailsForCustomer()


// const AuthInfo=JSON.parse(localStorage.getItem('AuthUser'));

// if(AuthInfo){
//   let oneDay=86400000;
// const interval = setInterval(() => {
//   props.logout();
//   Swal.fire({
//     position: 'top-end',
//     icon: 'warning',
//     text: 'The session has expired, please login again !',
//     showConfirmButton: false,
//     timer: 9000,
//     toast: true,

//   })
// }, oneDay);

// return () => clearInterval(interval);


// }



},[])




  return (
    <>
  
{props.preLoading?(

  <PreDataLoader 
   width={"40px"}
   height={"40px"}
   iconColor={"white"}
   backgroundColor={"#deb726"}
   left={"70%"}
   top={"90px"} />
)
 :
(
<div>
  <LeftSidebar/>

<section className="home-section">

     <div className="home-content" >
  
       <Outlet />

     </div>

   </section>

</div>

)

}


  
    </>
  )
}

const mapStateToProps = (state) => {

  const { preLoading } = state.preDataHandler;
  const {latestNotification,lastNotification,notificationsDeletedSuccess } = state.NotificationHandler;

  return {
    preLoading:preLoading,
    notificationsDeletedSuccess:notificationsDeletedSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  fetchDataBeforeLoadingPage: () =>
      dispatch(preDataLoad()),
      logout: () => dispatch(logout()),
      getLatestNotificationDetails:()=>dispatch(getLatestNotificationDetails()),
      TrackerStatusDetailsForCustomer:()=>dispatch(TrackerStatusDetailsForCustomer())
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(Home);