const InitialStates = {
  usersListResponse:null,
    loading: false,
    userDeletionResponse:[],
    userEditResponse:[],
    userUpdateErrors:null,
    currentPage:1,
    showModal:false,
    account_name:'',
    name:'',
    username:'',
    email:'',
    password:'',
    confirm_password:'',
    searchUsers:'',
    rolePicker:[],
    showCompanyModal:false,
    companyEditInfo:[],
    showCompanyEditModal:false,
    showConsigneeModal:false,
    showConsigneeEditModal:false,
  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

case "hideModalAction":
  return { ...state, 'showModal': false, 'contact':''  };


  case "toggleModalAction":
  return { ...state, 'showCompanyModal': !state.showCompanyModal };


  case "toggleModalEditAction":
    return { ...state, 'showCompanyEditModal': !state.showCompanyEditModal };
    case "toggleConsigneeModal":
      return { ...state, 'showConsigneeModal': !state.showConsigneeModal };
      case "toggleConsigneeEditModal":
        return { ...state, 'showConsigneeEditModal': !state.showConsigneeEditModal };
    
  case "searchUsers_started":
     
    return { ...state, 'loading': true,  };

      case "fetch_started":
     
        return { ...state, 'loading': true,  };
  
        case "fetch_error":
          return { ...state, 'loading': false,};

          case "rolePicker_success":
            return { ...state,  'rolePicker':action.payload , 'loading':false,  };
      
        case "fetch_success":
     
        return { ...state, 'usersListResponse': action.payload , 'loading':false, 'currentPage':action.payload.data.current_page };

        case "user_filter_success":
     
        return { ...state, 'usersListResponse': action.payload , 'loading':false, 'currentPage':action.payload.data.current_page };
        
  
  case "userDeletion_success":
   
        return { ...state, 'userDeletionResponse': action.payload, 'loading':false, 'currentPage':action.payload.currentPage };

  case "userEditDetails_success":
  
  return { ...state, 'userEditResponse': action.payload, 'loading':false, 'showModal':true };

  case "userUpdate_success":

    return { ...state, 'userUpdateResponse': action.payload, 'loading':false, 'showModal':false,'currentPage':action.payload.currentPage,'userUpdateErrors':null };


  
    case "userUpdate_failure":

      return { ...state, 'userUpdateErrors': action.payload, 'loading':false, };


//for searching users list 
      case "searchUsers_success":
       
        return { ...state, 'usersListResponse': action.payload, 'loading':false };
        


        case "companyEdit_success":

        return { ...state, 'companyEditInfo': action.payload, 'loading':false, };
    
case "perUserVehicles_success":
  return { ...state, 'userBasedVehicles': action.payload, 'loading':false,'currentPage':action.payload.currentPage };
  case "perUserContainers_success":
  return { ...state, 'userBasedContainers': action.payload, 'loading':false,'currentPage':action.payload.currentPage };

case "searchAutos_success":
  return { ...state, 'userBasedVehicles': action.payload, 'loading':false,'currentPage':action.payload.currentPage };

  case "addDetails_success":
    return { ...state, 'addDetailsSuccess': action.payload, 'loading':false, 'showCompanyModal':false, 'showCompanyEditModal':false };

  case "perUserConsigneeStarted_success":
    return { ...state, 'perUserList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };

    case "searchConsignee_success":
    return { ...state, 'perUserList': action.payload, 'loading':false,'currentPage':action.payload.currentPage };

    case "searchContainerPerUser_success":
  return { ...state, 'userBasedContainers': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


    case "registerconsignee_success":
      return { ...state, 'registerconsigneeSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false,showConsigneeModal:false};
      

      case "consigneeEditDetails_success":
        return { ...state, 'editDetails': action.payload,  'loading':false };

        case "consigneeUpdate_success":
          return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showConsigneeEditModal':false  };
       
          case "consigneeDeletion_success":
            return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };




      default:
        return state;
    }
  };
  