import { useState,useEffect } from 'react';
import '../../../css/settings.css';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Loader from "../../elements/Loader";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Pagination from "react-js-pagination";
import moment from 'moment';
import Swal from 'sweetalert2';
import { AsyncPaginate } from "react-select-async-paginate";
import NodataFound from '../../pages/error/noDataFoundMain';
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';

import {

  getSelectors,
  VehicleTrackersList,
  showAndHideVehicleTrackerModal,
  VehicleTrackerRegistration,
  selectedVehicleTrackersDelete,
  usersSelectorList,
  searchVehicleTrackerList,
  inputHandler,
  TrackerStatusDetailsForCustomer,

} from "../../../redux/actions";

import {can} from '../../utils/roleUtils'




function Settings(props) {

  const {t}=useTranslation();

  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


  const [VehicleTrackerDetails, setVehicleTrackerDetails] = useState({onId:'',subject:'',body:''});
  const [attachment,setAttachment]= new useState({fileName:'',file:null})
  const [bodyTextCon,setBodyTextCon]=new useState(false)
  const [isCheck, setIsCheck] = useState([]);
  const [broadcastVehicleTracker, setBroadCastVehicleTracker]=useState(false)
  const [searched, setSearched] = useState(false);
  const [value, onChange] = useState(null);

  const openAndcloseVehicleTrackerModal=()=>{
    setVehicleTrackerDetails({...VehicleTrackerDetails,'subject':'','body':''})
    setAttachment({'fileName':'','file':null})
      props.showAndHideVehicleTrackerModal()
  }
  


useEffect(()=>{


if(props.trackerIndividualStatus?.data !=null){
  setBroadCastVehicleTracker(true)
}

},[props.trackerIndividualStatus])


  const defaultAdditional = {
    page: 1
  };



  const inputChange=(e)=>{
    setVehicleTrackerDetails({...VehicleTrackerDetails,[e.target.name]:e.target.value})
}

const changeTypeVehicleTracker=()=>{
  let changeStatement = broadcastVehicleTracker? 'unpublic':'public'
  
  Swal.fire({
    title: "Are you sure?",
    text: "You want to "+changeStatement+" your vehicles information",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change status",
  }).then((result) => {
    if (result.isConfirmed) {
      setBroadCastVehicleTracker(!broadcastVehicleTracker)

        if(!broadcastVehicleTracker){
          props.VehicleTrackerRegistration('public')
          }else{
            props.VehicleTrackerRegistration('unpublic')

          }
   
    
    }
  });

}

  const loadOptions = async (search, page) => {
    // await sleep(1000);
    const optionsPerPage = 10;
  
      const responseJSON = await props.usersSelectorListResponse.data;
    // console.log(responseJSON.data)
    // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)
  
      let options; 
      if(responseJSON.data){
          options = responseJSON.data.map(function (data) {
          return { value: data.id, label: data.username}
        })
      }
      // inshallah will iterate it from back end
  // console.log(options)
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
  
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
  
    const hasMore = responseJSON.last_page > page;
  
    const slicedOptions = filteredOptions.slice(
      (page - 1) * optionsPerPage,
      page * optionsPerPage
    );
    // console.log(page)
    return {
      options: slicedOptions,
      hasMore
    };
  
  }

  const loadPageOptions = async (q, prevOptions, { page }) => {
    props.usersSelectorList(q,page)
  
    const { options, hasMore } = await loadOptions(q, page);
  
    return {
      options,
      hasMore,
  
      additional: {
        page:  page + 1,
      }
    };
  };
  

  

  return (
    <>
<div className="container-fluid cont">

{/* 1st row starts from here */}

	<div className="row">
  {can(currentUserPermissions,'Allow Warehouses Settings')?

	<div className="col-md-3 col-sm-4">

      <Link to="/home/settings/warehouses">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-warehouse"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Warehouses</div>
<div className="body-info">
  Add, Update, Delete Warehouse
</div>
</div>

  </div>
</div>
</Link>



      </div>:""}

      {can(currentUserPermissions,'Allow Ports Settings')?

    <div className="col-md-3 col-sm-4">
      
    <Link to="/home/settings/ports">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-map-marked"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Ports</div>
<div className="body-info">
  Add, Update, Delete Port
</div>
</div>

  </div>
</div>
</Link>
</div>:""}


  {can(currentUserPermissions,'Allow Cities Settings')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/cities">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-city"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Cities</div>
<div className="body-info">
  Add, Update, Delete City
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
  }
	
  {can(currentUserPermissions,'Allow Auctions Settings')?
  <div className="col-md-3 col-sm-4">
  <Link to="/home/settings/auctions">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-gavel"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Auctions</div>
<div className="body-info">
  Add, Update, Delete Auction
</div>
</div>

  </div>
</div>
</Link>
      </div>
	:""
  }
</div>

<div className="pt-3"></div>

{/* 2nd row starts from here */}


<div className='row'>
	
  {can(currentUserPermissions,'Allow AutoType Settings')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/autoTypes">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-car"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Auto Types</div>
<div className="body-info">
  Add, Update, Delete Auto Type
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
  }


  {can(currentUserPermissions,'Allow BuyerNumber Settings')?

  <div className="col-md-3 col-sm-4">
  <Link to="/home/settings/buyerNumbers">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-sort-amount-down"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Buyer Numbers</div>
<div className="body-info">
  Add, Update, Delete Buyer No
</div>
</div>

  </div>
</div>
</Link>
	</div>:""}


{can(currentUserPermissions,'Allow Consignees Settings')?
  <div className="col-md-3 col-sm-4">
  <Link to="/home/settings/consignee">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-id-card-alt"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Consignees</div>
<div className="body-info">
  Add, Update, Delete Consignee
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
}

{can(currentUserPermissions,'Allow Billers Settings')?

  <div className="col-md-3 col-sm-4">
  <Link to="/home/settings/billers">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-file-invoice"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Billers</div>
<div className="body-info">
  Add, Update, Delete Biller
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
}

	
</div>

{/* 3rd row starts from here */}

<div className="pt-3"></div>
<div className='row'>
{can(currentUserPermissions,'Public User Vehicles')?

  <div className="col-md-7 col-sm-4">
  <Link to="vehicleTracker">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container">
<i className="fas fa-truck"></i>
<i className="fas fa-car"></i>
</div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Public customer's vehicles for tracking</div>
<div className="body-info">
  Public vehicles of customers for tracking. Select customers and public their vehicles.
</div>
</div>



  </div>
</div>
</Link>
  </div>:''
  }

{can(currentUserPermissions,'Customers can Public Vehicles')?
  <div className="col-md-5 col-sm-4">
  <Link to="">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-car"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>{t('Public Your Vehicle')}</div>
<div className="body-info">
{t('key_allow_your_vehicles')}
</div>
</div>
<div className="checkbox-container">
<Form.Check 
        type="switch"
        id="custom-switch"
        label=" "
        checked={broadcastVehicleTracker}
      onChange={changeTypeVehicleTracker}
      />
</div>

  </div>
</div>
</Link>
  </div>:''
}
  
</div>
<div className="pt-3"></div>

<div className='row'>


{can(currentUserPermissions,'Allow Vendor Types Settings')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/vendorsTypes">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-building"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Vendor Types</div>
<div className="body-info">
  Add, Update, Delete Vendor Type
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
  }


{can(currentUserPermissions,'Allow Charges Settings')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/charges">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-money"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Charges</div>
<div className="body-info">
  Add, Update, Delete Charges
</div>
</div>

  </div>
</div>
</Link>
	</div>
  :""
  }

{can(currentUserPermissions,'Allow Vessels Settings')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/vessels">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-ship"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Vessels</div>
<div className="body-info">
  Add, Update, Delete Vessels
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
  }



{can(currentUserPermissions,'Allow Terms and Conditions')?

	<div className="col-md-3 col-sm-4">
  <Link to="/home/settings/agreement">
<div className="card card-custom-hover">
  <div className='card-body'>
<div className="icon-container"><i className="fas fa-file-contract"></i></div>
<div className='body-info-container'>
<div className='text-uppercase settings-title'>Terms and Conditions</div>
<div className="body-info">
  Add, Update, Delete Agreements
</div>
</div>

  </div>
</div>
</Link>
	</div>:""
  }

</div>


</div>












{/* 
========================================================
Modal of publishing VehicleTracker
======================================================== 
*/}


<div className='py-4'></div>


<Modal show={props.showVehicleTrackerModal} onHide={openAndcloseVehicleTrackerModal} size="lg">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Public Vehicles</Modal.Title>
          <CloseButton variant={"white"} onClick={openAndcloseVehicleTrackerModal} ></CloseButton>
        </Modal.Header>

        <form>
         



          <Modal.Body
            className="modal-body"
          >
      
        
        <div className="row">
    

            <div className="col-md-10 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
          Please select the customers to whom you want to public their vehicles.
             </label>
         <AsyncPaginate
         className="async-paginate-inner-container"
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        isClearable
        isDisabled={broadcastVehicleTracker?true:false}
        isMulti
    
      />

            </div>

            {/* <div className="col-md-5 d-flex pt-4">
     
      </div> */}
      {/* <span style={{color:'gray',fontSize:'11px'}}> {broadcastVehicleTracker? 'By enabling the button you are agree to public your vehicles information': ''}</span> */}

          </div>

          {/* <div className="row">
           
           <div className="col-md-12 mb-2">
           <Form.Label htmlFor="subject">Subject</Form.Label>
      <Form.Control
        type="text"
        id="subject"
        name="subject"
        aria-describedby="subject"
        required
        value={VehicleTrackerDetails.subject}
        onChange={inputChange}
      />
                

              </div>


              <div className="col-md-12 mb-3">

            <FloatingLabel controlId="floatingTextarea2" label="Message">
        <Form.Control
          as="textarea"
          placeholder=' '
          name="body"
          style={{ height: '290px' }}
          value={VehicleTrackerDetails.body}
          required
          onChange={inputChange}
        />
      </FloatingLabel>
              </div>
</div> */}



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
           
            <span><button className='btn btn-primary' type="submit">  &nbsp; Change Status</button> </span>

            {/* <div className="px-4">

   <span title="Attachment">
   <label className="custom-file-upload btn btn-primary" style={{marginTop:'9px'}} >
    <input type="file" 
onChange={handleAttachment}
accept="application/pdf"
    />
    <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;{attachment?.fileName} 
</label>
</span> {attachment?.fileName!=='' ?<span style={{cursor:'pointer'}} onClick={()=>{setAttachment({'fileName':'', 'file':null})}}> <i className="fa fa-times" aria-hidden="true"></i></span>:''} &nbsp;

   
</div> */}


          </Modal.Footer>
        </form>
      </Modal>









    </>
  )
}


const mapStateToProps = (state) => {

  const { preLoading,preDataLoadResponse } = state.preDataHandler;
  const {loading,searchVehicleTrackersText,showVehicleTrackerModal,VehicleTrackersListSuccess,currentPage,VehicleTrackersRegisteredSucess,VehicleTrackersDeletedSuccess,trackerIndividualStatus } = state.VehicleTrackerHandler
  const {usersSelectorListResponse} = state.AutosHandler;

  return {
    
    usersSelectorListResponse:usersSelectorListResponse,
    loading:loading,
    showVehicleTrackerModal:showVehicleTrackerModal,
    VehicleTrackersListSuccess:VehicleTrackersListSuccess,
    currentPage:currentPage,
    VehicleTrackersRegisteredSucess:VehicleTrackersRegisteredSucess,
    VehicleTrackersDeletedSuccess:VehicleTrackersDeletedSuccess,
    searchVehicleTrackersText:searchVehicleTrackersText,
    preDataLoadResponse:preDataLoadResponse,
    trackerIndividualStatus:trackerIndividualStatus,

    preLoading:preLoading,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {

    getSelectors: () => dispatch(getSelectors()),
    VehicleTrackersList:(pageNumeber)=>dispatch(VehicleTrackersList(pageNumeber)),
    showAndHideVehicleTrackerModal:()=>dispatch(showAndHideVehicleTrackerModal()),
    VehicleTrackerRegistration:(status)=>dispatch(VehicleTrackerRegistration(status)),
    selectedVehicleTrackersDelete:(ids)=>dispatch(selectedVehicleTrackersDelete(ids)),
    usersSelectorList:(query,page)=>dispatch(usersSelectorList(query,page)),
    searchVehicleTrackerList:(querry,currentPage)=>dispatch(searchVehicleTrackerList(querry,currentPage)),
    inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
    TrackerStatusDetailsForCustomer:()=>dispatch(TrackerStatusDetailsForCustomer())
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(Settings)