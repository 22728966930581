import React, { useState, useEffect } from "react";
import { PDFViewer} from '@react-pdf/renderer';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import NonHazardousDesign from "../PdfDesigns/NonHazardousDesign";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {nonHazardousPdf} from '../../../../redux/actions';



function NonHazardous(props) {
  
  const [id, setId] = useState('');
  const params = useParams();

  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
   
   props.nonHazardousPdf(decryptValue);
   }
   },[])
   

  return (
    <div>
   
      <PDFViewer  style={{height:'100vh',width:'100vw'}}   >
   
            <NonHazardousDesign data={props.PdfNonHazardousResponse} />
     
      </PDFViewer>


  </div>
  )
}



const mapStateToProps = (state) => {
  const {
    loading,
    PdfNonHazardousResponse
  } = state.PdfHandler;

  return {
    loading: loading,
    PdfNonHazardousResponse:PdfNonHazardousResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    nonHazardousPdf:(id)=>dispatch(nonHazardousPdf(id))
  
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(NonHazardous)