import React,{useEffect,useState} from 'react'
import "../../../css/tabs.css";
import { NavLink,Link, Outlet,useNavigate, useFetcher } from "react-router-dom";
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useTranslation } from 'react-i18next'
import { countryList } from '../../utils/countryUtils';

import {
  countLoadingLists,
  showAddConsigneeModal,
  hideAddConsigneeModal,
  registerConsignee,
  registerconsignee,
} from "../../../redux/actions";

import {can} from '../../utils/roleUtils';

function LoadingListsTabs(props) {

  // const [consigneeData, setConsigneeData] = useState({consignee_name:'',address:'',country:'',phone:'',email:''});
  const [consigneeData,setconsigneeData] = useState({onId:'',company_name:'',contact_name:'',email:'',secondary_email:'',phone:'', secondary_phone:'',address:'',line_2:'',city:'',state:'',zip_code:'',country:null });

    const [backgroundTabs, setBackgroundTabs] = useState("nab-tabs");
    const [btnRightFloat, setBtnRightFloat] = useState("floating-btn-right");
    const [activeIndexPage, setActiveIndexPage] = useState(true);


const navigate = new useNavigate();



  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


const listenScrollEvent = () => {
    if (window.scrollY > 99) {
      return setBackgroundTabs("nav-tabs-fix");
    } else if (window.scrollY < 80) {
      return setBackgroundTabs("nab-tabs");
    }
  };

const listenScrollEventBtn = () => {
  if (window.scrollY > 99) {
    return setBtnRightFloat("floating-btn-move");
  } else if (window.scrollY < 80) {
    return setBtnRightFloat("floating-btn-right");
  }
};

useEffect(()=>{
setconsigneeData({onId:'',company_name:'',contact_name:'',email:'',secondary_email:'',phone:'', secondary_phone:'',address:'',line_2:'',city:'',state:'',zip_code:'',country:null });
},[props.consigneeRegistered]);

  useEffect(()=>{
    // navigate('loadingList')
 props.countLoadingLists();
  window.removeEventListener("scroll", listenScrollEvent);
  window.removeEventListener("scroll", listenScrollEventBtn);

  window.addEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEventBtn);

  return () =>{ 
    window.removeEventListener("scroll", listenScrollEvent);
    window.removeEventListener("scroll", listenScrollEventBtn);

}
  },[])


  const handleRegConsgineeClose = () => {
    props.hideAddConsigneeModal();
  };
  

  const onInputchange=(e)=>{

    setconsigneeData({ ...consigneeData, [e.target.name]:e.target.value });
  
  }

  const handleChangeOptionCompany = (selected) => {
    setconsigneeData({ ...consigneeData, country: selected });
  };
  

  const handleSubmit=(e)=>{
e.preventDefault()
props.registerconsignee(null,consigneeData)
}

  const {t}=useTranslation();

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
      baseUnit: 4,
    },
  });
  
  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  




  return (
    <div>

<div className="container py-4  main-container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
          {/* <div className="row mb-5">
            <div className="col-lg-8 text-white py-4 text-center mx-auto">
              <h1 className="display-4">Bootstrap 4 tabs</h1>
            </div>
          </div> */}

          <div className="p-5 mb-5">
          <div className={backgroundTabs}>
              <ul
                id="myTab2"
                className="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center"
              >
                {/* <li className="nav-item flex-sm-fill" onClick={()=>setActiveIndexPage(true)}>
                  <NavLink
                   to=""
                    aria-selected="true"
                    className={
                      activeIndexPage
                      ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"                        
                    }
                  >
                  Create Loading List
                  </NavLink>
                </li>
                 */}

                
                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="pendingMode/pending"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
             {t('key_pending_lists')}
                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='pending').map((l,i)=>{
return (
  <span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>

                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="readyMode/ready"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
             {t('key_lists_ready_for_loading')}
                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='ready').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>
              
                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="rejectMode/rejected"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                             {t('key_rejected_lists')}

                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='rejected').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>

                <li className="nav-item flex-sm-fill" >
                  <NavLink
                    to="loadedMode/loaded"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                             {t('key_loaded_lists')}


                {
props.countLoadLists?.groupList?.filter(item=>item.loading_catagory==='loaded').map((l,i)=>{
return (
<span className="totalCount" key={i} dir="ltr"> ({l.totalLists})</span>
)

})

                }
                  </NavLink>
                </li>


{/* 
                <li className="nav-item flex-sm-fill">
                  <NavLink
                    to="roles"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                Receivers
                  </NavLink>
                </li> */}

              </ul>
            </div>
            <div className="tab-content">
         
            { can(currentUserPermissions,'Add Consignee') ?<button
                className={btnRightFloat}
                title="Register new consignee to system"
             onClick={()=>{props.showAddConsigneeModal()}}
              >
                {t('key_consignee')}
              </button>:''
            }


              <Outlet />
            </div>
          </div>
        </div>




{/* ======================
Add consignee Modal
====================== */}
      <Modal show={props.openRegConsgineeModal} onHide={handleRegConsgineeClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleRegConsgineeClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={handleSubmit}>
          <Modal.Body 
           style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          >
            <div className="row">


              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={consigneeData?.company_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.consigneeRegisterErrors?.error?.company_name?true:false}
                  />

                </FloatingLabel>

                {props.consigneeRegisterErrors?.error?.company_name ? (
                  <div className="validation-error">
                    The consignee name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>





              <div className="col-md-12 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={consigneeData?.contact_name}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>




              <div className="col-md-6 mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_email"
                    value={consigneeData?.secondary_email}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData?.phone}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_phone"
                    value={consigneeData?.secondary_phone}
                    
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={consigneeData?.line_2}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={consigneeData?.state}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={consigneeData?.city}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={consigneeData?.zip_code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <Select
                  options={countryList}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={consigneeData.country}
                  defaultValue={consigneeData.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {/* {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''} */}
              </div>



            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>






 {/* ========================================================
Modal to confirm loading list
======================================================== */}
{/* 
<Modal show={props.openRegConsgineeModal} onHide={handleRegConsgineeClose} size="l">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            {t('key_add_consignee')}{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleRegConsgineeClose}></CloseButton>
        </Modal.Header>
        <form onSubmit={handleSubmit}>

        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >


<div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Consginee Name">
                  <Form.Control
                    type="text"
                    name="consignee_name"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.addConsigneeErrors?.error.consignee_name?<div className="validation-error">
                    Please, input a valid consignee name, the name has already been taken !
                  </div>:''}
              </div>

            </div>



            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Address">
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>


            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Country">
                  <Form.Control
                    type="text"
                    name="country"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Phone">
                  <Form.Control
                    type="text"
                    name="phone"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Email">
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={(e)=>{onInputChange(e)}}

                    placeholder=' '
                 
                    required
                  />
                </FloatingLabel>
                {props.autoRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

            </div>


        </Modal.Body>

        <Modal.Footer className="modal-footer">
        
          <button className="btn-submit" type="submit">
              {t('key_add')}
            </button>
        </Modal.Footer>

        </form>
      </Modal> */}




    </div>
  )
}


const mapStateToProps = (state) => {
  const {preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {
    autosDataByWarehouse,
    loading,
    openConfirmListModal,
    selectedAutosConfirm,
    countLoadLists,
    openRegConsgineeModal,
    consigneeRegistered,
    addConsigneeErrors,
    
  } = state.LoadingListHandler;

  return {
    loading: loading,
    SelectorsFetch: SelectorsFetch,
    autosDataByWarehouse: autosDataByWarehouse,
    openConfirmListModal: openConfirmListModal,
    selectedAutosConfirm: selectedAutosConfirm,
    countLoadLists:countLoadLists,
    openRegConsgineeModal:openRegConsgineeModal,
    consigneeRegistered:consigneeRegistered,
    addConsigneeErrors:addConsigneeErrors,
    preDataLoadResponse:preDataLoadResponse,

  };
};



const mapDispatchToProps = (dispatch) => {
  return {

    showAddConsigneeModal:()=>dispatch(showAddConsigneeModal()),
    hideAddConsigneeModal:()=>dispatch(hideAddConsigneeModal()),
    countLoadingLists:()=>dispatch(countLoadingLists()),
    // registerConsignee:(consigneeDetails)=>dispatch(registerConsignee(consigneeDetails)),
    registerconsignee:(usersId,data)=>dispatch(registerconsignee(usersId,data)),

  
  };
};




export default connect(mapStateToProps,mapDispatchToProps) (LoadingListsTabs)