import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet,Image } from '@react-pdf/renderer';
import {styles} from  "../../styles"
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});

function Header() {
  return (
    <View style={{ backgroundColor: '#e0920d', width: '100%', flexDirection: 'row', alignItems: 'center', padding: 8 }}>

        <View style={{ width: 150, height: 50, backgroundColor: 'white', marginRight: 10,borderRadius:'6px' }}>
        <Image source={require('../../../../assets/GA-01.png')} style={{ width: 150, height: 50 }} />
        </View>
        {/* Text Component */}
        <View>
          <Text style={[styles.headerText, { color: 'white' }]}>{process.env.REACT_APP_NAME}</Text>
          <Text style={[styles.infoText,{color:'white'}]}>{process.env.REACT_APP_COMPANY_ADDRESS}</Text>     
           <Text style={[styles.infoText,{color:'white'}]}>{process.env.REACT_APP_COMPANY_PHONE}</Text>


          {/* <Text style={[styles.headerText, { fontWeight: '800', fontSize: '14px', color: 'white' }]}>Invoice</Text> */}
        </View>

        <View style={{ width: 150, height: 50, marginLeft: 10 }}>
        <Text style={[styles.infoText,{color:'white'}]}>{process.env.REACT_APP_COMPANY_EMAIL}</Text>

        </View>

      </View>
  )
}

export default Header