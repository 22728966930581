import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing city settings model
// -------------------------------------------------------------------------
export const hidecityModal=()=>{
    return {
      type:'hidecityModal'
    }
    
    }
    export const showcityModal=()=>{
  
      return {
        type:'showcityModal'
      }
      
      }
  
  
      export const showEditcityModal=()=>{
        return {
          type:'showEditcityModal'
        }
        
        }
      
        export const hideEditcityModal=()=>{
          return {
            type:'hideEditcityModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all citys for settings
// ---------------------------------------------------------------------------------------------------------

export const getcitys = (currentPage) => {



    return (dispatch) => {
        dispatch(getcityStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/citys?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getcitySuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getcityFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getcityStarted = () => ({
      type: "getcity_started",
    });
    
    
    const getcitySuccess = (data,currentPage) => ({
      type: "getcitysSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getcityFailure = (error) => ({
      type: "getcity_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registercity = (name) => {
  
  return (dispatch) => {
    dispatch(registercityStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let cityData={
     city_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/cityRegister`, cityData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registercitySuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The city has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registercityFailure(err.response.data.errors));
     
      });
  };
};

const registercityStarted = () => ({
  type: "registercity_started",
});

const registercitySuccess = (data) => ({
  type: "registercity_success",
  payload: {
    ...data,
  },
});

const registercityFailure = (error) => ({
  type: "registercity_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the city edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const cityEdit = (id) => {

  return (dispatch) => {
    dispatch(cityEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editcity/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(cityEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(cityEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const cityEditDetailsStarted = () => ({
  type: "cityEditDetails_started",
});

const cityEditDetailsSuccess = (data) => ({
  type: "cityEditDetails_success",
  payload: {
    ...data,
  },
});

const cityEditDetailsFailure = (error) => ({
  type: "cityEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the city update 
// ---------------------------------------------------------------------------------------------------------

export const cityUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(cityUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        city_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updatecity/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(cityUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The city has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(cityUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const cityUpdateStarted = () => ({
    type: "cityUpdate_started",
  });
  
  
  const cityUpdateSuccess = (data,currentPage) => ({
    type: "cityUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const cityUpdateFailure = (error) => ({
    type: "cityUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// citys Deletion
// ---------------------------------------------------------------------------------------------------------

export const cityDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(cityDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deletecity/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(cityDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The city has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(cityDeletionFailure(err.message));
      });
  };
};

const cityDeletionStarted = () => ({
  type: "cityDeletion_started",
});

const cityDeletionSuccess = (data,currentPage) => ({
  type: "cityDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const cityDeletionFailure = (error) => ({
  type: "cityDeletion_failure",
  payload: {
    error,
  },
});
