import React, { useEffect } from 'react';
import '../../css/messages.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import { columnMapping } from '../utils/activityLogsUtils';
function PerSubjectLogBox(props) {

 
  if (props.details) {
    const jsonObject = JSON.parse(props.details);
    console.log(jsonObject);
  } else {
    console.error("JSON string is undefined or null.");
  }
  const jsonObject = JSON.parse(props?.details)||{};

  const changesArray = Object.keys(jsonObject?.attributes).map((key) => {
    const oldValue = jsonObject.old[key];
    const newValue = jsonObject.attributes[key];
    const displayKey = columnMapping[key] || key; // Use the display name if it's mapped, otherwise use the original key

    const isTimestamp = (value) => moment(value, moment.ISO_8601, true).isValid();

    return {
      key:displayKey,
      oldValue:  isTimestamp(oldValue) ? moment(oldValue)?.add(5,'hours')?.format('MMMM Do YYYY, h:mm a') : oldValue,
      newValue:  isTimestamp(newValue) ? moment(newValue)?.add(5,'hours')?.format('MMMM Do YYYY, h:mm a') : newValue,
    };
  });

  return (
    <>

    <div className={`container message-container   ${props.onShow?'hide':''}`}  >
{props.gap?
        <div className={`row py-${props.gap}`}></div>:''
}

        <div className="row ">
            <div className="col-lg-12">

            
                <blockquote className={`blockquote blockquote-custom card-custom-2 shadow  rounded ${props.mediaQueryClass}`} style={{width:props.width}}>
 {props.checkboxProp?<div className="checkboxCustom">

{props.checkboxProp}

</div>:''}
              {/* minutes ago date */}
              {props.postDateRelative? <div className="log-status">{props.status}</div>:''}

               {props.postDateRelative? <div className="blockquote-custom-relative-date text-black">{props.postDateRelative}</div>:''}

                  <div className="langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                  
                              
                                {/* normal date*/}

                   {props.postDate? <div className="blockquote-custom-date bg-light-custome shadow-sm">{props.postDate}</div>:''}
                  {props.children? <div className="blockquote-custom-menu bg-light-custome">{props.children}</div>:''}

                   {props.causer? <> 
                   <div className="pt-2"></div>
                   <div className='row'>
                    <div className='col-md-8'>
<div className='row'>
  <div className='col-md-12'>
  <p className="">Caused by: {props.causer}</p> 

  </div>
  <div className='col-md-12'>
  <p className="">Subject: {props.logSubject}  {props.year?',':''}  {props.year} {props.make} {props.model}  </p> 

  </div>
</div>
                       </div>
                    <div className='col-md-4'>
                
                    </div>

           
                 </div>
                 </> :''}
                { props.line?<hr/>:''}

            <div className='row'>
              <div className='col-md-2'>

<span  className='old-badge'>Details</span>
              </div>

             <div className='col-md-8'>
              {changesArray.map((change, index) => (
          <div key={index}>
            <span className='key-titles'>{change.key}</span>: <span>{change.oldValue?change.oldValue:"null"} </span>  <span>{'=>'}</span>    <span> {change.newValue?change.newValue:"null"}</span> 
          </div>
        ))}
              </div> 
            

            </div>
                    {/* <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <p>Someone famous in</p>
                    </footer> */}
                   {props.closeButton?props.closeButton:''}

                </div>
                </blockquote>

           

            </div>
            
        </div>
    </div>


    </>
  )
}

export default PerSubjectLogBox