import React from 'react'
import '../../../css/forbiddenAccess.css';
import noAccess from '../../assets/forbiddenAccess.png'

function Forbidden() {
  return (
    <>
<div className="main-content">
<div className="error-main">

<img
            src={noAccess}
            style={{ width: "350px", height: "350px", paddingRight: "10px" }}
          />
          <h1>Oops!</h1>

<p>You do not have permission to access the page you requested.</p>
</div>
</div>
    </>
  )
}

export default Forbidden