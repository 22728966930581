import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing Port settings model
// -------------------------------------------------------------------------
export const hidePortModal=()=>{
    return {
      type:'hidePortModal'
    }
    
    }
    export const showPortModal=()=>{
  
      return {
        type:'showPortModal'
      }
      
      }
  
  
      export const showEditPortModal=()=>{
        return {
          type:'showEditPortModal'
        }
        
        }
      
        export const hideEditPortModal=()=>{
          return {
            type:'hideEditPortModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all Ports for settings
// ---------------------------------------------------------------------------------------------------------

export const getPorts = (currentPage) => {



    return (dispatch) => {
        dispatch(getPortStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/Ports?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getPortSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getPortFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getPortStarted = () => ({
      type: "getPort_started",
    });
    
    
    const getPortSuccess = (data,currentPage) => ({
      type: "getPortsSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getPortFailure = (error) => ({
      type: "getPort_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerPort = (name,port_address) => {
  
  return (dispatch) => {
    dispatch(registerPortStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let PortData={
     port_name:name,
     port_address:port_address,

    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/PortRegister`, PortData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerPortSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Port has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerPortFailure(err.response.data.errors));
     
      });
  };
};

const registerPortStarted = () => ({
  type: "registerPort_started",
});

const registerPortSuccess = (data) => ({
  type: "registerPort_success",
  payload: {
    ...data,
  },
});

const registerPortFailure = (error) => ({
  type: "registerPort_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the Port edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const PortEdit = (id) => {

  return (dispatch) => {
    dispatch(PortEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editPort/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(PortEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(PortEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const PortEditDetailsStarted = () => ({
  type: "PortEditDetails_started",
});

const PortEditDetailsSuccess = (data) => ({
  type: "PortEditDetails_success",
  payload: {
    ...data,
  },
});

const PortEditDetailsFailure = (error) => ({
  type: "PortEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the Port update 
// ---------------------------------------------------------------------------------------------------------

export const PortUpdate = (data,currentPage) => {
    return (dispatch) => {
      dispatch(PortUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        port_name:data.name,
        port_address:data.port_address,

       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updatePort/`+data.onId,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(PortUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Port has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(PortUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const PortUpdateStarted = () => ({
    type: "PortUpdate_started",
  });
  
  
  const PortUpdateSuccess = (data,currentPage) => ({
    type: "PortUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const PortUpdateFailure = (error) => ({
    type: "PortUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// Ports Deletion
// ---------------------------------------------------------------------------------------------------------

export const PortDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(PortDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deletePort/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(PortDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Port has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(PortDeletionFailure(err.message));
      });
  };
};

const PortDeletionStarted = () => ({
  type: "PortDeletion_started",
});

const PortDeletionSuccess = (data,currentPage) => ({
  type: "PortDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const PortDeletionFailure = (error) => ({
  type: "PortDeletion_failure",
  payload: {
    error,
  },
});
