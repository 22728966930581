const InitialStates = {
    SelectorsFetch:null,
     loading: false,
   
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
    
        
     
         case "getSelectors_success":
        // console.log(action.payload)
         return { ...state, 'SelectorsFetch': action.payload, 'loading':false };
   
     
   
       default:
         return state;
     }
   };
   