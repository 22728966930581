import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet,Image } from '@react-pdf/renderer';
import {styles} from  "../styles"
import moment from 'moment';
import { calculateTotal } from "../../../utils/chargesUtils";
import Header from "../PdfDesigns/DesignElements/Header"
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});


const stylesInline = StyleSheet.create({
  table: { flexDirection: 'column', marginTop: 10 },
  headerRow: { flexDirection: 'row', borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5 },
  headerCell: {  fontFamily: 'Open Sans',  fontWeight: 800, padding: 3, fontSize: 9, width: 100 }, 
  dataRow: { flexDirection: 'row', borderBottomWidth: 1, borderColor: '#000', paddingVertical: 5 },
  dataCell: { padding: 3, fontSize: 9, width: 100 }, 

  headerCellSmaller: {  fontFamily: 'Open Sans',  fontWeight: 800, padding: 3, fontSize: 9, width: 50 },
  dataCellSmaller: { padding: 3, fontSize: 9, width: 50 }, 

  headerCellLarger: {  fontFamily: 'Open Sans',  fontWeight: 800, padding: 3, fontSize: 9, width: 180 },
  dataCellLarger: { padding: 3, fontSize: 8, width: 180 }, 

});


function ContainerInvoiceDesign(props) {

  const totalVehicleCharges=calculateTotal(props.data?.charges,'total')
  const totalVehicleCost=calculateTotal(props.data?.charges,'cost')
  const totalContainerCost=calculateTotal(props.data?.containerCharges,'cost')
  const totalContainerCharges=calculateTotal(props.data?.containerCharges,'amount')


  return (
    <Document title="Invoice"  >
      
      <Page size="A4">
        <View style={styles.container}>
        <Image src={require('../../../assets/normLogo.png')} style={styles.backgroundImage} />

      <Header/>


<View style={[styles.row,styles.gap]}>

  <View style={{flexDirection:'row'}}>
  <Text style={[styles.column50,styles.textInvoice]}>Invoice No : {props.data?.containerInfo?.cont_invoice_no}</Text>
  <Text style={[styles.infoText, styles.column50]}>Date : {moment(props.data?.containerInfo?.cont_invoice_date).format('DD - MMMM - YYYY')}</Text>

  </View>

</View>



<View style={[styles.row]}>
<View style={[styles.column50]}>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Billing Name : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.company_name}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Billing Address : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.address}, {props.data?.containerInfo?.city}, {props.data?.containerInfo?.state}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Billing Phone : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.contact}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Billing Email : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.email}</Text>
    </View>
  
  </View>

  <View style={[styles.column50]}>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Shipping Name : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.consignee_name}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Shipping Address : </Text>      <Text style={styles.textDetail}> {props.data?.containerInfo?.consignee_address}, {props.data?.containerInfo?.consignee_city}, {props.data?.containerInfo?.consignee_state}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Shipping Phone : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.consignee_phone}</Text>
    </View>

    <View style={{flexDirection:'row'}}>
      <Text style={styles.textBoldAndCapatiliseLeft}>Shipping Email : </Text>      <Text style={styles.textDetail}>{props.data?.containerInfo?.consignee_email}</Text>
    </View>
  
  </View>

</View>
<View style={styles.gap}></View>

<View style={styles.solidLine}></View>
<View style={styles.gap}></View>
<View>
<Text style={[styles.headerText,{backgroundColor:'#e0920d',color:'white',padding:2}]}>
   Container Charges
  </Text>
</View>
<View style={stylesInline.table}>
      <View style={stylesInline.headerRow}>
        <Text style={stylesInline.headerCellSmaller}>Code</Text>
        <Text style={stylesInline.headerCellLarger}>Charge Name</Text>
        <Text style={stylesInline.headerCell}>Description</Text>
        <Text style={stylesInline.headerCell}>Amount</Text>
        <Text style={stylesInline.headerCell}>Cost</Text>
        <Text style={stylesInline.headerCell}>Profit</Text>
      </View>
      


      {props.data?.containerCharges && props.data.containerCharges.length !== 0 ?
    props.data.containerCharges.map((list, index) => {
        return (
          <View style={stylesInline.dataRow}>
          <Text style={stylesInline.dataCellSmaller}>{list.code}</Text>
          <Text style={stylesInline.dataCellLarger}>{list.charge_name}</Text>
          <Text style={stylesInline.dataCell}>{list.description}</Text>
          <Text style={stylesInline.dataCell}>{list.amount}</Text>
          <Text style={stylesInline.dataCell}>{list.cost}</Text>
          <Text style={stylesInline.dataCell}>{list.amount - list.cost}</Text>
  
  
        </View>
        );
    }) :
    <View>
        <Text>No container charges found</Text>
    </View>
}



  
    </View>



<View style={styles.gap}></View>

<View style={styles.gap}></View>
<View>
  <Text style={[styles.headerText,{backgroundColor:'#e0920d',color:'white',padding:2}]}>
  Auto Charges
  </Text>
</View>
<View style={stylesInline.table}>
      <View style={stylesInline.headerRow}>
        <Text style={stylesInline.headerCellLarger}>Vehicle</Text>
        <Text style={stylesInline.headerCellLarger}>Location/Description</Text>
        {props.data?.headers?.map((header, index) => (
        <Text key={index} style={stylesInline.headerCell}>{header}</Text>
      ))}
        


      </View>

      {props.data?.charges.map((charge, chargeIndex) => (
      charge.vin?
      <View style={stylesInline.dataRow} key={chargeIndex}>
        <View style={[stylesInline.dataCellLarger,{ flexDirection: 'column' }]}>
    <Text>{charge.year} {charge.make} {charge.model}</Text>
    <Text style={{marginTop:3, fontFamily: 'Open Sans',  fontWeight: 700}}>{charge.vin}</Text>
  </View>
        <Text style={[stylesInline.dataCellLarger]}>{charge.description}</Text>
        {props.data?.headers?.map((header, index) => (
          <Text style={stylesInline.dataCell} key={index}>
            {charge.charge_name === header ? charge.total : 0}
          </Text>
        ))}
      </View>:null
    ))}

    

    
  
    </View>

    <View style={styles.gap}></View>
    <View style={styles.row}>
      <View style={styles.column50}></View>
      <View style={styles.column50}>

      <View>
    <View style={stylesInline.table}>
      <View style={stylesInline.headerRow}>
        <Text style={stylesInline.headerCellLarger}>Total Charges</Text>
        <Text style={stylesInline.headerCellLarger}>Total Cost</Text>
        <Text style={stylesInline.headerCellLarger}>Total Profit</Text>
        


      </View>
      <View style={stylesInline.dataRow}>
          <Text style={stylesInline.headerCellLarger}>  
          $ {totalVehicleCharges + totalContainerCharges || 0}
         </Text>
        <Text style={stylesInline.headerCellLarger}>
        $ {totalVehicleCost + totalContainerCost || 0}
        </Text>
        <Text style={stylesInline.headerCellLarger}>
        $ {
    totalVehicleCharges + (totalContainerCharges || 0) - totalVehicleCost + (totalContainerCost|| 0)
    }
        </Text>
      </View>
  
    </View>

    </View>


      </View>


    </View>


       </View>
      </Page>
    </Document>
  );
}

export default ContainerInvoiceDesign;
