const InitialStates = {
    preDataLoadResponse:null,
  
     loading: false,

     rolesListSuccessResponse:null,
     roleRegisterSuccess:null,
     roleRegisterErrors:null,
     showModal:false,
     name:'',
     email:'',
     contact:'',
     currentPage:1,
     showPermissions:null,
     roleDeletedResponse:null,
     showEditModal:false,
   
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
       
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

       case 'showRoleModal':
        return { ...state, 'showModal':true };

        case 'hideRoleModal':
          return { ...state, 'showModal':false };
         
        case 'showEditModal':
          return { ...state, 'showEditModal':true };
         case 'hideEditModal':
            return { ...state, 'showEditModal':false, 'showPermissions':null};

       case "preData_started":
      
         return { ...state, 'loading': true,  };
   
         case "preData_failure":
           return { ...state, 'loading': false,};
     
         case "role_fetch_success":
        
         return { ...state, 'rolesListSuccessResponse': action.payload, 'loading':false };
   
         case "registerRole_success":

          return { ...state, 'roleRegisterSuccess': action.payload, 'loading':false, 'showModal':false, 'name':'',roleRegisterErrors:null};
         
          case "registerRole_failure":
      
         return { ...state, 'roleRegisterErrors': action.payload, 'loading':false};
          
         case "showRolePermissions_success":
          return { ...state, 'showPermissions': action.payload, 'loading':false};
         

          case "roleUpdate_success":
            return { ...state, 'showEditModal':false, 'showPermissions':null, 'currentPage':action.payload.currentPage };
           

            case "roleUpdate_failure":

              return { ...state, 'roleUpdateErrors': action.payload, 'loading':false, };
             
  



          case "roleDeletion_success":
          
          return { ...state, 'roleDeletedResponse': action.payload, 'currentPage':action.payload.currentPage};

       default:
         return state;
     }
   };
   