const InitialStates = {
    cityehousesList:null,
  
    currentPage: 1,
   
    showcityModal:false,
    showEditcityModal:false,
    loading:false,
    name:'',
  citysList:null,
    cityRegisterErrors:null,
    cityUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showcityModal":
      return { ...state, 'showWarModal':true };

      case "hidecityModal":
        return { ...state, 'showWarModal':false, 'cityRegisterErrors':'', 'name':'' };
  

        case "showEditcityModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditcityModal":
            return { ...state, 'showEditWarModal':false, 'cityUpdateErrors':'','name':'' };
// =======================================
// for loading purpose
// =======================================

            case "getcity_started":
              return {...state, 'loading':true}
    case "registercity_started":
              return {...state, 'loading':true}

              // case "cityEditDetails_started":
              //   return {...state, 'loading':true}

                // case "cityUpdate_started":
                //   return {...state, 'loading':true}

// ===============Endshere==================




       case "getcitysSettings_success":

         return { ...state, 'citysList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registercity_success":
    return { ...state, 'registercitySuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "cityEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "cityUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "cityDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registercity_failure":
    return { ...state, 'cityRegisterErrors': action.payload,  'loading':false };

case "cityUpdate_failure":
  return { ...state, 'cityUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   