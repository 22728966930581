import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import "../../../../css/pagination.css";
import Swal from "sweetalert2";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { countryList } from "../../../utils/countryUtils";

import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker,
  addDetails,
  vendorEdit,
  toggleCompanyModal,
  toggleCompanyEditModal
} from "../../../../redux/actions";

function VendorInformation(props) {

  const [id, setId] = useState('');
  const [companyDetails, setCompanyDetails]=useState({
    id:"",
    company_name:"",
    contact_name:"",
    email:"",
    secondary_email:"",
    other_email:"",
    contact:"",
    secondary_contact:"",
    other_contact:"",
    address:"",
    line_2:"",
    city:"",
    state:"",
    zip_code:"",
    country:"",
    tax_id:"",
    iaai:"",
    corporate_buyer:"",

})


const [countryFieldError, setCountryFieldError] = useState(null)

  const params = useParams();
  const {t}=useTranslation();


  let countryOptions=countryList;


useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
 props.vendorEdit(decryptValue);

 }
 },[props.addDetailsSuccess])

// useEffect(()=>{
//   if(props.addDetailsSuccess){
//    props.companyEdit(id);
//   }
// },[
//   props.addDetailsSuccess
// ])
 

 useEffect(()=>{
  let data = props?.companyEditInfo;
  setCompanyDetails({
      id:data?.id,
      company_name:data?.company_name,
      contact_name:data?.contact_name,
      email:data?.email,
      secondary_email:data?.secondary_email,
      other_email:data?.other_email,
      contact:data?.contact,
      secondary_contact:data?.secondary_contact,
      other_contact:data?.other_contact,
      address:data?.address,
      line_2:data?.line_2,
      city:data?.city,
      state:data?.state,
      zip_code:data?.zip_code,
      country:{value:data?.country,label:data?.country?.replace(/\b\w/g, (char) => char.toUpperCase())},
      tax_id:data?.tax_id,
      iaai:data?.iaai,
      corporate_buyer:data?.corporate_buyer,
    })
  },[props?.vendorEditResponse])
  
  






 const theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 55,
    baseUnit: 4,
  },
});

const menuPortal = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};


const handleClose = () => {
  // setUserUpdateData({...userUpdateData,contact:''})
  props.togglevendorEditModal()
};

 const handleChangeOptionCompany = (selected) => {
  setCompanyDetails({ ...companyDetails, country: selected });
};


const onInputchangeCompanyDetails = (e) => {
  setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
};




const onAddCompanyDetails = (e)=>{
  e.preventDefault();
  if(companyDetails.country !== null){
  props.addDetails(companyDetails);
  setCountryFieldError(null);
  
  }else{
  setCountryFieldError("The country field is required");
  }
  }

  
  return (
    <div>
        <div className='card card-custom'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>Vendor Details</div>
</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Vendor Name</div>
  <div className='col-md-8 text-uppercase'> {props.vendorEditResponse?.vendor_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Vendor Contact Name</div>
  <div className='col-md-8'>{props.vendorEditResponse?.vendor_contact_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Vendor Type</div>
  <div className='col-md-8 text-uppercase'> {props.vendorEditResponse?.vendor_type}  </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Vendor Suite</div>
  <div className='col-md-8 text-uppercase'> {props.vendorEditResponse?.vendor_suite}  </div>

</div>

          </div>
        </div>



        <div className='card card-custom mt-3'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-10'>More Details</div>
<div className='col-md-2'>


{/* <div className="company-button">
<button className="buttonCompany" onClick={()=>{
setTimeout(() => {
  props.toggleCompanyEditModal()

}, 1000);
  
  }} title="add user's company details">Edit Details</button>
</div> */}

</div>

</div>


<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Vendor Email</div>
  <div className='col-md-8 text-uppercase'> {props.vendorEditResponse?.vendor_email} </div>

</div>



<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>Primary Contact</div>
  <div className='col-md-4 text-uppercase'>{props.vendorEditResponse?.vendor_phone}</div>
  <div className='col-md-2 text-uppercase'>Secondary Contact</div>
  <div className='col-md-4 text-uppercase'>{props.vendorEditResponse?.vendor_secondary_phone}</div>


</div>



<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Address</div>
  <div className='col-md-8 text-uppercase'>
    <div>{props.vendorEditResponse?.vendor_address} </div>
    <div>{props.vendorEditResponse?.vendor_zip_code} , {props.vendorEditResponse?.vendor_state} , {props.vendorEditResponse?.vendor_country} </div>
   </div>

</div>




          </div>
        </div>




   
              
    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    currentPage,
    vendorEditResponse,
    rolePicker,
 
  } = state.VendorsHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    currentPage: currentPage,
    preDataLoadResponse: preDataLoadResponse,
    vendorEditResponse:vendorEditResponse?.data,
  };
};






const mapDispatchToProps = (dispatch) => {
  return {
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    rolePicker:()=>dispatch(rolePicker()),
    vendorEdit:(id)=>dispatch(vendorEdit(id)),
  
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(VendorInformation) 