import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Logo from "../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Loader from '../../elements/Loader';

import {AgreementUpdate,hideAgreementModal,showAgreementModal,showEditAgreementModal,hideEditAgreementModal,getAgreements,AgreementEdit,inputHandler,registerAgreement, AgreementDelete } from "../../../redux/actions";

function Agreements(props) {


// const [addAgreementData,setAddAgreementData] = useState({name:''});
const [AgreementData,setAgreementData] = useState({title:'',onId:'',body:''});
const [checkedState, setCheckedState] = useState([]);
const checkBoxRef = useRef([]);


  useEffect(() => {
    props.getAgreements(props.currentPage);
    setAgreementData({title:'',onId:'',body:''})
  }, [props.registerAgreementSuccess,props.updateSuccess,props.deleteSuccess]);


useEffect(()=>{
setAgreementData({...AgreementData,
    'title':props.editDetails?.data?.title||'',
    'body':props.editDetails?.data?.body||''

})
},[props.editDetails])



  //   useEffect(()=>{

  //     setAgreementNameEdit({name:props.showPermissions?.Agreement.name?props.showPermissions?.Agreement.name:''});
    
     
  
  
  // },[props.showPermissions] )



  const onInputchange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    // setAgreementData({ ...AgreementData, [e.target.name]: e.target.value });
  };





  const onInputUpdatechange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setAgreementData({...AgreementData, [e.target.name]:e.target.value  });
  };

// ============================================
//  to rigister Agreement modal
// ============================================
  const handleClose = () => {
    // setAgreementData ({title:'',onId:'',body:''})

    props.hideAgreementModal()

  };
  const handleShow = () => {
    setAgreementData ({title:'',onId:'',body:''})

    props.showAgreementModal()
  
  };

// ============================================
// to register edit modal open and close
// ============================================

  const handleEditClose = () => {
    props.hideEditAgreementModal();

  };

  const handleEditShow=()=>{
    props.showEditAgreementModal()

  }




const deleteAgreement = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to delete this Agreement",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.AgreementDelete(id, props.currentPage);
    }
  });
};





const submitAgreement = (e) => {
  e.preventDefault();
  let title=AgreementData.title;

  if(title){
    props.registerAgreement(AgreementData)
  }
}


const UpdateAgreement = (e) => {
  e.preventDefault();

  let currentPage=props.current_page;
  props.AgreementUpdate(AgreementData,currentPage);
  
}




  return (
    <>
      

      <div className="container-Layer">
      <Link to="/home/settings" className="back-button-outer">

    <center> <i className="fa fa-arrow-left" style={{color:'white'}}></i> </center> 
      </Link>

      <div className="row">
      <div className="col-md-7">   
      <div className="addButton"><a onClick={handleShow} className="btn btn-primary">Add Agreement</a></div>
      </div>
      <div className="col-md-5">

      </div>

     
        </div>

        <div className="row  pt-4">
          {props.loading ? 
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"0%"}
              top={"30%"}
            />
          :
            props.AgreementsList?.data?.data.map((list, index) => {
              return (
                <div
                  className="col-xl-12 col-sm-12 col-md-12 mb-5 card-outer"
                  key={index}
                >
                
            <div
              className="shadow-sm py-2 px-4 settings-list-card "
              style={{ borderLeft: "5px solid #fcba03" }}
            >
              <h6 className="mt-2 text-uppercase">{list.title}</h6>
              <hr/>
              <p className="paragraph-text text-justify" style={{textAlign:'justify',fontSize:'14px'}}>
                {list.body}
              </p>
              {/* <span className="small text-uppercase text-muted">Admin</span> */}




              <ul className="social mb-0 list-inline mt-3">
                
                <li className="list-inline-item settings-links">
                  <a  onClick={
                    ()=>{
                      setAgreementData({...AgreementData,'onId':list.id})
                      props.AgreementEdit(list.id)
                               setTimeout(()=>{
                                handleEditShow()
                               },190)    

                    }} className="icons-link">
                    <i className="fa fa-edit edit"></i>
                  </a>
                </li>
                <li className="list-inline-item settings-links">
                  <a  onClick={()=>deleteAgreement(list.id)} className="icons-link">
                    <i className="fa fa-trash trash"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
              );
            })}

          
            {props.AgreementsList && (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.AgreementsList?.data?.current_page}
              itemsCountPerPage={props.AgreementsList?.data?.per_page}
              totalItemsCount={props.AgreementsList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={(pageNumber) => props.getAgreements(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      )}



        </div>
      </div>


{/* ======================
Add Agreement Modal
====================== */}
      <Modal show={props.showWarModal} onHide={handleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New Agreement </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitAgreement}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Agreement Title">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="title"
                    value={AgreementData.title}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.AgreementRegisterErrors?.error?.title?true:false}
                  />

                </FloatingLabel>

                {props.AgreementRegisterErrors?.error?.title ? (
                  <div className="validation-error">
                    The Agreement name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>



              <div className="col-md-12">
              <div className="form-group">
        <label htmlFor="body">Statements:</label>
        <textarea className="form-control" name="body" onChange={onInputUpdatechange} style={{height:'300px'}}></textarea>
      </div>

              </div>
            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>











{/* ======================
Edit Agreement Modal
====================== */}
<Modal show={props.showEditWarModal} onHide={handleEditClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Agreement </Modal.Title>
          <CloseButton variant={"white"} onClick={handleEditClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={UpdateAgreement}>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel label="Agreement title">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="title"
                    value={AgreementData.title||''}
                    required
                    onChange={(e) => onInputUpdatechange(e)}
                    isInvalid={props.AgreementUpdateErrors?.error?.title ?true:false}
                  />

                </FloatingLabel>

                {props.AgreementUpdateErrors?.error?.Agreement_name ? (
                  <div className="validation-error">
                    The Agreement name already exists, you can't update it with this Agreement name !
                  </div>
                ) : (
                  ""
                )}

              </div>



              <div className="col-md-12">
              <div className="form-group">
        <label htmlFor="body">Statements:</label>
        <textarea className="form-control" name="body"  onChange={onInputUpdatechange} style={{height:'300px'}}>
            {AgreementData?.body}
        </textarea>
      </div>

              </div>


            </div>
    
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </>
  );
}

const mapStateToProps = (state) => {
  const {loading,AgreementsList,showWarModal,showEditWarModal,name,registerAgreementSuccess,AgreementRegisterErrors,currentPage,editDetails,updateSuccess,deleteSuccess,AgreementUpdateErrors} = state.SettingsAgreementHandler;

  return {
    loading: loading,
    name:name,
    AgreementRegisterErrors:AgreementRegisterErrors,
    currentPage:currentPage,
    AgreementsList:AgreementsList,
    showWarModal:showWarModal,
    showEditWarModal:showEditWarModal,
    registerAgreementSuccess:registerAgreementSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,
    AgreementUpdateErrors:AgreementUpdateErrors,
    currentPage:currentPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {


     inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
      getAgreements:(currentPage)=>dispatch(getAgreements(currentPage)),
      showAgreementModal:()=>dispatch(showAgreementModal()),
      hideAgreementModal:()=>dispatch(hideAgreementModal()),
      showEditAgreementModal:()=>dispatch(showEditAgreementModal()),
      hideEditAgreementModal:()=>dispatch(hideEditAgreementModal()),
      registerAgreement:(data)=>dispatch(registerAgreement(data)),
      AgreementEdit:(id)=>dispatch(AgreementEdit(id)),
      AgreementUpdate:(data,currentPage)=>dispatch(AgreementUpdate(data,currentPage)),
      AgreementDelete:(id,currentPage)=>dispatch(AgreementDelete(id,currentPage)),
  };


};

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);



// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================