
import {  StyleSheet } from '@react-pdf/renderer';


export const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    top: '35%',
    zIndex:1,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.1, // Adjust the opacity as needed
  },
    container: {
      paddingTop: 25,
      paddingRight: 20,
      paddingLeft: 20,
  zIndex:2
  
    },
    headerText: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 12,
      marginBottom: 7,
      color: 'black',
      textAlign: 'center',
    },
  
    headerSecondary: {
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: 10,
      marginBottom: 1,
      color: 'black',
      textAlign: 'center',
    },
  
    infoText: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: '700',
      marginBottom: 2,
    },
    table: {
      display: 'table',
      width: 'auto',
      marginBottom: 10,
      padding: 12,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,
    },
    tableHeader: {
      width: '16%',
      borderRightWidth: 'none',
      borderBottomWidth: 'none',
      borderRightColor: '#000',
      padding: 5,
      fontSize: 11,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      textDecoration: 'underline',
      textAlign:'center',

    },
    tableHeaderVin: {
      width: '30%',
      borderRightWidth: 'none',
      borderBottomWidth: 'none',
      borderRightColor: '#000',
      padding: 5,
      fontSize: 11,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      textDecoration: 'underline',
      textAlign:'center',

    },
    tableCell: {
      width: '16%',
      fontSize: 10,
      lineHeight: 1.2,
      paddingLeft: 3,
      paddingRight: 3,
      borderBottomWidth: 0.5,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      textAlign:'center',
      borderBottomColor: 'grey',


    },
    tableCellVin: {
      width: '30%',
      fontSize: 10,
      lineHeight: 1.2,
      paddingLeft: 3,
      paddingRight: 3,
      borderBottomWidth: 0.5,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      textAlign:'center',
      borderBottomColor: 'grey',

    },
    tableCellVin2:{
      width: '30%',
      fontSize: 10,
      lineHeight: 1.2,
      paddingLeft: 3,
      paddingRight: 3,
      borderBottomWidth: 0.5,
      borderBottomColor: 'grey',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      textAlign:'center'
    },
    
    solidLine: {
      borderBottomWidth: 1.5,
      borderBottomColor: '#000',
      marginTop: 3,
    },
    heading: {
      fontSize: 9,
      textAlign: 'left',
      paddingLeft: 12,
      marginTop: -10,
      fontFamily: 'Open Sans',
      fontWeight: '700',
    },
    normaltext: {
      fontSize: 9,
      paddingLeft: 9,
    },
    normaltext: {
      fontSize: 10,
      paddingLeft: 9,
    },
    row: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    column: {
      width: '20%',
      boxSizing: 'border-box',
      padding: 4,
    },
    column30: {
      width: '33.3%',
      boxSizing: 'border-box',
      padding: 4,
    },
    columnDouble: {
      width: '40%',
      boxSizing: 'border-box',
      padding: 4,
    },
    column50: {
      width: '50%',
      boxSizing: 'border-box',
      padding: 4,
    },
    columnTripple: {
      width: '60%',
      boxSizing: 'border-box',
      padding: 4,
    },
    columnQuadra: {
      width: '80%',
      boxSizing: 'border-box',
      padding: 4,
    },
    columnFull: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 4,
    },
    gap: {
      padding: 6,
    },
    boldtext:{
      fontFamily: 'Open Sans',
      fontWeight: '700',
  fontSize:10,
  textTransform:'uppercase',
    },
    contactText:{
  color:'blue',
    },
    underLineInput:{
borderBottomWidth:1,
    },
    normalTextCapatilise:{
      fontSize:8,
      textTransform:'uppercase',
      padding:2
    },
    belowLineDoted:{
      
borderBottomWidth:1,
borderBottomColor: 'black',
borderBottomStyle: 'dashed',
    },

    textBoldAndCapatilise:{
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize:9,
      textAlign:'center',
      textTransform:'uppercase',


    },
    textBoldAndCapatiliseLeft:{
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize:9,
      textAlign:'left',
      textTransform:'uppercase',


    },
    normalTextCapatiliseLargerSize:{
      fontSize:10,
      textTransform:'uppercase',
      padding:2
    },

    dashedBorder:{
      border:1,
      border: 'black',
      borderStyle: 'dashed',
    },

    textDetail:{
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize:9,
      textAlign:'left',
    },


    textInvoice: {
      fontSize: 10,
      fontFamily: 'Open Sans',
      fontWeight: '700',
      marginBottom: 2,
    },

  });