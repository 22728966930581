 export const countryList=[
        {"value": "afghanistan", "label": "Afghanistan"},
                  {"value": "aland islands", "label": "Aland Islands"},
                  {"value": "albania", "label": "Albania"},
                  {"value": "algeria", "label": "Algeria"},
                  {"value": "american samoa", "label": "American Samoa"},
                  {"value": "andorra", "label": "Andorra"},
                  {"value": "angola", "label": "Angola"},
                  {"value": "anguilla", "label": "Anguilla"},
                  {"value": "antarctica", "label": "Antarctica"},
                  {"value": "antigua and barbuda", "label": "Antigua and Barbuda"},
                  {"value": "argentina", "label": "Argentina"},
                  {"value": "armenia", "label": "Armenia"},
                  {"value": "aruba", "label": "Aruba"},
                  {"value": "australia", "label": "Australia"},
                  {"value": "austria", "label": "Austria"},
                  {"value": "azerbaijan", "label": "Azerbaijan"},
                  {"value": "bahamas", "label": "Bahamas"},
                  {"value": "bahrain", "label": "Bahrain"},
                  {"value": "bangladesh", "label": "Bangladesh"},
                  {"value": "barbados", "label": "Barbados"},
                  {"value": "belarus", "label": "Belarus"},
                  {"value": "belgium", "label": "Belgium"},
                  {"value": "belize", "label": "Belize"},
                  {"value": "benin", "label": "Benin"},
                  {"value": "bermuda", "label": "Bermuda"},
                  {"value": "bhutan", "label": "Bhutan"},
                  {"value": "bolivia", "label": "Bolivia"},
                  {"value": "bonaire", "label": "Bonaire"},
                  {"value": "bosnia and herzegovina", "label": "Bosnia and Herzegovina"},
                  {"value": "botswana", "label": "Botswana"},
                  {"value": "bouvet island", "label": "Bouvet Island"},
                  {"value": "brazil", "label": "Brazil"},
                  {"value": "brunei darussalam", "label": "Brunei Darussalam"},
                  {"value": "bulgaria", "label": "Bulgaria"},
                  {"value": "burkina faso", "label": "Burkina Faso"},
                  {"value": "burundi", "label": "Burundi"},
                  {"value": "cabo verde", "label": "Cabo Verde"},
                  {"value": "cambodia", "label": "Cambodia"},
                  {"value": "cameroon", "label": "Cameroon"},
                  {"value": "canada", "label": "Canada"},
                  {"value": "cayman islands", "label": "Cayman Islands"},
                  {"value": "central african republic", "label": "Central African Republic"},
                  {"value": "chad", "label": "Chad"},
                  {"value": "chile", "label": "Chile"},
                  {"value": "china", "label": "China"},
                  {"value": "christmas island", "label": "Christmas Island"},
                  {"value": "cocos islands", "label": "Cocos Islands"},
                  {"value": "colombia", "label": "Colombia"},
                  {"value": "comoros", "label": "Comoros"},
                  {"value": "congo", "label": "Congo"},
                  {"value": "congo", "label": "Congo"},
                  {"value": "cook islands", "label": "Cook Islands"},
                  {"value": "costa rica", "label": "Costa Rica"},
                  {"value": "cote d'ivoire", "label": "Cote d'Ivoire"},
                  {"value": "croatia", "label": "Croatia"},
                  {"value": "cuba", "label": "Cuba"},
                  {"value": "curacao", "label": "Curacao"},
                  {"value": "cyprus", "label": "Cyprus"},
                  {"value": "czech republic", "label": "Czech Republic"},
                  {"value": "denmark", "label": "Denmark"},
                  {"value": "djibouti", "label": "Djibouti"},
                  {"value": "dominica", "label": "Dominica"},
                  {"value": "dominican republic", "label": "Dominican Republic"},
                  {"value": "ecuador", "label": "Ecuador"},
                  {"value": "egypt", "label": "Egypt"},
                  {"value": "el salvador", "label": "El Salvador"},
                  {"value": "equatorial guinea", "label": "Equatorial Guinea"},
                  {"value": "eritrea", "label": "Eritrea"},
                  {"value": "estonia", "label": "Estonia"},
                  {"value": "ethiopia", "label": "Ethiopia"},
                  {"value": "falkland islands", "label": "Falkland Islands"},
                  {"value": "faroe islands", "label": "Faroe Islands"},
                  {"value": "fiji", "label": "Fiji"},
                  {"value": "finland", "label": "Finland"},
                  {"value": "france", "label": "France"},
                  {"value": "french guiana", "label": "French Guiana"},
                  {"value": "french polynesia", "label": "French Polynesia"},
                  {"value": "french southern territories", "label": "French Southern Territories"},
                  {"value": "gabon", "label": "Gabon"},
                  {"value": "gambia", "label": "Gambia"},
                  {"value": "georgia", "label": "Georgia"},
                  {"value": "germany", "label": "Germany"},
                  {"value": "ghana", "label": "Ghana"},
                  {"value": "gibraltar", "label": "Gibraltar"},
                  {"value": "greece", "label": "Greece"},
                  {"value": "greenland", "label": "Greenland"},
                  {"value": "grenada", "label": "Grenada"},
                  {"value": "guadeloupe", "label": "Guadeloupe"},
                  {"value": "guam", "label": "Guam"},
                  {"value": "guatemala", "label": "Guatemala"},
                  {"value": "guernsey", "label": "Guernsey"},
                  {"value": "guinea", "label": "Guinea"},
                  {"value": "guinea-bissau", "label": "Guinea-Bissau"},
                  {"value": "guyana", "label": "Guyana"},
                  {"value": "haiti", "label": "Haiti"},
                  {"value": "heard and mcdonald islands", "label": "Heard and McDonald Islands"},
                  {"value": "holy see", "label": "Holy See"},
                  {"value": "honduras", "label": "Honduras"},
                  {"value": "hong kong", "label": "Hong Kong"},
                  {"value": "hungary", "label": "Hungary"},
                  {"value": "iceland", "label": "Iceland"},
                  {"value": "india", "label": "India"},
                  {"value": "indonesia", "label": "Indonesia"},
                  {"value": "iran", "label": "Iran"},
                  {"value": "iraq", "label": "Iraq"},
                  {"value": "ireland", "label": "Ireland"},
                  {"value": "isle of man", "label": "Isle of Man"},
                  {"value": "israel", "label": "Israel"},
                  {"value": "italy", "label": "Italy"},
                  {"value": "jamaica", "label": "Jamaica"},
                  {"value": "japan", "label": "Japan"},
                  {"value": "jersey", "label": "Jersey"},
                  {"value": "jordan", "label": "Jordan"},
                  {"value": "kazakhstan", "label": "Kazakhstan"},
                  {"value": "kenya", "label": "Kenya"},
                  {"value": "kiribati", "label": "Kiribati"},
                  {"value": "korea", "label": "Korea"},
                  {"value": "korea", "label": "Korea"},
                  {"value": "kuwait", "label": "Kuwait"},
                  {"value": "kyrgyzstan", "label": "Kyrgyzstan"},
                  {"value": "lao peoples", "label": "Lao People's"},
                  {"value": "latvia", "label": "Latvia"},
                  {"value": "lebanon", "label": "Lebanon"},
                  {"value": "lesotho", "label": "Lesotho"},
                  {"value": "liberia", "label": "Liberia"},
                  {"value": "libya", "label": "Libya"},
                  {"value": "liechtenstein", "label": "Liechtenstein"},
                  {"value": "lithuania", "label": "Lithuania"},
                  {"value": "luxembourg", "label": "Luxembourg"},
                  {"value": "macao", "label": "Macao"},
                  {"value": "macedonia", "label": "Macedonia"},
                  {"value": "madagascar", "label": "Madagascar"},
                  {"value": "malawi", "label": "Malawi"},
                  {"value": "malaysia", "label": "Malaysia"},
                  {"value": "maldives", "label": "Maldives"},
                  {"value": "mali", "label": "Mali"},
                  {"value": "malta", "label": "Malta"},
                  {"value": "marshall islands", "label": "Marshall Islands"},
                  {"value": "martinique", "label": "Martinique"},
                  {"value": "mauritania", "label": "Mauritania"},
                  {"value": "mauritius", "label": "Mauritius"},
                  {"value": "mayotte", "label": "Mayotte"},
                  {"value": "mexico", "label": "Mexico"},
                  {"value": "micronesia", "label": "Micronesia"},
                  {"value": "moldova", "label": "Moldova"},
                  {"value": "monaco", "label": "Monaco"},
                  {"value": "mongolia", "label": "Mongolia"},
                  {"value": "montenegro", "label": "Montenegro"},
                  {"value": "montserrat", "label": "Montserrat"},
                  {"value": "morocco", "label": "Morocco"},
                  {"value": "mozambique", "label": "Mozambique"},
                  {"value": "myanmar", "label": "Myanmar"},
                  {"value": "namibia", "label": "Namibia"},
                  {"value": "nauru", "label": "Nauru"},
                  {"value": "nepal", "label": "Nepal"},
                  {"value": "netherlands", "label": "Netherlands"},
                  {"value": "new caledonia", "label": "New Caledonia"},
                  {"value": "new zealand", "label": "New Zealand"},
                  {"value": "nicaragua", "label": "Nicaragua"},
                  {"value": "niger", "label": "Niger"},
                  {"value": "nigeria", "label": "Nigeria"},
                  {"value": "niue", "label": "Niue"},
                  {"value": "norfolk island", "label": "Norfolk Island"},
                  {"value": "northern mariana islands", "label": "Northern Mariana Islands"},
                  {"value": "norway", "label": "Norway"},
                  {"value": "oman", "label": "Oman"},
                  {"value": "pakistan", "label": "Pakistan"},
                  {"value": "palau", "label": "Palau"},
                  {"value": "palestine", "label": "Palestine"},
                  {"value": "panama", "label": "Panama"},
                  {"value": "papua new guinea", "label": "Papua New Guinea"},
                  {"value": "paraguay", "label": "Paraguay"},
                  {"value": "peru", "label": "Peru"},
                  {"value": "philippines", "label": "Philippines"},
                  {"value": "pitcairn", "label": "Pitcairn"},
                  {"value": "poland", "label": "Poland"},
                  {"value": "portugal", "label": "Portugal"},
                  {"value": "puerto rico", "label": "Puerto Rico"},
                  {"value": "qatar", "label": "Qatar"},
                  {"value": "reunion", "label": "Reunion"},
                  {"value": "romania", "label": "Romania"},
                  {"value": "russian federation", "label": "Russian Federation"},
                  {"value": "rwanda", "label": "Rwanda"},
                  {"value": "saint barthelemy", "label": "Saint Barthelemy"},
                  {"value": "saint helena", "label": "Saint Helena"},
                  {"value": "saint kitts and nevis", "label": "Saint Kitts and Nevis"},
                  {"value": "saint lucia", "label": "Saint Lucia"},
                  {"value": "saint martin", "label": "Saint Martin"},
                  {"value": "saint pierre and miquelon", "label": "Saint Pierre and Miquelon"},
                  {"value": "saint vincent and the grenadines", "label": "Saint Vincent and the Grenadines"},
                  {"value": "samoa", "label": "Samoa"},
                  {"value": "san marino", "label": "San Marino"},
                  {"value": "sao tome and principe", "label": "Sao Tome and Principe"},
                  {"value": "saudi arabia", "label": "Saudi Arabia"},
                  {"value": "senegal", "label": "Senegal"},
                  {"value": "serbia", "label": "Serbia"},
                  {"value": "seychelles", "label": "Seychelles"},
                  {"value": "sierra leone", "label": "Sierra Leone"},
                  {"value": "singapore", "label": "Singapore"},
                  {"value": "sint maarten", "label": "Sint Maarten"},
                  {"value": "slovakia", "label": "Slovakia"},
                  {"value": "slovenia", "label": "Slovenia"},
                  {"value": "solomon islands", "label": "Solomon Islands"},
                  {"value": "somalia", "label": "Somalia"},
                  {"value": "south africa", "label": "South Africa"},
                  {"value": "south georgia", "label": "South Georgia"},
                  {"value": "south sudan", "label": "South Sudan"},
                  {"value": "spain", "label": "Spain"},
                  {"value": "sri lanka", "label": "Sri Lanka"},
                  {"value": "sudan", "label": "Sudan"},
                  {"value": "suriname", "label": "Suriname"},
                  {"value": "svalbard and jan mayen", "label": "Svalbard and Jan Mayen"},
                  {"value": "swaziland", "label": "Swaziland"},
                  {"value": "sweden", "label": "Sweden"},
                  {"value": "switzerland", "label": "Switzerland"},
                  {"value": "syrian arab republic", "label": "Syrian Arab Republic"},
                  {"value": "taiwan", "label": "Taiwan"},
                  {"value": "tajikistan", "label": "Tajikistan"},
                  {"value": "tanzania", "label": "Tanzania"},
                  {"value": "thailand", "label": "Thailand"},
                  {"value": "timor leste", "label": "Timor Leste"},
                  {"value": "togo", "label": "Togo"},
                  {"value": "tokelau", "label": "Tokelau"},
                  {"value": "tonga", "label": "Tonga"},
                  {"value": "trinidad and tobago", "label": "Trinidad and Tobago"},
                  {"value": "tunisia", "label": "Tunisia"},
                  {"value": "turkey", "label": "Turkey"},
                  {"value": "turkmenistan", "label": "Turkmenistan"},
                  {"value": "turks and caicos islands", "label": "Turks and Caicos Islands"},
                  {"value": "tuvalu", "label": "Tuvalu"},
                  {"value": "uganda", "label": "Uganda"},
                  {"value": "ukraine", "label": "Ukraine"},
                  {"value": "united arab emirates", "label": "United Arab Emirates"},
                  {"value": "united kingdom", "label": "United Kingdom"},
                  {"value": "united states of america", "label": "United States of America"},
                  {"value": "uruguay", "label": "Uruguay"},
                  {"value": "uzbekistan", "label": "Uzbekistan"},
                  {"value": "vanuatu", "label": "Vanuatu"},
                  {"value": "venezuela", "label": "Venezuela"},
                  {"value": "viet nam", "label": "Viet Nam"},
                  {"value": "virgin islands", "label": "Virgin Islands"},
                  {"value": "virgin islands", "label": "Virgin Islands"},
                  {"value": "wallis and futuna", "label": "Wallis and Futuna"},
                  {"value": "western sahara", "label": "Western Sahara"},
                  {"value": "yemen", "label": "Yemen"},
                  {"value": "zambia", "label": "Zambia"},
                  {"value": "zimbabwe", "label": "Zimbabwe"}
      ]



      export const containerSize=[
       {"value": "20ft", "label": "20ft"},     
       {"value": "40ft", "label": "40ft"},     
       {"value": "45ft", "label": "45ft"},     
     ]


     export const paidStatus=[
       {"value": "paid", "label": "paid"},     
       {"value": "unpaid", "label": "unpaid"},     
     ]

  
     export const sequenceSteps=[
      {"value": "New", "label": "New"},     
      {"value": "Submit-to-CPB", "label": "Submit to CPB"},     
      {"value": "CPB-Approved", "label": "CPB Approved"},     
      {"value": "HBL-Confirmed", "label": "HBL Confirmed"},     
      {"value": "SI-Submitted", "label": "SI Submitted"},     
      {"value": "VGM", "label": "VGM"},     
      {"value": "BL-sent-to-client", "label": "B/L sent to client"},     
      {"value": "ETA", "label": "ETA"},  
      {"value": "Incomplete", "label": "Incomplete"},     
      {"value": "Completed", "label": "Completed"},     


    ]

 

    export const titleStatusOptions=[
      {"value": "Titled", "label": "Titled"},     
      {"value": "VIR", "label": "VIR"},     
      {"value": "Pending", "label": "Pending"},     
      {"value": "Receipt Only", "label": "Receipt Only"},     
      {"value": "Lein Sale", "label": "Lein Sale"},     
      {"value": "Bill of Sale", "label": "Bill of Sale"},     
    ]

    export const paymentTypes=[
      {"value": "Check", "label": "Check"},     
      {"value": "Credit Card", "label": "Credit Card"},     
      {"value": "Cash", "label": "Cash"},     
      {"value": "Debit Card", "label": "Debit Card"},     
      {"value": "International Bank Transfer", "label": "International Bank Transfer"},     
      {"value": "Local Bank Transfer", "label": "Local Bank Transfer"},     

     
    ]


    export const accountTypes=[
      {"value": "Individual", "label": "Individual"},     
      {"value": "Corporation", "label": "Corporation"},     
   
    ]