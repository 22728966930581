import React, { useState, useEffect } from "react";

import { Outlet,NavLink,Link,useLocation,useParams,useMatch, } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {can} from '../../../utils/roleUtils';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next'
import {downloadImagesZip,getChargesVehicleRows,getAutoAdditionalDetails} from '../../../../redux/actions';
import { calculateTotal } from "../../../utils/chargesUtils";
import SimpleCourousel from "../../../elements/SimpleCourousel";
import moment from 'moment'
import _ from 'lodash';
import {goBack} from '../../../utils/historyUtils'

const AutoView = (props) => {
  const location = useLocation();
  const [imagesModal, setImagesModal] = useState(false);

  const [isTabActive, setIsTabActive] = useState(false);
  const [id, setId] = useState('');
  const [onId, setOnId] = useState(null);

  const params = useParams();
  const {t}=useTranslation();


  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

  const match = useMatch('/home/autoView/:id');

  useEffect(() => {
    setIsTabActive(match && match.params.id !== undefined);
  }, [match]);

  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
   props.getChargesVehicleRows(decryptValue);
   
   props.getAutoAdditionalDetails(decryptValue);
   }
   },[])


 const downloadZipImages=(id)=>{


  Swal.fire({
    title: "Are you sure?",
    text: "You want to download images of container",
    icon: "warning",
    width: 400,
    showCancelButton: true,
    timer: 4000,
    timerProgressBar: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, download it!",
  }).then((result) => {
    if (result.isConfirmed) {
      props.downloadImagesZip(id);
    }
  });
  

}
 


const totalCost = calculateTotal(props.getChargesRows?.data, 'cost');
  const totalQuantity = calculateTotal(props.getChargesRows?.data, 'quantity');
  const totalAmount = calculateTotal(props.getChargesRows?.data, 'total');




const autoDetails = props.getAdditionalRegDataSuccess;

  return (
    <div className="container">
    <div className="main-body">
    
    <div className="back-button">
         <a onClick={goBack} className="buttonback">   <i className="bx bx-arrow-back"></i>
</a> 

     </div>
    
          <div className="gutters-sm side-row">
            <div className=" fixed-sidebar-container mb-3">
              <div className="card card-custom">
                <div className="card-container">
                  <div className="align-items-center text-center">
                 
       { autoDetails?
        
<SimpleCourousel images={autoDetails?.images}  imagesLocation={process.env.REACT_APP_IMAGES_ADRESS} width={500} height={500}  dragEnable={false} play={false}/>:null
}
                    
                  </div>
                </div>
              </div>



  

              <div className="container py-4">
        
        <div className="row">
            <div className="col-md-12">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
             
                <NavLink to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListGhulam_123'))}`} end replace className={ (isTabActive ? 'nav-link  shadow active' : 'nav-link  shadow')}  role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i className="fa fa-car"></i>
                        <span className="font-weight-bold small text-uppercase">Auto information</span></NavLink>
{ can(currentUserPermissions,'Allow Individual Vehicle Log')?
  <NavLink to="auto-logs" replace className={({ isActive }) => (isActive ? 'nav-link  shadow active' : 'nav-link  shadow')}   aria-controls="v-pills-messages" aria-selected="false">
                        <i className="fa fa-history mr-3"></i>
                        <span className="font-weight-bold small text-uppercase">Logs</span></NavLink>:null}
                   
                    </div>
            </div>


            
        </div>
    </div>


<div className="card card-custom scroller mt-2 langDirection "  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
<div className="card-body">
<ul className="list-group  list-group-flush">
<li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-info"/></span><span className="header-font">Name</span></h6>
                    <span className="text-sub-head">{autoDetails?.data?.year} {autoDetails?.data?.make} {autoDetails?.data?.model} </span>
                  </li>

                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-info"/></span><span className="header-font">VIN</span></h6>
                    <span className="text-sub-">{autoDetails?.data?.vin}</span>
                  </li>
              { 
                  <li className=" d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-info"/></span><span className="header-font">LOT</span></h6>
                    <span className="text-sub-head">{autoDetails?.data?.lot}</span>
                  </li>
}

<li className=" d-flex justify-content-between align-items-center flex-wrap mt-3 border-top p-2">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-check"/></span><span className="header-font">Status</span></h6>
                    <span className="process-status">{autoDetails?.data.auto_catagory?.replace(/-/g,' ')?.toUpperCase()}</span>
                  </li>


                  {/* <li className=" d-flex justify-content-between align-items-center flex-wrap mt-3 border-top p-2">
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-check"/></span><span className="header-font">Status</span></h6>
                    <span className="process-status">{autoDetails?.data.title_status}</span>
                  </li> */}

                </ul>
                { can(currentUserPermissions,'Download Autos Images')?
                <button className="btn mt-2" onClick={()=>downloadZipImages(id)}>
                    <h6 className="mb-0"><span className="p-2"><i className="fa fa-download"/></span><span className="header-font">Download Images</span></h6>
                  </button>:null}

</div>
</div>

            
            </div>



            <div className=" scroller  overflow-auto-container">
               
               <Outlet/>
               
               </div>
 




</div>


        </div>
        </div>
  );
};



const mapStateToProps = (state) => {
  const {loading, getAdditionalRegDataSuccess,getChargesRows } = state.AutosHandler;
  
    const { preDataLoadResponse } = state.preDataHandler;

    return {
    
      loading:loading,
      preDataLoadResponse:preDataLoadResponse,
     
      getAdditionalRegDataSuccess:getAdditionalRegDataSuccess,
      getChargesRows:getChargesRows,
    };
};


const mapDispatchToProps = (dispatch) => {
  return {
  
    getAutoAdditionalDetails:(id)=>dispatch(getAutoAdditionalDetails(id)),
      downloadImagesZip:(id)=>dispatch(downloadImagesZip(id)),
      getChargesVehicleRows:(id)=>dispatch(getChargesVehicleRows(id))

  };
};

export default connect(mapStateToProps,mapDispatchToProps)(AutoView);
