import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  LoginAuth,
  searchVehicleTrackerPublic,  
  inputHandler,

} from "../../../redux/actions";

import { connect } from "react-redux";


function TrackVehicle(props) {

  const {t} = useTranslation();
  
  const onInputchange=(e)=>{
    // console.log(e.target.value)
    props.inputHandler({ key: e.target.name, value: e.target.value });

  }
  
const onSubmitTrackVehicle=(e)=>{
  e.preventDefault();

  props.searchVehicleTrackerPublic(props.trackerQuery)
}



  return (
    <>



<div className="login-wrap p-4 p-md-5">

{props.loading ? (
                  <div className="LoginLoader"></div>
          ) : (
            ""
          )}
              

                    <div className="d-flex langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                      <div className="w-100">
                        <h3 className="mb-4">{t('key_track_your_vehicle')}</h3>
                      </div>
                      {/* <div className="w-100">
									<p className="social-media d-flex justify-content-end">
										<a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-facebook"></span></a>
										<a href="#" className="social-icon d-flex align-items-center justify-content-center"><span className="fa fa-twitter"></span></a>
									</p>
								</div> */}
                    </div>
                    <form onSubmit={onSubmitTrackVehicle} className="signin-form langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))} >
                      <div className="form-group mb-3">
                        <label className="label" htmlFor="name">
                         {t('key_lot_or_vin')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('key_search_by_lot_or_vin')}
                          name="trackerQuery"
                          value={props.trackerQuery ||''}
                          onChange={(e) => {
                            onInputchange(e);
                          }}
                          autoComplete='off'
                          required
                        />
                      </div>
                     
                    

                      <div className="form-group">
                        <button
                          type="submit"
                          className="form-control btn  rounded px-3"
              
                        >
                          {t('key_track')}
                        </button>
                      </div>
                      <div className="form-group d-md-flex">
                     
                        <div className="w-50 text-md-right ">
                          {/* <a href="#">Forgot Password</a> */}
                        </div>
                      </div>
                    </form>
                    {/* <p className="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p> */}
                  </div>








    </>
  )
}


const mapStateToProps = (state) => {
  const {loading, showAndHideAutoPublicModal,trackerQuery} = state.VehicleTrackerHandler

  return {
    loading:loading,
    trackerQuery:trackerQuery,
  
  };
};

const mapDispatchToProps=(dispatch)=>{
  return {
    searchVehicleTrackerPublic:(query)=>dispatch(searchVehicleTrackerPublic(query)),
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(TrackVehicle)