import Routes from "./routes/Routes.js";
import CustomRouter from "./routes/CustomRouter";
import History from "./routes/CustomHistory";
import React,{useEffect} from 'react';
// import './App.css';
// import { BrowserRouter, Link } from "react-router-dom";

function App() {
// =============================
// design version: 1.0
// design by sabaoon bedar
// =============================
// this code is helpful when you want to change your site to redirect from http to https
// useEffect(() => {
//   if (window.location.protocol !== 'https:') {
//     window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
//   }
// }, []);



  return (
    <>
      <CustomRouter history={History}>
        <Routes />
      </CustomRouter>
    </>
  );
}

export default App;
