import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
//this is stored in local storage
 const isAuthenticated = JSON.parse(localStorage.getItem('fkey'));


  if (!isAuthenticated ) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
