import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing VendorType settings model
// -------------------------------------------------------------------------
export const hideVendorTypeModal=()=>{
    return {
      type:'hideVendorTypeModal'
    }
    
    }
    export const showVendorTypeModal=()=>{
  
      return {
        type:'showVendorTypeModal'
      }
      
      }
  
  
      export const showEditVendorTypeModal=()=>{
        return {
          type:'showEditVendorTypeModal'
        }
        
        }
      
        export const hideEditVendorTypeModal=()=>{
          return {
            type:'hideEditVendorTypeModal'
          }
        }



// ---------------------------------------------------------------------------------------------------------
// to get all VendorTypes for settings
// ---------------------------------------------------------------------------------------------------------

export const getVendorTypes = (currentPage) => {



    return (dispatch) => {
        dispatch(getVendorTypeStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/VendorTypes?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getVendorTypeSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getVendorTypeFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getVendorTypeStarted = () => ({
      type: "getVendorType_started",
    });
    
    
    const getVendorTypeSuccess = (data,currentPage) => ({
      type: "getVendorTypesSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getVendorTypeFailure = (error) => ({
      type: "getVendorType_failure",
      payload: {
        error,
      }, 
    });
  
  
  






// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerVendorType = (name) => {
  console.log(name)
  return (dispatch) => {
    dispatch(registerVendorTypeStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let VendorTypeData={
     type_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/VendorTypeRegister`, VendorTypeData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerVendorTypeSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Vendor Type has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerVendorTypeFailure(err.response.data.errors));
     
      });
  };
};

const registerVendorTypeStarted = () => ({
  type: "registerVendorType_started",
});

const registerVendorTypeSuccess = (data) => ({
  type: "registerVendorType_success",
  payload: {
    ...data,
  },
});

const registerVendorTypeFailure = (error) => ({
  type: "registerVendorType_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the VendorType edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const VendorTypeEdit = (id) => {

  return (dispatch) => {
    dispatch(VendorTypeEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editVendorType/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(VendorTypeEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(VendorTypeEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const VendorTypeEditDetailsStarted = () => ({
  type: "VendorTypeEditDetails_started",
});

const VendorTypeEditDetailsSuccess = (data) => ({
  type: "VendorTypeEditDetails_success",
  payload: {
    ...data,
  },
});

const VendorTypeEditDetailsFailure = (error) => ({
  type: "VendorTypeEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the VendorType update 
// ---------------------------------------------------------------------------------------------------------

export const VendorTypeUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(VendorTypeUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let VendorTypeData={
        type_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateVendorType/`+id,VendorTypeData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(VendorTypeUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Vendor Type has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(VendorTypeUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const VendorTypeUpdateStarted = () => ({
    type: "VendorTypeUpdate_started",
  });
  
  
  const VendorTypeUpdateSuccess = (data,currentPage) => ({
    type: "VendorTypeUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const VendorTypeUpdateFailure = (error) => ({
    type: "VendorTypeUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// VendorTypes Deletion
// ---------------------------------------------------------------------------------------------------------

export const VendorTypeDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(VendorTypeDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteVendorType/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(VendorTypeDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Vendor Type has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(VendorTypeDeletionFailure(err.message));
      });
  };
};

const VendorTypeDeletionStarted = () => ({
  type: "VendorTypeDeletion_started",
});

const VendorTypeDeletionSuccess = (data,currentPage) => ({
  type: "VendorTypeDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const VendorTypeDeletionFailure = (error) => ({
  type: "VendorTypeDeletion_failure",
  payload: {
    error,
  },
});
