import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from  "../styles"
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});


function CustomDesign(props) {

  return (
    <Document title="Customs"  >
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.headerText}>U.S. CUSTOMS AND BORDER PROTECTION</Text>

          {/* <Text style={styles.headerText}>Port of Honolulu 300 Ala Moana Blvd Room 2500 HI 96850</Text> */}
          <Text style={styles.headerText}>{props.data?.header?.port_address}</Text>

          <Text style={[styles.headerText, { fontWeight: '600', marginTop: '8px', marginBottom: '14px' }]}>
            Tel.
            <Text style={styles.contactText}>
              (808) 356-4100
            </Text>
          </Text>

          <Text style={[styles.headerText, { fontWeight: '800', fontSize: '14px' }]}>COVER LETTER</Text>

          <Text style={styles.infoText}>VEHICLE INFORMATION</Text>

          <View style={styles.table}>
            {/* Table Headers */}
            <View style={styles.tableRow}>
              <View style={styles.tableHeader}>
                <Text>Year</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Make</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>Model</Text>
              </View>
              <View style={styles.tableHeaderVin}>
                <Text>VIN</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>TITLE</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>CONE</Text>
              </View>
              <View style={styles.tableHeader}>
                <Text>VALUE</Text>
              </View>
            </View>

            {props.data?.containerAutos.map((item,index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{item.year}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.make} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.model}  </Text>
                </View>
                <View style={styles.tableCellVin}>
                  <Text>{item.vin} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Value </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.cone} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{item.auto_price}  </Text>
                </View>
              </View>
            ))}

            <View style={styles.solidLine} />
          </View>

          <View style={styles.row}>
            <Text style={styles.heading}>
              EXPORTER INFORMATION
            </Text>
          </View>
          {/* <View style={styles.gap}></View> */}

          <View style={styles.row}>
            <View style={styles.columnDouble}>
              <Text style={styles.normaltext}>
                Exporter (USPPI) Name:
              </Text>
            </View>
            <View style={styles.columnDouble}>
            <Text style={[styles.boldtext,styles.underLineInput]}>
               {props.data?.forCustomer?.company_name}
              </Text>
            </View>
          </View>
{/* second row */}
          <View style={[styles.row,{paddingRight:22}]}>
            <View style={styles.column}>
              <Text style={styles.normaltext}>
              U.S. Address:
              </Text>
            </View>
            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.forCustomer?.address}
              </Text>
            </View>

            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.forCustomer?.city}
              </Text>
            </View>

            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.forCustomer?.state}

              </Text>
            </View>
            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.forCustomer?.zip_code}

              </Text>
            </View>
          </View>
          
          <View style={[styles.row,{paddingRight:22}]}>
            <View style={styles.column}>
              <Text style={styles.normaltext}>
              </Text>
            </View>
            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            Street
              </Text>
            </View>

            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            City
              </Text>
            </View>

            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            State
              </Text>
            </View>
            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            Zip
              </Text>
            </View>
          </View>
          

          <View style={styles.row}>
            <View style={styles.column50}>
              <Text style={styles.normaltext}>
              Telephone: <Text style={styles.boldtext}> 
              {props.data?.forCustomer?.contact}
</Text>
              </Text>
            </View>
            <View style={[styles.column50]}>
            <Text style={styles.normaltext}>
            Fax:      <Text style={styles.boldtext}> </Text>      
              </Text>
            </View>

          </View>


          <View style={styles.row}>
            <View style={styles.columnTripple}>
              <Text style={styles.normaltext}>
              Filling Agent/Freight Forwarder:  <Text style={[styles.boldtext]}>
              {props.data?.data?.vendor_name}

              </Text>
              </Text>
            </View>
            <View style={[styles.columnDouble]}>
            <Text style={styles.normaltext}>
            Phone:    <Text style={styles.boldtext}>       
                   {props.data?.data?.vendor_phone}
</Text>   
              </Text>
            </View>

          </View>


          <View style={styles.row}>
            <View style={styles.columnTripple}>
              <Text style={styles.normaltext}>
              Loading location (if different from forwarder): <Text style={styles.boldtext}>
              {props.data?.data?.vendor_name}

              </Text>
              </Text>
            </View>
            <View style={[styles.columnDouble]}>
            <Text style={styles.normaltext}>
            Contact: <Text style={styles.boldtext}>
              
              </Text>      
              </Text>
            </View>

          </View>


          <View style={[styles.row,{paddingRight:22}]}>
            <View style={styles.column}>
              <Text style={styles.normaltext}>
              U.S. Address:
              </Text>
            </View>
            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.data?.vendor_address}

              </Text>
            </View>

            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.data?.vendor_city}

              </Text>
            </View>

            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.data?.vendor_state}
              </Text>
            </View>
            <View style={[styles.column,{borderBottomWidth:1}]}>
            <Text style={styles.boldtext}>
            {props.data?.data?.vendor_zip_code}
              </Text>
            </View>
          </View>
          
          <View style={[styles.row,{paddingRight:22}]}>
            <View style={styles.column}>
              <Text style={styles.normaltext}>
              </Text>
            </View>
            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            Street
              </Text>
            </View>

            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            City
              </Text>
            </View>

            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            State
              </Text>
            </View>
            <View style={[styles.column]}>
            <Text style={styles.normaltext}>
            Zip
              </Text>
            </View>
          </View>

          <View style={styles.solidLine} />

          <Text style={[styles.headerSecondary]}>EXPORT INFORMATION</Text>

          <View style={styles.row}>
            <View style={styles.columnDouble}>
              <Text style={styles.normaltext}>
              Booking #:  <Text style={styles.boldtext}>
              {props.data?.data?.cont_booking_no}

              </Text>
              </Text>
            </View>
            <View style={[styles.columnTripple]}>
            <Text style={styles.normaltext}>
            Vessel Name & Voyage#:  <Text style={styles.boldtext}>
            {props.data?.data?.cont_vessel_name}, {props.data?.data?.cont_vessel_voyage}

              </Text>   
              </Text>
            </View>

          </View>

          <View style={styles.row}>
            <View style={styles.columnDouble}>
              <Text style={styles.normaltext}>
              Vessel Departure Date:  <Text style={styles.boldtext}>
              {props.data?.data?.cont_departure_date},
              </Text>
              </Text>
            </View>
            <View style={[styles.columnTripple]}>
            <Text style={styles.normaltext}>
            U.S. Port of Export:  <Text style={styles.boldtext}>
               {props.data?.data?.cont_port}
               </Text>   
              </Text>
            </View>

          </View>

          <View style={styles.row}>
            <View style={styles.columnFull}>
              <Text style={styles.normaltext}>
              City and Country of Ultimate Destination:  <Text style={styles.boldtext}>
              {props.data?.data?.cont_port_discharge}

              </Text>
              </Text>
            </View>
          
          </View>

          <View style={styles.row}>
            <View style={styles.column30}>
              <Text style={styles.normaltext}>
              Steamship Line: <Text style={styles.boldtext}>
              {props.data?.data?.cont_steamship_line}

              </Text>
              </Text>
            </View>

            <View style={styles.column30}>
              <Text style={styles.normaltext}>
              Terminal:  <Text style={styles.boldtext}>
              {props.data?.data?.cont_terminal}

              </Text>
              </Text>
            </View>

            <View style={styles.column30}>
              <Text style={styles.normaltext}>
              Container#:  <Text style={styles.boldtext}>
              {props.data?.data?.container_no}

              </Text>
              </Text>
            </View>
          
          </View>



          <View style={styles.row}>
          
            <View style={[styles.columnTripple]}>
            <Text style={styles.normaltext}>
            Seal:  <Text style={styles.boldtext}>
            {props.data?.data?.cont_seal}

              </Text>   
              </Text>
            </View>

          </View>
          <View style={styles.solidLine} />

          <Text style={styles.headerSecondary}>AES INFORMATION</Text>

          <View style={styles.row}>
          
            <View style={[styles.columnFull]}>
            <Text style={styles.normaltext}>
            ITN#:  <Text style={styles.boldtext}>
            {props.data?.data?.cont_itn}

              </Text>   
              </Text>
            </View>

          </View>
          <Text style={styles.normaltext}>I certify under pranlty of perjury under the laws of United States of America (Title 18 U.S.C § 1001) that the foregoing is true and correct. Title 18 U.S.C §1001 prohibits making false statement, lying to or concealing information from a federal official by oral affirmation, written statement or mere denial.</Text>
<View style={styles.gap}></View>
<View style={styles.gap}></View>

          <View style={styles.row}>
            <View style={styles.columnTripple}>
              <Text style={styles.normaltext}>
              AUTHORIZED SIGNATURE:___________________________  
              </Text>
            </View>
            <View style={[styles.columnDouble]}>
            <Text style={styles.normaltext}>
            DATE:_________________________ 
              </Text>
            </View>

          </View>

          <View style={styles.row}>
            <View style={styles.columnTripple}>
              <Text style={[styles.normaltext,{marginLeft:25}]}>
            Exporter Agent
              </Text>
            </View>
         

          </View>
        </View>
      </Page>
    </Document>
  );
}

export default CustomDesign;
