import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import CustomDesign from "./pdfs/PdfDesigns/CustomDesign";
  
import React from 'react';
import { Page, Document, View, Text, Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from  "../../components/pages/pdfs/styles";
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/open-sans-regular.ttf' },
    { src: '/fonts/open-sans-700.ttf', fontWeight: 700 },
    { src: '/fonts/open-sans-600.ttf', fontWeight: 600 },
    { src: '/fonts/open-sans-800.ttf', fontWeight: 800 },
  ]
});


function Otherpage() {

    <Document title="customs"  >
      <Page size="A4">
        <View style={styles.container}>
          <Text style={styles.headerText}>Alibaba Global Shipping</Text>

          <Text style={styles.infoText}>1260 57TH AVE. OAKLAND, CA 94621 TEL: 510 261 4900 </Text>
       

          <Text style={[styles.headerText, { fontWeight: '800', fontSize: '14px' }]}>NON-HAZARDOUS DECLARATION</Text>

          <View style={styles.gap}></View>
          <View style={styles.gap}></View>

          <View style={styles.solidLine}></View>

      
        </View>
      </Page>
    </Document>


  
}

export default Otherpage