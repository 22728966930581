import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import '../../../css/searchBox.css'
import "../../../css/containers.css";
import "../../../css/containerBadges.css";

import Swal from "sweetalert2";
import Loader from "../../elements/Loader";
import CloseButton from "react-bootstrap/esm/CloseButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import ImagesGrid from "../autos/AutoImagesGrid";
import { connect } from "react-redux";
import {NavLink,useParams,useLocation,Link,useNavigate, useFetcher} from 'react-router-dom';
import { PDFDownloadLink,pdf} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { FileUploader } from "react-drag-drop-files";
import ProgressBar from "react-bootstrap/ProgressBar";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Logo from "../../assets/normLogo.png";
import _ from "lodash";
import NodataFound from '../../pages/error/noDataFoundMain';
import { useTranslation } from 'react-i18next'

import { AsyncPaginate } from "react-select-async-paginate";
import Pagination from "react-js-pagination";
import ContainerImagesCarousel from '../../pages/containers/ContainerImagesCarousel'
import Checkbox from '../../elements/Checkbox';
import ContainerImagesGrid from '../containers/ContainerImagesGrid'
import {containerSize, countryList, paidStatus, sequenceSteps} from '../../utils/countryUtils';
import {loadContainerOptions,loadForwarderOptions,loadTruckerOptions,loadConsigneeOptions,loadVesselOptions, loadCustomersOptions} from '../../utils/asyncOptionsUtils';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Dropdown from 'react-bootstrap/Dropdown';


import {
  getSelectors,
  hideContainerEditModal,
  showContainerEditModal,
  showContainerRegModal,
  showContainerInvoiceModal,
  hideContainerRegModal,
  VinNumberFetchForContainers,
  ContainerRegistration,
  ContainersList,
  getContainerImages,
  downloadImagesContainerZip,
  ContainerDelete,
  showContainerAutoAdditionModal,
  hideContainerAutoAdditionModal,
  containerAddAutos,
  selectedAutosInContainersDelete,
  getContainerDetails,
  ContainerUpdate,
  storeImagesContainer,
  selectedContainersDelete,
  searchContainersList,
  inputHandler,
  getChargesContainersRows,
  storeContainerCharges,
  showContainerChargesModal,
  containersCatagoryList,
  nonHazardousPdf,
  customsPdf,
  bLInstructionPdf,
  ContainerInvoice,
  ContainerInvoiceUpdate,
} from "../../../redux/actions";
import axios from 'axios';

import {can} from '../../utils/roleUtils';
import { calculateTotal } from "../../utils/chargesUtils";
import Customs from "../pdfs/PdfViews/Customs";
import NonHazardousDesign from "../pdfs/PdfDesigns/NonHazardousDesign";
import CustomDesign from "../pdfs/PdfDesigns/CustomDesign";
import BL_InstructionDesign from "../pdfs/PdfDesigns/BL_InstructionDesign";
import BLInstructionDesign from "../pdfs/PdfDesigns/BL_InstructionDesign";
var CryptoJS = require("crypto-js");

function ContainerList(props) {

  const [containerId,setContainerId]= useState('');
  const [onId, setOnId] = useState(null);
  const [error, setError] = useState('');

  const [header, setHeader] = useState("all-headers");
  const [wizardPosition, setWizardPosition] = useState("wizard-steps");

  const [value, onChange] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerAddOn, setCustomerAddOn] = useState(null);


  const [autosInContainer, setAutosInContainer] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState({dock_receipt:'Choose Dock Receipt',bl_copy:'Choose BL Copy',telex_release:'Choose Telex Release',validations:'Choose Validations'});
  const [searched, setSearched] = useState(false);

  const [chargesData, setChargesData] = useState([
    { description: '',unit:'', amount:0,cost:0, quantity:0, total:0, chargesId:'' },
  ]);
  

  const [isCheckAll, setIsCheckAll] = useState(false);   
const [isCheck, setIsCheck] = useState([]);
const [isCheckedContainer, setIsCheckContainer] = useState([]);
const [isTabActive, setIsTabActive] = useState(false);
const [isOpenDropDown, setIsOpenDropDown] = useState({});


  const [dragAndDropTextImages, setdragAndDropTextImages] = useState(
    "Upload or Drag and Drop images here"
  );
  const [dragAndDropSelected, setdragAndDropSelected] = useState(false);

  const [
    dragAndDropAdditionalInvoiceText,
    setdragAndDropAdditionalInvoiceText,
  ] = useState("Upload or Drag and Drop your Invoice here");
  const [
    dragAndDropAdditionalInvoiceSelected,
    setdragAndDropAdditionalInvoiceSelected,
  ] = useState(false);

  const [dragAndDropSelectedImages, setdragAndDropSelectedImages]=useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [vinsFieldRequired, setVinsFieldRequired] = useState(false);
  const [idPdf,setIdPdf] = useState('');

  const [containerType, setContainerType]=useState(false)
  const [containerTypeAddOn, setContainerTypeAddOn]=useState(false)

  const [autoAdditionalDataError, setAutoAdditionalDataErrors] = useState({
    auto_catagory_error: "",
  });

  const [containerRegData, setcontainerRegData] = useState({
    onId: "",
    catagory:{label:'New', value:'New'},
    container_number: "",
    images: [],
    booking_number: "",
    lines: null,
    dock_receipt: null,
    bill_copy: null,
    telex_release: null,
    expected_arrival_date: null,
    container_arrival_date: null,
    container_unloading_date:null,
    validations: null,
    point_of_loading:"",
    consignee:null,
    forwarder:null,
    trucker:null,
    exporter_name:'',
    port:null,
    country_port:null,
    port_discharge:null,
    country_discharge:null,
    seal:'',
    container_size:null,
    vessel_name:'',
    vessel_voyage:'',
    document_cut_date: null,
    port_cut_date: null,
    departure_date: null,
    eta: null,
    cpb_completed: null,
    si_submission_date: null,
    loading_date: null,
    completed_date: null,
    validation_date: null,
    itn:'',
    xtn:'',
    steamship_line:'',
    terminal:'',
    paid_status:{value:'unpaid',label:'unpaid'},
    container_status:'New',



  });

  const [autoRegistrationData, setAutoRegistrationData] = useState({
    vin: "",
    lot: "",
    autoType: null,
    model: "",
    make: "",
    year: "",
    color: "",
    purchase_date: new Date(),
    auction: null,
    city: null,
    buyer_no: null,
    payment_to_auction: new Date(),
    port: null,
    point_of_loading: null,
    customer_notes: "",
    dismantle: "false",
    self_delivered: "false",
    invoice: null,
    auto_price: "",
  });

  const now = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format


const [invoiceUpdateDate,setInvoiceUpdate]= useState({container_id:'',invoice_no:'',invoice_date:''});

useEffect(()=>{
  setInvoiceUpdate({
    ...invoiceUpdateDate,
    invoice_date:props.containerInvoiceInformation?.containerInfo?.cont_invoice_date!=null?moment(props.containerInvoiceInformation?.containerInfo?.cont_invoice_date).toDate():null,
    invoice_no:props.containerInvoiceInformation?.containerInfo?.cont_invoice_no,
    container_id:props.containerInvoiceInformation?.containerInfo?.cont_id,


  });

},[props.containerInvoiceInformation])




const invoiceInputChange=(e)=>{
  setInvoiceUpdate({
    ...invoiceUpdateDate,
    [e.target.name]: e.target.value,
  });
}

const handleDateInputChangeInvoice = (e, dateSelectorName) => {
  // let formated = moment(e).format('YYYY-MM-DD')
  setInvoiceUpdate({ ...invoiceUpdateDate, [dateSelectorName]: e });
};

const updateContainerInv=(e)=>{
  e.preventDefault();
props.ContainerInvoiceUpdate(invoiceUpdateDate);
}



  let countryOptions=countryList;

  const params = useParams();


  const listenScrollEvent = () => {
    if (window.scrollY > 134) {
      return setHeader("all-headers-fixed");
    } else if (window.scrollY < 80) {
      return setHeader("all-headers");
    }
  };

  const navigate= useNavigate();
 const location = useLocation();
  const currentPath = location.pathname;


  useEffect(() => {

    window.removeEventListener("scroll", listenScrollEvent);

    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);

    };
  }, []);


  useEffect(() =>{
    // props?.getSelectors();
    
    let catagory= params.catagory;

if(typeof catagory === 'undefined'){
  props?.ContainersList(props.currentPage);


}else{

  props.containersCatagoryList(props.currentPage,catagory);

}

if(props.contAutosRegResponse){

    setcontainerRegData({...containerRegData,images:[]})
  
  
          setdragAndDropTextImages('Upload or Drag and Drop images here')
          setcontainerRegData({...containerRegData,images:[]})
          setdragAndDropSelectedImages(false)
}


if(props.contAutosRegResponse){
  if(props.nullValues){
    onChange([])
  setcontainerRegData(
  {
    onId: "",
    catagory:null,
    container_number: "",
    images: [],
    booking_number: "",
    lines: null,
    dock_receipt: null,
    bill_copy: null,
    telex_release: null,
    expected_arrival_date: null,
    container_arrival_date: null,
    container_unloading_date:null,
    validations: null,
    point_of_loading:"",
    consignee:null,
    forwarder:null,
    trucker:null,
    exporter_name:'',
    port:null,
    country_port:null,
    port_discharge:null,
    country_discharge:null,
    seal:'',
    container_size:null,
    vessel_name:'',
    vessel_voyage:'',
    document_cut_date: null,
    port_cut_date: null,
    departure_date: null,
    eta: null,
    cpb_completed: null,
    si_submission_date: null,
    loading_date: null,
    completed_date: null,
    validation_date: null,
    itn:'',
    xtn:'',
    steamship_line:'',
    terminal:'',
    paid_status:{value:'unpaid',label:'unpaid'},
    container_status:'New'


  }
  )
}
}

    if( props.contAddAutosResponse){
      if(props.nullValues){
        setAutosInContainer([]);
      }
    }

     
    if( props.containerSelectedAutosDeleteResponse){
      if(props.nullValues){
        setIsCheck([])
      }
    }

    if(props?.selectedDeletionContainerResponse){
      setIsCheckContainer([]);
    }


  },[props.containerDeleteResponse,props.contAutosRegResponse, props.contAddAutosResponse,props.containerSelectedAutosDeleteResponse,props.containerUpdateResponse,props.selectedDeletionContainerResponse,props.containerImagesUploadResponse,props.containerImagesDeletionResponse])


  const handlePageChange=(pageNumber) => {
    
    props.containersCatagoryList(pageNumber,params.catagory);
  
  }
  

  useEffect(()=>{
    if(props.containerImagesUploadResponse){
      if(props.nullValues){
  
        setcontainerRegData({...containerRegData,images:[]})
  
  
          setdragAndDropTextImages('Upload or Drag and Drop images here')
          setcontainerRegData({...containerRegData,images:[]})
          setdragAndDropSelectedImages(false)
      }
    
    }
  },[props.containerImagesUploadResponse])



useEffect(()=>{
  props?.getSelectors();
},[])

useEffect(()=>{


  if(props.containerDetailsResponse){
    let customerInfo=props.containerDetailsResponse?.forUser;
// console.log(customerInfo)
    let containerInfo=props.containerDetailsResponse?.containerInfo;
    let consigneeInfo=props.containerDetailsResponse?.consignee;
    let truckersInfo=props.containerDetailsResponse?.truckers;
    let forwardersInfo=props.containerDetailsResponse?.forwarders;
    setCustomer(
       customerInfo?.username?{value:customerInfo.id, label:customerInfo?.username}:null,

    )

    // console.log(consigneeInfo)
    setcontainerRegData({...containerRegData,
      port: containerInfo?.cont_port?{value:containerInfo?.cont_port, label:containerInfo?.cont_port}:'',

      onId:containerInfo?.id,
      consignee: consigneeInfo?.company_name?{value:consigneeInfo?.id, label:consigneeInfo?.company_name}:'',
      trucker: truckersInfo?.vendor_name?{value:truckersInfo?.id, label:truckersInfo?.vendor_name}:'',
      forwarder: forwardersInfo?.vendor_name?{value:forwardersInfo?.id, label:forwardersInfo?.vendor_name}:'',

      container_number:containerInfo?.container_no,
      booking_number:containerInfo?.cont_booking_no,
      seal:containerInfo?.cont_seal,
      xtn:containerInfo?.cont_xtn,
      itn:containerInfo?.cont_itn,
      steamship_line:containerInfo?.cont_steamship_line,
      terminal:containerInfo?.cont_terminal,
    
      paid_status: containerInfo?.cont_paid_status?{value:containerInfo?.cont_paid_status, label:containerInfo?.cont_paid_status}:'',
      country_port: containerInfo?.cont_country_port?{value:containerInfo?.cont_country_port, label:containerInfo?.cont_country_port?.replace(/\b\w/g, (char) => char.toUpperCase())}:'',
      country_discharge: containerInfo?.cont_country_discharge?{value:containerInfo?.cont_country_discharge, label:containerInfo?.cont_country_discharge?.replace(/\b\w/g, (char) => char.toUpperCase())}:'',
      port_discharge: containerInfo?.cont_port_discharge?{value:containerInfo?.cont_port_discharge, label:containerInfo?.cont_port_discharge}:'',
      catagory: containerInfo?.cont_catagory?{value:containerInfo?.cont_catagory, label:containerInfo?.cont_catagory?.replace(/-/g,' ')}:'',

      vessel_voyage:containerInfo?.cont_vessel_voyage,
      exporter_name:containerInfo?.cont_exporter_name,
      container_size: containerInfo?.cont_container_size?{value:containerInfo?.cont_container_size, label:containerInfo?.cont_container_size}:'',
      vessel_name: containerInfo?.cont_vessel_name?{value:containerInfo?.cont_vessel_name, label:containerInfo?.cont_vessel_name}:'',


      lines:containerInfo?.cont_line,
      document_cut_date:containerInfo?.cont_document_cut_date!=null?moment(containerInfo?.cont_document_cut_date).toDate():null,
      port_cut_date:containerInfo?.cont_port_cut_date!=null?moment(containerInfo?.cont_port_cut_date).toDate():null,
      departure_date:containerInfo?.cont_departure_date!=null?moment(containerInfo?.cont_departure_date).toDate():null,
      eta:containerInfo?.cont_eta!=null?moment(containerInfo?.cont_eta).toDate():null,

      cpb_completed:containerInfo?.cont_cpb_completed!=null?moment(containerInfo?.cont_cpb_completed).toDate():null,
      si_submission_date:containerInfo?.cont_si_submission_date!=null?moment(containerInfo?.cont_si_submission_date).toDate():null,
      completed_date:containerInfo?.cont_completed_date!=null?moment(containerInfo?.cont_completed_date).toDate():null,

      loading_date:containerInfo?.cont_date_loading!=null?moment(containerInfo?.cont_date_loading).toDate():null,
      validation_date:containerInfo?.cont_validation_date!=null?moment(containerInfo?.cont_validation_date).toDate():null,

      expected_arrival_date:containerInfo?.cont_expect_date_arrival!=null?moment(containerInfo?.cont_expect_date_arrival).toDate():null,
      container_arrival_date:containerInfo?.cont_arrival_date!=null?moment(containerInfo?.cont_arrival_date).toDate():null,
      container_unloading_date:containerInfo?.cont_unloading_date!=null?moment(containerInfo?.cont_unloading_date).toDate():null,

      point_of_loading: containerInfo?.cont_point_loading?{value:containerInfo?.cont_point_loading, label:containerInfo?.cont_point_loading}:'',

      
    })

setFileName(  {...fileName, 
   telex_release:containerInfo?.cont_telex_release?.substring(13,20).concat('...')||'Choose Telex Release',
   bl_copy:containerInfo?.cont_bill_copy?.substring(13,20).concat('...')||'Choose BL Copy',
   dock_receipt:containerInfo?.cont_doc_receipt?.substring(13,20).concat('...')||'Choose Dock Receipt',
   validations:containerInfo?.cont_validation?.substring(13,20).concat('...')||'Choose Validations',

}
   )


// let  carsInContainer= props.containerDetailsResponse?.autosInContainer;

//   let carsVins;
//   if (carsInContainer) {
//     carsVins = carsInContainer.map(function (data) {
//       return { value:data.autoId, label:`${data.vin} | ${data.userName}` };
//     });
//   }
//   onChange(carsVins);


  }




},[props.containerDetailsResponse])










useEffect(() => {
  if (props.getContainerChargesRows?.data) {
    const rowsFromDatabase = props.getContainerChargesRows?.data;
    const initialChargesData = Array.from({ length: rowsFromDatabase.length }, (_, index) => ({
      description: rowsFromDatabase[index]?.description || '',
      amount: rowsFromDatabase[index]?.amount ||rowsFromDatabase[index]?.per_unit_price||0,
      cost: rowsFromDatabase[index]?.cost ||rowsFromDatabase[index]?.per_unit_cost||0,
      quantity: rowsFromDatabase[index]?.quantity || 0,
      total: rowsFromDatabase[index]?.total || 0,
      unit: rowsFromDatabase[index]?.unit || null,
      chargesId: rowsFromDatabase[index]?.id || '',


    }));

    setChargesData(initialChargesData);
  }
}, [props.getContainerChargesRows]);



const changedSelectorData = (selectedOption,field) => {
  let selectorName=field;
  setcontainerRegData({
    ...containerRegData,
    [selectorName]: selectedOption,
});
};








const { t } = useTranslation() 



  const handleChangeImages = (FileList) => {
    // console.log(FileList);
    if (FileList.length > 0) {
      setdragAndDropSelectedImages(true);
      if (FileList.length == 1) {
        setdragAndDropTextImages(`${FileList.length} image`);
      } else {
        setdragAndDropTextImages(`${FileList.length} images`);
      }
      const imagesArray = [];

      for (let i = 0; i < FileList.length; i++) {
        imagesArray.push(FileList[i]);
      }

      setcontainerRegData({ ...containerRegData, images: imagesArray });
    } else {
      setdragAndDropSelectedImages(false);
    }
  };

  const onstoreContainerData = (e) => {
    e.preventDefault();
  //here i have to use the vin number issue.s
  if(customer){
  if(value?.length !=0 ){
      props.ContainerRegistration(
        containerRegData,
        value,
        customer,
        props?.containersListResponse?.data?.current_page
        );

        setVinsFieldRequired(false)
      }else{
        setVinsFieldRequired(true)

      }
    }else{
      
    Swal.fire({
      text: "Please select Customer",
      icon: "warning",
      width: 400,
      timer: 900,
      timerProgressBar: true,
      toast:true
    })
    }
  };

  const onUpdateContainerData = (e) => {
    e.preventDefault();
  
      props.ContainerUpdate(
        containerRegData,
        value,
        customer,
        props?.containersListResponse?.data?.current_page
        
        );

  };


  const onInputChangeAdditional = (e) => {
    setcontainerRegData({
      ...containerRegData,
      [e.target.name]: e.target.value,
    });
  };





  const handleAttachment=(e)=>{
    // console.log(e)
    if(e.target?.files[0]?.name==undefined || e.target?.files[0]==undefined ){
      setcontainerRegData({...containerRegData, [e.target.name]:null})
      setFileName({...fileName,telex_release:'Choose Telex Release',dock_receipt:'Choose Dock Receipt', bl_copy:'Choose BL Copy', validations:'Choose Validations'})
    }else{
      setcontainerRegData({...containerRegData,[e.target.name]:e.target?.files[0]})
      setFileName({...fileName,[e.target.name]:e.target?.files[0]?.name})

    }
  }

  const handleContainerChargesToggle = () => props.showContainerChargesModal();


  const handleInputChange = (index, field, value) => {
    const newData = [...chargesData];
    newData[index][field] = value;

    if (field === 'amount' || field === 'quantity') {
      // Calculate total if amount or quantity changes
      const amount = parseFloat(newData[index].amount) || 0;
      const quantity = parseFloat(newData[index].quantity) || 0;
      const unit = newData[index].unit || null;
      
      if(unit=="Day"){
        newData[index].total = amount * quantity;

      }else{
        newData[index].total = amount;

      }
    }

    setChargesData(newData);
  };





  const handleContainerInvoiceToggle = () => props.showContainerChargesModal();

  const [totals, setTotals] = useState({});

  const totalVehicleCharges=calculateTotal(props.containerInvoiceInformation?.charges,'total')
  const totalVehicleCost=calculateTotal(props.containerInvoiceInformation?.charges,'cost')
  const totalContainerCost=calculateTotal(props.containerInvoiceInformation?.containerCharges,'cost')
  const totalContainerCharges=calculateTotal(props.containerInvoiceInformation?.containerCharges,'amount')

  // useEffect(() => {
  //   const initialTotals = props.containerInvoiceInformation?.charges?.reduce((acc, header) => {
  //     acc[header] = 0;
  //     return acc;
  //   }, {});

  
  // }, [props.containerInvoiceInformation]);


const storeContainerCharge=(e)=>{
  e.preventDefault();
  props.storeContainerCharges(chargesData,containerId,totalAmount,totalCost)
  
  }


  const defaultAdditional = {
    page: 1
  };
  
  const [pdfAction,setPdfAction] = useState('')

  useEffect(() => {
    if (pdfAction==='non-hazardous') {
     const constainerData= props.PdfNonHazardousResponse?.data
      setTimeout(() => {
        downloadPdfDocument( <NonHazardousDesign data={props.PdfNonHazardousResponse}/>, `Non Hazardous ${constainerData.cont_booking_no}`);

      }, 600);
    }

    if (pdfAction==='customs') {
      const constainerData= props.pdfCustomsResponse?.data
       setTimeout(() => {
         downloadPdfDocument( <CustomDesign data={props.pdfCustomsResponse}/>, `Customs ${constainerData.cont_booking_no}`);
 
       }, 600);
     }

     if (pdfAction==='bl_instructions') {
      const constainerData= props.PdfBLInstructionResponse?.data
       setTimeout(() => {
         downloadPdfDocument( <BLInstructionDesign data={props.PdfBLInstructionResponse}/>, `Instructions ${constainerData.cont_booking_no}`);
 
       }, 600);
     }

  }, [props.PdfNonHazardousResponse,props.pdfCustomsResponse,props.PdfBLInstructionResponse]);


  const downloadPdfDocument = async (document,fileName) => {

      const blob = await pdf((
        document
      
    )).toBlob();
    saveAs(blob, fileName);
   
   
};

const handleDownloadClick = async (id) => {

    await props.nonHazardousPdf(id);
 
};



  const downloadZipImages=(id)=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to download images of container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.downloadImagesContainerZip(id);
      }
    });
    

  }


  const deleteContainer=(id)=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.ContainerDelete(id);
      }
    });
    

  }

  

  const deleteSelectedAutosContainer=()=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete selected autos in this container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.selectedAutosInContainersDelete(isCheck);
      }
    });
    

  }


  const handleSearchPages=(pageNumber)=>{
    console.log(pageNumber)
    props.searchContainersList(props.searchContainers,pageNumber)
  
  }



  const toggleDropdown = (index) => {
    setIsOpenDropDown((prevOpenState) => ({
      ...prevOpenState,
      [index]: !prevOpenState[index],
    }));
  };

  const closeDropdown = () => {
    setIsOpenDropDown({});
  };

  const handleDateInputChange = (e, dateSelectorName) => {
    // let formated = moment(e).format('YYYY-MM-DD')
    setAutoRegistrationData({ ...autoRegistrationData, [dateSelectorName]: e });
  };

  const handleDateInputChangeContainer = (e, dateSelectorName) => {
    // let formated = moment(e).format('YYYY-MM-DD')
    setcontainerRegData({ ...containerRegData, [dateSelectorName]: e });
  };



  const handleMoreInfoSelectors = (e, selectorName) => {
    setAutoAdditionalDataErrors({ auto_catagory_error: "" });
    let selectName = selectorName.name.toString();
    setcontainerRegData({ ...containerRegData, [selectName]: e });
  };

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const titleStatus = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "BOS (exportable)", label: "BOS (exportable)" },
  ];

  // ==========================************================
  // All selectors options
  // ==========================************================

 


  let landingPointOptions;
  if (props?.SelectorsFetch?.warehouse) {
    landingPointOptions = props.SelectorsFetch?.warehouse.map(function (data) {
      return { value: data.warehouse_name, label: data.warehouse_name };
    });
  }

  let portOptions;
  if (props?.SelectorsFetch?.port) {
    portOptions = props.SelectorsFetch?.port.map(function (data) {
      return { value: data.port_name, label: data.port_name };
    });
  }

  const [uniqueValues,setUniqeValues]=useState('');
  const [uniqueValuesAddOn,setUniqeValuesAddOn]=useState('');
  const [containerStatus,setContainerStatus]=useState('not-mixed');
  const [containerStatusAddOn,setContainerStatusAddOn]=useState('not-mixed');


  useEffect(() => {
  
    if (customer ||containerStatus ) {
        const filterBy=customer?customer.value:'no-value'
      
      loadContainerOptions('', [], filterBy,containerStatus, { page: 1}).then((data)=>{
        setUniqeValues(data.options)
      })
        .catch((error) => {
          console.error('Error loading data:', error);
        });
    }
    
  }, [customer,containerStatus]);
 
  

  useEffect(() => {
  
    if (customerAddOn || containerStatusAddOn ) {
        const filterBy=customerAddOn?customerAddOn.value:'no-value'
      
      loadContainerOptions('', [], filterBy,containerStatusAddOn, { page: 1}).then((data)=>{
        setUniqeValuesAddOn(data.options)
      })
        .catch((error) => {
          console.error('Error loading data:', error);
        });
    }
    
  }, [customerAddOn,containerStatusAddOn]);
 
  


 
  const handleRegContainerClose = () => {
    setcontainerRegData({ ...containerRegData, invoice: null });
    setdragAndDropAdditionalInvoiceSelected(false);
    setdragAndDropAdditionalInvoiceText(
      "Upload or Drag and Drop your images here"
    );
    setcontainerRegData({ ...containerRegData, images: [] });

    setdragAndDropSelectedImages(false);
    setdragAndDropTextImages("Upload or Drag and Drop your images here");
    props.hideContainerRegModal();

    setAutoAdditionalDataErrors({ auto_catagory_error: "" });
  };

  const handleChangeAdditional = (file) => {
    if (_.isEmpty(file)) {
      setdragAndDropAdditionalInvoiceSelected(true);
      setdragAndDropAdditionalInvoiceText(file.name);
      setcontainerRegData({ ...containerRegData, invoice: file });
    } else {
      setdragAndDropAdditionalInvoiceSelected(false);
    }
  };

  const handleEditContainerClose=()=>{
    setcontainerRegData({
      onId: "",
      container_number: "",
      images: [],
      booking_number: "",
      lines: null,
      dock_receipt: null,
      bill_copy: null,
      telex_release: null,
      port: "",
      loading_date: null,
      expected_arrival_date: null,
      container_arrival_date: null,
      validations: null,
    })
    props.hideContainerEditModal()
  }

const handleAddAutoInContainerClose=()=>{
props.hideContainerAutoAdditionModal()
}

const handleInvoiceModalToggle=()=>{
  props.showContainerInvoiceModal()
  }
const addAutosInContainer=(e)=>{
  e.preventDefault();
  if(autosInContainer.length > 0){
props.containerAddAutos(onId,autosInContainer,props?.containersListResponse?.data?.current_page)
  }else{
    setError('Please, select any vin of the auto');
  }

}


const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchContainers){
  setSearched(true);
    props.searchContainersList(props.searchContainers,props.currentPage)
    }else{
      setSearched(false);
     
  
    }
  
  }


// search function
const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    
    props?.ContainersList(props.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const filteredCharges = props.containerInvoiceInformation?.charges.filter(charge =>
  props.containerInvoiceInformation?.headers?.includes(charge.charge_name)
);



// const handleSelectAll = e => {
//   setIsCheckAll(!isCheckAll);
//   setIsCheck(props.containersListResponse?.containerAutos?.map(li => li.id));
//   if (isCheckAll) {
//     setIsCheck([]);
//   }
// };

const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};

const handleClickCheckContainer = e => {
  const { id, checked } = e.target;
  setIsCheckContainer([...isCheckedContainer, id]);
    if (!checked) {
      setIsCheck([]);
      setIsCheckContainer(isCheckedContainer.filter((item) => item !== id));
    }
 
};


const uploadImages = () =>{

  props.storeImagesContainer(containerRegData)

}

const handleCatagory=(page,catagory)=>{
props.containersCatagoryList(page,catagory)
}


// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



// for calculations of total amount and total cost
const totalCost = calculateTotal(chargesData,'cost');


const totalQuantity = calculateTotal(chargesData,'quantity');

const totalAmount = calculateTotal(chargesData,'total');

const countTotalContainers=(catagory)=>{
  const categoryData =props?.containersListResponse?.totalContainers?.find((item)=>item.cont_catagory===catagory)?.totalContainers||0;

return categoryData;
}

 
const handleNavigate=(id,loc)=>{
  navigate(`/${loc}/${id}`);
}
const changeTypeContainer = (event) => {
  const checked = event.target.checked;
  setCustomer(null);
  onChange([]);
  
  if (checked) {
    setContainerStatus('mixed');
    setContainerType(true);
  } else {
    setContainerStatus('not-mixed');
    setContainerType(false);
  }
};

const changeTypeContainerAddon = (event) => {
  const checked = event.target.checked;
  setCustomerAddOn(null);
  setAutosInContainer([]);
  
  if (checked) {
    setContainerStatusAddOn('mixed');
    setContainerTypeAddOn(true);
  } else {
    setContainerStatusAddOn('not-mixed');
    setContainerTypeAddOn(false);
  }
};



  return (
    <>
      <div className="outer-container box-shadow">

   
        <div className={`card ${header}`}>
          <div className="row">
            <div className="col">
              <form onSubmit={onSearch}>
                <div className="row">
     

                  {/* <div className="col-md-2 ">
          <OverlayTrigger
      key={'forSearch'}
      placement={'bottom'}
      delay={{ show: 3000, hide: 0 }}
      overlay={
        TooltipSearchFor
      }
    >

            <div className="selectBox">
              <select className="searchIn" name="">
                <option disabled>Search Catagory</option>
                <option>VIN</option>
                <option>LOT</option>
              </select>
            </div>
           </OverlayTrigger>
          </div> */}
          <div className="col-md-2 "> 
          { isCheckedContainer.length>0?<span style={{position:'absolute',top:'8px',right:'60px'}}><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={()=>props.selectedContainersDelete(isCheckedContainer)}>Delete ({(isCheckedContainer.length)})</a></span>:''}

           </div>
                  <div className="col-md-6 ">
                  <div className="outLineOfSearch">
                    <div className="input-group">
                   
                 
                    
                          <input
                            type="search"
                            name="searchContainers"
                            onChange={onSearchInputChange}
                            className="form-control search"
                            placeholder="Seach by Container No or Arrival Date"
                            autoComplete="off"
                            required
                          />
                      
                      
                        {/* { isCheck.length>0?<span style={{position:'absolute',top:'8px',right:'60px'}}><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={()=>props.selectedAutosDelete(isCheck)}>Delete ({(isCheck.length)})</a></span>:''} */}
                    
                      <button type="submit" className="btn btn-primary">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  </div>



                  <div className="col-md-2 pt-2">
                  <span style={{paddingLeft:'42px'}}>{isCheck.length>0 ? <a className="delete-selected-autos" onClick={()=>{deleteSelectedAutosContainer()}}>Delete selected ({isCheck.length})</a>:''}</span>
</div>


                </div>
              </form>
            </div>
          </div>





          <div className="row">
<div className="col-md-12">
<div className="wizard-steps-wrapper">

<div className="wizard-steps">
    <div className={currentPath === '/home/containerslist/New' ? 'active-step' : 'step'}>
        <NavLink to="/home/containerslist/New"  onClick={()=>handleCatagory(props.currentPage,'New')} >
          
            <span>{countTotalContainers('New')}</span> 
              New
        </NavLink>
    </div>
    <div  className={currentPath === '/home/containerslist/Submit-to-CPB' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/Submit-to-CPB" onClick={()=>handleCatagory(props.currentPage,'Submit-to-CPB')} >
            <span>{countTotalContainers('Submit-to-CPB')}</span> 
            SUBMIT TO CPB
        </NavLink>
    </div>
    <div  className={currentPath === '/home/containerslist/CPB-Approved' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/CPB-Approved" onClick={()=>handleCatagory(props.currentPage,'CPB-Approved')} >
            <span>{countTotalContainers('CPB-Approved')}</span> 
            CPB APPROVED
        </NavLink>
    </div>
    <div  className={currentPath === '/home/containerslist/HBL-Confirmed' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/HBL-Confirmed" onClick={()=>handleCatagory(props.currentPage,'HBL-Confirmed')} >
    <span>{countTotalContainers('HBL-Confirmed')}</span> 
            HBL CONFIRMED
        </NavLink>
    </div>

    <div  className={currentPath === '/home/containerslist/SI-Submitted' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/SI-Submitted" onClick={()=>handleCatagory(props.currentPage,'SI-Submitted')} >
    <span>{countTotalContainers('SI-Submitted')}</span> 
            SI SUBMITTED
        </NavLink>
    </div>

    <div  className={currentPath === '/home/containerslist/VGM' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/VGM" onClick={()=>handleCatagory(props.currentPage,'VGM')} >
    <span>{countTotalContainers('VGM')}</span> 
            VGM
        </NavLink>
    </div>


    <div  className={currentPath === '/home/containerslist/BL-sent-to-client' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/BL-sent-to-client" onClick={()=>handleCatagory(props.currentPage,'BL-sent-to-client')} >
    <span>{countTotalContainers('BL-sent-to-client')}</span> 
            B/L SENT TO CLIENT
        </NavLink>
    </div>

    <div  className={currentPath === '/home/containerslist/ETA' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/ETA" onClick={()=>handleCatagory(props.currentPage,'ETA')} >
    <span>{countTotalContainers('ETA')}</span> 
           ETA
        </NavLink>
    </div>

    <div className={currentPath === '/home/containerslist/Incomplete' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/Incomplete" style={countTotalContainers('Incomplete')>0?{background:'#7a0414',color:'white'}:{}} onClick={()=>handleCatagory(props.currentPage,'Incomplete')} >
    <span>{countTotalContainers('Incomplete')}</span> 
            INCOMPLETE
        </NavLink>
    </div>

    <div  className={currentPath === '/home/containerslist/Completed' ? 'active-step' : 'step'}>
    <NavLink to="/home/containerslist/Completed" onClick={()=>handleCatagory(props.currentPage,'Completed')} >
    <span>{countTotalContainers('Completed')}</span> 
            COMPLETED
        </NavLink>
    </div>
    
</div>
</div>
</div>

</div>








        </div>








 {/* ========functional Loader==========   */}

 {
  props.functionalLoader?
<Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:''
}


        { 
  searched && <div style={{textAlign:'center',color:'white',paddingTop:'12px'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchContainers}</span></p></div>
}
        {
          props.containersListResponse?.data?.data?.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}
        {/* {props?.containersListResponse?.data.data.filter((filterValue)=> props?.containersListResponse.containerAutos.some((someValue)=>filterValue.id===someValue.containers_id)).map((list,index)=>{ */}
        {
         props.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:
          
          props?.containersListResponse?.data.data.map((list,index)=>{

          return (

<div key={index}>
        <div className="gap"></div>

        <div className="card listSection langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))} >
    {can(currentUserPermissions,'Delete Multiple Containers') ?  <div className="checkbox-container">

    { list.cont_paid_status==="paid"?<div className="status-paid">PAID</div>:<div className="status-unpaid">UNPAID</div>}

         <div className={`cont-badge-${list.cont_catagory}`}>{list.cont_catagory?.replace(/-/g,' ')?.toUpperCase()}</div>
               

        

         <Checkbox
 key={list.id}
 type="checkbox"
 name={list.id}
 id={list.id}
 handleClick={handleClickCheckContainer}
 isChecked={isCheckedContainer.find(li=>li===list.id)}
/>
       </div>:""}


          <div className="row listSecitionInside">
            {  props.containersListResponse?.images?.filter(image => image.containers_id === list.id).map((img,ind)=>{
           return(
            <div className="col-md-2" style={{cursor:'pointer'}} key={ind}>

            <div className="imgStyle" onClick={()=>{
                 setOnId(list.id)
                 props.getContainerImages(list.id)
  setTimeout(() => {
    setImagesModal(true)
  },1000)
            }}>
                <span>
                  <span className="badge-container">{img.totalImages}</span>
               
<img alt="autos"  src={`${process.env.REACT_APP_CONTAINER_IMAGES}/${img.image_name}`} className="card-image-top"/>

                </span>
              </div>
              </div>
           )
           
            })}
             
          
            <div className="col">
              <div className="row listSecitionInside">
                <div className="col-md-3">
                  <span className="titleCont">{t('key_container_no_booking')}: </span>
                  <span>{list.container_no}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_booking_no')}: </span>
                  <span>{list.cont_booking_no}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_lines')}: </span>
                  <span>{list.cont_line}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('ETA')}: </span>
                  <span>{list.cont_eta}</span>
                </div>
            {/* {can(currentUserPermissions,'View Dock Receipt')?    <div className="col-md-3">
                  <span className="titleCont" dir="">Dock Receipt: </span>
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_doc_receipt}`} target={"_blank"} dir="ltr">{list?.cont_doc_receipt? list?.cont_doc_receipt.substring(13,20).concat('...'):''}</a></span>

                </div>:""} */}
              </div>

              <div className="row listSecitionInside">
               {/* { can(currentUserPermissions,'View BL Copy')?<div className="col-md-3">
                  <span className="titleCont">BL Copy: </span>{" "}
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_bill_copy}`} target={"_blank"} dir="ltr">{list?.cont_bill_copy? list?.cont_bill_copy.substring(13,20).concat('...'):''}</a></span>
                </div>:""} */}


                <div className="col-md-3">
                  <span className="titleCont">{t('Loading Date')}: </span> <span>{list.cont_date_loading}</span>
                </div>

                <div className="col-md-3">
                  <span className="titleCont">{t('Completed')}: </span> <span>{list.cont_completed_date}</span>
                </div>

             
                {/* { can(currentUserPermissions,'View Telex Release')? <div className="col-md-3">
                  <span className="titleCont">Telex Release: </span>{" "}
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_telex_release}`} target={"_blank"} dir="ltr">{list?.cont_telex_release?list?.cont_telex_release.substring(13,20).concat('...'):''}</a></span>
                </div>:""} */}

                <div className="col-md-3">
                  <span className="titleCont">{t('key_port')}: </span> <span>{list.cont_port}</span>
                </div>

                
                <div className="col-md-3">
                  <span className="titleCont">{t('key_point_of_loading')}:</span><span>{list.cont_point_loading}</span>
                </div>
              </div>


              <div className="row listSecitionInside">
               

            
               <div className="col-md-3">
                 <span className="titleCont">{t('Port of Loading')}: </span>
                 <span>{list.cont_port}, {list.cont_country_port}</span>
               </div>
             

               <div className="col-md-3">
                 <span className="titleCont">{t('Port of Discharge')}: </span>
                 <span>{list.cont_port_discharge}, {list.cont_country_discharge}</span>
               </div>

               <div className="col-md-3">
                 <span className="titleCont">{t('Container Size')}: </span>
                 <span>{list.cont_container_size}</span>
               </div>
                 <div className="col-md-3">
                 <span className="titleCont">{t('Vessel Name')}: </span>
                 <span>{list.cont_vessel_name}</span>
               </div> 
</div>

              <div className="row listSecitionInside">
               

                {/* <div className="col-md-3">
                  <span className="titleCont">{t('key_date_of_loading')}: </span>
                  <span>{list.cont_date_loading}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_expected_arrival_date')}: </span>
                  <span>{list.cont_expect_date_arrival}</span>
                </div>

                <div className="col-md-3">
                  <span className="titleCont">{t('key_container_arrival_date')}: </span>
                  <span>{list.cont_arrival_date}</span>
                </div> */}

               {can(currentUserPermissions,'View Validations') ? <div className="col-md-3">
                <span className="titleCont">Validations: </span> 
                <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_validation}`} target={"_blank"} dir="ltr">{list?.cont_validation? list?.cont_validation.substring(13,20).concat('...'):''}</a></span>

                </div>:""}


              </div>

        

              <div className="lineContiner"></div>
          

<div className="row pt-1">
<span className="titleCont pb-2">{t('key_place_vehicles_in_container')} </span> 
                  {/* add maping here */}

                  {  props.containersListResponse?.containerAutos?.filter(filterValue => filterValue.containers_id === list.id).map((contVehicle,i)=>{
           return(
          
<div className="col-md-3" key={i}>
                
                <div style={{ position: "relative" }}>
                  <div className="card pb-1 card-new"  >
                  <Link to={`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(contVehicle.autoId?.toString(),'autoListGhulam_123'))}`}>

                    <div className="card-body p-2">
                    <div style={{ color: "#084896", fontWeight: "600", }}>
                     {contVehicle.vin}
                    </div>
                    <div>
                      <span  className="info-header" >LOT: &nbsp;</span>
                      <span>{contVehicle.lot} </span>
<div  className="user-vehicle">  <span className="info-header">By: &nbsp;</span>
                      <span>{contVehicle.userName}</span></div>
                    
                    </div>
                  </div>
                  </Link>
                  <div style={{ height: "6px" }}></div>
               {can(currentUserPermissions,'Delete Autos in Container') ? <div className="delete-icon-autos">
                  <Checkbox
          key={contVehicle.id}
          type="checkbox"
          name={contVehicle.id}
          id={contVehicle.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===contVehicle.id)}
        />
                  </div>:''}
                  </div>
                </div>
        

      </div>

           )
           
            })}



       
  
      





                                  {/* add maping here */}



</div>






            </div>

            <div className="outer-layer-icon-bar">
          <div className="icon-bar-container">

          { can(currentUserPermissions,'Delete Container')?   <Link to={`/home/containerView/${encodeURIComponent(CryptoAES.encrypt(list.id.toString(),'autoListAli_123'))}`} >
              <i className="fa fa-eye" title="view this container"></i>
            </Link>:''}
   


         { can(currentUserPermissions,'Add Autos to Container')?<a onClick={()=>{
            setOnId(list.id);
            props.showContainerAutoAdditionModal()
            }}>
              <i className="fa fa-plus" title="Add Vehicle to Containers"></i>
            </a>:''}

            { can(currentUserPermissions,'Download Container Images Zip')?    <a onClick={()=>downloadZipImages(list.id)}>
            <i className="fas fa-file-archive" title="download container images"></i>
            </a>:''}
            

            { can(currentUserPermissions,'Edit Container')? <a onClick={()=>{
              props.getContainerDetails(list.id)
              setTimeout(
                ()=>{
                  props.showContainerEditModal()
                },550)
              }}>
              <i className="fa fa-edit" title="edit container information"></i>
            </a>:""}


            { can(currentUserPermissions,'Allow Container Charges')? <a  onClick={()=> {
                
                props.getChargesContainersRows(list.id)
                setContainerId(list.id)

           setTimeout(()=>{

             props.showContainerChargesModal()

           },550)
          //  setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,'onId':list.real_auto_id})

         
         }}>
           <i className="fas fa-money" title="Container Charges">

             
           </i>
         </a>:''}

         { can(currentUserPermissions,'Allow Container Invoice')?    <a  onClick={()=> {
                
                props.ContainerInvoice(list.id)
                // setContainerId(list.id)
                // setInvoiceUpdate({...invoiceUpdateDate,container_id:list.id})

           setTimeout(()=>{

          handleInvoiceModalToggle()

           },550)
          //  setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,'onId':list.real_auto_id})

         
         }}>
              <i className="fas fa-file-invoice" title="View container Invoice"></i>
            </a>:''}

          {can(currentUserPermissions,'Allow Pdfs') ?
         <div key={index} className={`dropdownCustom ${isOpenDropDown[index] ? 'open' : ''}`}>
          <div className="dropdownCustom-toggle" onClick={() => {
            setIdPdf(list.id);
            toggleDropdown(index);
            }}>
          <i class="fas fa-file-pdf"></i>            
          </div>
          <ul className="dropdownCustom-menu">
  <li>
  <span><i className="fas fa-eye"></i></span>  <span>View PDFs</span>

  </li>

          {can(currentUserPermissions,'Allow Customs Pdf') ?   <a href={`/customs-pdf/${encodeURIComponent(CryptoAES.encrypt(list.id.toString(),'autoListGhulam_123'))}`} onClick={closeDropdown} target="_blank" >Customs Report</a> :null}
          {can(currentUserPermissions,'Allow NonHazardous Pdf') ?   <a href={`/non-hazardous-pdf/${encodeURIComponent(CryptoAES.encrypt(list.id.toString(),'autoListGhulam_123'))}`} onClick={closeDropdown} target="_blank">Non-Hazardous Report</a>:null}
          {can(currentUserPermissions,'Allow BL Intructions Pdf') ? <a href={`/bl_Instruction/${encodeURIComponent(CryptoAES.encrypt(list.id.toString(),'autoListGhulam_123'))}`} onClick={closeDropdown} target="_blank">Bl. Instruction Report</a>:null}
<li>           
    <span><i className="fas fa-download"></i></span>  <span>Download PDFs</span>
</li>

{can(currentUserPermissions,'Allow Customs Pdf') ? <a onClick={()=>{
  setPdfAction('customs')
props.customsPdf(list.id)
  }}>Customs PDF</a>:null
  }

{can(currentUserPermissions,'Allow NonHazardous Pdf') ? <a onClick={()=>{
    setPdfAction('non-hazardous')

props.nonHazardousPdf(list.id)
  }}>Non Hazardous PDF</a>:null
  }

{can(currentUserPermissions,'Allow BL Intructions Pdf') ? <a onClick={()=>{
    setPdfAction('bl_instructions')
props.bLInstructionPdf(list.id)
  }}>Bl. Instructions PDF</a>:null
  }

          </ul>
        </div>
        :null
        }

            { can(currentUserPermissions,'Delete Container')?   <a onClick={()=>{deleteContainer(list.id)}}>
              <i className="fa fa-trash" title="delete this container"></i>
            </a>:''}
          </div>
        </div>
           
          </div>
          {/* outer row */}

         




         




</div>
    </div> 
    // full outer
     





          )
        })}

        

        { can(currentUserPermissions,'Create Container')?<button
          className="floating-btn-container"
          onClick={() => props.showContainerRegModal()}
        >
          Create Container
        </button>:''}




        { props.containersListResponse?.data?.data.length===0 ? '':
<div>
      {props.containersListResponse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.containersListResponse?.data?.current_page}
              itemsCountPerPage={props.containersListResponse?.data?.per_page}
              totalItemsCount={props.containersListResponse?.data?.total}
                // pageRangeDisplayed={5}
              // onChange={searched==true? handleSearchPages:(pageNumber) => props.ContainersList(pageNumber)}
              onChange={
                searched==true? handleSearchPages
                :params.catagory? handlePageChange :(pageNumber) => props.containersCatagoryList(pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}





      </div>

      {/*
 =================================================================================================================
modal to add container information to the database
==================================================================================================================
*/}

      <Modal
        show={props.openContainerRegistrationModal}
        onHide={handleRegContainerClose}
        size="xl"
        scrollable={true}
      >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Register Container
          </Modal.Title>
          <CloseButton
            variant={"white"}
            onClick={handleRegContainerClose}
          ></CloseButton>
        </Modal.Header>

        <form
          onSubmit={onstoreContainerData}
          encType="multipart/form-data"
          id="imageForm"
        >
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
  <div className="row">
  <div className="col-md-6 pb-3">
            <label htmlFor="vins" className="picker-inputs-lebel">
                 Select Customer
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={customer}
        loadOptions={loadCustomersOptions}
        onChange={(selected)=>{
          setCustomer(selected)
          onChange([])
        }}
        closeMenuOnSelect={true}
        isClearable
        additional={{
          page: 1,
        }}

        isDisabled={containerType?true:false}
        
      />
     {/* {vinsFieldRequired? (
                  <div className="validation-error">Please select any VIN !</div>
                ) : (
                  ""
                )} */}

            </div>


<div className="col-md-6 pb-3">

<Form.Check 
        type="switch"
        id="custom-switch"
        label="Mixed Container"
        checked={containerType}
      onChange={changeTypeContainer}
      />
</div>

</div>
            <div className="row">

            <div className="col-md-6 pb-3">
            <label htmlFor="vins" className="picker-inputs-lebel">
                 Select VINS
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={value}
        loadOptions={(search, loadedOptions, {page}) =>{
       const filterBy=customer?customer.value:'no-value';
          return loadContainerOptions(search, loadedOptions,filterBy,containerStatus,{page}) 
          }  }
        loadOptionsOnMenuOpen={true}
        onChange={onChange}
        isMulti
        closeMenuOnSelect={false}
        additional={{
          page: 1,
        }}
        key={uniqueValues}
      />
     {vinsFieldRequired? (
                  <div className="validation-error">Please select any VIN !</div>
                ) : (
                  ""
                )}

            </div>
       
<div className="col-md-3 mb-2">
  <label htmlFor="catagory" className="picker-inputs-lebel">
    Container Process
  </label>
  <Select
    options={sequenceSteps}
    theme={theme}x
    placeholder="select process status"
    name="catagory"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.catagory || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>


            <div className="col-md-3 pb-3">
            <label htmlFor="consignee" className="picker-inputs-lebel">
                Consignee
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.consignee}
        loadOptions={loadConsigneeOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'consignee')}
        closeMenuOnSelect={true}
        isClearable
        additional={{
          page: 1,
        }}
        
      />
     

            </div>


             
            </div>


<div className="row">
<div className="col-md-3 mb-4">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading (warehouse)
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.point_of_loading || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />
                {props.autoEditErrors?.error.point_of_loading ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 pb-3">
            <label htmlFor="forwarder" className="picker-inputs-lebel">
                 Forwarder
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.forwarder}
        loadOptions={loadForwarderOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'forwarder')}
      isClearable
        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
     

            </div>


            <div className="col-md-3 pb-3">
            <label htmlFor="trucker" className="picker-inputs-lebel">
                 Trucker
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.trucker}
        loadOptions={loadTruckerOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'trucker')}
        isClearable
        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
     

            </div>
            <div className=" col-md-3 mb-2">
              <label htmlFor="exporter_name" className="picker-inputs-lebel">
                  Exporter Name
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                value={containerRegData.exporter_name}
                name="exporter_name"
                placeholder="Exporter Name"
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>

</div>



            <div className="row">


            

              <div className="col-md-3 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port of Loading
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.port || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

          
              <div className="col-md-3 mb-2">
                <label htmlFor="country_port" className="picker-inputs-lebel">
                  Country of Loading
                </label>
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country Loading"
                  name="country_port"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.country_port || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>


              <div className="col-md-3 mb-2">
                <label htmlFor="port_discharge" className="picker-inputs-lebel">
                  Port of Discharge
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port_discharge"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.port_discharge || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-3 mb-2">
                <label htmlFor="country_discharge" className="picker-inputs-lebel">
                  Country of Discharge
                </label>
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country Discharge"
                  name="country_discharge"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.country_discharge || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>



            </div>

            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Container Details</span></div>
</div>
</div>
            <div className="row">
              <div className="col-md-3 mt-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="container_number"
                    placeholder=" "
                    // required
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.container_number || ""}
                    // required
                  />
                  <label htmlFor="container_number">Container Number</label>
                </Form.Floating>
                {props.containersCreationErrors?.error?.container_number ? (
                  <div className="validation-error">The Container Number has already been taken !</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="booking_number"
                    required
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.booking_number || ""}
                  />
                  <label htmlFor="booking_number">Booking Number</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="lines"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.lines || ""}
                  />
                  <label htmlFor="lines">Shipping Lines</label>
                </Form.Floating>
              </div>

              <div className="col-md-3 mt-3  mb-2">
                <Form.Floating>
                  <Form.Control
                    type="input"
                    name="seal"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.seal || ""}
                  />
                  <label htmlFor="seal">Seal</label>
                </Form.Floating>
              </div>

              
            </div>


            <div className="row">

            <div className="col-md-3 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Container Size
                </label>
                <Select
                  options={containerSize}
                  theme={theme}x
                  placeholder="container size"
                  name="container_size"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.container_size || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>


            <div className="col-md-6 pb-3">
            <label htmlFor="vessel_name" className="picker-inputs-lebel">
                 Vessel Name
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.vessel_name}
        isClearable
        loadOptions={loadVesselOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'vessel_name')}

        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
    

            </div>

        


              <div className=" col-md-3 mb-2">
              <label htmlFor="vessel_voyage" className="picker-inputs-lebel">
                  Vessel Voyage
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="vessel_voyage"
                placeholder="Vessel Voyage"
                value={containerRegData.vessel_voyage}
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
                // Add your onChange and value props as needed
              />
            </div>

            </div>

            <div className="row">
  
            </div>



            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="document_cut_date" className="picker-inputs-lebel">
                  Document Cut Date
                </label>

                <DatePicker
                  selected={containerRegData.document_cut_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "document_cut_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Document Cut Date"
                  name="document_cut_date"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="document_cut_date"
                  className="picker-inputs-lebel"
                >
                 Port Cut Date
                </label>

                <DatePicker
                  selected={containerRegData.port_cut_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "port_cut_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Port Cut Date"
                  name="port_cut_date"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="departure_date"
                  className="picker-inputs-lebel"
                >
                 Departure Date
                </label>

                <DatePicker
                  selected={containerRegData.departure_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "departure_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Departure Date"
                  name="departure_date"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="eta"
                  className="picker-inputs-lebel"
                >
             ETA
                </label>

                <DatePicker
                  selected={containerRegData.eta}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "eta")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="ETA"
                  name="eta"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="cbp_completed" className="picker-inputs-lebel">
                CPB Completed
                </label>

                <DatePicker
                  selected={containerRegData.cpb_completed}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "cpb_completed")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="CPB Completed"
                  name="cpb_completed"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="si_submission_date"
                  className="picker-inputs-lebel"
                >
                 SI Submission Date
                </label>

                <DatePicker
                  selected={containerRegData.si_submission_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "si_submission_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="SI Submission Date"
                  name="si_submission_date"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label htmlFor="loading_date" className="picker-inputs-lebel">
                  Loading Date
                </label>

                <DatePicker
                  selected={containerRegData.loading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "loading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Date of loading"
                  name="loading_date"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="completed_date"
                  className="picker-inputs-lebel"
                >
            Completed
                </label>

                <DatePicker
                  selected={containerRegData.completed_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "completed_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Completed"
                  name="completed_date"
                  autoComplete="off"
                />
              </div>
            </div>

        
            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Validation Details</span></div>
</div>
</div>
<div className="row">

<div className="col-md-3 mb-2">
                <label
                  htmlFor="validation_date"
                  className="picker-inputs-lebel"
                >
            Validation Date
                </label>

                <DatePicker
                  selected={containerRegData.validation_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "validation_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Validation Date"
                  name="validation_date"
                  autoComplete="off"
                />
              </div>

              <div className=" col-md-2 mb-2">
              <label htmlFor="itn" className="picker-inputs-lebel">
                  ITN
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="itn"
                placeholder="ITN"
                value={containerRegData.itn}

                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>
            <div className=" col-md-2 mb-2">
              <label htmlFor="xtn" className="picker-inputs-lebel">
                  XTN
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="xtn"
                placeholder="XTN"
                value={containerRegData.xtn}

                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
            />
            </div>
            <div className=" col-md-2 mb-2">
              <label htmlFor="steamship_line" className="picker-inputs-lebel">
                  Steamship Line
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="steamship_line"
                placeholder="Steamship Line"
                value={containerRegData.steamship_line}
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>

            <div className=" col-md-3 mb-2">
              <label htmlFor="terminal" className="picker-inputs-lebel">
                  Terminal
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="terminal"
                value={containerRegData.terminal}
                placeholder="Terminal"
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>


</div>


            <div className="row">
             

              {/* <div className="col-md-3 mb-2">
                <label
                  htmlFor="expected_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Expected Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.expected_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "expected_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Expected Arrival Date"
                  name="expected_arrival_date"
                  autoComplete="off"
                />
              </div> */}

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Container Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.container_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Container Arrival Date"
                  name="container_arrival_date"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Unloading Date
                </label>

                <DatePicker
                  selected={containerRegData.container_unloading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_unloading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Unloading Date"
                  name="container_unloading_date"
                  autoComplete="off"
                />
              </div>


              <div className="col-md-3 mb-2">
                <label htmlFor="paid_status" className="picker-inputs-lebel">
                  Paid Status
                </label>
                <Select
                  options={paidStatus}
                  theme={theme}
                  placeholder="Paid Status"
                  name="paid_status"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.paid_status || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

            </div>






            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>File Uploads</span></div>
</div>
</div>


            <div className="row">

<div className="col-md-4">

{can(currentUserPermissions,'Add Container Images')?
<div style={{ marginTop: "30px" }}>
      
           
      <FileUploader
   
        handleChange={handleChangeImages}
        name="file"
        types={["jpeg","jpg"]}
        label={"Upload invoice here"}
        classes={""}
        multiple
        children={
          <div
            className={
              dragAndDropSelectedImages === false
                ? "dragDrop"
                : "dragDropSelected"
            }
          >
        
            <span>
              {dragAndDropTextImages}
              {dragAndDropSelectedImages === true ? (
                <span
                  style={{ fontSize: "11px", color: "darkgreen" }}
                >
                  {" "}
                  selected
                </span>
              ) : (
                ""
              )}
            </span>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2px",
                color: "rgb(10, 80, 134)",
              }}
            >
              jpeg, jpg{" "}
              {dragAndDropSelectedImages === false ? (
                ""
              ) : (
                <i className="fas fa-check"></i>
              )}
            </div>
          </div>
        }
      />
      &nbsp;  &nbsp;

      { containerRegData.images.length>0? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
        setcontainerRegData({...containerRegData,images:[]})
        setdragAndDropSelectedImages(false)
        setdragAndDropTextImages("Upload or Drag and Drop your images here")
   
        }    }>Reset Images</a>:'' }
    </div>:""}

</div>
<div className="col-md-8">

<div className="row ">
              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="dock_receipt" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.dock_receipt}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>


          
              <div className="col-md-6">
                <div className="fileuploadcontainer" >
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="bl_copy" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
    </span> <span>{fileName.bl_copy}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>


            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf"  onChange={handleAttachment} name="telex_release"/>
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.telex_release}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="validations" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.validations}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

            </div>









</div>


            </div>

            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Create Container
            </button>
          </Modal.Footer>
        </form>
      </Modal>










  {/*
 =================================================================================================================
modal for editing container details
==================================================================================================================
*/}

<Modal
        show={props.openContainerEdit}
        onHide={handleEditContainerClose}
        size="fullscreen"
    

      >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Edit Container Details
          </Modal.Title>
          <CloseButton
            variant={"white"}
            onClick={handleEditContainerClose}
          ></CloseButton>
        </Modal.Header>

        <form
          onSubmit={onUpdateContainerData}
          encType="multipart/form-data"
          id="imageForm"
        >
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
            maxHeight: "calc(100vh - 140px)",
            overflowY: "auto",
            }}
            className="modal-body"
          >

<div className="row">
  <div className="col-md-6 pb-3">
            <label htmlFor="vins" className="picker-inputs-lebel">
                 Select Customer
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={customer}
        loadOptions={loadCustomersOptions}
        onChange={setCustomer}
        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
   

            </div>
</div>


             <div className="row">
            
<div className="col-md-6 mb-2">
  <label htmlFor="port" className="picker-inputs-lebel">
    Container Process
  </label>
  <Select
    options={sequenceSteps}
    theme={theme}
    placeholder="Catagory"
    name="catagory"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.catagory || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>

            <div className="col-md-4 pb-3">
            <label htmlFor="consignee" className="picker-inputs-lebel">
                Consignee
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.consignee}
        loadOptions={loadConsigneeOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'consignee')}
        closeMenuOnSelect={true}
        isClearable
        additional={{
          page: 1,
        }}
        
      />
     

            </div>


             
            </div>



            <div className="row">
<div className="col-md-3 mb-4">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading (warehouse)
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.point_of_loading || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />
                {props.autoEditErrors?.error.point_of_loading ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-3 pb-3">
            <label htmlFor="forwarder" className="picker-inputs-lebel">
                 Forwarder
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.forwarder}
        loadOptions={loadForwarderOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'forwarder')}
      isClearable
        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
    

            </div>


            <div className="col-md-3 pb-3">
            <label htmlFor="trucker" className="picker-inputs-lebel">
                 Trucker
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={containerRegData.trucker}
        loadOptions={loadTruckerOptions}
        onChange={(selectedOption)=>changedSelectorData(selectedOption,'trucker')}
        isClearable
        closeMenuOnSelect={true}
        additional={{
          page: 1,
        }}
        
      />
    

            </div>
            <div className=" col-md-3 mb-2">
              <label htmlFor="exporter_name" className="picker-inputs-lebel">
                  Exporter Name
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                value={containerRegData.exporter_name}
                name="exporter_name"
                placeholder="Exporter Name"
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>

</div>







<div className="row">


            

<div className="col-md-3 mb-2">
  <label htmlFor="port" className="picker-inputs-lebel">
    Port of Loading
  </label>
  <Select
    options={portOptions}
    theme={theme}
    placeholder="Port"
    name="port"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.port || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>


<div className="col-md-3 mb-2">
  <label htmlFor="country_port" className="picker-inputs-lebel">
    Country of Loading
  </label>
  <Select
    options={countryOptions}
    theme={theme}
    placeholder="Country Loading"
    name="country_port"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.country_port || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>


<div className="col-md-3 mb-2">
  <label htmlFor="port_discharge" className="picker-inputs-lebel">
    Port of Discharge
  </label>
  <Select
    options={portOptions}
    theme={theme}
    placeholder="Port"
    name="port_discharge"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.port_discharge || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>

<div className="col-md-3 mb-2">
  <label htmlFor="country_discharge" className="picker-inputs-lebel">
    Country of Discharge
  </label>
  <Select
    options={countryOptions}
    theme={theme}
    placeholder="Country Discharge"
    name="country_discharge"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.country_discharge || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>



</div>

<div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Container Details</span></div>
</div>
</div>
<div className="row">
<div className="col-md-3 mt-3 mb-2">
  <Form.Floating>
    <Form.Control
      type="input"
      name="container_number"
      placeholder=" "
      // required
      onChange={(e) => {
        onInputChangeAdditional(e);
      }}
      value={containerRegData.container_number || ""}
      // required
    />
    <label htmlFor="container_number">Container Number</label>
  </Form.Floating>
  {props.containersCreationErrors?.error?.container_number ? (
    <div className="validation-error">The Container Number has already been taken !</div>
  ) : (
    ""
  )}
</div>
<div className="col-md-3 mt-3  mb-2">
  <Form.Floating>
    <Form.Control
      type="input"
      name="booking_number"
      placeholder=" "
      onChange={(e) => {
        onInputChangeAdditional(e);
      }}
      required
      value={containerRegData.booking_number || ""}
    />
    <label htmlFor="booking_number">Booking Number</label>
  </Form.Floating>
</div>
<div className="col-md-3 mt-3  mb-2">
  <Form.Floating>
    <Form.Control
      type="input"
      name="lines"
      placeholder=" "
      onChange={(e) => {
        onInputChangeAdditional(e);
      }}
      value={containerRegData.lines || ""}
    />
    <label htmlFor="lines">Shipping Lines</label>
  </Form.Floating>
</div>

<div className="col-md-3 mt-3  mb-2">
  <Form.Floating>
    <Form.Control
      type="input"
      name="seal"
      placeholder=" "
      onChange={(e) => {
        onInputChangeAdditional(e);
      }}
      value={containerRegData.seal || ""}
    />
    <label htmlFor="seal">Seal</label>
  </Form.Floating>
</div>


</div>


<div className="row">

<div className="col-md-3 mb-2">
  <label htmlFor="port" className="picker-inputs-lebel">
    Container Size
  </label>
  <Select
    options={containerSize}
    theme={theme}x
    placeholder="container size"
    name="container_size"
    isClearable={true}
    menuPortalTarget={document.body}
    styles={menuPortal}
    value={containerRegData?.container_size || ""}
    // defaultValue={containerRegData?.port || ""}
    onChange={(e, selector) =>
      handleMoreInfoSelectors(e, selector)
    }
  />

  {props.autoEditErrors?.error.port ? (
    <div className="validation-error">Required !</div>
  ) : (
    ""
  )}
</div>


<div className="col-md-6 pb-3">
<label htmlFor="vessel_name" className="picker-inputs-lebel">
   Vessel Name
  </label>
<AsyncPaginate
 className="async-paginate-inner-container"
debounceTimeout={500}
value={containerRegData.vessel_name}
isClearable
loadOptions={loadVesselOptions}
onChange={(selectedOption)=>changedSelectorData(selectedOption,'vessel_name')}

closeMenuOnSelect={true}
additional={{
page: 1,
}}

/>


</div>




<div className=" col-md-3 mb-2">
<label htmlFor="vessel_voyage" className="picker-inputs-lebel">
    Vessel Voyage
  </label>
<input
  type="text"
  className="form form-control inputWidth"
  name="vessel_voyage"
  placeholder="Vessel Voyage"
  value={containerRegData.vessel_voyage}
  onChange={(e) => {
    onInputChangeAdditional(e);
  }}
  // Add your onChange and value props as needed
/>
</div>

</div>

<div className="row">

</div>



<div className="row">
<div className="col-md-3 mb-2">
  <label htmlFor="document_cut_date" className="picker-inputs-lebel">
    Document Cut Date
  </label>

  <DatePicker
    selected={containerRegData.document_cut_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "document_cut_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="Document Cut Date"
    name="document_cut_date"
    autoComplete="off"
  />
</div>

<div className="col-md-3 mb-2">
  <label
    htmlFor="document_cut_date"
    className="picker-inputs-lebel"
  >
   Port Cut Date
  </label>

  <DatePicker
    selected={containerRegData.port_cut_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "port_cut_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="Port Cut Date"
    name="port_cut_date"
    autoComplete="off"
  />
</div>

<div className="col-md-3 mb-2">
  <label
    htmlFor="departure_date"
    className="picker-inputs-lebel"
  >
   Departure Date
  </label>

  <DatePicker
    selected={containerRegData.departure_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "departure_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="Departure Date"
    name="departure_date"
    autoComplete="off"
  />
</div>
<div className="col-md-3 mb-2">
  <label
    htmlFor="eta"
    className="picker-inputs-lebel"
  >
ETA
  </label>

  <DatePicker
    selected={containerRegData.eta}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "eta")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="ETA"
    name="eta"
    autoComplete="off"
  />
</div>
</div>

<div className="row">
<div className="col-md-3 mb-2">
  <label htmlFor="cbp_completed" className="picker-inputs-lebel">
  CPB Completed
  </label>

  <DatePicker
    selected={containerRegData.cpb_completed}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "cpb_completed")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="CPB Completed"
    name="cpb_completed"
    autoComplete="off"
  />
</div>

<div className="col-md-3 mb-2">
  <label
    htmlFor="si_submission_date"
    className="picker-inputs-lebel"
  >
   SI Submission Date
  </label>

  <DatePicker
    selected={containerRegData.si_submission_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "si_submission_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="SI Submission Date"
    name="si_submission_date"
    autoComplete="off"
  />
</div>

<div className="col-md-3 mb-2">
  <label htmlFor="loading_date" className="picker-inputs-lebel">
    Loading Date
  </label>

  <DatePicker
    selected={containerRegData.loading_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "loading_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="Date of loading"
    name="loading_date"
    autoComplete="off"
  />
</div>

<div className="col-md-3 mb-2">
  <label
    htmlFor="completed_date"
    className="picker-inputs-lebel"
  >
Completed
  </label>

  <DatePicker
    selected={containerRegData.completed_date}
    onChange={(e) =>
      handleDateInputChangeContainer(e, "completed_date")
    }
    // minDate={moment().toDate()}
    dateFormat="dd-MM-yyyy"
    wrapperClassName="date-picker"
    isClearable={true}
    placeholderText="Completed"
    name="completed_date"
    autoComplete="off"
  />
</div>
</div>




<div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>Validation Details</span></div>
</div>
</div>
<div className="row">

<div className="col-md-3 mb-2">
                <label
                  htmlFor="validation_date"
                  className="picker-inputs-lebel"
                >
            Validation Date
                </label>

                <DatePicker
                  selected={containerRegData.validation_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "validation_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Validation Date"
                  name="validation_date"
                  autoComplete="off"
                />
              </div>

              <div className=" col-md-2 mb-2">
              <label htmlFor="itn" className="picker-inputs-lebel">
                  ITN
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="itn"
                placeholder="ITN"
                value={containerRegData.itn}

                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>
            <div className=" col-md-2 mb-2">
              <label htmlFor="xtn" className="picker-inputs-lebel">
                  XTN
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="xtn"
                placeholder="XTN"
                value={containerRegData.xtn}

                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
            />
            </div>
            <div className=" col-md-2 mb-2">
              <label htmlFor="steamship_line" className="picker-inputs-lebel">
                  Steamship Line
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="steamship_line"
                placeholder="Steamship Line"
                value={containerRegData.steamship_line}
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>

            <div className=" col-md-3 mb-2">
              <label htmlFor="terminal" className="picker-inputs-lebel">
                  Terminal
                </label>
              <input
                type="text"
                className="form form-control inputWidth"
                name="terminal"
                value={containerRegData.terminal}
                placeholder="Terminal"
                onChange={(e) => {
                  onInputChangeAdditional(e);
                }}
              />
            </div>


</div>


            <div className="row">
             

              {/* <div className="col-md-3 mb-2">
                <label
                  htmlFor="expected_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Expected Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.expected_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "expected_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Expected Arrival Date"
                  name="expected_arrival_date"
                  autoComplete="off"
                />
              </div> */}

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Container Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.container_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Container Arrival Date"
                  name="container_arrival_date"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Unloading Date
                </label>

                <DatePicker
                  selected={containerRegData.container_unloading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_unloading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Unloading Date"
                  name="container_unloading_date"
                  autoComplete="off"
                />
              </div>


              <div className="col-md-3 mb-2">
                <label htmlFor="paid_status" className="picker-inputs-lebel">
                  Paid Status
                </label>
                <Select
                  options={paidStatus}
                  theme={theme}
                  placeholder="Paid Status"
                  name="paid_status"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.paid_status || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

            </div>





           

            <div className="row">
<div className="col-md-12 mb-3">
<div className="lineInPermissions "><span>File Uploads</span></div>
</div>
</div>

            <div className="row">
{can(currentUserPermissions,'Edit Container Images')?
<div className="col-md-4">


<div style={{ marginTop: "30px" }}>
      
           
      <FileUploader
   
        handleChange={handleChangeImages}
        name="file"
        types={["jpeg","jpg"]}
        label={"Upload invoice here"}
        classes={""}
        multiple
        children={
          <div
            className={
              dragAndDropSelectedImages === false
                ? "dragDrop"
                : "dragDropSelected"
            }
          >
        
            <span>
              {dragAndDropTextImages}
              {dragAndDropSelectedImages === true ? (
                <span
                  style={{ fontSize: "11px", color: "darkgreen" }}
                >
                  {" "}
                  selected
                </span>
              ) : (
                ""
              )}
            </span>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2px",
                color: "rgb(10, 80, 134)",
              }}
            >
              jpeg, jpg{" "}
              {dragAndDropSelectedImages === false ? (
                ""
              ) : (
                <i className="fas fa-check"></i>
              )}
            </div>
          </div>
        }
      />
      &nbsp;  &nbsp;

      { containerRegData.images.length>0? <> <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
        setcontainerRegData({...containerRegData,images:[]})
        setdragAndDropSelectedImages(false)
        setdragAndDropTextImages("Upload or Drag and Drop your images here")
   
        }    }>Reset Images</a>   &nbsp;       <a className="add-images-btn" onClick={()=>uploadImages()}>Add Images</a>     </>      :'' }
    </div>

</div>:""}
<div className="col-md-8">

<div className="row ">
              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="dock_receipt" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.dock_receipt}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>


          
              <div className="col-md-6">
                <div className="fileuploadcontainer" >
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="bl_copy" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
    </span> <span>{fileName.bl_copy}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>


            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf"  onChange={handleAttachment} name="telex_release"/>
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.telex_release}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="validations" />
    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span> <span>{fileName.validations}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

            </div>









</div>


{can(currentUserPermissions,'Edit Container Images')?
<ContainerImagesGrid id={containerRegData.onId}/>
:""}



            </div>

            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update Container
            </button>
          </Modal.Footer>
        </form>
      </Modal>






{/* 
========================================================
Modal Container Charges
======================================================== 
*/}

<Modal show={props.openContainerChargesModal} onHide={handleContainerChargesToggle} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Container Charges &nbsp; <i className="fa fa-money"></i> </Modal.Title>
          <CloseButton variant={"white"} onClick={handleContainerChargesToggle}></CloseButton>
        </Modal.Header>

        <form onSubmit={storeContainerCharge} >
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
          style={{
            maxHeight: "calc(110vh - 210px)",
            overflowY: "auto",
         
          }}
            className="modal-body"
          >
            <div className="row auto-charges-header">
              <div className="col-md-2"><span>Charge Name</span></div>
              <div className="col-md-3"><span>Description</span></div>
              <div className="col-md-2"><span>Amount</span></div>
             <div className="col-md-1"><span>Days</span></div>
              <div className="col-md-2"><span>cost</span></div>

              <div className="col-md-2"><span>Total</span></div>



            </div>
            {props.getContainerChargesRows?.data.map((item,index)=>{
              return(
                item.charge_status==1?
                <div key={index}>
                
                <div className="row">
           
          <div className="col-md-2 mt-4">
               <span className="left-header">{item.charge_name}</span>
              </div>

              <div className="col-md-3 mb-2 mt-2">
              <Form.Control size="sm" type="text" placeholder="description"
              disabled
                 value={chargesData[index]?.description}
              onChange={(e) => handleInputChange(index, 'description', e.target.value)}
              
              />

              </div>

              <div className="col-md-2 mb-2 mt-2">
              <Form.Control size="sm" type="number" step="1" placeholder="amount"
                  value={chargesData[index]?.amount}
                  onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
              />

              </div>

             <div className="col-md-1 mb-2 mt-2">
             {item.unit=="Day"?
              <Form.Control size="sm" type="number" min="0"  placeholder="days" 
                    value={chargesData[index]?.quantity}
                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
              />
            
            :''}
              </div>
            

              <div className="col-md-2 mb-2 mt-2">
              <Form.Control size="md" type="number" step="1" placeholder="cost" 
                value={chargesData[index]?.cost}
                onChange={(e) => handleInputChange(index, 'cost', e.target.value)}
              />

              </div>
              <div className="col-md-2 mb-2 mt-2">
              <Form.Control size="md" type="number" disabled step="1" placeholder="total"
              
              value={chargesData[index]?.total}
              onChange={(e) => handleInputChange(index, 'total', e.target.value)}
              />
              </div>
 
</div>
                </div>:null
 
          
              )
            })}
                              <hr/>

            <div className="mt-2"></div>
                <div className="row border-top">
                  <div className="col-md-8 pb-2"></div><div className="col-md-2"><span>Total Cost:</span></div><div className="col-md-2"> <span className="cost-bold">$  {isNaN(totalCost)?'0':totalCost}</span></div>
                  <div className="col-md-8 pt-2"></div><div className="col-md-2"><span>Total Amount:</span></div><div className="col-md-2"> <span className="cost-bold">$  {isNaN(totalAmount)?'0':totalAmount}</span></div>



                </div>


            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
      </Modal.Body>
      {props.getContainerChargesRows?.data.length > 0?
          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit"  disabled={props.getContainerChargesRows?.data.length > 0? false:true}>
           Save Charges
            </button>
          </Modal.Footer>:null

      }
        </form>
      </Modal>









{/* ========================================================
model for images
======================================================== */}

<Modal show={imagesModal} onHide={()=>{setImagesModal(false)}} size="fullscreen"  className="special_modal" centered >
        {/* <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">System Details</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header> */}

          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}
         <div style={{position:'absolute',right:'5%', top:'1%', zIndex:'99999', backgroundColor:'white'}} className="circle2"> <CloseButton  style={{fontSize:'12px', color:'black'}} onClick={()=>{setImagesModal(false)}}></CloseButton></div>

          <Modal.Body
            className="modal-body"
          >
            <div className="row">
      {  can(currentUserPermissions,'Download Container Images Zip')?   <div style={{position:'fixed', top:'10px', left:'10px', color:'white', zIndex:'999'}}>
<button className="modal-images-download" onClick={()=>{props.downloadImagesContainerZip(onId);}}>
 Download Images
 </button>
        
       </div>:""}
              <div className="slider-container">

              <ContainerImagesCarousel/>


              </div>


            </div>

            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

 
      </Modal>






{/* ========================================================
model for adding autos to the container
======================================================== */}

<Modal show={props.openContainerAutoAddition} onHide={handleAddAutoInContainerClose} size="lg">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add auto to the container </Modal.Title>
          <CloseButton variant={"white"} onClick={handleAddAutoInContainerClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={addAutosInContainer}>
         
          
          <Modal.Body
            className="modal-body"
          >




<div className="row">
  <div className="col-md-8 pb-3">
            <label htmlFor="vins" className="picker-inputs-lebel">
                 Select Customer
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={customerAddOn}
        loadOptions={loadCustomersOptions}
        onChange={(selected)=>{
          setCustomerAddOn(selected)
          setAutosInContainer([])
        }}
        closeMenuOnSelect={true}
        isClearable
        isDisabled={containerTypeAddOn?true:false}
        additional={{
          page: 1,
        }}
        
      />
     {/* {vinsFieldRequired? (
                  <div className="validation-error">Please select any VIN !</div>
                ) : (
                  ""
                )} */}

            </div>


            <div className="col-md-4 pb-3">

<Form.Check 
        type="switch"
        id="custom-switch"
        label="Mixed Container"
        checked={containerTypeAddOn}
      onChange={changeTypeContainerAddon}
      />
</div>

</div>


          <div className="row">
          <div className="col-md-8 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select auto VINS
                </label>
         <AsyncPaginate
          className="async-paginate-inner-container"
         debounceTimeout={500}
        value={autosInContainer}
        loadOptions={(search, loadedOptions, {page}) =>{
          const filterBy=customerAddOn?customerAddOn.value:'no-value';
             return loadContainerOptions(search, loadedOptions,filterBy,containerStatusAddOn,{page}) 
             }  }
        onChange={setAutosInContainer}
        loadOptionsOnMenuOpen={true}
        isMulti
        closeMenuOnSelect={false}
        additional={{
          page: 1,
        }}
        key={uniqueValuesAddOn}
      />



                  <div className="validation-error">
                  {error}
                  </div>
               
               

            </div>
           
</div>





            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
           Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>






{/* ========================================================
modal for invoice 
======================================================== */}

<Modal show={props.openContainerInvoiceModal} onHide={handleInvoiceModalToggle} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text"> Invoice  </Modal.Title>
          <CloseButton variant={"white"} onClick={handleInvoiceModalToggle}></CloseButton>
        </Modal.Header>

         
          
          <Modal.Body
          style={{ 
          maxHeight: "calc(110vh - 210px)",
          overflowY: "auto",
        }}
            className="modal-body"
          >



<div className="container">
  <div className="row">
<div className="col-md-6">
<form onSubmit={updateContainerInv}>
<div className="row">

  <div className="col-md-6">
  <label style={{fontSize:'14px'}}>Invoice No :</label>
    <input type="text"
     className="form-control"
     name="invoice_no"
     value={invoiceUpdateDate.invoice_no}
     required
     placeholder="invoice number"
     onChange={invoiceInputChange}
     ></input>
  </div>
</div>
<div className="row pt-3">
  <div className="col-md-6">
    <label style={{fontSize:'14px'}}>Invoice Date : &nbsp; </label>
    {/* <input className="form-control" 
    type="date" 
    name="invoice_date" 
    placeholder="invoice date"
    onChange={invoiceInputChange}

    ></input> */}

<DatePicker
                  selected={invoiceUpdateDate.invoice_date}
                  onChange={(e) =>
                    handleDateInputChangeInvoice(e, "invoice_date")
                  }
                  // minDate={moment().toDate()}
                  
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="invoice date"
                  name="invoice_date"
                  autoComplete="off"
                  required
                  // maxDate={new Date()}

                />
  </div>
</div>

<button className="btn-submit mt-3" type="submit">
          Edit
            </button>
           </form>

           <hr/>
    
<div className=" pt-4">
  <div className="col-md-12">
    <span className="text-label">Billing Name :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.company_name}</span>
  </div>
</div>


<div className=" pt-2">
  <div className="col-md-12">
    <span className="text-label">Billing Address :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.address}, {props.containerInvoiceInformation?.containerInfo?.city}, {props.containerInvoiceInformation?.containerInfo?.state}</span>
  </div>
</div>

<div className=" pt-2">
  <div className="col-md-12">
    <span className="text-label">Billing Phone :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.contact}</span>
  </div>
</div>


<div className=" pt-2">
  <div className="col-md-12">
    <span className="text-label">Billing Email :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.email}</span>
  </div>
</div>


</div>




<div className="col-md-6">

<div className=" pt-2">
  <div className="col-md-6">
    <span className="text-label">Total Charges :</span>
    <span className="text-details">
   $ {totalVehicleCharges + totalContainerCharges || 0}
      </span>
  </div>
</div>
<div className=" pt-2">
  <div className="col-md-6">
    <span className="text-label">Total Cost :</span>
    <span className="text-details">
   $ {totalVehicleCost + totalContainerCost || 0}
      
      </span>
  </div>
</div>

<div className="pt-2">
  <div className="col-md-6">
    <span className="text-label">Total Profit :</span>
    <span className="text-details">
      {/* this is for the purpose to calculate total profit */}
   $ {
    totalVehicleCharges + (totalContainerCharges || 0) - totalVehicleCost + (totalContainerCost|| 0)
    }
      
      </span>
  </div>
</div>
<div className="gap-y">
</div>
<hr/>

<div className=" py-0 px-0 pt-2">
  <div className="col-md-12">
    <span className="text-label">Shipping Name :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.consignee_name}</span>
  </div>
</div>


<div className=" pt-2">
  <div className="col-md-12">
    <span className="text-label">Shipping Address :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.consignee_address}, {props.containerInvoiceInformation?.containerInfo?.consignee_city}, {props.containerInvoiceInformation?.containerInfo?.consignee_state}</span>
  </div>
</div>


<div className="pt-2">
  <div className="col-md-12">
    <span className="text-label">Shipping Phone :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.consignee_phone}</span>
  </div>
</div>

<div className=" pt-2">
  <div className="col-md-12">
    <span className="text-label">Shipping Email :</span>
    <span className="text-details">{props.containerInvoiceInformation?.containerInfo?.consignee_email}</span>
  </div>
</div>

</div>
</div>


<hr/>

<div className="header-invoice">
  <span>Container Charges</span>
</div>
<div className="pt-3">
<table class="table table-condensed">
    <thead>
      <tr>
        <th>Code</th>
        <th>Charge Name	</th>
        <th>Description</th>
        <th>Amount</th>
        <th>Cost</th>
        <th>Profit</th>

      </tr>
    </thead>
    <tbody>
    {props.containerInvoiceInformation?.containerCharges && props.containerInvoiceInformation.containerCharges.length !== 0 ?
    props.containerInvoiceInformation.containerCharges.map((list, index) => {
        return (
            <tr key={index}>
                <td>{list.code}</td>
                <td>{list.charge_name}</td>
                <td>{list.description}</td>
                <td>{list.amount}</td>
                <td>{list.cost}</td>
                <td>{list.amount - list.cost}</td>
            </tr>
        );
    }) :
    <tr>
        <td colSpan="6">No container charges found</td>
    </tr>
}
      
     
    </tbody>
  </table>


</div>



<div className="header-invoice">
  <span>Auto Charges</span>
</div>

<div className="pt-3">
<table className="table table-condensed">
  <thead>
    <tr>
      <th>Vehicle</th>
      <th>Location/Description</th>
      {props.containerInvoiceInformation?.headers?.map((header, index) => (
        <th key={index}>{header}</th>
      ))}
    </tr>
  </thead>
  <tbody>
    {props.containerInvoiceInformation?.charges.map((charge, chargeIndex) => (
      charge.vin?
      <tr key={chargeIndex}>
        <td>{charge.year} {charge.make} {charge.model} ({charge.vin})</td>
        <td>{charge.description}</td>
        {props.containerInvoiceInformation?.headers?.map((header, index) => (
          <td key={index}>
            {charge.charge_name === header ? charge.total : 0}
          </td>
        ))}
      </tr>:null
    ))}
  </tbody>
</table>


</div>
</div>


               







            {/* <Button text={"Submit"} className={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            {/* <button className="btn-submit" style={{background:'black'}} >
        <i className="fas fa-print"></i> Download Invoice
            </button> */}

         {invoiceUpdateDate?.container_id?  <a style={{background:'black',padding:'8px',borderRadius:'5px',color:'white',fontSize:'15px'}}  href={`/containerInvoice-pdf/${encodeURIComponent(CryptoAES.encrypt(invoiceUpdateDate.container_id.toString(),'autoListGhulam_123'))}`} onClick={closeDropdown} target="_blank" ><i className="fas fa-print"></i> Print Invoice </a> :''}

          </Modal.Footer>
      </Modal>







    </>
  );
}








const mapStateToProps = (state) => {
  const { preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  
  const { 
    loading,
    searchContainers,
    openContainerRegistrationModal,
    VinNumberFetchResponse,
    containersListResponse,
    containerImagesResponse,
    openContainerAutoAddition,
    currentPage,
    contAutosRegResponse,
    contAddAutosResponse,
    nullValues,
    containerSelectedAutosDeleteResponse,
    openContainerEdit,
    containerDetailsResponse,
    containerImagesUploadResponse,
    containerUpdateResponse,
    containerDeleteResponse,
    selectedDeletionContainerResponse,
    containersCreationErrors,
    containersUpdateErrors,
    containerImagesDeletionResponse,
    functionalLoader,
    openContainerChargesModal,
    getContainerChargesRows,
    containerChargesStoreSuccess,
    openContainerInvoiceModal,
    containerInvoiceInformation,
    invoiceUpdated,
  } = state.ContainerHandler;
  const {
    PdfNonHazardousResponse,
    pdfCustomsResponse,
    PdfBLInstructionResponse,
  } = state.PdfHandler;

  return {
    loading:loading,
    searchContainers:searchContainers,
    currentPage:currentPage,
    openContainerRegistrationModal: openContainerRegistrationModal,
    openContainerAutoAddition:openContainerAutoAddition,

    VinNumberFetchResponse:VinNumberFetchResponse,
    containersListResponse:containersListResponse,
    containerImagesResponse:containerImagesResponse,
    contAutosRegResponse:contAutosRegResponse,
    contAddAutosResponse:contAddAutosResponse,
    nullValues:nullValues,
    containerSelectedAutosDeleteResponse:containerSelectedAutosDeleteResponse,
    openContainerEdit:openContainerEdit,
    openContainerInvoiceModal:openContainerInvoiceModal,
    containerDetailsResponse:containerDetailsResponse,
    containerImagesUploadResponse:containerImagesUploadResponse,
    containerUpdateResponse:containerUpdateResponse,
    containerDeleteResponse:containerDeleteResponse,
    selectedDeletionContainerResponse:selectedDeletionContainerResponse,
    containersCreationErrors:containersCreationErrors,
    SelectorsFetch:SelectorsFetch,
    containersUpdateErrors:containersUpdateErrors,
    preDataLoadResponse:preDataLoadResponse,
    containerImagesDeletionResponse:containerImagesDeletionResponse,
    functionalLoader:functionalLoader,
    openContainerChargesModal:openContainerChargesModal,
    getContainerChargesRows:getContainerChargesRows,
    containerChargesStoreSuccess:containerChargesStoreSuccess,
    PdfNonHazardousResponse:PdfNonHazardousResponse,
    pdfCustomsResponse:pdfCustomsResponse,
    PdfBLInstructionResponse:PdfBLInstructionResponse,
    containerInvoiceInformation:containerInvoiceInformation,
    invoiceUpdated:invoiceUpdated,
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectors: () => dispatch(getSelectors()),
    showContainerRegModal: () => dispatch(showContainerRegModal()),
    showContainerInvoiceModal:()=>dispatch(showContainerInvoiceModal()),
    hideContainerRegModal: () => dispatch(hideContainerRegModal()),
    showContainerAutoAdditionModal: () => dispatch(showContainerAutoAdditionModal()),
    hideContainerAutoAdditionModal: () => dispatch(hideContainerAutoAdditionModal()),
    showContainerEditModal: () => dispatch(showContainerEditModal()),
    hideContainerEditModal: () => dispatch(hideContainerEditModal()),
    VinNumberFetchForContainers:(query)=>dispatch(VinNumberFetchForContainers(query)),
    ContainerRegistration:(registrationData,vinValues,customerValue,currentPage)=>dispatch(ContainerRegistration(registrationData,vinValues,customerValue,currentPage)),
    ContainersList:(pageNumber)=>dispatch(ContainersList(pageNumber)),
    getContainerImages:(id)=>dispatch(getContainerImages(id)),
    downloadImagesContainerZip:(id)=>dispatch(downloadImagesContainerZip(id)),
    ContainerDelete:(id)=>dispatch(ContainerDelete(id)),
    containerAddAutos:(id,vinValues,currentPage)=>dispatch(containerAddAutos(id,vinValues,currentPage)),
    selectedAutosInContainersDelete:(ids)=>dispatch(selectedAutosInContainersDelete(ids)),
    getContainerDetails:(id)=>dispatch(getContainerDetails(id)),
    ContainerUpdate:(registrationData,vinValues,customerValue,currentPage)=>dispatch(ContainerUpdate(registrationData,vinValues,customerValue,currentPage)),
    storeImagesContainer:(containerImages)=>dispatch(storeImagesContainer(containerImages)),
    selectedContainersDelete:(ids)=>dispatch(selectedContainersDelete(ids)),
    searchContainersList:(query,currentPage)=>dispatch(searchContainersList(query,currentPage)),
    inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
    getChargesContainersRows:(id)=>dispatch(getChargesContainersRows(id)),
    storeContainerCharges:(data,id,totalAmount,totalCost)=>dispatch(storeContainerCharges(data,id,totalAmount,totalCost)),
    showContainerChargesModal:()=>dispatch(showContainerChargesModal()),
    containersCatagoryList:(page,catagory)=>dispatch(containersCatagoryList(page,catagory)),
    // for pdfs
    nonHazardousPdf:(id)=>dispatch(nonHazardousPdf(id)),
    customsPdf:(id)=>dispatch(customsPdf(id)),
    bLInstructionPdf:(id)=>dispatch(bLInstructionPdf(id)),
    ContainerInvoice:(id)=>dispatch(ContainerInvoice(id)),
    ContainerInvoiceUpdate:(data)=>dispatch(ContainerInvoiceUpdate(data))


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerList);
