import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing vessel settings model
// -------------------------------------------------------------------------
export const hidevesselModal=()=>{
    return {
      type:'hidevesselModal'
    }
    
    }
    export const showvesselModal=()=>{
  
      return {
        type:'showvesselModal'
      }
      
      }
  
  
      export const showEditvesselModal=()=>{
        return {
          type:'showEditvesselModal'
        }
        
        }
      
        export const hideEditvesselModal=()=>{
          return {
            type:'hideEditvesselModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all vessels for settings
// ---------------------------------------------------------------------------------------------------------

export const getvessels = (currentPage) => {



    return (dispatch) => {
        dispatch(getvesselStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/vessels?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getvesselSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getvesselFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getvesselStarted = () => ({
      type: "getvessel_started",
    });
    
    
    const getvesselSuccess = (data,currentPage) => ({
      type: "getvesselsSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getvesselFailure = (error) => ({
      type: "getvessel_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registervessel = (name) => {
  
  return (dispatch) => {
    dispatch(registervesselStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let vesselData={
     vessel_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/vesselRegister`, vesselData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registervesselSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The vessel has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registervesselFailure(err.response.data.errors));
     
      });
  };
};

const registervesselStarted = () => ({
  type: "registervessel_started",
});

const registervesselSuccess = (data) => ({
  type: "registervessel_success",
  payload: {
    ...data,
  },
});

const registervesselFailure = (error) => ({
  type: "registervessel_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the vessel edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const vesselEdit = (id) => {

  return (dispatch) => {
    dispatch(vesselEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editvessel/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(vesselEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(vesselEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const vesselEditDetailsStarted = () => ({
  type: "vesselEditDetails_started",
});

const vesselEditDetailsSuccess = (data) => ({
  type: "vesselEditDetails_success",
  payload: {
    ...data,
  },
});

const vesselEditDetailsFailure = (error) => ({
  type: "vesselEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the vessel update 
// ---------------------------------------------------------------------------------------------------------

export const vesselUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(vesselUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        vessel_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updatevessel/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(vesselUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The vessel has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(vesselUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const vesselUpdateStarted = () => ({
    type: "vesselUpdate_started",
  });
  
  
  const vesselUpdateSuccess = (data,currentPage) => ({
    type: "vesselUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const vesselUpdateFailure = (error) => ({
    type: "vesselUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// vessels Deletion
// ---------------------------------------------------------------------------------------------------------

export const vesselDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(vesselDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deletevessel/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(vesselDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The vessel has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(vesselDeletionFailure(err.message));
      });
  };
};

const vesselDeletionStarted = () => ({
  type: "vesselDeletion_started",
});

const vesselDeletionSuccess = (data,currentPage) => ({
  type: "vesselDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const vesselDeletionFailure = (error) => ({
  type: "vesselDeletion_failure",
  payload: {
    error,
  },
});
