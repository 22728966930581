import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");



// ---------------------------------------------------------------------------------------------------------
// for fetching data to the role selection
// ---------------------------------------------------------------------------------------------------------

export const rolePicker = () => {
    return (dispatch) => {
      dispatch(rolePickerStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/roleSelector`, axiosConfig)
        .then((response) => {
          if (response.data) {
            //console.log(response.data);
  
            dispatch(rolePickerSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(rolePickerFailure(err.message));
        });
    };
  };
  
  const rolePickerStarted = () => ({
    type: "rolePicker_started",
  });
  
  const rolePickerSuccess = (data) => ({
    type: "rolePicker_success",
    payload: {
      ...data,
    },
  });
  
  const rolePickerFailure = (error) => ({
    type: "rolePicker_failure",
    payload: {
      error,
    },
  });
  






// ---------------------------------------------------------------------------------------------------------
// roles List in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const rolesList = (pageNumber) => {
    return (dispatch) => {
      dispatch(fetchrolesListStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/rolesList?page=${pageNumber}`, axiosConfig)
        .then((response) => {
          // console.log(response.data);

          if (response.data.success_message) {
            console.log(response.data);
  
            dispatch(fetchrolesListSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          dispatch(fetchrolesListFailure(err.message));
        });
    };
  };
  
  const fetchrolesListStarted = () => ({
    type: "role_fetch_started",
  });
  
  const fetchrolesListSuccess = (data) => ({
    type: "role_fetch_success",
    payload: {
      ...data,
    },
  });
  
  const fetchrolesListFailure = (error) => ({
    type: "role_fetch_failure",
    payload: {
      error,
    },
  });
  






// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerRole = (name,permission) => {
  
  return (dispatch) => {
    dispatch(registerRoleStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let RoleData={
     name:name,
     permission:permission,
    
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/createRole`, RoleData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerRoleSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Role has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerRoleFailure(err.response.data.errors));
     
      });
  };
};

const registerRoleStarted = () => ({
  type: "registerRole_started",
});

const registerRoleSuccess = (data) => ({
  type: "registerRole_success",
  payload: {
    ...data,
  },
});

const registerRoleFailure = (error) => ({
  type: "registerRole_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// roles show in the system with pagination Action - used for both edit and update of role for fetching the role data only
// ---------------------------------------------------------------------------------------------------------



  export const showRolePermissions = (id) => {
    
    return (dispatch) => {
      dispatch(showRolePermissionsStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/showPermissions/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
  
         //console.log(response.data)
            dispatch(showRolePermissionsSuccess(response.data));
          }
        })
        .catch((err) => {
        
          dispatch(showRolePermissionsFailure(err.response.data.errors));
       
        });
    };
  };
  
  const showRolePermissionsStarted = () => ({
    type: "showRolePermissions_started",
  });
  
  const showRolePermissionsSuccess = (data) => ({
    type: "showRolePermissions_success",
    payload: {
      ...data,
    },
  });
  
  const showRolePermissionsFailure = (error) => ({
    type: "showRolePermissions_failure",
    payload: {
      error,
    },
  });















// ---------------------------------------------------------------------------------------------------------
// the role edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const roleEdit = (id) => {

  return (dispatch) => {
    dispatch(roleEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/updateDetails/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(roleEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(roleEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const roleEditDetailsStarted = () => ({
  type: "roleEditDetails_started",
});

const roleEditDetailsSuccess = (data) => ({
  type: "roleEditDetails_success",
  payload: {
    ...data,
  },
});

const roleEditDetailsFailure = (error) => ({
  type: "roleEditDetails_failure",
  payload: {
    error,
  },
});



// -------------------------------------------------------------------------
// for opening and closing registration model
// -------------------------------------------------------------------------
export const hideRoleModal=()=>{
  return {
    type:'hideRoleModal'
  }
  
  }
  export const showRoleModal=()=>{

    return {
      type:'showRoleModal'
    }
    
    }


    export const showEditRoleModal=()=>{
      return {
        type:'showEditModal'
      }
      
      }
    
      export const hideEditRoleModal=()=>{
        return {
          type:'hideEditModal'
        }
      }
// ---------------------------------------------------------------------------------------------------------
// the role update function will update the role data
// ---------------------------------------------------------------------------------------------------------

export const roleUpdate = (id,name,permissions,currentPage) => {
//console.log(name)
  return (dispatch) => {
    dispatch(roleUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    let roleData={
      name:name,
      permission:permissions,
    
     }


    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateRole/`+id,roleData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(roleUpdateSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The role has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(roleUpdateFailure(err.response.data.errors));
     
      });
  };
};

const roleUpdateStarted = () => ({
  type: "roleUpdate_started",
});


const roleUpdateSuccess = (data,currentPage) => ({
  type: "roleUpdate_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const roleUpdateFailure = (error) => ({
  type: "roleUpdate_failure",
  payload: {
    error,
  }, 
});








// ---------------------------------------------------------------------------------------------------------
// roles deletion in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const roleDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(roleDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteRole/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(roleDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The role has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(roleDeletionFailure(err.message));
      });
  };
};

const roleDeletionStarted = () => ({
  type: "roleDeletion_started",
});

const roleDeletionSuccess = (data,currentPage) => ({
  type: "roleDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const roleDeletionFailure = (error) => ({
  type: "roleDeletion_failure",
  payload: {
    error,
  },
});
