import React, { useState, useEffect } from "react";
import { PDFViewer} from '@react-pdf/renderer';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import ContainerInvoiceDesign from "../PdfDesigns/ContainerInvoiceDesign";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {ContainerInvoice} from '../../../../redux/actions';

function ContainerInvoicePdf(props) {
  
  const [id, setId] = useState('');
  const params = useParams();

  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
   if(decryptValue){
     setId(decryptValue);
   
   props.ContainerInvoice(decryptValue);
   }
   },[])
   

  return (
    <div>
    {props.loading ? (
      <div style={{color:'white',justifyContent:'center',alignItems:'center',display:'flex'}}>Loading...</div>
    ) : (
      <PDFViewer  style={{height:'100vh',width:'100vw'}}   >
   
            <ContainerInvoiceDesign data={props?.containerInvoiceInformation}/>
     
      </PDFViewer>


    )}
  </div>
  )
}



const mapStateToProps = (state) => {
  const {
    loading,
    containerInvoiceInformation
  } = state.ContainerHandler;

  return {
    loading: loading,
    containerInvoiceInformation:containerInvoiceInformation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    ContainerInvoice:(id)=>dispatch(ContainerInvoice(id))
  
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(ContainerInvoicePdf)