import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client';

// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

import i18next from 'i18next';
import PreDataLoader from '../src/components/elements/PreDataLoader'
import LanguageDetector from 'i18next-browser-languagedetector'

// store.subscribe(()=>{console.log(store.getState())});


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

  const loadingMarkup = (
    <PreDataLoader 
   width={"40px"}
   height={"40px"}
   iconColor={"white"}
   backgroundColor={"#deb726"}
   left={"70%"}
   top={"90px"} />
   
  )

  const root = createRoot(document.getElementById('root'));

  root.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
  
  <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
