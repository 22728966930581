const InitialStates = {
  preDataLoadResponse: null,
  loading: false,
  showVehicleTrackerModal: false,
  currentPage: 1,
  showAndHideAutoPublicModal: false,
  // lastVehicleTracker: true,
  searchVehicleTrackersText: "",
  // VehicleTrackerIndicationSuccess:'noVehicleTracker',
  // VehicleTrackerIndicationSuccess:'',
  VehicleTrackersRegisteredSucess:[],
  message:''
};

export default (state = InitialStates, action) => {
  switch (action.type) {



    case "showAndHideVehiclePublicModal":
      return {
        ...state,
        showAndHideAutoPublicModal: !state.showAndHideAutoPublicModal,
      };


    case "inputHandler":
      return { ...state, [action.payload.key]: action.payload.value };



      case "searchVehicleTrackerPublic_started":
        return { ...state, loading: true };


    case "searchVehicleTracker_started":
     return { ...state, loading: true };

    case "VehicleTrackerReg_started":
      return { ...state, loading: true };



    case "selectedVehicleTrackers_started":
      return { ...state, loading: true };

    case "VehicleTrackerReg_started":
      return { ...state, loading: true };


case "TrackerCustomerStatus_success":
    return {
      ...state,
      trackerIndividualStatus: action.payload,
      loading: false,
    };



    case "VehicleTrackers_fetch_success":
      return {
        ...state,
        VehicleTrackersListSuccess: action.payload,
        loading: false,
      };

    case "getVehicleTrackerDetails_success":
      return {
        ...state,
        VehicleTrackersEditInfoDetails: action.payload,
        loading: false,
      };

   
      case "VehicleTrackerReg_success":
        return {
          ...state,
          VehicleTrackersRegisteredSucess: action.payload,
          currentPage: action.payload.currentPage,
          loading: false,
          showVehicleTrackerModal: false,
        };
  

    case "vehicleTrackerMultipleReg_success":
      return {
        ...state,
        VehicleTrackersMultipleRegSuccess: action.payload,
        currentPage: action.payload.currentPage,
        loading: false,
      };


      case "searchVehicleTrackerPublic_success":
      return {
        ...state,
        VehicleTrackersPublicSearchSuccess: action.payload,
        currentPage: action.payload.currentPage,
        showAndHideAutoPublicModal:true,
        loading: false,
      };


    case "selectedVehicleTrackers_success":
      return {
        ...state,
        VehicleTrackersDeletedSuccess: action.payload,
        loading: false,
      };

 

  
    case "vehicle_failure":

      return { ...state, showAndHideAutoPublicModal: false, loading:false, message:'vehicle_not_found' };


    case "searchVehicleTracker_success":
      return {
        ...state,
        VehicleTrackersListSuccess: action.payload,
        loading: false,
      };

     



    default:
      return state;
  }
};
