const InitialStates = {
    AuctionsList:null,
  
    currentPage: 1,
   
    showAuctionModal:false,
    showEditWarModal:false,
    loading:false,
    name:'',
    AuctionsList:null,
    AuctionRegisterErrors:null,
    AuctionUpdateErrors:null
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}


      case "showAuctionModal":
      return { ...state, 'showWarModal':true };

      case "hideAuctionModal":
        return { ...state, 'showWarModal':false, 'AuctionRegisterErrors':'', 'name':'' };
  

        case "showEditAuctionModal":
          return { ...state, 'showEditWarModal':true };
    
          case "hideEditAuctionModal":
            return { ...state, 'showEditWarModal':false, 'AuctionUpdateErrors':'','name':'' };

// =======================================
// for loading purposes
// =======================================
            case "getAuction_started":
              return {...state, 'loading':true}
    case "registerAuction_started":
              return {...state, 'loading':true}

              // case "AuctionEditDetails_started":
              //   return {...state, 'loading':true}

                // case "AuctionUpdate_started":
                //   return {...state, 'loading':true}

                
// ==============ends here=========================

       case "getAuctionsSettings_success":

         return { ...state, 'AuctionsList': action.payload, 'currentPage':action.payload.currentPage, 'loading':false };

       

   case "registerAuction_success":
    return { ...state, 'registerAuctionSuccess': action.payload, 'currentPage':action.payload.currentPage, 'loading':false, name:'',showWarModal:false};
    
   
    case "AuctionEditDetails_success":
  return { ...state, 'editDetails': action.payload,  'loading':false };

  case "AuctionUpdate_success":
   return { ...state, 'updateSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage, 'showEditWarModal':false  };

case "AuctionDeletion_success":
  return { ...state, 'deleteSuccess': action.payload,  'loading':false,  'currentPage':action.payload.currentPage };


//errors starts from here
  case "registerAuction_failure":
    return { ...state, 'AuctionRegisterErrors': action.payload,  'loading':false };

case "AuctionUpdate_failure":
  return { ...state, 'AuctionUpdateErrors': action.payload,  'loading':false,  };


       default:
         return state;
     }
   };
   