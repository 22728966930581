import axios from "axios";
import Swal from "sweetalert2";
import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");







// ---------------------------------------------------------------------------------------------------------
// this is when email go to the clients and they click on link to verify
// ---------------------------------------------------------------------------------------------------------



export const agreementVerification = (id,token) => {
  
  return (dispatch) => {
    dispatch(agreementVerificationStarted());

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
      },
    };
    

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/email/verify/${id}/${token}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       console.log(response.data)
          dispatch(agreementVerificationSuccess(response.data));

    
        }
      })
      .catch((err) => {
      
        dispatch(agreementVerificationFailure(err.response.data.errors));
        console.log(err)
     
      });
  };
};

const agreementVerificationStarted = () => ({
  type: "agreementVerification_started",
});

const agreementVerificationSuccess = (data) => ({
  type: "agreementVerification_success",
  payload: {
    ...data,
  },
});

const agreementVerificationFailure = (error) => ({
  type: "agreementVerification_failure",
  payload: {
    error,
  },
});










// ---------------------------------------------------------------------------------------------------------
// guest users who want to make accounts or want to become member with alibaba global shipping
// ---------------------------------------------------------------------------------------------------------



export const getRequestsCount = () => {
  
  return (dispatch) => {
    dispatch(requestCountStarted());


    const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/RequestsCount`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

      //  console.log(response.data)
          dispatch(requestCountSuccess(response.data));

    
        }
      })
      .catch((err) => {
      
        dispatch(requestCountFailure(err.response.data.errors));
     
      });
  };
};

const requestCountStarted = () => ({
  type: "requestCount_started",
});

const requestCountSuccess = (data) => ({
  type: "requestCount_success",
  payload: {
    ...data,
  },
});

const requestCountFailure = (error) => ({
  type: "requestCount_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// guest users who want to make accounts or want to become member with alibaba global shipping
// ---------------------------------------------------------------------------------------------------------



export const requestMembershipList = () => {
  
  return (dispatch) => {
    dispatch(requestMembershipListStarted());


    const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/requestMembershipList`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       console.log(response.data)
          dispatch(requestMembershipListSuccess(response.data));

    
        }
      })
      .catch((err) => {
      
        dispatch(requestMembershipListFailure(err.response.data.errors));
     
      });
  };
};

const requestMembershipListStarted = () => ({
  type: "requestMembershipList_started",
});

const requestMembershipListSuccess = (data) => ({
  type: "requestMembershipList_success",
  payload: {
    ...data,
  },
});

const requestMembershipListFailure = (error) => ({
  type: "requestMembershipList_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// guest users who want to make accounts or want to become member with alibaba global shipping
// ---------------------------------------------------------------------------------------------------------



export const requestMembership = (data) => {
  
    return (dispatch) => {
      dispatch(requestMembershipStarted());
 
  
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
        },
      };
  
      let MembershipData={
       full_name:data.full_name,
       company_name:data.company_name,
       address:data.address,
       city:data.city,
       state:data.state,
       zip:data.zip,
       username:data.username,
       agreement_status:data.agreement_status,
       phone:data.phone,
       email:data.email,
       passport:data.passport,
       account_type:data.account_type?.value,
       country:data.country?.value,
      //  receiver_country:data.receiver_country?.value,
       receiver_full_name:data.receiver_full_name,
       receiver_company_name:data.receiver_company_name,
       receiver_address:data.receiver_address,
       receiver_city:data.receiver_city,
       receiver_state:data.receiver_state,
       receiver_zip:data.receiver_zip,
       receiver_username:data.receiver_username,
       receiver_phone:data.receiver_phone,
       receiver_email:data.receiver_email,
      }
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/requestMembershipRegister`, MembershipData, axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
  
         //console.log(response.data)
            dispatch(requestMembershipSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'Your request has been succesfully submitted for review',
              showConfirmButton: false,
              timer: 3000,
              toast: true,
  
            })
            History.replace("/customer-request/success");

          }
        })
        .catch((err) => {
        
          dispatch(requestMembershipFailure(err.response.data.errors));
       
        });
    };
  };
  
  const requestMembershipStarted = () => ({
    type: "requestMembership_started",
  });
  
  const requestMembershipSuccess = (data) => ({
    type: "requestMembership_success",
    payload: {
      ...data,
    },
  });
  
  const requestMembershipFailure = (error) => ({
    type: "requestMembership_failure",
    payload: {
      error,
    },
  });
  
  
  



// ---------------------------------------------------------------------------------------------------------
// guest users who want to make accounts or want to become member with alibaba global shipping
// ---------------------------------------------------------------------------------------------------------



export const acceptOrReject = (id,status,link_address) => {
  
  return (dispatch) => {
    dispatch(acceptOrRejectStarted());


    const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    

    let MembershipData={
      id:id,
     status:status,
    link_address:link_address,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/acceptOrRejectRequestMembership`, MembershipData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(acceptOrRejectSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: `The customer has been ${status}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,

          })

        }
      })
      .catch((err) => {
      
        dispatch(acceptOrRejectFailure(err.response.data.errors));
     
      });
  };
};

const acceptOrRejectStarted = () => ({
  type: "acceptOrReject_started",
});

const acceptOrRejectSuccess = (data) => ({
  type: "acceptOrReject_success",
  payload: {
    ...data,
  },
});

const acceptOrRejectFailure = (error) => ({
  type: "acceptOrReject_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// guest users who want to make accounts or want to become member with alibaba global shipping
// ---------------------------------------------------------------------------------------------------------



export const acceptOrRejectMultiple = (ids,status,link_address) => {
  
  return (dispatch) => {
    dispatch(acceptOrRejectMultipleStarted());


    const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
    

    let MembershipData={
      ids:ids,
     status:status,
    link_address:link_address,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/acceptOrRejectSelected`, MembershipData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       console.log(response.data)
          dispatch(acceptOrRejectMultipleSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: `The customers have been ${status}`,
            showConfirmButton: false,
            timer: 3000,
            toast: true,

          })

        }
      })
      .catch((err) => {
      
        dispatch(acceptOrRejectMultipleFailure(err.response.data.errors));
     
      });
  };
};

const acceptOrRejectMultipleStarted = () => ({
  type: "acceptOrRejectMultiple_started",
});

const acceptOrRejectMultipleSuccess = (data) => ({
  type: "acceptOrRejectMultiple_success",
  payload: {
    ...data,
  },
});

const acceptOrRejectMultipleFailure = (error) => ({
  type: "acceptOrRejectMultiple_failure",
  payload: {
    error,
  },
});