import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function Loader(props) {
  const loaderStyle={
position:'fixed',
zIndex:'9999',
left:props.left,
right:props.right,
top:props.top,
bottom:props.bottom,

  }
  return (
      <>
      <div className="loader" style={loaderStyle}>
    <div style={{zIndex:'99999'}}>
    <Spinner animation="grow"  style={{width:props.width,height:props.height,color:props.backgroundColor}}>
    <span style={{display:'flex',justifyContent:'center',alignItems:'center',color:'red',marginTop:'13px'}}>
    
    <i className="fas fa-car" style={{color:props.iconColor, fontSize:'18px'}}></i>    
    </span>
    </Spinner>
  </div>


</div>
  </>
  )
}

export default Loader