import React from 'react';
import '../../css/messages.css';

function RequestsBox(props) {

const data = props.dataObject;



  return (
    <>

    <div className={`container message-container  ${props.onShow?'hide':''}`}  >
{props.gap?
        <div className={`row py-${props.gap}`}></div>:''
}

        <div className="row">
            <div className="col-lg-12">

            
                <blockquote className={`blockquote blockquote-custom bg-white shadow rounded ${props.mediaQueryClass}`} style={{width:props.width}}>
 {props.checkboxProp?<div className="checkboxCustom">

{props.checkboxProp}

</div>:''}
              {/* minutes ago date */}
               {props.postDateRelative? <div className="blockquote-custom-relative-date bg-light-custome">{props.postDateRelative}</div>:''}
               {props.messageImage? <div className="blockquote-custom-icon bg-light-custome shadow-sm"><img src={require(`../assets/${props.messageImage}`)} width="40px"></img></div>:''}

                  <div className="langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
                  
                              
                                {/* normal date*/}

                   {props.postDate? <div className="blockquote-custom-date bg-light-custome shadow-sm">{props.postDate}</div>:''}
                  {props.children? <div className="blockquote-custom-menu bg-light-custome">{props.children}</div>:''}

                  <div className='container'>
    <div className='table-responsive'>
      <table className='table table-requests'>
        <thead>
          <tr>
          <th scope='col'>username</th>
            <th scope='col'>Name</th>
            <th scope='col'>Company Name</th>
            <th scope='col'>Email</th>
            <th scope='col'>Phone</th>
            <th scope='col'>Address</th>
            <th scope='col'>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>{data.username}</td>
            <td>{data.full_name}</td>
            <td>{data.company_name}</td>
            <td>{data.email}</td>
            <td>{data.phone}</td>
            <td>{`${data.address}${data.city ? ', ' : ''}${data.city}${data.state ? ', ' : ''}${data.state}${data.zip ? ', ' : ''}${data.zip}`}</td>
            <td>{data.account_type}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
                
                </div>
                </blockquote>

           

            </div>
            
        </div>
    </div>


    </>
  )
}

export default RequestsBox