import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing AutoInvoice settings model
// -------------------------------------------------------------------------
export const showInvoiceRegModal=()=>{
  return {
    type:'openinvoiceRegistrationModal'
  }
  }

  export const hideInvoiceRegModal=()=>{
    return {
      type:'hideinvoiceRegistrationModal'
    }
    }

    

export const showInvoiceInfoModal=()=>{
return {
type:'openInvoiceInformationModal'
}
}

export const hideInvoiceInfoModal=()=>{
return {
  type:'hideInvoiceInformationModal'
}
}

export const showInvoiceInfoEditModal=()=>{
  return {
    type:'openInvoiceInfoEditModal'
  }
  }

  export const hideInvoiceInfoEditModal=()=>{
    return {
      type:'hideInvoiceInfoEditModal'
    }
    }

    export const showInvoiceBillerEditModal=()=>{
      return {
        type:'openInvoiceBillerEditModal'
      }
      }
    
      export const hideInvoiceBillerEditModal=()=>{
        return {
          type:'hideInvoiceBillerEditModal'
        }
        }
    




// ---------------------------------------------------------------------------------------------------------
// to get all AutoInvoices for settings
// ---------------------------------------------------------------------------------------------------------

export const getAutoInvoices = (id) => {



    return (dispatch) => {
        dispatch(getAutoInvoiceStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
        // console.log(id)
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/AutoInvoiceDetailsFetch/`+id,axiosConfig)
        
          .then((response) => {
            if (response.data.success_message) {
              // console.log(response.data);
              dispatch(getAutoInvoiceSuccess(response.data));
          
            } else if(response.data.error_message){
              //console.log(response.data);
              dispatch(getAutoInvoiceSuccess(response.data));
            }
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getAutoInvoiceFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getAutoInvoiceStarted = () => ({
      type: "getAutoInvoice_started",
    });
    
    
    const getAutoInvoiceSuccess = (data) => ({
      type: "getAutoInvoices_success",
      payload: {
        ...data,
    },
    });
    
    const getAutoInvoiceFailure = (error) => ({
      type: "getAutoInvoice_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerAutoInvoice = (data) => {
  
  return (dispatch) => {
    dispatch(registerAutoInvoiceStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let AutoInvoiceData={
    autos_id:data.autos_id,
    invoice_catagory:data.invoice_catagory?.value,
    amount:data.amount,
    notes:data.notes,
    bill_to:data.bill_to?.value,



    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/AutoInvoiceRegister`, AutoInvoiceData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerAutoInvoiceSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The AutoInvoice has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerAutoInvoiceFailure(err.response.data.errors));
     
      });
  };
};

const registerAutoInvoiceStarted = () => ({
  type: "registerAutoInvoice_started",
});

const registerAutoInvoiceSuccess = (data) => ({
  type: "registerAutoInvoice_success",
  payload: {
    ...data,
  },
});

const registerAutoInvoiceFailure = (error) => ({
  type: "registerAutoInvoice_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// just to set the print date in the database Print date
// ---------------------------------------------------------------------------------------------------------



export const invoicePrint = (id) => {
  //console.log(id)
  return (dispatch) => {
    dispatch(invoicePrintStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let AutoInvoiceData={
    id:id
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/invoicePrint`, AutoInvoiceData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(invoicePrintSuccess(response.data));

        }
      })
      .catch((err) => {
      
        dispatch(invoicePrintFailure(err.response.data.errors));
     
      });
  };
};

const invoicePrintStarted = () => ({
  type: "invoicePrint_started",
});

const invoicePrintSuccess = (data) => ({
  type: "invoicePrint_success",
  payload: {
    ...data,
  },
});

const invoicePrintFailure = (error) => ({
  type: "invoicePrint_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// this will fetch the information of auto invoice, like calculations etc inside bill
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceEditInfo = (id) => {

  return (dispatch) => {
    dispatch(AutoInvoiceEditInfoDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editAutoInvoiceInfo/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AutoInvoiceEditInfoDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AutoInvoiceEditInfoDetailsFailure(err.response.data.errors));
     
      });
  };
};

const AutoInvoiceEditInfoDetailsStarted = () => ({
  type: "AutoInvoiceEditInfoDetails_started",
});

const AutoInvoiceEditInfoDetailsSuccess = (data) => ({
  type: "AutoInvoiceEditInfoDetails_success",
  payload: {
    ...data,
  },
});

const AutoInvoiceEditInfoDetailsFailure = (error) => ({
  type: "AutoInvoiceEditInfoDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the AutoInvoice update 
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceUpdateInfo = (id,invoice_catagory,notes,amount) => {
    return (dispatch) => {
      dispatch(AutoInvoiceInfoUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        invoice_catagory:invoice_catagory.value,
        notes:notes,
        amount:amount,
        
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateAutoInvoiceInfo/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(AutoInvoiceInfoUpdateSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The auto invoice has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(AutoInvoiceInfoUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const AutoInvoiceInfoUpdateStarted = () => ({
    type: "AutoInvoiceInfoUpdate_started",
  });
  
  
  const AutoInvoiceInfoUpdateSuccess = (data) => ({
    type: "AutoInvoiceInfoUpdate_success",
    payload: {
      ...data,
    },
  });
  
  const AutoInvoiceInfoUpdateFailure = (error) => ({
    type: "AutoInvoiceInfoUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// AutoInvoices Deletion
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceDelete = (id) => {

  return (dispatch) => {
    dispatch(AutoInvoiceDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAutoInvoiceInfo/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(AutoInvoiceDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auto invoice description has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(AutoInvoiceDeletionFailure(err.message));
      });
  };
};

const AutoInvoiceDeletionStarted = () => ({
  type: "AutoInvoiceDeletion_started",
});

const AutoInvoiceDeletionSuccess = (data) => ({
  type: "AutoInvoiceDeletion_success",
  payload: {
    ...data,
  },
});

const AutoInvoiceDeletionFailure = (error) => ({
  type: "AutoInvoiceDeletion_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// this will fetch biller info
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceEditBillerInfo = (id) => {

  return (dispatch) => {
    dispatch(AutoInvoiceEditBillerStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editAutoInvoiceBiller/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AutoInvoiceEditBillerSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AutoInvoiceEditBillerFailure(err.response.data.errors));
     
      });
  };
};

const AutoInvoiceEditBillerStarted = () => ({
  type: "AutoInvoiceEditBiller_started",
});

const AutoInvoiceEditBillerSuccess = (data) => ({
  type: "AutoInvoiceEditBiller_success",
  payload: {
    ...data,
  },
});

const AutoInvoiceEditBillerFailure = (error) => ({
  type: "AutoInvoiceEditBiller_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the AutoInvoice update 
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceBillerUpdateInfo = (id,biller) => {
  //note id in here is autos id for bills
  return (dispatch) => {
    dispatch(AutoInvoiceBillerUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    let warhouseData={
      id:id,
      billers_id:biller?.value||'',
     }


    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateAutoInvoiceBillerInfo/`+id,warhouseData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(AutoInvoiceBillerUpdateSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The auto invoice has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AutoInvoiceBillerUpdateFailure(err.response.data.errors));
     
      });
  };
};

const AutoInvoiceBillerUpdateStarted = () => ({
  type: "AutoInvoiceBillerUpdate_started",
});


const AutoInvoiceBillerUpdateSuccess = (data) => ({
  type: "AutoInvoiceBillerUpdate_success",
  payload: {
    ...data,
  },
});

const AutoInvoiceBillerUpdateFailure = (error) => ({
  type: "AutoInvoiceBillerUpdate_failure",
  payload: {
    error,
  }, 
});







// ---------------------------------------------------------------------------------------------------------
// AutoInvoices Deletion
// ---------------------------------------------------------------------------------------------------------

export const AutoInvoiceOriginDelete = (id) => {

  return (dispatch) => {
    dispatch(AutoInvoiceOriginDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAutoInvoiceOrigin/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AutoInvoiceOriginDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auto invoice has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(AutoInvoiceOriginDeletionFailure(err.message));
      });
  };
};

const AutoInvoiceOriginDeletionStarted = () => ({
  type: "AutoInvoiceOriginDeletion_started",
});

const AutoInvoiceOriginDeletionSuccess = (data) => ({
  type: "AutoInvoiceOriginDeletion_success",
  payload: {
    ...data,
  },
});

const AutoInvoiceOriginDeletionFailure = (error) => ({
  type: "AutoInvoiceOriginDeletion_failure",
  payload: {
    error,
  },
});


