import {combineReducers} from 'redux';
import authReducer from './AuthReducer';
import usersReducer from './UsersReducer';
import preDataReducer from './PreDataReducer';
import RolesReducer from './RolesReducer';
import SelectorsReducer from './SelectorsReducer';
import AutosReducer from './AutosReducer';
import LoadingListReducer from './LoadingListsReducer';
import SettingsReducerWarehouse from './SettingsReducers/warehouseReducer';
import SettingsReducerPort from './SettingsReducers/portReducer';
import SettingsReducerCity from './SettingsReducers/cityReducer';
import SettingsReducerAuction from './SettingsReducers/auctionReducer';
import SettingsReducerAutoType from './SettingsReducers/autoTypeReducer';
import SettingsReducerBuyerNumber from './SettingsReducers/buyerNumberReducer';
import SettingsReducerConsignee from './SettingsReducers/consigneeReducer';
import SettingsReducerBillers from './SettingsReducers/BillerReducer';
import NotificationReducer from './NotificationReducer';
import VehicleTrackerReducer from './SettingsReducers/vehicleTrackerReducer';
import SettingsVendorType from './SettingsReducers/vendorTypeReducer';
import SettingsCharges from './SettingsReducers/chargeReducer';
import SettingsVessel from './SettingsReducers/vesselReducer';
import AgreementReducer from './SettingsReducers/AgreementReducer';

import ActivityLog from './LogsReducers/LogsActivityReducer'
import PdfReducer from './PdfReducers/PdfReportReducer';


import AutoInvoiceReducer from './InvoiceReducers/AutoInvoiceReducer';
import ContainerReducer from './ContainerReducer';
import VendorsReducer from './VendorsReducer';
import CustomerRequestReducer from './CustomerRequestReducer';



export default combineReducers({

authenticationHandler: authReducer,
userManagementHandler: usersReducer,
preDataHandler:preDataReducer,
RolesHandler:RolesReducer,
SelectorsHandler:SelectorsReducer,
AutosHandler:AutosReducer,
LoadingListHandler:LoadingListReducer,
SettingsWarehouseHandler:SettingsReducerWarehouse,
SettingsPortHandler:SettingsReducerPort,
SettingsCityHandler:SettingsReducerCity,
SettingsAuctionHandler:SettingsReducerAuction,
SettingsAutoTypeHandler:SettingsReducerAutoType,
SettingsBuyerNumberHandler:SettingsReducerBuyerNumber,
SettingsConsigneeHandler:SettingsReducerConsignee,
SettingsBillerHandler:SettingsReducerBillers,
SettingsVendorTypeHandler:SettingsVendorType,
AutoInvoiceHandler:AutoInvoiceReducer,
ContainerHandler:ContainerReducer,
NotificationHandler:NotificationReducer,
VehicleTrackerHandler:VehicleTrackerReducer,
VendorsHandler:VendorsReducer,
SettingsChargesHandler:SettingsCharges,
SettingsVesselHandler:SettingsVessel,
SettingsAgreementHandler:AgreementReducer,
LogsActivityHandler:ActivityLog,
CustomerRequestHandler:CustomerRequestReducer,
PdfHandler:PdfReducer,


})