import React, { useState, useEffect } from "react";

import { Outlet,NavLink,Link,useLocation,useParams,useMatch, } from 'react-router-dom';
import { matchPath } from 'react-router';
import {connect} from 'react-redux';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import {can} from '../../../utils/roleUtils';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next'
import {downloadImagesZip,getChargesVehicleRows,getAutoAdditionalDetails} from '../../../../redux/actions';
import { calculateTotal } from "../../../utils/chargesUtils";
import SimpleCourousel from "../../../elements/SimpleCourousel";
import moment from 'moment'
import _ from 'lodash';
import {goBack} from '../../../utils/historyUtils'

function AutoInformation(props) {

  const [id, setId] = useState('');
  



  const params = useParams();
  const {t}=useTranslation();


  useEffect(()=>{

    let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
    let decryptValue=_ciphertext.toString(CryptoENC);
    console.log(decryptValue)
   if(decryptValue){
     setId(decryptValue);
   props.getChargesVehicleRows(decryptValue);
   
   props.getAutoAdditionalDetails(decryptValue);
   }
   },[])






const totalCost = calculateTotal(props.getChargesRows?.data, 'cost');
  const totalQuantity = calculateTotal(props.getChargesRows?.data, 'quantity');
  const totalAmount = calculateTotal(props.getChargesRows?.data, 'total');




const autoDetails = props.getAdditionalRegDataSuccess;





  
  return (
    <div>
        

    
            <div className='card card-custom'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-8'>{t('key_auto_details')}</div>
<div className='col-md-4'>        
{/* { autoDetails?.data?.auto_status==="paid"?<div className="status-paid" style={{right:'12px',padding:'18px'}}>PAID</div>:<div className="status-unpaid" style={{right:'12px',padding:'18px'}}>UNPAID</div>} */}
</div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>Auto Name</div>
  <div className='col-md-8 text-uppercase'> {autoDetails?.data?.year} {autoDetails?.data?.make} {autoDetails?.data?.model} </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>VIN</div>
  <div className='col-md-8 text-uppercase'> {autoDetails?.data?.vin} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>LOT</div>
  <div className='col-md-8'>{autoDetails?.data?.lot}</div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_towing_company')}</div>
  <div className='col-md-8 text-uppercase'>{autoDetails?.vendors_data?.vendor_name} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_auction')}</div>
  <div className='col-md-8 text-uppercase'>{autoDetails?.data?.auction} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_buyer_no')}</div>
  <div className='col-md-8 text-uppercase'>{autoDetails?.data?.buyer_no} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_color')}</div>
  <div className='col-md-8 text-uppercase'>{autoDetails?.data?.color} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_keys')}</div>
  <div className='col-md-8 text-uppercase'> {autoDetails?.data?.keys=='true'? 'Yes':'No'} </div>

</div>

          </div>
        </div>  





        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'></div>
</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_weight')}</div>
  <div className='col-md-8'>{autoDetails?.data?.weight}</div>

</div>
<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_cone')}</div>
  <div className='col-md-8 text-uppercase'>  {autoDetails?.data?.cone} </div>

</div>
<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_stock')}</div>
  <div className='col-md-8 text-uppercase'> {autoDetails?.data?.stock} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_pick_up_location')}</div>
  <div className='col-md-8'>{autoDetails?.data?.pick_up_location}</div>

</div>





          </div>
        </div>         





        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>{t('key_more_info')}</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>{t('key_point_of_loading')}</div>
  <div className='col-md-3'> {autoDetails?.data?.point_loading} </div>
  <div className='col-md-3 text-uppercase'> {t('key_city')} </div>
  <div className='col-md-3 '>{autoDetails?.data?.city}  </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>{t('key_destination_port')}</div>
  <div className='col-md-3'> {autoDetails?.data?.port} </div>
  <div className='col-md-3 text-uppercase'> {t('Towing Payment')}  </div>
  <div className='col-md-3 '> {autoDetails?.data?.invoice_customer?'Invoice Customer':'Customer Paid'} </div>

</div>


<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>{t('key_container_no')}</div>
  <div className='col-md-3'> {autoDetails?.data?.container_num_booking} </div>
  <div className='col-md-3 text-uppercase'>  {t('key_auto_type')}  </div>
  <div className='col-md-3 '>{autoDetails?.data?.auto_type}  </div>

</div>


<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>{t('key_date')}</div>
  <div className='col-md-3'> {moment(autoDetails?.data?.created_at).format('YYYY-MM-DD')} </div>
  <div className='col-md-3 text-uppercase'> {t('key_purchase_date')} </div>
  <div className='col-md-3 '> {moment(autoDetails?.data?.purchase_date).format('YYYY-MM-DD')} </div>

</div>

<div className='row main-font-size'>

<div className='col-md-3 text-uppercase'>{t('key_promised_pick_up')}</div>
<div className='col-md-3'> {autoDetails?.data?.promise_pickup} </div>
<div className='col-md-3 text-uppercase'>{t('key_delievered_to_warehouse')} </div>
<div className='col-md-3 '> {autoDetails?.data?.delivered_warehouse} </div>

</div>



<div className='row main-font-size'>

<div className='col-md-3 text-uppercase'>{t('key_arrival_date')}</div>
<div className='col-md-3'> {autoDetails?.data?.arrival_date} </div>
<div className='col-md-3 text-uppercase'>{t('key_unloading_date')} </div>
<div className='col-md-3 '> {autoDetails?.data?.unloading_date} </div>

</div>



          </div>
        </div>         

      

       


        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>{t('key_other_information')}</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_title')}</div>
  <div className='col-md-8'> {autoDetails?.data?.title_status=="true"? 'Yes':'No'} </div>

</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>{t('key_terminal_notes')}</div>
  <div className='col-md-8'>{autoDetails?.data?.terminal_notes}</div>


</div>

<div className='row main-font-size'>

<div className='col-md-2 text-uppercase'>{t('key_notes')} </div>
  <div className='col-md-8'> {autoDetails?.data?.customer_notes} </div>



</div>


          </div>
        </div>         



  


        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>{t('key_auto_charges')}</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-2 text-uppercase'>Charge Name</div>
  <div className='col-md-2 text-uppercase'>Description</div>
  <div className='col-md-2 text-uppercase'>Amount</div>
  <div className='col-md-2 text-uppercase'>Days</div>
  <div className='col-md-2 text-uppercase'>Cost</div>
  <div className='col-md-2 text-uppercase'>Total</div>


</div>
{props.getChargesRows?.data.map((item,index)=>{
  return (
<div className='row main-font-size' key={index}>

<div className='col-md-2 text-uppercase'>{item.charge_name}</div>
<div className='col-md-2 text-uppercase'>{item.charge_description}</div>
<div className='col-md-2 text-uppercase'>{item.amount}</div>
<div className='col-md-2 text-uppercase'>{item.quantity}</div>
<div className='col-md-2 text-uppercase'>{item.cost}</div>
<div className='col-md-2 text-uppercase'>{item.total}</div>


</div>
  )
})

}

<div className='row main-font-size'>

  <div className='col-md-3 text-uppercase'>Total Chares</div>
  <div className='col-md-3 '>{totalAmount}</div>

  <div className='col-md-3 text-uppercase'>Total Cost</div>
  <div className='col-md-3 '>{totalCost}</div>


</div>

          </div>
        </div>         






        <div className='card card-custom mt-2'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-12'>{t('key_files')}</div>
</div>

<div className='row main-font-size'>

  <div className='col-md-4 text-uppercase'>{t('key_invoice')}</div>
  <div className='col-md-8'>


  { _.isNull(autoDetails?.invoice[0]?.file_name)?'':<a style={{textDecoration:'none',color:'yellow'}} href={`${process.env.REACT_APP_FILES_ADRESS}/${autoDetails?.invoice[0]?.file_name}`} target={"_blank"}>{autoDetails?.invoice[0]?.file_name}</a>}


  </div>

</div>







          </div>
        </div>         







   
   



              
    </div>
  )
}




const mapStateToProps = (state) => {

    const {loading, getAdditionalRegDataSuccess,getChargesRows } = state.AutosHandler;

  const { preDataLoadResponse } = state.preDataHandler;

  return {
    loading: loading,
    loading:loading,
    preDataLoadResponse:preDataLoadResponse,
   
    getAdditionalRegDataSuccess:getAdditionalRegDataSuccess,
    getChargesRows:getChargesRows,
 
  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    downloadImagesZip:(id)=>dispatch(downloadImagesZip(id)),
    getChargesVehicleRows:(id)=>dispatch(getChargesVehicleRows(id)),
    getAutoAdditionalDetails:(id)=>dispatch(getAutoAdditionalDetails(id)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(AutoInformation) 