import React,{useEffect} from 'react'
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';

function RequestMessage(props) {
const navigate= useNavigate();
const goHome=()=>{
  navigate('/customer-request')
}

  return (
    <div>
      {props.requestMembershipResponse?
        <div style={{ textAlign: 'center', padding: '60px',color:'white' }}>
        <h1>Great you are two steps away!</h1>

      <h4>Your membership request is in process successfully</h4>
      <p >You will be notified via email in order to agree to the terms and conditions of Alibaba Global Shipping.</p>
      <p style={{color:'yellow'}}>Note: You won't be able to submit membership request on the same email until it is rejected.</p>

      <span role="img" aria-label="Happy face" style={{ fontSize: '2em' }}>
        😀
      </span>
      <div><button className='btn btn-warning' onClick={goHome}>Back Home</button></div>

    </div>:
    <div><button className='btn btn-warning' onClick={goHome}>Back Home</button></div>
    }
    </div>
  )
}


const mapStateToProps = (state) => {

  const { requestMembershipResponse,loading } = state.CustomerRequestHandler;

  return {
loading:loading, 
requestMembershipResponse:requestMembershipResponse,
  };
};
export default connect(mapStateToProps)(RequestMessage) 