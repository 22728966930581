import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing Auction settings model
// -------------------------------------------------------------------------
export const hideAuctionModal=()=>{
    return {
      type:'hideAuctionModal'
    }
    
    }
    export const showAuctionModal=()=>{
  
      return {
        type:'showAuctionModal'
      }
      
      }
  
  
      export const showEditAuctionModal=()=>{
        return {
          type:'showEditAuctionModal'
        }
        
        }
      
        export const hideEditAuctionModal=()=>{
          return {
            type:'hideEditAuctionModal'
          }
        }




// ---------------------------------------------------------------------------------------------------------
// to get all Auctions for settings
// ---------------------------------------------------------------------------------------------------------

export const getAuctions = (currentPage) => {



    return (dispatch) => {
        dispatch(getAuctionStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
    
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/Auctions?page=${currentPage}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              //console.log(response.data);
              dispatch(getAuctionSuccess(response.data,currentPage));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getAuctionFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getAuctionStarted = () => ({
      type: "getAuction_started",
    });
    
    
    const getAuctionSuccess = (data,currentPage) => ({
      type: "getAuctionsSettings_success",
      payload: {
        ...data,'currentPage':currentPage,
    },
    });
    
    const getAuctionFailure = (error) => ({
      type: "getAuction_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerAuction = (name) => {
  
  return (dispatch) => {
    dispatch(registerAuctionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let AuctionData={
     Auction_name:name,
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/AuctionRegister`, AuctionData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(registerAuctionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auction has been succesfully registered',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerAuctionFailure(err.response.data.errors));
     
      });
  };
};

const registerAuctionStarted = () => ({
  type: "registerAuction_started",
});

const registerAuctionSuccess = (data) => ({
  type: "registerAuction_success",
  payload: {
    ...data,
  },
});

const registerAuctionFailure = (error) => ({
  type: "registerAuction_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the Auction edit function will only fetch the data of a single role 
// ---------------------------------------------------------------------------------------------------------

export const AuctionEdit = (id) => {

  return (dispatch) => {
    dispatch(AuctionEditDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editAuction/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(AuctionEditDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(AuctionEditDetailsFailure(err.response.data.errors));
     
      });
  };
};

const AuctionEditDetailsStarted = () => ({
  type: "AuctionEditDetails_started",
});

const AuctionEditDetailsSuccess = (data) => ({
  type: "AuctionEditDetails_success",
  payload: {
    ...data,
  },
});

const AuctionEditDetailsFailure = (error) => ({
  type: "AuctionEditDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the Auction update 
// ---------------------------------------------------------------------------------------------------------

export const AuctionUpdate = (id,name,currentPage) => {
    return (dispatch) => {
      dispatch(AuctionUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        Auction_name:name,
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateAuction/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(AuctionUpdateSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Auction has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(AuctionUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const AuctionUpdateStarted = () => ({
    type: "AuctionUpdate_started",
  });
  
  
  const AuctionUpdateSuccess = (data,currentPage) => ({
    type: "AuctionUpdate_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const AuctionUpdateFailure = (error) => ({
    type: "AuctionUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// Auctions Deletion
// ---------------------------------------------------------------------------------------------------------

export const AuctionDelete = (id,currentPage) => {

  return (dispatch) => {
    dispatch(AuctionDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteAuction/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(AuctionDeletionSuccess(response.data,currentPage));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Auction has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(AuctionDeletionFailure(err.message));
      });
  };
};

const AuctionDeletionStarted = () => ({
  type: "AuctionDeletion_started",
});

const AuctionDeletionSuccess = (data,currentPage) => ({
  type: "AuctionDeletion_success",
  payload: {
    ...data,'currentPage':currentPage,
  },
});

const AuctionDeletionFailure = (error) => ({
  type: "AuctionDeletion_failure",
  payload: {
    error,
  },
});
