import React from 'react'
import noData from '../../assets/noDataFound1.png'
function NoDataError(props) {
  return (
    <>
    <center>
<div className="main-nodata pt-3">
<div className="error-nodata pt-1">
<img
            src={noData}
            style={{ width:props.imageWidth, height:props.imageHeight, paddingRight:props.paddingRight }}
          />

<h1 style={{color:props.headerColor}}>{props.headerText}</h1>

<p style={{color:props.notFoundColor}}>{props.notFoundText}</p>
</div>
</div>
</center>
    </>
  )
}

export default NoDataError