import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {useParams,Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import Pagination from "react-js-pagination";
import NodataFound from '../../error/noDataFoundMain';
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/normLogo.png";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { countryList } from "../../../utils/countryUtils";
import Select from "react-select";
import Swal from "sweetalert2";

import {
  usersList,
  userDelete,
  userEdit,
  hideUpdateModal,
  userUpdate,
  inputHandler,
  searchUsersList,
  rolePicker,
  addDetails,
  toggleCompanyModal,
  perUserVehicles,
  searchAutosList,
  perUserConsignees,
  searchConsigneeList,
  toggleConsigneeModal,
  registerconsignee,
  toggleConsigneeEditModal,
  consigneeEdit,
  consigneeUpdate,
  consigneeDelete,
} from "../../../../redux/actions";

function UserConsignees(props) {

  const [id, setId] = useState('');
  const [searched, setSearched] = useState(false);
  const [consigneeData,setconsigneeData] = useState({onId:'',company_name:'',contact_name:'',email:'',secondary_email:'',phone:'', secondary_phone:'',address:'',line_2:'',city:'',state:'',zip_code:'',country:null });
  const [value, onChange] = useState([]);
  const [editValue, setEditValue] = useState([]);
  const params = useParams();
  const {t}=useTranslation();



  

useEffect(()=>{
  let _ciphertext= CryptoAES.decrypt(params.id, 'autoListAli_123');
  let decryptValue=_ciphertext.toString(CryptoENC);
 if(decryptValue){
   setId(decryptValue);
props.perUserConsignees(decryptValue,props.currentPage) }
 },[props.registerconsigneeSuccess,props.updateSuccess,props.deleteSuccess])
 



 useEffect(()=>{
  let data=props.editDetails?.data
setconsigneeData({...consigneeData,
  'onId':data?.id||'',
  'company_name':data?.company_name||'',
  'contact_name':data?.contact_name||'',
  'email':data?.email||'',
  'secondary_email':data?.secondary_email||'',
  'phone':data?.phone||'',
  'secondary_phone':data?.secondary_phone||'',
  'address':data?.address||'',
  'line_2':data?.line_2||'',
  'city':data?.city||'',
  'state':data?.state||'',
  'zip_code':data?.zip_code||'',
  'country':data?.country?{value:data?.country,label:data?.country?.replace(/\b\w/g, (char) => char.toUpperCase())}:null,

})
// setEditValue({label:props.editDetails?.data?.customerName, value:props.editDetails?.data?.userId})
},[props.editDetails])









 const onSearchInputChange=(e)=>{

  if(e.target.value=='' && searched ){
    props.perUserConsignees(id,props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}


const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchConsignee){
  setSearched(true);
    props.searchConsigneeList(id,props.searchConsignee,props?.currentPage)
    }else{
      setSearched(false);
     
  
    }
  
  }


  const handleSearchPages=(pageNumber)=>{
    props.searchConsigneeList(id,props.searchConsignee,pageNumber)
  
  }


  const onInputchange = (e) => {
    // props.inputHandler({ key: e.target.name, value: e.target.value });
    setconsigneeData({ ...consigneeData, [e.target.name]: e.target.value });
  };



  let countryOptions=countryList;



  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
      baseUnit: 4,
    },
  });
  
  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  
  
  
  const handleChangeOptionCompany = (selected) => {
    setconsigneeData({ ...consigneeData, country: selected });
  };
  



  const submitconsignee = (e) => {
    e.preventDefault();
  
      props.registerconsignee(id,consigneeData)
    
  }
  
  

  const Updateconsignee = (e) => {
    e.preventDefault();
  
    let currentPage=props.perUserList?.data?.current_page;
  
    props.consigneeUpdate(id,currentPage,consigneeData)
  
   
  
    
  }
  
  
  const handleClose = () => {
  
    props.toggleConsigneeModal()

  };

  const editHandleClose = ()=>{
    props.toggleConsigneeEditModal();
  }
  




  const deleteconsignee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this consignee",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.consigneeDelete(id, props.currentPage);
      }
    });
  };
  


  return (
    <div>
        <div className='vehicle-card'>
          <div className='card-body'>
          <div className='row mb-2'>
<div className='col-md-4 text-uppercase'>{props.companyEditInfo?.name}  consignees </div>
<div className='col-md-4 text-uppercase'>
  <button className="addConsigneeButton" style={{background:'black',color:'white',padding:'6px'}} onClick={
  ()=>{
    props.toggleConsigneeModal();
  }
}>
          Add Consignee
        </button> </div>


</div>
</div>
  </div>




<div className="gap-vehicle"></div>
<div className="scroller layer-outer-vehicle mt-2">

{ 
  searched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchConsignee}</span></p></div>


}
{props.perUserList?.data?.data.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}


  {props.perUserList?.data?.data.map((item,index)=>{
    return(
      <div class="card card-custom mt-3" key={index}>
 <div className="delete-btn-container">
        <button  onClick={()=>deleteconsignee(item.id)} className="delete-btton">
          Delete
                  </button>
                  </div>
         <button className="edit-button" onClick={
          ()=>{
            props.consigneeEdit(item.id);
            setTimeout(() => {
              props.toggleConsigneeEditModal();

            },500);

          }
         }>
          Edit
        </button>
       

      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="card-body">
            <div className="row">

              <div className="col-md-6">
              <span className="label-car">Company Name:</span>  <span className="title-car">{item.company_name}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Contact Name:</span> <span className="title-car">{item?.contact_name}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Email:</span> <span className="title-car">{item?.email}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Secondary Email:</span> <span className="title-car">{item?.secondary_email}</span>
              </div>

              <div className="col-md-6">
              <span className="label-car">Phone:</span> <span className="title-car">{item?.phone}</span>
              </div>
              <div className="col-md-6">
              <span className="label-car">Secondary Phone:</span> <span className="title-car">{item?.secondary_phone}</span>
              </div>
              
              <div className="col-md-12">
              <span className="label-car">Address:</span> <span className="title-car">{item?.address}{item.line_2?', ':null}{item?.line_2} {item.state?', ':null}{item?.state}{item.city?', ':null}{item?.city}{item.zip_code?', ':null}{item?.zip_code}{item.country?', ':null}{item?.country}</span>
              </div>
            
             
              
            </div>




          </div>
        </div>
      </div>


    </div>
    )
  })}
 


 <div className="floating-search">
<form onSubmit={onSearch}>
      <div className="input-group">
  <div className="form-outline">
    <input type="search" name="searchConsignee" autoComplete="off" className="form-control" required
    onChange={(e)=>{onSearchInputChange(e)}}
     />
  </div>
  <button type="submit"  className="btn btn-primary">
    <i className="fas fa-search"></i>
  </button>
</div>
</form>
      </div>

</div>


{ props.perUserList?.data?.data.length===0 ? '':

<div>
      {props.perUserList?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.perUserList?.data.current_page}
              itemsCountPerPage={props.perUserList?.data.per_page}
              totalItemsCount={props.perUserList?.data.total}
              //   pageRangeDisplayed={5}
              onChange={ searched==true? handleSearchPages:
               (pageNumber) => props.perUserVehicles(id,pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}




{/* ======================
Add consignee Modal
====================== */}
      <Modal show={props.showConsigneeModal} onHide={handleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add New consignee </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={submitconsignee}>
          <Modal.Body 
           style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          >
            <div className="row">

           

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={consigneeData?.company_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.consigneeRegisterErrors?.error?.company_name?true:false}
                  />

                </FloatingLabel>

                {props.consigneeRegisterErrors?.error?.company_name ? (
                  <div className="validation-error">
                    The consignee name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>





              <div className="col-md-12 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={consigneeData?.contact_name}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>




              <div className="col-md-6 mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_email"
                    value={consigneeData?.secondary_email}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData?.phone}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_phone"
                    value={consigneeData?.secondary_phone}
                    
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={consigneeData?.line_2}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={consigneeData?.state}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={consigneeData?.city}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={consigneeData?.zip_code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={consigneeData.country}
                  defaultValue={consigneeData.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {/* {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''} */}
              </div>



            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>













{/* ======================
edit consignee
====================== */}
      <Modal show={props.showConsigneeEditModal} onHide={editHandleClose} size="xl" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Consignee Details </Modal.Title>
          <CloseButton variant={"white"} onClick={editHandleClose}></CloseButton>
        </Modal.Header> 

        <form onSubmit={Updateconsignee}>
          <Modal.Body 
           style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
          >
            <div className="row">

           

              <div className="col-md-12 mb-3">
                <FloatingLabel label="Company Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="company_name"
                    value={consigneeData?.company_name}
                    required
                    onChange={(e) => onInputchange(e)}
                    isInvalid={props.consigneeRegisterErrors?.error?.company_name?true:false}
                  />

                </FloatingLabel>

                {props.consigneeRegisterErrors?.error?.company_name ? (
                  <div className="validation-error">
                    The consignee name already exists !
                  </div>
                ) : (
                  ""
                )}

              </div>





              <div className="col-md-12 mb-3">
                <FloatingLabel label="Contact Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact_name"
                    value={consigneeData?.contact_name}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>




              <div className="col-md-6 mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={consigneeData?.email}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Email">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_email"
                    value={consigneeData?.secondary_email}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="phone"
                    value={consigneeData?.phone}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>


              <div className="col-md-6 mb-3">
                <FloatingLabel label="Secondary Phone">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="secondary_phone"
                    value={consigneeData?.secondary_phone}
                    
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Address">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="address"
                    value={consigneeData?.address}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-6 mb-3">
                <FloatingLabel label="Line 2">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="line_2"
                    value={consigneeData?.line_2}
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <FloatingLabel label="State">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="state"
                    value={consigneeData?.state}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="City">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="city"
                    value={consigneeData?.city}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>

              <div className="col-md-3 mb-3">
                <FloatingLabel label="Zip Code">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="zip_code"
                    value={consigneeData?.zip_code}
                    required
                    onChange={(e) => onInputchange(e)}
                  />

                </FloatingLabel>

              </div>



              <div className="col-md-3 mb-3">
                <Select
                  options={countryOptions}
                  theme={theme}
                  placeholder="Country"
                  name="country"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  setValue={consigneeData.country}
                  defaultValue={consigneeData.country}
                  onChange={(e) => handleChangeOptionCompany(e)}
                  isClearable={true}
                />

         {/* {countryFieldError?<div className="validation-error">{countryFieldError} !</div>:''} */}
              </div>



            </div>
   
            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>








    </div>
  )
}




const mapStateToProps = (state) => {
  const {
    loading,
    usersListResponse,
    userDeletionResponse,
    currentPage,
    userEditResponse,
    showModal,
    userUpdateErrors,
    userUpdateResponse,
    name,
    username,
    email,
    password,
    confirm_password,
    searchUsers,
    showCompanyModal,
    companyEditInfo,
    rolePicker,
    userBasedVehicles,
    searchAutos,
    perUserList,
    searchConsignee,
    showConsigneeModal,
    registerconsigneeSuccess,
    showConsigneeEditModal,
    editDetails,
    updateSuccess,
    deleteSuccess,
  } = state.userManagementHandler;
  const { userRegistrationResponse } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
    rolePickerData:rolePicker,
    loading: loading,
    showCompanyModal:showCompanyModal,
    usersListResponse: usersListResponse,
    userRegistrationResponse: userRegistrationResponse,
    userDeletionResponse: userDeletionResponse,
    userUpdateResponse: userUpdateResponse,
    currentPage: currentPage,
    userEditResponse: userEditResponse.data,
    userUpdateErrors: userUpdateErrors,
    showModal: showModal,
    preDataLoadResponse: preDataLoadResponse,
    name: name,
    searchAutos:searchAutos,
    username: username,
    email: email,
    password: password,
    confirm_password: confirm_password,
    searchUsers: searchUsers,
    companyEditInfo:companyEditInfo?.data,
    userBasedVehicles:userBasedVehicles,
    perUserList:perUserList,
    searchConsignee:searchConsignee,
    showConsigneeModal:showConsigneeModal,
    showConsigneeEditModal:showConsigneeEditModal,
    registerconsigneeSuccess:registerconsigneeSuccess,
    editDetails:editDetails,
    updateSuccess:updateSuccess,
    deleteSuccess:deleteSuccess,

  };
};






const mapDispatchToProps = (dispatch) => {
  return {

    userDelete: (id, currentPage) => dispatch(userDelete(id, currentPage)),

    userEdit: (id) => dispatch(userEdit(id)),

    hideUpdateModal: () => dispatch(hideUpdateModal()),

    userUpdate: (
      id,
      currentPage,
      name,
      username,
      email,
      contact,
      role,
      password,
      confirmPassword
    ) =>
      dispatch(
        userUpdate(
          id,
          currentPage,
          name,
          username,
          email,
          contact,
          role,
          password,
          confirmPassword
        )
      ),

    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchUsersList:(querry,currentPage)=>dispatch(searchUsersList(querry,currentPage)),
    rolePicker:()=>dispatch(rolePicker()),
    addDetails:(inputData)=>dispatch(addDetails(inputData)),
    toggleCompanyModal:()=>dispatch(toggleCompanyModal()),
    perUserVehicles:(id,pageNumber)=>dispatch(perUserVehicles(id,pageNumber)),
    searchAutosList:(querry,currentPage)=>dispatch(searchAutosList(querry,currentPage)),
    perUserConsignees:(id,pageNumber)=>dispatch(perUserConsignees(id,pageNumber)),
    searchConsigneeList:(id,q,pageNumber)=>dispatch(searchConsigneeList(id,q,pageNumber)),
    toggleConsigneeModal:()=>dispatch(toggleConsigneeModal()),
    registerconsignee:(value,data)=>dispatch(registerconsignee(value,data)),
    toggleConsigneeEditModal:()=>dispatch(toggleConsigneeEditModal()),
    consigneeEdit:(id)=>dispatch(consigneeEdit(id)),
    consigneeUpdate:(usersId,currentPage,data)=>dispatch(consigneeUpdate(usersId,currentPage,data)),
    consigneeDelete:(id,currentPage)=>dispatch(consigneeDelete(id,currentPage)),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(UserConsignees) 