import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {NavLink,Link,useLocation,useParams,useMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next'

import "../../../../../css/pagination.css";
import Swal from "sweetalert2";

import {
  getAgreementMainPage
} from "../../../../../redux/actions";

function TermsAndConditions(props) {

  const [id, setId] = useState('');
  


const [countryFieldError, setCountryFieldError] = useState(null)

  const params = useParams();
  const {t}=useTranslation();




useEffect(()=>{


 props.getAgreementMainPage();

 
 },[])



  
  return (
    <div>
   

{props.termConditions?.data?.map((item,index)=>{
  return (
    <div key={index}>
     <div className='card card-custom mt-3'>
          <div className='card-body'>
          <div className='row mb-2'>
          <div className='col-md-12 text-uppercase'>{item.title}</div>
         </div>


<div className='row main-font-size'>

<div className='col-md-12' style={{textAlign:'justify'}}>
  {item.body}
</div>

</div>


          </div>
        </div>

    </div>
  )
})}

    

              
    </div>
  )
}




const mapStateToProps = (state) => {
  const {loading,termConditions} = state.SettingsAgreementHandler;

  const { userRegistrationResponse, } = state.authenticationHandler;
  const { preDataLoadResponse } = state.preDataHandler;

  return {
loading:loading, 
termConditions:termConditions

  };
};






const mapDispatchToProps = (dispatch) => {
  return {


    getAgreementMainPage:()=>dispatch(getAgreementMainPage()),

  };
};



export default connect(mapStateToProps,mapDispatchToProps)(TermsAndConditions) 